import axios from 'axios';
import config from "./../../config/config.json"
import { ethers } from 'ethers';

import IERC20 from "./ABIs/IERC20.json";

export const registerTxHash = async (txhash, chain, token, ls) => {
    try {
        console.log('IM IN registerTxHash FUNC');
        const endpoint = `${config.tokens[token][chain].apiEndpoint}/${chain}/${txhash}`;
        console.log("Endpoint: "+endpoint);
        const data = await axios.get(endpoint);
        console.log("data: "+data);
        if (data.success == false) {
            return false;
        } else {
            var cacheData = await ls.getItem("claim");
            if (cacheData != null)
                cacheData = JSON.parse(cacheData);
            else
                cacheData = [];
            for(var i = 0; i < data.data.data.length; i++) {
                cacheData.push(data.data.data[i]);
            }
            await ls.setItem("claim", JSON.stringify(cacheData));
            return true;
        }
    } catch (e) {
        console.log(e)
        return false;
    }
}

export const getBridgeSignature = async (txhash, chain, token) => {
    try {
        console.log('IM IN registerTxHash FUNC');
        const endpoint = `${config.tokens[token][chain].apiEndpoint}/${chain}/${txhash}`;
        console.log("Endpoint: "+endpoint);
        const data = await axios.get(endpoint);
        console.log("data: "+data);
        if (data.success == false) {
            return false;
        } else {
            return data.data.data[0];
        }
    } catch (e) {
        console.log(e)
        return false;
    }
}

export const getExplorerLatestTransactions = async (address, chain) => {
    const URL = `${config.networks[chain].explorerApi}?module=account&action=txlist&address=${address}&startBlock=0&endBlock=999999999&page=1&sort=desc`;

    const data = await axios.get(URL);
    if (data.success == false) {
        return false;
    }

    var retData = data.data.result;

    return retData;
}

export const scanForUnclaimedTokens = async (address, chain, token, ls) => {
    try {
        var foundSome = false;
        var provider = new ethers.providers.JsonRpcProvider(config.networks[chain].endpoint);
        var latestRecordsToSearch = 10;
        var searched = 0;
        var transactions = await getExplorerLatestTransactions(address, chain, latestRecordsToSearch);
        if (transactions == false) {
            return false;
        }

        for(var i = 0; i < transactions.length; i++) {
            var tx = transactions[i];
            if (tx.from.toLowerCase() != address.toLowerCase() || tx.isError == true) {
                continue;
            }
            console.log(`Checking ${tx.hash}`)
            searched++;
            // Check if OK
            var registered = await registerTxHash(tx.hash, chain, token, ls);
            if (registered) {
                foundSome = true;
            }
            if (searched == latestRecordsToSearch)
                break;
        }

        return foundSome;
    } catch(e) {
        console.log(e);
        return false;
    }
}

export const getUserRadarBalance = async (addr, network) => {
    try {
        var iff = new ethers.utils.Interface(
            JSON.parse(JSON.stringify(IERC20.abi))
        );

        var provider = new ethers.providers.JsonRpcProvider(config.networks[network].endpoint);

        var contract = new ethers.Contract(
            config.tokens.RADAR[network].address,
            iff,
            provider
        );

        var balance = await contract.balanceOf(addr);
        balance /= 10**18;

        return balance;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

export const getRadarPrice = async () => {
    try {
        var data = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=radar&vs_currencies=usd`);
        var price = data.data["radar"].usd;
        return price;
    } catch(e) {
        return 0;
    }
}

export const getRadar24hChange = async () => {
    try {
        var data = await axios.get(`https://api.coingecko.com/api/v3/coins/radar?tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false`);
        return data.data.market_data.price_change_percentage_24h;
    } catch(e) {
        console.log(e);
        return 0;
    }
}