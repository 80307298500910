import React, { Component } from "react";
import { connect } from "react-redux";
import { checkWallet } from "../../redux/actions/OnboardActions";
import "../../utils/ethereum/onboard";

import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import Sidebar from "../../layouts/sidebar/Sidebar";
import BridgeTransferFunds from "./sub-components/transfer-funds/BridgeTransferFunds";
import BridgeFindTransaction from "./sub-components/find-transaction/BridgeFindTransaction";

import WalletNotConnectedOverlay from "../../components/overlays/wallet-not-connected/WalletNotConnectedOverlay";

import styles from "../../styles/index.module.css";
import "./bridgePage.css";

class BridgePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",

      selectedAction: "transferFunds",

      unclaimedTransactions: 0
    };

    this.loadUnclaimedTransactions = this.loadUnclaimedTransactions.bind(this);
  }

  componentDidMount = async () => {
    await this.loadUnclaimedTransactions();
  };

  loadUnclaimedTransactions = async () => {
    try {
      await this.setState({
        unclaimedTransactions: JSON.parse(window.localStorage.getItem("claim")).length
      })
    } catch(e) {
      await this.setState({unclaimedTransactions: 0});
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
    }
  }

  renderTransferFunds() {
    return (
      <>
        <div className={`${"bridge-input-menu"} ${styles.greyBg}`}>
          <div className={`${"bridge-input-menu-item"} ${styles.whiteBg}`}>
            <div className={`${styles.labelMBold} ${styles.gradientText}`}>
              Transfer Funds
            </div>
          </div>
          <div
            className={`${styles.inputMenuItem} ${styles.greyBg} ${styles.labelMBold} ${styles.primaryText}`}
            onClick={() =>
              this.setState({
                selectedAction: "findTransaction",
              })
            }
          >
            Find Transactions
            {this.state.unclaimedTransactions > 0 && <div className="bridge-input-menu-item-pink-badge">
              <div className={`${styles.labelMBold} ${styles.gradientText}`}>
                {this.state.unclaimedTransactions}
              </div>
            </div>}
          </div>
        </div>
        <BridgeTransferFunds {...this.props} refreshBubble={this.loadUnclaimedTransactions} />
      </>
    );
  }

  renderFindTransaction() {
    return (
      <>
        <div className={`${"bridge-input-menu"} ${styles.greyBg}`}>
          <div
            className={`${styles.inputMenuItem} ${styles.greyBg} ${styles.labelMBold} ${styles.primaryText}`}
            onClick={() =>
              this.setState({
                selectedAction: "transferFunds",
              })
            }
          >
            Transfer Funds
          </div>
          <div className={`${"bridge-input-menu-item"} ${styles.whiteBg}`}>
            <div className={`${styles.labelMBold} ${styles.gradientText}`}>
              Find Transactions
            </div>
            {this.state.unclaimedTransactions > 0 && <div className="bridge-input-menu-item-pink-badge">
              <div className={`${styles.labelMBold} ${styles.gradientText}`}>
                {this.state.unclaimedTransactions}
              </div>
            </div>}
          </div>
        </div>
        <BridgeFindTransaction {...this.props} refreshBubble={this.loadUnclaimedTransactions} />
      </>
    );
  }

  render() {
    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };
    return (
      <>
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Sidebar {...this.props} state={this.state} />
          {this.props.onboard.walletConnected === false && (
            <WalletNotConnectedOverlay {...this.props} />
          )}
          <div
            className={styles.wrapper}
            style={
              this.props.onboard.walletConnected === false ? displayBlur : {}
            }
          >
            <div className={styles.content}>
              <div className={styles.headerSection}>
                <div className={`${styles.headingM} ${styles.primaryText}`}>
                  Bridge
                </div>
              </div>
              {this.state.selectedAction === "transferFunds" && (
                <>{this.renderTransferFunds()}</>
              )}
              {this.state.selectedAction === "findTransaction" && (
                <>{this.renderFindTransaction()}</>
              )}
            </div>
          </div>
          <Footer {...this.props} state={this.state} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  checkWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(BridgePage);
