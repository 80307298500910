import React, { Component } from "react";
import { connect } from "react-redux";
import { manualChangeNetwork } from "../../../redux/actions/OnboardActions";
import config from "../../../config/config.json";

import styles from "../../../styles/index.module.css";
import "./switchNetworkOverlay.css";

class SwitchNetworkOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",

      primaryText: this.props.primaryTextFromParent,
      secondaryText: this.props.secondaryTextFromParent,

      blockchains: this.props.blockchainsFromParent,
    };
  }

  blockchainButtonClick = async (e, network) => {
    e.preventDefault();
    await this.props.manualChangeNetwork(config.networks[network].networkId);
  };

  getBlockchainIcon = (type) => {
    const icon = require(`../../../assets/SVGs/${type}`).default;
    return icon;
  };

  render() {
    return (
      <div className={styles.popupWrapper}>
        <div className="wnc-text-section">
          <div className={`${styles.headingL} ${styles.primaryText}`}>
            {this.state.primaryText}
          </div>
          <div
            className={`${styles.paragraphM} ${
              styles.secondaryText
            } ${"sn-secondary-text"}`}
            style={{ marginTop: "16px" }}
          >
            {this.state.secondaryText}
          </div>
          {Object.keys(this.state.blockchains).map((i) => (
            <div
              className={`${styles.button} ${
                styles.whiteBg
              } ${"sn-switch-button"}`}
              onClick={(e) =>
                this.blockchainButtonClick(e, this.state.blockchains[i])
              }
            >
              <img
                src={this.getBlockchainIcon(
                  config.networks[this.state.blockchains[i]].icon
                )}
                alt="blockchain-icon"
                className="sn-blockchain-icon"
              />
              <div className={`${styles.labelMRegular} ${styles.primaryText}`}>
                {config.networks[this.state.blockchains[i]].name}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  manualChangeNetwork,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchNetworkOverlay);
