import { ethers } from 'ethers';
import RadarBond from './abis/RadarBond.json';
import RadarStaking from './abis/RadarStaking.json';
import RadarSingleAssetBond from './abis/RadarSingleAssetBond.json';

export const RadarBondInterface = new ethers.utils.Interface(
    JSON.parse(JSON.stringify(RadarBond.abi))
);

export const RadarStakingInterface = new ethers.utils.Interface(
    JSON.parse(JSON.stringify(RadarStaking.abi))
);

export const RadarSingleAssetBondInterface = new ethers.utils.Interface(
    JSON.parse((JSON.stringify(RadarSingleAssetBond.abi)))
);