import StabilizerABI from "./abis/Stabilizer.json";
import IERC20Abi from "./abis/IERC20.json";
import { ethers } from "ethers";
import { getOraclePrice } from "../../../web3/usdr/api";

export const getFees = async (
    rpc,
    address
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(StabilizerABI.abi))
    );
    const stabilizer = new ethers.Contract(
        address,
        iff,
        provider
    );

    const MINT_FEE = await stabilizer.MINT_FEE();
    const BURN_FEE = await stabilizer.BURN_FEE();

    return {
        mint: MINT_FEE / 10000,
        burn: BURN_FEE / 10000
    }
}

export const getTokenData = async (
    tokenAddress,
    rpc,
    oracleAddress,
    userAddress,
    stabilizerAddress
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(StabilizerABI.abi))
    );
    const stabilizer = new ethers.Contract(
        stabilizerAddress,
        iff,
        provider
    );
    const iercInterface = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(IERC20Abi.abi))
    )

    var price = await getOraclePrice(rpc, oracleAddress, tokenAddress);
    var available = await stabilizer.availableForBurning(tokenAddress);
    var tokenCont = new ethers.Contract(
        tokenAddress,
        iercInterface,
        provider
    );

    var decimals = await tokenCont.decimals();
    var userBal = await tokenCont.balanceOf(userAddress);

    available = (available.div(10**4)) / 10**(decimals-4)
    userBal = (userBal.div(10**4)) / 10**(decimals-4)

    return {
        price,
        available,
        decimals,
        userBal
    }
}

export const getTokensInfo = async (
    tokens,
    rpc,
    oracleAddress,
    userAddress,
    stabilizerAddress
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(StabilizerABI.abi))
    );
    const stabilizer = new ethers.Contract(
        stabilizerAddress,
        iff,
        provider
    );
    const iercInterface = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(IERC20Abi.abi))
    )

    var tokenData = {};
    await Object.keys(tokens).map(async (i) => {
        var price = await getOraclePrice(rpc, oracleAddress, tokens[i].address);
        var available = await stabilizer.availableForBurning(tokens[i].address);
        var tokenCont = new ethers.Contract(
            tokens[i].address,
            iercInterface,
            provider
        );

        var decimals = await tokenCont.decimals();
        var userBal = await tokenCont.balanceOf(userAddress);

        tokenData[i] = {
            price,
            available,
            decimals,
            userBal
        }
    });

    return tokenData;
}

export const mintUSDR = async (
    signer,
    stabilizerAddress,
    token,
    amount
) => {
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(StabilizerABI.abi))
    );
    const stabilizer = new ethers.Contract(
        stabilizerAddress,
        iff,
        signer
    );

    const tx = await stabilizer.mint(token, amount);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const burnUSDR = async (
    signer,stabilizerAddress,
    token,
    amount,
    permitData
) => {
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(StabilizerABI.abi))
    );
    const stabilizer = new ethers.Contract(
        stabilizerAddress,
        iff,
        signer
    );

    const tx = await stabilizer.burn(token, amount, permitData);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}