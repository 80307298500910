import React, { Component } from "react";

import config from "../../config/config.json";
import radarVerticalLogo from "../../assets/logos/radar-vertical.svg";
import menuSelectorIcon from "../../assets/SVGs/sidebar/menu-selector.svg";
import tooltipIcon from "../../assets/SVGs/sidebar/tooltip.svg";
import styles from "../../styles/index.module.css";
import "./sidebar.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarHoverComponent: false,
      selectedItemForHover: "",
    };

    this.toPage = this.toPage.bind(this);
  }

  toPage = (path) => {
    this.props.history.push(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  getSidebarItemIcon = (type) => {
    const icon = require(`../../assets/SVGs/sidebar/${type}`).default;
    return icon;
  };

  handleSidebarItemIconHover = (i) => {
    this.setState({
      selectedItemForHover: i,
      sidebarHoverComponent: true,
    });
  };

  handleSidebarItemIconLeave = () => {
    this.setState({
      selectedItemForHover: "",
      sidebarHoverComponent: false,
    });
  };

  getFirstParamOfPath = () => {
    const curentPath = window.location.pathname;
    const currentPoolAddress = curentPath.split("/")[1];
    let str = "/";
    return str.concat(currentPoolAddress);;
  };

  render() {
    const doNotDisplay = {
      display: "none",
    };

    return (
      <>
        {window.innerWidth > 1299 ? (
          <div className="sidebar-wrapper">
            <div className={`${styles.whiteBg} ${"sidebar"}`}>
              <div className="sidebar-content">
                <div className="sidebar-logo-section">
                  <img
                    onClick={() => this.toPage("/")}
                    src={radarVerticalLogo}
                    alt="radar-logo"
                    className="sidebar-logo"
                  />
                </div>
                <div className="sidebar-menu">
                  {Object.keys(config.sidebar).map((i) => (
                    <div className="sidebar-item">
                      <div
                        className="sidebar-item-icon-section"
                        onClick={() => this.toPage(config.sidebar[i].route)}
                        onMouseOver={() => this.handleSidebarItemIconHover(i)}
                        onMouseLeave={this.handleSidebarItemIconLeave}
                      >
                        <img
                          src={
                            i === this.state.selectedItemForHover
                              ? this.getSidebarItemIcon(
                                  config.sidebar[i].hoverIcon
                                )
                              : this.getSidebarItemIcon(config.sidebar[i].icon)
                          }
                          alt="icon"
                        />
                      </div>
                      {(config.sidebar[i].route === this.props.location.pathname ||
                        config.sidebar[i].route === this.getFirstParamOfPath()) 
                        && 
                      (
                        <div className="sidebar-menu-selector-section">
                          <img
                            src={menuSelectorIcon}
                            alt="menu-selector-icon"
                            className="sidebar-menu-selector"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {this.state.sidebarHoverComponent === true && (
              <div className="sidebar-hover-section">
                <div className="sidebar-hover-menu">
                  {Object.keys(config.sidebar).map((i) => (
                    <div className="sidebar-hover-menu-row">
                      <div
                        className="sidebar-hover-menu-item-section"
                        style={
                          i !== this.state.selectedItemForHover
                            ? doNotDisplay
                            : {}
                        }
                      >
                        <img
                          src={tooltipIcon}
                          alt="tooltip-icon"
                          className="sidebar-hover-menu-tooltip-icon"
                        />
                        <div
                          className={`${styles.whiteBg} ${
                            styles.elevationLow
                          } ${"sidebar-hover-menu-item"}`}
                        >
                          <div
                            className={`${
                              styles.labelMBold
                            } ${"sidebar-hover-menu-item-text"}`}
                          >
                            {config.sidebar[i].text}
                          </div>
                          {config.sidebar[i].bullet === "yes" && (
                            <div className="sidebar-hover-menu-item-bullet">
                              <div
                                className={`${styles.labelSBold} ${styles.gradientText}`}
                              >
                                USDR
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </>
    );
  }
}

export default Sidebar;
