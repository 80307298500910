import { toastr } from "react-redux-toastr";
import { ethers } from "ethers";
import { onboard } from "../../utils/ethereum/onboard";
import config from "./../../config/config.json";

export const ACCOUNT_CHANGE = "WALLET_CHANGE_SUBSCRIPTION";
export const ONBOARD_CREATED = "ONBOARD_CREATED";
export const ONBOARD_UPDATED = "ONBOARD_UPDATED";
export const ACCOUNT_DISCONNECT = "ONBOARD_DESTORYED";
export const ONBOARD_ADDRESS_CHANGE = "ONBOARD_ADDRESS_UPDATE";
export const ONBOARD_BALANCE_UPDATE = "ONBOARD_BALANCE_UPDATE";
export const ONBOARD_NETWORK_CHANGE = "ONBOARD_NETWORK_CHANGE";

export const walletChange = (newWallet) => {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_CHANGE,
      payload: newWallet,
    });
  };
};

export const balanceChange = (newBalance) => {
  return async (dispatch) => {
    dispatch({
      type: ONBOARD_BALANCE_UPDATE,
      payload: newBalance,
    });
  };
};

export const addressChange = (newAddress) => {
  return async (dispatch) => {
    dispatch({
      type: ONBOARD_ADDRESS_CHANGE,
      payload: newAddress,
    });
  };
};

export const onboardUpdated = () => {
  return async (dispatch) => {
    const check = await onboard.checkWallet();
    if (check) {
      var statee = await onboard.getState();
      dispatch({
        type: ONBOARD_UPDATED,
        payload: {
          onboard,
          provider: statee.wallet.provider,
          address: statee.address,
          balance: statee.balance,
          networkId: statee.network,
        },
      });
    } else {
      disconnectAccountOnboard();
    }
  };
};

export const walletPersistance = () => {
  return async (dispatch) => {
    try {
      const persistedWallet = window.localStorage.getItem('selectedWallet');
      if (persistedWallet != null && typeof(persistedWallet) != "undefined" && persistedWallet != "undefined") {
        await onboard.walletSelect(persistedWallet);
        var check = await onboard.walletCheck();
        if (!check) {
          window.localStorage.removeItem('selectedWallet');
          return;
        }
        var statee = await onboard.getState();
        dispatch({
          type: ONBOARD_CREATED,
          payload: {
            onboard,
            provider: statee.wallet.provider,
            address: statee.address,
            balance: statee.balance,
            networkId: statee.network,
          },
        });
      }
    } catch(e) {

    }
  }
}

export const connectAccountOnboard = () => {
  return async (dispatch) => {
    try {
      // const onboard = initOnboard();
      await onboard.walletReset();
      const hasConnected = await onboard.walletSelect();
      if (hasConnected) {
        var check = await onboard.walletCheck();
        if (!check) {
          window.localStorage.removeItem('selectedWallet');
          return;
        }
        const cs = window.localStorage.getItem("slippage");
        if(typeof(cs) == "undefined" || cs == "undefined" || cs == null) {
          window.localStorage.setItem("slippage", "0.0200");
        }
        var statee = await onboard.getState();
        dispatch({
          type: ONBOARD_CREATED,
          payload: {
            onboard,
            provider: statee.wallet.provider,
            address: statee.address,
            balance: statee.balance,
            networkId: statee.network,
          },
        });
      }
      // toastr.success("Successfully connect to your account");
    } catch (e) {
      toastr.error("Error connecting: ", e.message);
    }
  };
};

export const disconnectAccountOnboard = () => {
  try {
    const data = async (dispatch) => {
      await onboard.walletReset();
      dispatch({
        type: ACCOUNT_DISCONNECT,
      });
    };

    // toastr.success("Successfully disconnect to your account");

    return data;
  } catch (e) {
    // toastr.error("Error disconnecting: ", e.message);
  }
};

export const checkWallet = () => {
  return async (dispatch) => {
    const check = await onboard.walletCheck();
    if (check) {
      var statee = await onboard.getState();
      dispatch({
        type: ONBOARD_UPDATED,
        payload: {
          onboard,
          provider: statee.wallet.provider,
          address: statee.address,
          balance: statee.balance,
          networkId: statee.network,
        },
      });
    }
  };
};

export const manualChangeNetwork = (network) => {
  return async (dispatch) => {
    const cState = await onboard.getState();
    console.log("Onboard provider: ")
    console.log(cState.wallet.provider)
    const provider = new ethers.providers.Web3Provider(cState.wallet.provider);
    try {
      await provider.send("wallet_switchEthereumChain", [{chainId: `0x${network.toString(16)}`}])
      await onboard.config({networkId: network});
    } catch(e) {
      if (e.code == 4902) {
        var specificNetId = null;
        const networks = Object.keys(config.networks);
        for (var i = 0; i < networks.length; i++) {
          var currentNet = networks[i];
          if (config.networks[currentNet].networkId == network) {
            specificNetId = currentNet;
            break;
          }
        }

        if (specificNetId == null) {
          throw Error("Cannot change network");
        }

        const chainId = `0x${network.toString(16)}`;
        const chainName = config.networks[specificNetId].name;
        const nativeCurrency = {
          name: config.networks[specificNetId].assetName,
          symbol: config.networks[specificNetId].assetName,
          decimals: 18
        };
        const rpcUrls = [config.networks[specificNetId].endpoint];
        const blockExplorerUrls = [config.networks[specificNetId].explorerURL];

        await provider.send('wallet_addEthereumChain', [{
          chainId,
          chainName,
          nativeCurrency,
          rpcUrls,
          blockExplorerUrls
        }]);
        
      } else {
        throw Error("Cannot change network");
      }
    }
  }
}
