import { BigNumber, ethers } from "ethers";

import DurationFixedRewardsFarmPoolABI from "./abis/DurationFixedRewardsFarmPool.json";
import LendingOracleAggregatorABI from "./../usdr/abis/LendingOracleAggregator.json";

const DurationFixedRewardsFarmPoolInterface = new ethers.utils.Interface(
    JSON.parse(JSON.stringify(DurationFixedRewardsFarmPoolABI.abi))
);
const LendingOracleAggregatorInterface = new ethers.utils.Interface(
    JSON.parse(JSON.stringify(LendingOracleAggregatorABI.abi))
);

export const getFarmPoolTVLUSD = async (
    rpc,
    depositAssetPrice,
    depositAssetDecimals,
    poolType,
    poolAddress
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    var totalDepositedAssets = 0;
    if (poolType == "DurationFixedRewardsFarmPool") {
        const pool = new ethers.Contract(
            poolAddress,
            DurationFixedRewardsFarmPoolInterface,
            provider
        );

        totalDepositedAssets = await pool.totalSupply();
        totalDepositedAssets /= 10**depositAssetDecimals;
    }

    return (depositAssetPrice * totalDepositedAssets);
}

export const getFarmPoolAPR = async (
    rpc,
    depositAssetPrice,
    depositAssetDecimals,
    poolType,
    poolAddress,
    radarPrice
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    var radarPerTokenYearly = 0;
    if (poolType == "DurationFixedRewardsFarmPool") {
        const pool = new ethers.Contract(
            poolAddress,
            DurationFixedRewardsFarmPoolInterface,
            provider
        );

        const rewardRate = await pool.rewardRate();
        const totalSupply = await pool.totalSupply();

        if (rewardRate == 0 || totalSupply == 0) {
            radarPerTokenYearly = 0;
        } else {
            var tokensInYear = rewardRate * (365 * 24 * 60 * 60);
            radarPerTokenYearly = (tokensInYear / 10**18) / (totalSupply / 10**depositAssetDecimals);
        }
    }

    var apr = (radarPerTokenYearly * radarPrice) / depositAssetPrice;
    return apr;
}

export const getUserStaked = async (
    rpc,
    depositAssetDecimals,
    poolType,
    poolAddress,
    userAddress
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    var userDeposits = 0;
    if (poolType == "DurationFixedRewardsFarmPool") {
        const pool = new ethers.Contract(
            poolAddress,
            DurationFixedRewardsFarmPoolInterface,
            provider
        );

        userDeposits = await pool.balanceOf(userAddress);
        userDeposits /= 10**depositAssetDecimals;
    }

    return userDeposits;
}

export const getUserReward = async (
    rpc,
    poolType,
    poolAddress,
    userAddress
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    var userRewards = 0;
    if (poolType == "DurationFixedRewardsFarmPool") {
        const pool = new ethers.Contract(
            poolAddress,
            DurationFixedRewardsFarmPoolInterface,
            provider
        );

        userRewards = await pool.earned(userAddress);
        userRewards /= 10**18;
    }

    return userRewards;
}

export const depositToFarm = async (
    signer,
    poolType,
    poolAddress,
    userAddress,
    amount
) => {
    if (poolType == "DurationFixedRewardsFarmPool") {
        const pool = new ethers.Contract(
            poolAddress,
            DurationFixedRewardsFarmPoolInterface,
            signer
        );

        const tx = await pool.stake(amount, userAddress);
        await tx.wait();

        await new Promise(r => setTimeout(r, 2000));
    }
}

export const withdrawFromFarm = async (
    signer,
    poolType,
    poolAddress,
    amount
) => {
    if (poolType == "DurationFixedRewardsFarmPool") {
        const pool = new ethers.Contract(
            poolAddress,
            DurationFixedRewardsFarmPoolInterface,
            signer
        );

        const tx = await pool.withdraw(amount);
        await tx.wait();

        await new Promise(r => setTimeout(r, 2000));
    }
}

export const farmClaimReward = async (
    signer,
    poolType,
    poolAddress
) => {
    if (poolType == "DurationFixedRewardsFarmPool") {
        const pool = new ethers.Contract(
            poolAddress,
            DurationFixedRewardsFarmPoolInterface,
            signer
        );

        const tx = await pool.getReward();
        await tx.wait();

        await new Promise(r => setTimeout(r, 2000));
    }
}