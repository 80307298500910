import React, { Component } from "react";
import { connect } from "react-redux";

import { manualChangeNetwork } from "../../../redux/actions/OnboardActions";
import config from "../../../config/config.json";

import styles from "../../../styles/index.module.css";
import "./blockchainDropdown.css";

class BlockchainDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockchainDropdownCss: this.props.state.blockchainDropdownCss,
      dropdownClicked: false,
    };
  }

  getBlockchainIcon = (type) => {
    const icon = require(`../../../assets/SVGs/${type}`).default;
    return icon;
  };

  blockchainPopupClick = async (e, network) => {
    e.preventDefault();
    await this.props.manualChangeNetwork(config.networks[network].networkId);
    this.closeBlockchainDropdown();
  };

  closeBlockchainDropdown = () => {
    if (this.state.dropdownClicked === false) {
      this.props.closeBlockchainDropdownEvent();
    } else {
    }
  };

  render() {
    const doNotDisplay = {
      display: "none",
    };

    return (
      <>
        {window.innerWidth > 1299 ? (
          <>
            <div
              className="blockchain-dropdown-wrapper"
              onClick={() => this.closeBlockchainDropdown()}
            >
              <div className="blockchain-dropdown-layout">
                <div
                  className={`${styles.whiteBg} ${styles.elevationLow} ${this.props.state.blockchainDropdownCss}`}
                  onClick={() =>
                    this.setState({
                      dropdownClicked: true,
                    })
                  }
                >
                  {Object.keys(config.networks).map((i) => (
                    <div
                      className="blockchain-dropdown-row"
                      style={i === "POLYGON" ? doNotDisplay : {}}
                      onClick={(e) => this.blockchainPopupClick(e, i)}
                    >
                      <img
                        src={this.getBlockchainIcon(config.networks[i].icon)}
                        alt="blockchain-icon"
                        className="blockchain-dropdown-row-icon"
                      />
                      <div
                        className={`${
                          styles.labelMBold
                        } ${"blockchain-dropdown-row-text"}`}
                      >
                        {config.networks[i].name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="blockchain-dropdown-wrapper"
              onClick={() => this.closeBlockchainDropdown()}
            >
              <div className="blockchain-dropdown-layout">
                <div
                  className={`${styles.whiteBg} ${styles.elevationLow} ${"blockchain-dropdown"}`}
                  onClick={() =>
                    this.setState({
                      dropdownClicked: true,
                    })
                  }
                >
                  {Object.keys(config.networks).map((i) => (
                    <div
                      className="blockchain-dropdown-row"
                      style={i === "POLYGON" ? doNotDisplay : {}}
                      onClick={(e) => this.blockchainPopupClick(e, i)}
                    >
                      <img
                        src={this.getBlockchainIcon(config.networks[i].icon)}
                        alt="blockchain-icon"
                        className="blockchain-dropdown-row-icon"
                      />
                      <div
                        className={`${
                          styles.labelMBold
                        } ${"blockchain-dropdown-row-text"}`}
                      >
                        {config.networks[i].name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  manualChangeNetwork,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockchainDropdown);
