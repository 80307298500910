import React, { Component } from 'react';

import icon from '../../assets/SVGs/info.svg';

class InfoWarningIcon extends Component {

    render() {

        return (

            <>
                <img
                    src={icon}
                    alt='icon'
                />
            </>
        )
    }
}

export default InfoWarningIcon;