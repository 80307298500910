import React, { Component } from 'react';

import icon from '../../assets/SVGs/check.svg';

class CheckIcon extends Component {

    render() {

        return (

            <>
                <img
                    src={icon}
                    alt='icon'
                />
            </>
        )
    }
}

export default CheckIcon;