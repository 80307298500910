export const getSlippage = (ls) => {
    const lss = ls.getItem("slippage");
    if (lss != null && typeof(lss) != "undefined" && lss != undefined) {
        return parseFloat(lss);
    } else {
        return 0.02;
    }
}

export const setSlippage = (ls, slippage) => {
    ls.setItem("slippage", slippage.toFixed(4).toString());
}