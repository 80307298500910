import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config/config.json";

import Header from "../../layouts/header/Header";
import Sidebar from "../../layouts/sidebar/Sidebar";
import Footer from "../../layouts/footer/Footer";
import Loader from "../../components/loader/Loader";
import SwitchNetworkOverlay from "../../components/overlays/switch-network/SwitchNetworkOverlay";
import WalletNotConnectedOverlay from "../../components/overlays/wallet-not-connected/WalletNotConnectedOverlay";

import GradientArrowRightIcon from "../../components/icons/GradientArrowRightIcon";
import LinkIcon from "../../components/icons/LinkIcon";
import styles from "../../styles/index.module.css";
import "./farmPage.css";
import {
  getFarmPoolAPR,
  getFarmPoolTVLUSD,
  getUserReward,
  getUserStaked,
} from "../../web3/farms/api";
import { getRadarPrice } from "../../web3/bonds/lib/dao";

class FarmPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",
      poolInfo: {},
    };
  }

  renderLoading() {
    return <Loader {...this.props} />;
  }

  checkNested(obj /*, level1, level2, ... levelN*/) {
    var args = Array.prototype.slice.call(arguments, 1);

    for (var i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
        return false;
      }
      obj = obj[args[i]];
    }
    return true;
  }

  getAssetIcon = (type) => {
    const icon = require(`../../assets/SVGs/assets/${type}`).default;
    return icon;
  };

  getBlockchainIcon = (type) => {
    const icon = require(`../../assets/SVGs/${type}`).default;
    return icon;
  };

  toPage(route, subroute, params) {
    this.props.history.push(route + "/" + subroute, params);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  async componentDidMount() {
    await this.getFarmData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
      await this.getFarmData();
    }
  }

  async getFarmData() {
    var poolInfo = this.state.poolInfo;

    const bl = this.getBlockchain(this.props.onboard.networkId);
    const rpc = config.networks[bl].endpoint;
    const userAddress = this.props.onboard.address;
    const radarPrice = await getRadarPrice();

    // Get our pools data
    const ourPoolKeys = Object.keys(config.farm.pools);
    for (var i = 0; i < ourPoolKeys.length; i++) {
      var poolKey = ourPoolKeys[i];
      poolInfo[poolKey] = {};
      await this.setState({ poolInfo });

      var poolUsdTVL = await getFarmPoolTVLUSD(
        rpc,
        1, // TODO: CHANGE ME
        config.farm.pools[poolKey].depositAsset.decimals,
        config.farm.pools[poolKey].contractType,
        config.farm.pools[poolKey].address
      );
      poolInfo[poolKey]["tvl"] = poolUsdTVL;
      await this.setState({ poolInfo });

      var userBalance = await getUserStaked(
        rpc,
        config.farm.pools[poolKey].depositAsset.decimals,
        config.farm.pools[poolKey].contractType,
        config.farm.pools[poolKey].address,
        userAddress
      );
      poolInfo[poolKey]["balance"] = userBalance;
      await this.setState({ poolInfo });

      var userRewards = await getUserReward(
        rpc,
        config.farm.pools[poolKey].contractType,
        config.farm.pools[poolKey].address,
        userAddress
      );
      poolInfo[poolKey]["reward"] = userRewards;
      await this.setState({ poolInfo });

      var apr = await getFarmPoolAPR(
        rpc,
        1, // TODO: CHANGE ME
        config.farm.pools[poolKey].depositAsset.decimals,
        config.farm.pools[poolKey].contractType,
        config.farm.pools[poolKey].address,
        radarPrice
      );
      poolInfo[poolKey]["apr"] = apr;
      await this.setState({ poolInfo });
    }
  }

  renderOurPools() {
    return (
      <>
        {window.innerWidth > 1299 ? (
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div
                className={`${"farm-table-b-cell"} ${styles.labelSRegular} ${
                  styles.secondaryText
                }`}
              >
                Asset
              </div>
              <div
                className={`${"farm-table-s-cell"} ${styles.labelSRegular} ${
                  styles.secondaryText
                }`}
              >
                TVL
              </div>
              <div
                className={`${"farm-table-s-cell"} ${styles.labelSRegular} ${
                  styles.secondaryText
                }`}
              >
                APR
              </div>
              <div
                className={`${"farm-table-s-cell"} ${styles.labelSRegular} ${
                  styles.secondaryText
                }`}
              >
                Your staked
              </div>
              <div
                className={`${"farm-table-s-cell farm-right"} ${
                  styles.labelSRegular
                } ${styles.secondaryText}`}
                style={{ textAlign: "right" }}
              >
                Your reward
              </div>
            </div>
            {Object.keys(config.farm.pools)
              .filter((j) => {
                return config.farm.pools[j].type === "1";
              })
              .map((i) => (
                <div
                  className={styles.tableRow}
                  onClick={() =>
                    this.toPage("/farm", config.farm.pools[i].address, {
                      i,
                    })
                  }
                >
                  <div
                    className={`${"farm-table-b-cell"} ${
                      styles.labelMRegular
                    } ${styles.primaryText}`}
                  >
                    <div className="farm-table-pool-name">
                      <img
                        src={this.getAssetIcon(
                          config.farm.pools[i].assets[1].icon
                        )}
                        alt="collaretal-icon"
                        className="farm-table-b-icon1"
                      />
                      <img
                        src={this.getAssetIcon(
                          config.farm.pools[i].assets[2].icon
                        )}
                        alt="collaretal-icon"
                        className="farm-table-b-icon2"
                      />
                      <div
                        className={`${"farm-table-pool-name-text"} ${
                          styles.labelM
                        } ${styles.primaryText}`}
                      >
                        {config.farm.pools[i].name}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${"farm-table-s-cell"} ${
                      styles.labelMRegular
                    } ${styles.primaryText}`}
                  >
                    {!this.checkNested(this.state.poolInfo, i, "tvl") ? (
                      this.renderLoading()
                    ) : (
                      <>${this.state.poolInfo[i].tvl.toLocaleString(undefined, {maximumFractionDigits: 2})}</>
                    )}
                  </div>
                  <div
                    className={`${"farm-table-s-cell"} ${
                      styles.labelMRegular
                    } ${styles.primaryText}`}
                  >
                    {!this.checkNested(this.state.poolInfo, i, "apr") ? (
                      this.renderLoading()
                    ) : (
                      <>{(this.state.poolInfo[i].apr * 100).toFixed(2)}%</>
                    )}
                  </div>
                  <div
                    className={`${"farm-table-s-cell"} ${
                      styles.labelMRegular
                    } ${styles.primaryText}`}
                  >
                    {!this.checkNested(this.state.poolInfo, i, "balance") ? (
                      this.renderLoading()
                    ) : (
                      <>
                        {this.state.poolInfo[i].balance.toFixed(2)}{" "}
                        {config.farm.pools[i].depositAsset.sym}
                      </>
                    )}
                  </div>
                  <div
                    className={`${"farm-table-s-cell farm-right"} ${
                      styles.labelMRegular
                    } ${styles.primaryText}`}
                  >
                    {!this.checkNested(this.state.poolInfo, i, "reward") ? (
                      this.renderLoading()
                    ) : (
                      <>{this.state.poolInfo[i].reward.toFixed(2)} RADAR</>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className={styles.mobTableCardsSection}>
            {Object.keys(config.farm.pools)
              .filter((j) => {
                return config.farm.pools[j].type === "1";
              })
              .map((i) => (
                <div
                  className={`${styles.card} ${styles.whiteBg}`}
                  style={{ height: "auto" }}
                  onClick={() =>
                    this.toPage("/farm", config.farm.pools[i].address, {
                      i,
                    })
                  }
                >
                  <div className={styles.mobTableCardHeader}>
                    <div className="farm-table-pool-name">
                      <img
                        src={this.getAssetIcon(
                          config.farm.pools[i].assets[1].icon
                        )}
                        alt="collaretal-icon"
                        className="farm-table-b-icon1"
                      />
                      <img
                        src={this.getAssetIcon(
                          config.farm.pools[i].assets[2].icon
                        )}
                        alt="collaretal-icon"
                        className="farm-table-b-icon2"
                      />
                      <div
                        className={`${"farm-table-pool-name-text"} ${
                          styles.labelM
                        } ${styles.primaryText}`}
                      >
                        {config.farm.pools[i].name}
                      </div>
                    </div>
                  </div>
                  <div className={styles.mobTableCardRow}>
                    <div
                      className={`${styles.labelMRegular} ${styles.secondaryText}`}
                    >
                      TVL
                    </div>
                    <div
                      className={`${styles.labelMBold} ${styles.primaryText}`}
                    >
                      {!this.checkNested(this.state.poolInfo, i, "tvl") ? (
                        this.renderLoading()
                      ) : (
                        <>${this.state.poolInfo[i].tvl.toLocaleString(undefined, {maximumFractionDigits: 2})}</>
                      )}
                    </div>
                  </div>
                  <div className={styles.mobTableCardRow}>
                    <div
                      className={`${styles.labelMRegular} ${styles.secondaryText}`}
                    >
                      APR
                    </div>
                    <div
                      className={`${styles.labelMBold} ${styles.primaryText}`}
                    >
                      {!this.checkNested(this.state.poolInfo, i, "apr") ? (
                        this.renderLoading()
                      ) : (
                        <>{(this.state.poolInfo[i].apr * 100).toFixed(2)}%</>
                      )}
                    </div>
                  </div>
                  <div className={styles.mobTableCardRow}>
                    <div
                      className={`${styles.labelMRegular} ${styles.secondaryText}`}
                    >
                      You staked
                    </div>
                    <div
                      className={`${styles.labelMBold} ${styles.primaryText}`}
                    >
                      {!this.checkNested(this.state.poolInfo, i, "balance") ? (
                        this.renderLoading()
                      ) : (
                        <>
                          {this.state.poolInfo[i].balance.toFixed(2)}{" "}
                          {config.farm.pools[i].depositAsset.sym}
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.mobTableCardRow}>
                    <div
                      className={`${styles.labelMRegular} ${styles.secondaryText}`}
                    >
                      Your reward
                    </div>
                    <div
                      className={`${styles.labelMBold} ${styles.primaryText}`}
                    >
                      {!this.checkNested(this.state.poolInfo, i, "reward") ? (
                        this.renderLoading()
                      ) : (
                        <>{this.state.poolInfo[i].reward.toFixed(2)} RADAR</>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </>
    );
  }

  renderExternalPools() {
    return (
      <>
        {window.innerWidth > 1299 ? (
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div
                className={`${"farm-table-cell"} ${styles.labelSRegular} ${
                  styles.secondaryText
                }`}
              >
                Platform
              </div>
              <div
                className={`${"farm-table-cell"} ${styles.labelSRegular} ${
                  styles.secondaryText
                }`}
              >
                Assets
              </div>
              <div
                className={`${"farm-table-cell"} ${styles.labelSRegular} ${
                  styles.secondaryText
                }`}
              >
                Token earned
              </div>
              <div
                className={`${"farm-table-cell farm-right"} ${
                  styles.labelSRegular
                } ${styles.secondaryText}`}
              />
            </div>
            {Object.keys(config.farm.pools)
              .filter((j) => {
                return config.farm.pools[j].type === "2";
              })
              .map((i) => (
                <div
                  className={styles.tableRow}
                  onClick={() =>
                    this.toPage("/farm", config.farm.pools[i].address, {
                      i,
                    })
                  }
                >
                  <div
                    className={`${"farm-table-cell"} ${styles.labelMRegular} ${
                      styles.primaryText
                    }`}
                  >
                    <div className="farm-table-pool-name">
                      <img
                        src={this.getBlockchainIcon(
                          config.farm.pools[i].platform.blockchainIcon
                        )}
                        alt="blockchain-icon"
                        className="farm-table-blockchain-icon"
                      />
                      <div
                        className={`${""} ${styles.labelM} ${
                          styles.primaryText
                        }`}
                      >
                        {config.farm.pools[i].platform.name}
                      </div>
                    </div>
                  </div>
                  <div className={`${"farm-table-cell"}`}>
                    {Object.keys(config.farm.pools[i].assets).map((j) => (
                      <img
                        src={this.getAssetIcon(
                          config.farm.pools[i].assets[j].icon
                        )}
                        alt="blockchain-icon"
                        className="farm-table-asset-icon"
                      />
                    ))}
                  </div>
                  <div className={`${"farm-table-cell"}`}>
                    <div className={`${"farm-table-token-earned-cell"}`}>
                      <img
                        src={this.getAssetIcon(
                          config.farm.pools[i].tokenEarned.icon
                        )}
                        alt="blockchain-icon"
                        className="farm-table-asset-icon"
                      />
                      <div
                        className={`${"farm-table-pool-name-text"} ${
                          styles.labelM
                        } ${styles.primaryText}`}
                      >
                        {config.farm.pools[i].tokenEarned.sym}
                      </div>
                    </div>
                  </div>
                  <a
                    href={config.farm.pools[i].link}
                    target="_blank"
                    className={`${"farm-table-cell farm-right"}`}
                  >
                    <LinkIcon />
                  </a>
                </div>
              ))}
          </div>
        ) : (
          <div className={styles.mobTableCardsSection}>
            {Object.keys(config.farm.pools)
              .filter((j) => {
                return config.farm.pools[j].type === "2";
              })
              .map((i) => (
                <div
                  className={`${styles.card} ${styles.whiteBg}`}
                  style={{ height: "auto" }}
                  onClick={() =>
                    this.toPage("/farm", config.farm.pools[i].address, {
                      i,
                    })
                  }
                >
                  <div className={styles.mobTableCardHeader}>
                    <div className={styles.mobTableCardHeaderLeftSection}>
                      <div className="farm-table-pool-name">
                        <img
                          src={this.getAssetIcon(
                            config.farm.pools[i].assets[1].icon
                          )}
                          alt="collaretal-icon"
                          className="farm-table-b-icon1"
                        />
                        <img
                          src={this.getAssetIcon(
                            config.farm.pools[i].assets[2].icon
                          )}
                          alt="collaretal-icon"
                          className="farm-table-b-icon2"
                        />
                        <div
                          className={`${"farm-table-pool-name-text"} ${
                            styles.labelM
                          } ${styles.primaryText}`}
                        >
                          {config.farm.pools[i].assets[1].sym} -{" "}
                          {config.farm.pools[i].assets[2].sym} LP
                        </div>
                      </div>
                    </div>
                    <div className={styles.mobTableCardHeaderRighttSection}>
                      <a
                        href={config.farm.pools[i].link}
                        target="_blank"
                        className={`${"farm-table-cell farm-right"}`}
                      >
                        <LinkIcon />
                      </a>
                    </div>
                  </div>
                  <div className={styles.mobTableCardRow}>
                    <div
                      className={`${styles.labelMRegular} ${styles.secondaryText}`}
                    >
                      Platform
                    </div>
                    <div
                      className={`${styles.labelMBold} ${styles.primaryText}`}
                    >
                      <div className="farm-table-pool-name">
                        <img
                          src={this.getBlockchainIcon(
                            config.farm.pools[i].platform.blockchainIcon
                          )}
                          alt="blockchain-icon"
                          className="farm-table-blockchain-icon"
                        />
                        <div
                          className={`${""} ${styles.labelM} ${
                            styles.primaryText
                          }`}
                        >
                          {config.farm.pools[i].platform.name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.mobTableCardRow}>
                    <div
                      className={`${styles.labelMRegular} ${styles.secondaryText}`}
                    >
                      Assets
                    </div>
                    <div>
                      {Object.keys(config.farm.pools[i].assets).map((j) => (
                        <img
                          src={this.getAssetIcon(
                            config.farm.pools[i].assets[j].icon
                          )}
                          alt="blockchain-icon"
                          className="farm-table-asset-icon"
                        />
                      ))}
                    </div>
                  </div>
                  <div className={styles.mobTableCardRow}>
                    <div
                      className={`${styles.labelMRegular} ${styles.secondaryText}`}
                    >
                      Token earned
                    </div>
                    <div
                      className={`${styles.labelMBold} ${styles.primaryText}`}
                    >
                      <div className={`${"farm-table-token-earned-cell"}`}>
                        <img
                          src={this.getAssetIcon(
                            config.farm.pools[i].tokenEarned.icon
                          )}
                          alt="blockchain-icon"
                          className="farm-table-asset-icon"
                        />
                        <div
                          className={`${"farm-table-pool-name-text"} ${
                            styles.labelM
                          } ${styles.primaryText}`}
                        >
                          {config.farm.pools[i].tokenEarned.sym}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.mobTableCardRow}>
                    <div
                      className={`${styles.labelMRegular} ${styles.secondaryText}`}
                    >
                      Your reward
                    </div>
                    <div
                      className={`${styles.labelMBold} ${styles.primaryText}`}
                    >
                      x RADAR
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </>
    );
  }

  render() {
    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };
    return (
      <>
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Sidebar {...this.props} state={this.state} />
          {this.props.onboard.walletConnected === false ? (
            <WalletNotConnectedOverlay {...this.props} />
          ) : (
            <>
              {this.getBlockchain(this.props.onboard.networkId) !==
                "AVAXC" &&
                this.getBlockchain(this.props.onboard.networkId) !==
                  "ETHEREUM" && (
                  <SwitchNetworkOverlay
                    {...this.props}
                    primaryTextFromParent={
                      "There are no available farms on " +
                      config.networks[this.getBlockchain(this.props.onboard.networkId)].name
                    }
                    secondaryTextFromParent="Please switch your wallet to the following network"
                    blockchainsFromParent={["AVAXC"]}
                  />
                )}
            </>
          )}
          <div
            className={styles.wrapper}
            style={
              this.props.onboard.walletConnected === false ||
              (this.getBlockchain(this.props.onboard.networkId) !==
                "AVAXC" &&
                this.getBlockchain(this.props.onboard.networkId) !== "ETHEREUM")
                ? displayBlur
                : {}
            }
          >
            <div className={styles.content}>
              <div className={styles.headerSection}>
                <div className={`${styles.headingM} ${styles.primaryText}`}>
                  Farm
                </div>
                <div className={styles.subheaderSection}>
                  <div className={`${styles.paragraphM} ${styles.primaryText}`}>
                    RADAR and USDR holders have access to a diverse set of yield
                    farming opportunities
                  </div>
                  <div className={styles.mobSubheaderLinkSection}>
                    <a
                      href="https://radar.blog/radar-farm-tutorial-how-to-start-farming-and-earning-rewards-1fce04011e94"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className={`${styles.paragraphM} ${styles.gradientText} ${styles.subheaderMiddle}`}
                      >
                        Learn more about Farm
                      </div>
                    </a>
                    <a
                      href=""
                      target="_blank"
                      style={{ textDecoration: "none", marginTop: "-2px" }}
                    >
                      <GradientArrowRightIcon />
                    </a>
                  </div>
                </div>
              </div>
              {this.renderOurPools()}
              {Object.keys(config.farm.pools).filter((j) => {
                return config.farm.pools[j].type === "2";
              }).length > 0 && (
                <>
                  <div
                    className={`${"farm-other-pools-header"} ${
                      styles.headingXS
                    } ${styles.primaryText}`}
                  >
                    Other pools
                  </div>
                  {this.renderExternalPools()}
                </>
              )}
            </div>
          </div>
          <Footer {...this.props} state={this.state} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FarmPage);
