import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { ethers } from "ethers";
import { abi as BridgeAbi } from "../../../../web3/bridge/ABIs/RadarBridge.json";
import {
  registerTxHash,
  scanForUnclaimedTokens,
} from "../../../../web3/bridge/api";
import {
  checkWallet,
  manualChangeNetwork,
} from "../../../../redux/actions/OnboardActions";
import "../../../../utils/ethereum/onboard";
import config from "../../../../config/config.json";
import configBridge from "../../../../web3/bridge/config_bridge.json";
import LoaderOverlay from "../../../../components/overlays/loader/LoaderOverlay";

import CaretDownIcon from "../../../../components/icons/CaretDownIcon";
import CaretUpIcon from "../../../../components/icons/CaretUpIcon";
import CheckIcon from "../../../../components/icons/CheckIcon";

import radarIcon from "../../../../assets/SVGs/assets/radar.svg";
import ethereumIcon from "../../../../assets/SVGs/ethereum.svg";
import binanceIcon from "../../../../assets/SVGs/binance.svg";
import arrowIcon from "../../../../assets/SVGs/arrowRight.svg";
import styles from "../../../../styles/index.module.css";
import "./findTransaction.css";

class BridgeFindTransaction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // currentStep: 1,
      currentMethod: 1,
      loadingOverlay: false,

      tokenDropdown: false,
      selectedToken: "RADAR",

      blockchainDropdown: false,
      blockchain: "ETH",

      TXHash: "lore",
      wrongInputsAlert: false,

      assetsToClaim: [],
    };
  }

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  getBlockchainIcon = (type) => {
    const icon = require(`../../../../assets/SVGs/${type}`).default;
    return icon;
  };

  getTokenIcon = (type) => {
    const icon = require(`../../../../assets/SVGs/assets/${type}`).default;
    return icon;
  };

  async componentDidMount() {
    await this.loadAssets();
  }

  loadAssets = async () => {
    try {
      const assets = JSON.parse(window.localStorage.getItem("claim"));
      if (assets.length > 0) {
        await this.setState({ assetsToClaim: assets });
      } else {
        await this.setState({ assetsToClaim: [] });
      }
      this.props.refreshBubble();
    } catch (e) {
      console.log(e);
      await this.setState({ assetsToClaim: [] });
    }
  };

  TXHashField = async (e) => {
    var value = e.target.value;

    if (value == "") {
      await this.setState({
        TXHash: "",
        wrongInputsAlert: true,
      });
      return;
    }

    const re = /^0x([A-Fa-f0-9]{64})$/;
    if (!re.test(value)) {
      await this.setState({
        TXHash: value,
        wrongInputsAlert: true,
      });
      return;
    }

    this.setState({
      TXHash: value,
      wrongInputsAlert: false,
    });
  };

  scan = async () => {
    if (this.state.currentMethod === 1) {
      /* Daca NU exista TX Hash */
      this.setState({
        loadingOverlay: true,
      });
      try {
        const res = await scanForUnclaimedTokens(
          this.props.onboard.address,
          this.state.blockchain,
          this.state.selectedToken,
          localStorage
        );

        if (!res)
          toastr.error(
            "Error",
            "Couldn't find any unclaimed tokens. Please try again later."
          );
        else {
          toastr.success("Success", "You can now claim your tokens.");
          // this.setState({
          //   currentStep: 2,
          // });
        }
      } catch (e) {
        console.log(e);
        toastr.error("Error", "Error scanning for tokens: " + e.message);
      }
      this.setState({
        loadingOverlay: false,
      });
    } else {
      /* Daca exista TX Hash */
      if (this.state.wrongInputsAlert === true || this.state.TXHash === "")
        return;

      this.setState({
        loadingOverlay: true,
      });
      try {
        const res = await registerTxHash(
          this.state.TXHash,
          this.state.blockchain,
          this.state.selectedToken,
          localStorage
        );
        if (!res)
          toastr.error(
            "Error",
            "Couldn't retrieve signature. Please try again later."
          );
        else {
          toastr.success("Success", "You can now claim your tokens.");
          // this.setState({
          //   currentStep: 2,
          // });
        }
      } catch (e) {
        console.log(e);
        toastr.error("Error", "Error retrieving signature: " + e.message);
      }
      this.setState({
        loadingOverlay: false,
      });
    }

    await this.loadAssets();
  };

  claimTokens = async (i) => {
    const signature = this.state.assetsToClaim[i];
    await this.setState({ loadingOverlay: true });

    try {
      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      const signer = await provider.getSigner();

      const RadarBridgeInterface = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(BridgeAbi))
      );

      const RadarBridge = new ethers.Contract(
        config.networks[signature.destChain].bridgeAddress,
        RadarBridgeInterface,
        signer
      );

      const receipt = await RadarBridge.claimTokens(
        ethers.utils.formatBytes32String(signature.tokenId),
        signature.amount,
        ethers.utils.formatBytes32String(signature.srcChain),
        ethers.utils.formatBytes32String(signature.destChain),
        signature.srcTimestamp,
        signature.nonce,
        signature.destAddress,
        signature.signature
      );
      await receipt.wait();

      toastr.success("Success", "Tokens Claimed successfully!");
    } catch (e) {
      if (e.message.includes("Double Spending")) {
        toastr.error("Error", "You have already claimed these tokens.");
      } else {
        toastr.error(
          "Error",
          "Error claiming tokens (did you already claim this transaction?). Try scanning and claiming again."
        );
      }
    }

    var data = await localStorage.getItem("claim");
    if (data != null && typeof data != "undefined") {
      // Remove claim from localStorage
      data = JSON.parse(data);

      for (var i = 0; i < data.length; i++) {
        if (data[i].nonce == signature.nonce) {
          data.splice(i, 1);
          break;
        }
      }

      data = JSON.stringify(data);
      await localStorage.setItem("claim", data);
    }
    await this.setState({ loadingOverlay: false });
    await this.loadAssets();
  };

  goToBlockchain = async () => {
    await this.props.manualChangeNetwork(
      config.networks[this.state.blockchain].networkId
    );
  };

  renderStep1() {
    const doNotDisplay = {
      display: "none",
    };
    return (
      <div className={`${"bridge-step-card"} ${styles.whiteBg}`}>
        <div className="bridge-step-card-header">
          <div>
            <div className={`${styles.headingS} ${styles.primaryText}`}>
              Find transaction
            </div>
            <div className={`${styles.paragraphS} ${styles.secondaryText}`}>
              Scan for unclaimed tokens in your latest transactions.
            </div>
          </div>
          {/* <div
            className={`${"bridge-step-no"} ${styles.gradientBg} ${
              styles.labelSRegular
            } ${styles.whiteText}`}
          >
            1
          </div> */}
        </div>
        {this.state.currentMethod === 1 ? (
          <>
            <div className={`${"bridge-substep-card"} ${styles.bg}`}>
              <div className="bridge-substep-card-header">
                <div className="bridge-substep-selected-radio" />
                <div
                  className={`${styles.labelMRegular} ${styles.primaryText}`}
                >
                  Scan network
                </div>
              </div>
              {this.state.blockchainDropdown === true ? (
                <>
                  <div
                    className="bridge-tx-dropdown-selector-box gradient-border"
                    onClick={() =>
                      this.setState({
                        blockchainDropdown: false,
                      })
                    }
                  >
                    <div className="bridge-dropdown-selector-box-asset-section">
                      <img
                        src={this.getBlockchainIcon(
                          config.networks[this.state.blockchain].icon
                        )}
                        alt="token-icon"
                        className="bridge-dropdown-asset-icon"
                      />
                      <div
                        className={`${styles.labelMRegular} ${styles.primaryText}`}
                      >
                        {config.networks[this.state.blockchain].name}
                      </div>
                    </div>
                    <CaretUpIcon />
                  </div>
                  <div
                    className={`${"bridge-dropdown tx-full-dd"} ${
                      styles.whiteBg
                    } ${styles.elevationLow}`}
                  >
                    {Object.keys(config.tokens[this.state.selectedToken]).map(
                      (chain) => (
                        <div
                          className="bridge-dropdown-row"
                          style={
                            chain === this.state.blockchain ? doNotDisplay : {}
                          }
                          onClick={() =>
                            this.setState({
                              blockchain: chain,
                              blockchainDropdown: false,
                            })
                          }
                        >
                          <img
                            src={this.getBlockchainIcon(
                              config.networks[chain].icon
                            )}
                            alt="token-icon"
                            className="bridge-dropdown-asset-icon"
                          />
                          <div
                            className={`${styles.labelMRegular} ${styles.primaryText}`}
                          >
                            {config.networks[chain].name}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              ) : (
                <div
                  className="bridge-tx-dropdown-selector-box"
                  onClick={() =>
                    this.setState({
                      blockchainDropdown: true,
                      tokenDropdown: false,
                    })
                  }
                >
                  <div className="bridge-dropdown-selector-box-asset-section">
                    <img
                      src={this.getBlockchainIcon(
                        config.networks[this.state.blockchain].icon
                      )}
                      alt="token-icon"
                      className="bridge-dropdown-asset-icon"
                    />
                    <div
                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                    >
                      {config.networks[this.state.blockchain].name}
                    </div>
                  </div>
                  <CaretDownIcon />
                </div>
              )}
              <div
                className={`${styles.labelSRegular} ${
                  styles.primaryText
                } ${"bridge-tx-box-info"}`}
              >
                Asset
              </div>
              {this.state.tokenDropdown === true ? (
                <>
                  <div
                    className="bridge-tx-dropdown-selector-box gradient-border"
                    onClick={() =>
                      this.setState({
                        tokenDropdown: false,
                      })
                    }
                  >
                    <div className="bridge-dropdown-selector-box-asset-section">
                      <img
                        src={this.getTokenIcon(
                          config.token_metadata[this.state.selectedToken].icon
                        )}
                        alt="token-icon"
                        className="bridge-dropdown-asset-icon"
                      />
                      <div
                        className={`${styles.labelMRegular} ${styles.primaryText}`}
                      >
                        {this.state.selectedToken}
                      </div>
                    </div>
                    <CaretUpIcon />
                  </div>
                  <div
                    className={`${"bridge-dropdown tx-full-dd"} ${
                      styles.whiteBg
                    } ${styles.elevationLow}`}
                  >
                    {Object.keys(config.tokens).map((symbol) => (
                      <div
                        className="bridge-dropdown-row"
                        style={
                          symbol === this.state.selectedToken
                            ? doNotDisplay
                            : {}
                        }
                        onClick={(e) =>
                          this.setState({
                            selectedToken: symbol,
                            tokenDropdown: false,
                          })
                        }
                      >
                        <img
                          src={this.getTokenIcon(
                            config.token_metadata[symbol].icon
                          )}
                          alt="token-icon"
                          className="bridge-dropdown-asset-icon"
                        />
                        <div
                          className={`${styles.labelMRegular} ${styles.primaryText}`}
                        >
                          {symbol}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div
                  className="bridge-tx-dropdown-selector-box"
                  onClick={() =>
                    this.setState({
                      tokenDropdown: true,
                      blockchainDropdown: false,
                    })
                  }
                >
                  <div className="bridge-dropdown-selector-box-asset-section">
                    <img
                      src={this.getTokenIcon(
                        config.token_metadata[this.state.selectedToken].icon
                      )}
                      alt="token-icon"
                      className="bridge-dropdown-asset-icon"
                    />
                    <div
                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                    >
                      {this.state.selectedToken}
                    </div>
                  </div>
                  <CaretDownIcon />
                </div>
              )}
            </div>
            <div
              className={`${"bridge-substep-card2"} ${styles.whiteBg}`}
              onClick={() =>
                this.setState({
                  currentMethod: 2,
                })
              }
            >
              <div className="bridge-substep-card-header">
                <div className="bridge-substep-not-selected-radio" />
                <div
                  className={`${styles.labelMRegular} ${styles.primaryText}`}
                >
                  Scan by Transaction ID
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {this.state.currentMethod === 2 ? (
              <>
                <div
                  className={`${"bridge-substep-card2"}`}
                  onClick={() =>
                    this.setState({
                      currentMethod: 1,
                    })
                  }
                  Ï
                >
                  <div className="bridge-substep-card-header">
                    <div className="bridge-substep-not-selected-radio" />
                    <div
                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                    >
                      Scan network
                    </div>
                  </div>
                </div>
                <div className={`${"bridge-substep-card"} ${styles.bg}`}>
                  <div className="bridge-substep-card-header">
                    <div className="bridge-substep-selected-radio" />
                    <div
                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                    >
                      Scan by Transaction ID
                    </div>
                  </div>
                  <div
                    className={`${styles.paragraphS} ${
                      styles.secondaryText
                    } ${"bridge-tx-box-info"}`}
                  >
                    Enter the transaction ID for the tokens transfer to the
                    bridge.
                  </div>
                  <div
                    className={`${styles.inputBox} ${"bridge-tx-input-box"}`}
                  >
                    <input
                      type="text"
                      id="transactionId"
                      name="transactionId"
                      value={this.state.amount}
                      onChange={(e) => this.TXHashField(e)}
                      style={{
                        fontSize: "15px",
                        lineHeight: "24px",
                      }}
                    />
                  </div>
                  <div
                    className={`${styles.labelSRegular} ${
                      styles.primaryText
                    } ${"bridge-tx-box-info"}`}
                  >
                    Blockchain
                  </div>
                  {this.state.blockchainDropdown === true ? (
                    <>
                      <div
                        className="bridge-tx-dropdown-selector-box gradient-border"
                        onClick={() =>
                          this.setState({
                            blockchainDropdown: false,
                          })
                        }
                      >
                        <div className="bridge-dropdown-selector-box-asset-section">
                          <img
                            src={this.getBlockchainIcon(
                              config.networks[this.state.blockchain].icon
                            )}
                            alt="token-icon"
                            className="bridge-dropdown-asset-icon"
                          />
                          <div
                            className={`${styles.labelMRegular} ${styles.primaryText}`}
                          >
                            {config.networks[this.state.blockchain].name}
                          </div>
                        </div>
                        <CaretUpIcon />
                      </div>
                      <div
                        className={`${"bridge-dropdown tx-full-dd"} ${
                          styles.whiteBg
                        } ${styles.elevationLow}`}
                      >
                        {Object.keys(
                          config.tokens[this.state.selectedToken]
                        ).map((chain) => (
                          <div
                            className="bridge-dropdown-row"
                            style={
                              chain === this.state.blockchain
                                ? doNotDisplay
                                : {}
                            }
                            onClick={() =>
                              this.setState({
                                blockchain: chain,
                                blockchainDropdown: false,
                              })
                            }
                          >
                            <img
                              src={this.getBlockchainIcon(
                                config.networks[chain].icon
                              )}
                              alt="token-icon"
                              className="bridge-dropdown-asset-icon"
                            />
                            <div
                              className={`${styles.labelMRegular} ${styles.primaryText}`}
                            >
                              {config.networks[chain].name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div
                      className="bridge-tx-dropdown-selector-box"
                      onClick={() =>
                        this.setState({
                          blockchainDropdown: true,
                          tokenDropdown: false,
                        })
                      }
                    >
                      <div className="bridge-dropdown-selector-box-asset-section">
                        <img
                          src={this.getBlockchainIcon(
                            config.networks[this.state.blockchain].icon
                          )}
                          alt="token-icon"
                          className="bridge-dropdown-asset-icon"
                        />
                        <div
                          className={`${styles.labelMRegular} ${styles.primaryText}`}
                        >
                          {config.networks[this.state.blockchain].name}
                        </div>
                      </div>
                      <CaretDownIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.labelSRegular} ${
                      styles.primaryText
                    } ${"bridge-tx-box-info"}`}
                  >
                    Asset
                  </div>
                  {this.state.tokenDropdown === true ? (
                    <>
                      <div
                        className="bridge-tx-dropdown-selector-box gradient-border"
                        onClick={() =>
                          this.setState({
                            tokenDropdown: false,
                          })
                        }
                      >
                        <div className="bridge-dropdown-selector-box-asset-section">
                          <img
                            src={this.getTokenIcon(
                              config.token_metadata[this.state.selectedToken]
                                .icon
                            )}
                            alt="token-icon"
                            className="bridge-dropdown-asset-icon"
                          />
                          <div
                            className={`${styles.labelMRegular} ${styles.primaryText}`}
                          >
                            {this.state.selectedToken}
                          </div>
                        </div>
                        <CaretUpIcon />
                      </div>
                      <div
                        className={`${"bridge-dropdown tx-full-dd"} ${
                          styles.whiteBg
                        } ${styles.elevationLow}`}
                      >
                        {Object.keys(config.tokens).map((symbol) => (
                          <div
                            className="bridge-dropdown-row"
                            style={
                              symbol === this.state.selectedToken
                                ? doNotDisplay
                                : {}
                            }
                            onClick={(e) =>
                              this.setState({
                                selectedToken: symbol,
                                tokenDropdown: false,
                              })
                            }
                          >
                            <img
                              src={this.getTokenIcon(
                                config.token_metadata[symbol].icon
                              )}
                              alt="token-icon"
                              className="bridge-dropdown-asset-icon"
                            />
                            <div
                              className={`${styles.labelMRegular} ${styles.primaryText}`}
                            >
                              {symbol}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div
                      className="bridge-tx-dropdown-selector-box"
                      onClick={() =>
                        this.setState({
                          tokenDropdown: true,
                          blockchainDropdown: false,
                        })
                      }
                    >
                      <div className="bridge-dropdown-selector-box-asset-section">
                        <img
                          src={this.getTokenIcon(
                            config.token_metadata[this.state.selectedToken].icon
                          )}
                          alt="token-icon"
                          className="bridge-dropdown-asset-icon"
                        />
                        <div
                          className={`${styles.labelMRegular} ${styles.primaryText}`}
                        >
                          {this.state.selectedToken}
                        </div>
                      </div>
                      <CaretDownIcon />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <div
          className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
          onClick={() => this.scan()}
        >
          Scan
        </div>
      </div>
    );
  }

  renderStep2() {
    return (
      <>
        {/* <div className="bridge-step-done">
          <div className="bridge-step-done-header">
            <div className={`${styles.headingS} ${styles.disabledText}`}>
              Find Transaction
            </div>
            <div className={`${"bridge-step-no"} ${styles.warningBg}`}>
              <CheckIcon />
            </div>
          </div>
          {this.state.currentMethod === 2 && (
            <div
              className={`${styles.paragraphS} ${
                styles.primaryText
              } ${"bridge-done-card-info"}`}
            >
              Transaction ID: {this.state.TXHash}
            </div>
          )}
        </div> */}
        <div className={`${"bridge-step-card"} ${styles.whiteBg}`}>
          <div className="bridge-step-card-header">
            <div>
              <div className={`${styles.headingS} ${styles.primaryText}`}>
                Pending transactions
              </div>
            </div>
            {/* <div
              className={`${"bridge-step-no"} ${styles.gradientBg} ${
                styles.labelSRegular
              } ${styles.whiteText}`}
            >
              2
            </div> */}
          </div>
          <>
            {/* <div className="bridge-step-info-text">
                <div className={`${styles.paragraphL} ${styles.primaryText}`}>
                  Last step
                </div>
                <div className={`${styles.paragraphS} ${styles.secondaryText}`}>
                  Submit transaction to receive your asset.
                </div>
              </div> */}
            {Object.keys(this.state.assetsToClaim).map((i) => (
              <div className="bridge-transaction-box">
                <div className="bridge-transaction-box-left-section">
                  <img
                    src={this.getTokenIcon(
                      config.token_metadata[this.state.assetsToClaim[i].tokenId]
                        .icon
                    )}
                    alt="asset-icon"
                    className="bridge-transaction-box-asset-icon"
                  />
                  <div className="bridge-transaction-box-left-text-area">
                    <div
                      className={`${styles.labelLRegular} ${styles.primaryText}`}
                    >
                      {this.state.assetsToClaim[i].tokenId}
                    </div>
                    <div
                      className={`${styles.labelSRegular} ${styles.secondaryText}`}
                    >
                      {(
                        parseInt(this.state.assetsToClaim[i].amount) /
                        10 **
                          config.tokens[this.state.assetsToClaim[i].tokenId][
                            this.state.assetsToClaim[i].destChain
                          ].decimals
                      ).toFixed(2)}{" "}
                      {this.state.assetsToClaim[i].tokenId}
                    </div>
                  </div>
                </div>
                <div className="bridge-transaction-box-center-section">
                  <img
                    src={this.getBlockchainIcon(
                      config.networks[this.state.assetsToClaim[i].srcChain].icon
                    )}
                    alt="blockchain-icon"
                    className="bridge-transaction-box-blockchain-icon"
                  />
                  <img
                    src={arrowIcon}
                    alt="arrow-right-icon"
                    className="bridge-transaction-box-arrow-right-icon"
                  />
                  <img
                    src={this.getBlockchainIcon(
                      config.networks[this.state.assetsToClaim[i].destChain]
                        .icon
                    )}
                    alt="blockchain-icon"
                    className="bridge-transaction-box-blockchain-icon"
                  />
                </div>
                <div className="bridge-transaction-box-right-section">
                  {/* conditie pentru network-ul tranzactiei respective */}
                  {this.getBlockchain(this.props.onboard.networkId) !==
                  this.state.assetsToClaim[i].destChain ? (
                    <div
                      className="bridge-transaction-box-button"
                      onClick={async () => {
                        try {
                          await this.props.manualChangeNetwork(
                            config.networks[
                              this.state.assetsToClaim[i].destChain
                            ].networkId
                          );
                        } catch (e) {
                          toastr.error(
                            "Error",
                            "Switching networks failed. Try switching manually."
                          );
                        }
                      }}
                    >
                      <div
                        className={`${styles.labelMBold} ${styles.gradientText}`}
                      >
                        Switch network
                      </div>
                    </div>
                  ) : (
                    <div className="bridge-transaction-box-button">
                      <div
                        className={`${styles.labelMBold} ${styles.gradientText}`}
                        onClick={() => this.claimTokens(i)}
                      >
                        CLAIM
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        </div>
      </>
    );
  }

  render() {
    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };
    return (
      <>
        {this.state.loadingOverlay === true && (
          <LoaderOverlay {...this.props} />
        )}
        {/* <div style={this.state.loadingOverlay === true ? displayBlur : {}}>
          {this.state.currentStep === 1 ? (
            <>{this.renderStep1()}</>
          ) : (
            <>
              {this.state.currentStep === 2 ? <>{this.renderStep2()}</> : <></>}
            </>
          )}
        </div> */}
        <div style={this.state.loadingOverlay === true ? displayBlur : {}}>
          {this.state.assetsToClaim.length > 0 && this.renderStep2()}
          {this.renderStep1()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  checkWallet,
  manualChangeNetwork,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BridgeFindTransaction);
