import React, { Component } from "react";
import { connect } from "react-redux";

import "../../utils/ethereum/onboard";
import {
  connectAccountOnboard,
  checkWallet,
  walletPersistance,
  manualChangeNetwork,
} from "../../redux/actions/OnboardActions";
import config from "../../config/config.json";

import BlockchainDropdown from "../../components/dropdowns/blockchain-dropdown/BlockchainDropdown";
import DisconnectDropdown from "../../components/dropdowns/disconnect-dropdown/DisconnectDropdown";
import AccountOverlay from "../../components/overlays/account/AccountOverlay";

import BlackChevronLeftIcon from "../../components/icons/BlackChevronLeftIcon";
import HamburgerMenuIcon from "../../components/icons/HamburgerMenuIcon";
import CloseIcon from "../../components/icons/CloseIcon";
import radarLogo from "../../assets/logos/radar.svg";
import styles from "../../styles/index.module.css";
import "./header.css";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentNet: "ETH",

      backButton: this.props.state.backButton,
      backButtonRoute: this.props.state.backButtonRoute,

      blockchainDropdown: false,
      disconnectDropdown: false,

      headerWrapperCss: "header-wrapper",
      headerContentCss: "header-content",
      blockchainDropdownCss: "blockchain-dropdown",
      headerBackButtonCss: "header-back-button",
      headerBlockchainButtonCss: "header-blockchain-button",
      headerAddressButtonCss: "header-address-button",
      headerAmountSectionCss: "header-amount-section",
      disconnectDropdownCss: "disconnect-dropdown",

      mobileHeaderDropdown: false,
    };
    this.toPage = this.toPage.bind(this);
  }

  toPage = (path) => {
    this.props.history.push(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.setState({
      mobileHeaderDropdown: false,
    });
  };

  getMenuItemIcon = (type) => {
    const icon = require(`../../assets/SVGs/sidebar/${type}`).default;
    return icon;
  };

  closeBlockchainDropdown = () => {
    this.setState({
      blockchainDropdown: false,
    });
  };

  // closeDisconnectDropdown = () => {
  //   this.setState({
  //     disconnectDropdown: false,
  //   });
  // };

  closeAccountOverlay = () => {
    this.setState({
      disconnectDropdown: false,
    });
  };

  connectWallet = () => {
    this.props.connectAccountOnboard();
  };

  getBlockchainIcon = (type) => {
    const icon = require(`../../assets/SVGs/${type}`).default;
    return icon;
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  displayAddress = (address) => {
    return `${address.substring(0, 2)}...${address.substring(38)}`;
  };

  componentDidMount = async () => {
    window.addEventListener("scroll", this.handleScroll);
    if (!this.props.onboard.walletConnected) this.props.walletPersistance();
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
    }
  }

  handleScroll = () => {
    if (window.scrollY > 16) {
      this.setState({
        headerWrapperCss: "header-wrapper-on-scroll",
        headerContentCss: "header-content-on-scroll",
        blockchainDropdownCss: "blockchain-dropdown-on-scroll",
        headerBackButtonCss: "header-back-button-on-scroll",
        headerBlockchainButtonCss: "header-blockchain-button-on-scroll",
        headerAddressButtonCss: "header-address-button-on-scroll",
        headerAmountSectionCss: "header-amount-section-on-scroll",
        disconnectDropdownCss: "disconnect-dropdown-on-scroll",
      });
    } else if (window.scrollY < 16) {
      this.setState({
        headerWrapperCss: "header-wrapper",
        headerContentCss: "header-content",
        blockchainDropdownCss: "blockchain-dropdown",
        headerBackButtonCss: "header-back-button",
        headerBlockchainButtonCss: "header-blockchain-button",
        headerAddressButtonCss: "header-address-button",
        headerAmountSectionCss: "header-amount-section",
        disconnectDropdownCss: "disconnect-dropdown",
      });
    }
  };

  render() {
    const noBottomBorderRadius = {
      borderRadius: "8px 8px 0px 0px",
    };
    const doNotDisplay = {
      display: "none",
    };
    return (
      <>
        {window.innerWidth > 1299 ? (
          <>
            <div className={`${this.state.headerWrapperCss}`}>
              <div
                className={`${styles.content} ${this.state.headerContentCss}`}
              >
                <div
                  className={
                    this.state.backButton === true
                      ? "header-layout-with-button"
                      : "header-layout"
                  }
                >
                  {this.state.backButton === true && (
                    <div
                      className={`${styles.button} ${
                        this.state.headerBackButtonCss
                      } ${"header-back-button"}`}
                      onClick={() => this.toPage(this.state.backButtonRoute)}
                    >
                      <BlackChevronLeftIcon />
                      <div
                        className={`${
                          styles.labelMBold
                        } ${"header-back-button-text"}`}
                      >
                        Back
                      </div>
                    </div>
                  )}
                  <div className="header-right-section">
                    <div
                      className={`${styles.button} ${this.state.headerBlockchainButtonCss}`}
                      style={
                        this.props.onboard.walletConnected === false
                          ? doNotDisplay
                          : {}
                      }
                      onClick={() =>
                        this.setState({
                          blockchainDropdown: true,
                          disconnectDropdown: false,
                        })
                      }
                    >
                      <div className={`${"header-blockchain-button-icon"}`}>
                        <img
                          src={
                            this.getBlockchain(this.props.onboard.networkId) !=
                              null &&
                            this.getBlockchainIcon(
                              config.networks[
                                this.getBlockchain(this.props.onboard.networkId)
                              ].icon
                            )
                          }
                          alt="blockchain-icon"
                          className="header-network-icon"
                        />
                      </div>
                    </div>
                    {this.props.onboard.walletConnected === true ? (
                      <div
                        className={`${styles.button} ${this.state.headerAddressButtonCss}`}
                        onClick={() =>
                          this.setState({
                            disconnectDropdown: true,
                            blockchainDropdown: false,
                          })
                        }
                      >
                        <div
                          className={`${styles.labelMBold} ${styles.secondaryText}  ${this.state.headerAmountSectionCss}`}
                        >
                          {parseFloat(
                            this.props.onboard.balance == null
                              ? 0
                              : this.props.onboard.balance / 10 ** 18
                          ).toFixed(2)}
                          {this.getBlockchain(this.props.onboard.networkId) !=
                          null
                            ? config.networks[
                                this.getBlockchain(this.props.onboard.networkId)
                              ].assetName
                            : ""}
                        </div>
                        <div
                          className={`${styles.labelMBold} ${
                            styles.primaryText
                          } ${"header-address-section"}`}
                        >
                          {this.displayAddress(
                            this.props.onboard.address
                              ? this.props.onboard.address
                              : ""
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${styles.button} ${
                          styles.gradientBg
                        } ${"header-connect-wallet-button"}`}
                        onClick={() => this.connectWallet()}
                      >
                        <div
                          className={`${styles.labelMBold} ${styles.whiteText}`}
                        >
                          Connect wallet
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.state.blockchainDropdown === true && (
              <BlockchainDropdown
                {...this.props}
                state={this.state}
                closeBlockchainDropdownEvent={this.closeBlockchainDropdown}
              />
            )}
            {/* {this.state.disconnectDropdown === true && (
              <DisconnectDropdown
                {...this.props}
                state={this.state}
                closeDisconnectDropdownEvent={this.closeDisconnectDropdown}
              />
            )} */}
            {this.state.disconnectDropdown === true && (
              <AccountOverlay
                {...this.props}
                state={this.state}
                closeAccountOverlayEvent={this.closeAccountOverlay}
              />
            )}
          </>
        ) : (
          <>
            <div className="mob-header-wrapper">
              <div
                className={`${"mob-header-box"} ${styles.elevationLow} ${
                  styles.whiteBg
                }`}
                style={
                  this.state.mobileHeaderDropdown === true
                    ? noBottomBorderRadius
                    : {}
                }
              >
                <div
                  className="mob-header-logo-section"
                  onClick={() => this.toPage("/")}
                >
                  <img
                    src={radarLogo}
                    alt="radar-logo"
                    className="mob-header-logo"
                  />
                </div>
                {this.state.mobileHeaderDropdown === false ? (
                  <div
                    className="mob-header-menu-section"
                    onClick={() =>
                      this.setState({
                        mobileHeaderDropdown: true,
                      })
                    }
                  >
                    <HamburgerMenuIcon />
                  </div>
                ) : (
                  <div
                    className="mob-header-menu-section"
                    onClick={() =>
                      this.setState({
                        mobileHeaderDropdown: false,
                      })
                    }
                  >
                    <CloseIcon />
                  </div>
                )}
              </div>
              {this.state.mobileHeaderDropdown === true ? (
                <div
                  className={`${"mob-header-dropdown-box"} ${
                    styles.elevationLow
                  } ${styles.whiteBg}`}
                >
                  {Object.keys(config.sidebar).map((i) => (
                    <div
                      className="mob-header-dropdown-row"
                      onClick={() => this.toPage(config.sidebar[i].route)}
                    >
                      <img
                        src={
                          config.sidebar[i].route ===
                          this.props.location.pathname
                            ? this.getMenuItemIcon(config.sidebar[i].hoverIcon)
                            : this.getMenuItemIcon(config.sidebar[i].icon)
                        }
                        alt="menu-icon"
                        className="mob-header-dropdown-menu-icon"
                      />
                      {config.sidebar[i].route ===
                      this.props.location.pathname ? (
                        <div
                          className={`${styles.labelMBold} ${styles.gradientText}`}
                        >
                          {config.sidebar[i].text}
                        </div>
                      ) : (
                        <div
                          className={`${styles.labelMBold} ${styles.primaryText}`}
                        >
                          {config.sidebar[i].text}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  connectAccountOnboard,
  checkWallet,
  walletPersistance,
  manualChangeNetwork,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
