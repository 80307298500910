import React, { Component } from 'react';

import icon from '../../assets/SVGs/interface.svg';

class BlackChevronLeftIcon extends Component {

    render() {

        return (

            <>
                <img
                    src={icon}
                    alt='icon'
                />
            </>
        )
    }
}

export default BlackChevronLeftIcon;