import { BigNumber, ethers } from "ethers";
import config from "./../../config/config.json";

import ISwapperABI from "./abis/ISwapper.json";
import { getOraclePrice, scaleDecimals } from "./api";

export const getSwapData = async (
    rpc,
    requiredMinAmounts,
    oracleAddress,
    collateralAddress,
    collateralDecimals,
    collateralUnderlyingAddress,
    collateralUnderlyingDecimals,
    startingAmountInUSD,
    slippage
) => {
    var lastMinAmountUnscaledInUSD = startingAmountInUSD;
    var minAmounts = [];
    var dataTypes = [];

    for(var i = 0; i < requiredMinAmounts.length; i++) {
        dataTypes.push("uint256");
        var currentAsset = requiredMinAmounts[i];
        if (currentAsset.type == "inter") {
            var slippedAmount = lastMinAmountUnscaledInUSD - lastMinAmountUnscaledInUSD * slippage;
            lastMinAmountUnscaledInUSD = slippedAmount;
            var assetPrice = await getOraclePrice(rpc, oracleAddress, currentAsset.address);
            var minAmountUnscaled = slippedAmount / assetPrice;
            var minAmount = scaleDecimals(minAmountUnscaled, currentAsset.decimals);
            minAmounts.push(minAmount);
        } else if (currentAsset.type == "collateral") {
            var slippedAmount = lastMinAmountUnscaledInUSD - lastMinAmountUnscaledInUSD * slippage;
            lastMinAmountUnscaledInUSD = slippedAmount;
            var assetPrice = await getOraclePrice(rpc, oracleAddress, collateralAddress);
            var minAmountUnscaled = slippedAmount / assetPrice;
            var minAmount = scaleDecimals(minAmountUnscaled, collateralDecimals);
            minAmounts.push(minAmount);
        } else if (currentAsset.type == "USDR") {
            var slippedAmount = lastMinAmountUnscaledInUSD - lastMinAmountUnscaledInUSD * slippage;
            lastMinAmountUnscaledInUSD = slippedAmount;
            var minAmount = scaleDecimals(slippedAmount, 18);
            minAmounts.push(minAmount);
        } else if (currentAsset.type == "collateralUnderlying") {
            var slippedAmount = lastMinAmountUnscaledInUSD - lastMinAmountUnscaledInUSD * slippage;
            lastMinAmountUnscaledInUSD = slippedAmount;
            var assetPrice = await getOraclePrice(rpc, oracleAddress, collateralUnderlyingAddress);
            var minAmountUnscaled = slippedAmount / assetPrice;
            var minAmount = scaleDecimals(minAmountUnscaled, collateralUnderlyingDecimals);
            minAmounts.push(minAmount)
        }
    }

    const abiCoder = new ethers.utils.AbiCoder();

    const swapData = abiCoder.encode(dataTypes, minAmounts);

    return swapData;
}

export const getMinUSDRAmount = (
    swapsLength,
    startingAmountInUSD,
    slippage
) => {
    return startingAmountInUSD * ((1 - slippage)**swapsLength);
}