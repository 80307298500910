import React, { Component } from "react";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import { toastr } from "react-redux-toastr";
import { ethers } from "ethers";
import { abi as BridgeAbi } from "../../../../web3/bridge/ABIs/RadarBridge.json";
import {
  checkWallet,
  manualChangeNetwork,
} from "../../../../redux/actions/OnboardActions";
import {
  getUserRadarBalance,
  getRadarPrice,
} from "../../../../web3/bonds/lib/dao";

// Web3 bridge
import IERC20 from "../../../../web3/bridge/ABIs/IERC20.json";
import RadarBridge from "../../../../web3/bridge/ABIs/RadarBridge.json";
import { fullNumber, sleep } from "../../../../utils/functions/utilFunctions";
import { getBridgeSignature } from "../../../../web3/bridge/api";

import "../../../../utils/ethereum/onboard";
import config from "../../../../config/config.json";

import Loader from "../../../../components/loader/Loader";
import LoaderOverlay from "../../../../components/overlays/loader/LoaderOverlay";
import CaretDownIcon from "../../../../components/icons/CaretDownIcon";
import CaretUpIcon from "../../../../components/icons/CaretUpIcon";
import ToggleOnIcon from "../../../../components/icons/ToggleOnIcon";
import ToggleOffIcon from "../../../../components/icons/ToggleOffIcon";
import CheckIcon from "../../../../components/icons/CheckIcon";

import copyIcon from "../../../../assets/SVGs/copy.svg";
import gradLinkIcon from "../../../../assets/SVGs/gradLink.svg";
import styles from "../../../../styles/index.module.css";
import "./transferFunds.css";
import { getTokenBalance, scaleDecimals } from "../../../../web3/usdr/api";
import { coingeckoGetPrice } from "../../../../web3/bonds/utils/utils";

class BridgeTransferFunds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      tokenDropdown: false,
      firstBlockchainDropdown: false,
      secondBlockchainDropdown: false,

      selectedToken: "RADAR",
      firstBlockchain: "BSC",
      secondBlockchain: "ETH",
      requiredNetwork: 1,

      amount: "",
      amountInUSD: 0,
      amountInUSDLoading: false,

      currentTokenBalance: 0,
      currentTokenBalanceLoading: false,

      addressToggle: true,
      address: "",

      allowance: 0,

      maxAmountAlert: false,
      loadingOverlay: false,

      tokensToClaim: false,

      transactionId: "",

      selectedTokenPrice: 0,

      showFees: false,

      maxAmountClicked: false,

      explorerURL: "",

      step3Data: {
        asset: "",
        from: "",
        to: "",
        amount: "",
        requiredNetworkId: -1,
      },
    };
  }

  displayTransactionId = (id) => {
    return `${id.substring(0, 2)}...${id.substring(60)}`;
  };

  renderLoading() {
    return <Loader {...this.props} />;
  }

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  getTokenIcon = (type) => {
    const icon = require(`../../../../assets/SVGs/assets/${type}`).default;
    return icon;
  };

  getBlockchainIcon = (type) => {
    const icon = require(`../../../../assets/SVGs/${type}`).default;
    return icon;
  };

  selectToken = async (e, symbol) => {
    e.preventDefault();
    await this.setState({
      selectedToken: symbol,
      tokenDropdown: false,
      amount: "",
      amountInUSD: 0,
      maxAmountClicked: false,
    });
    await this.setInitialBlockchains();
    await this.getSelectedTokenBalance();
    await this.getSelectedTokenPrice();
    await this.getAllowance();
  };

  setInitialBlockchains = async () => {
    if (this.state.currentStep != 1) {
      return;
    }
    const currentNetwork = this.getBlockchain(this.props.onboard.networkId);
    const selectedToken = this.state.selectedToken;
    if (!Object.keys(config.tokens[selectedToken]).includes(currentNetwork)) {
      const fb = Object.keys(config.tokens[selectedToken])[0];
      const sb = Object.keys(config.tokens[selectedToken])[1];
      await this.setState({
        firstBlockchain: fb,
        secondBlockchain: sb,
        requiredNetwork: config.networks[fb].networkId,
        selectedToken,
      });
      await this.props.manualChangeNetwork(config.networks[fb].networkId);
    } else {
      var secondBlockchain;
      if (
        Object.keys(config.tokens[selectedToken]).includes(
          this.state.secondBlockchain
        ) &&
        this.state.secondBlockchain != currentNetwork
      ) {
        secondBlockchain = this.state.secondBlockchain;
      } else {
        for (
          var i = 0;
          i < Object.keys(config.tokens[selectedToken]).length;
          i++
        ) {
          const n = Object.keys(config.tokens[selectedToken])[i];
          if (n != currentNetwork) {
            secondBlockchain = n;
            break;
          }
        }
      }

      await this.setState({
        firstBlockchain: currentNetwork,
        secondBlockchain: secondBlockchain,
        requiredNetwork: config.networks[currentNetwork].networkId,
        selectedToken,
      });
    }
  };

  selectBlockchain = async (e, which, chain) => {
    e.preventDefault();
    var beforeFirst = this.state.firstBlockchain;
    var beforeSeconds = this.state.secondBlockchain;
    if (which == 0) {
      if (chain == this.state.secondBlockchain) {
        await this.setState({
          secondBlockchain: this.state.firstBlockchain,
          firstBlockchain: chain,
          requiredNetwork: config.networks[chain].networkId,
          firstBlockchainDropdown: false,
        });
      } else {
        await this.setState({
          firstBlockchain: chain,
          requiredNetwork: config.networks[chain].networkId,
          firstBlockchainDropdown: false,
        });
      }
    } else {
      if (chain == this.state.firstBlockchain) {
        await this.setState({
          firstBlockchain: this.state.secondBlockchain,
          requiredNetwork:
            config.networks[this.state.secondBlockchain].networkId,
          secondBlockchain: chain,
          secondBlockchainDropdown: false,
        });
      } else {
        await this.setState({
          secondBlockchain: chain,
          secondBlockchainDropdown: false,
        });
      }
    }
    if (
      this.getBlockchain(this.props.onboard.networkId) !==
      this.state.firstBlockchain
    ) {
      try {
        await this.props.manualChangeNetwork(
          config.networks[this.state.firstBlockchain].networkId
        );
      } catch (e) {
        await this.setState({
          firstBlockchain: beforeFirst,
          secondBlockchain: beforeSeconds,
          requiredNetwork: config.networks[beforeFirst].networkId,
        });
      }
    }
  };

  getSelectedTokenBalance = async () => {
    await this.setState({
      currentTokenBalanceLoading: true,
    });

    const bl = this.getBlockchain(this.props.onboard.networkId);
    const rpc = config.networks[bl].endpoint;
    const tokenAddress = config.tokens[this.state.selectedToken][bl].address;
    const userAddress = this.props.onboard.address;
    const assetDecimals = config.tokens[this.state.selectedToken][bl].decimals;

    var bal = await getTokenBalance(rpc, tokenAddress, userAddress);
    bal /= 10 ** assetDecimals;

    await this.setState({
      currentTokenBalance: bal,
      currentTokenBalanceLoading: false,
    });
  };

  getSelectedTokenPrice = async () => {
    await this.setState({
      amountInUSDLoading: true,
    });

    const price = await coingeckoGetPrice(
      config.token_metadata[this.state.selectedToken].coingeckoid
    );

    await this.setState({
      amountInUSD: this.state.amount != "" ? this.state.amount * price : 0,
      amountInUSDLoading: false,
      selectedTokenPrice: price,
    });
  };

  amountField = (e) => {
    if (
      parseFloat(e.target.value) > parseFloat(this.state.currentTokenBalance)
    ) {
      this.setState({ maxAmountAlert: true });
    } else {
      this.setState({ maxAmountAlert: false });
    }
    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") return;
    }
    var value = e.target.value;
    this.setState({
      amount: value,
      amountInUSD:
        value != "" ? parseFloat(value) * this.state.selectedTokenPrice : 0,
      maxAmountClicked: false,
    });
  };

  getInitialAddress = () => {
    this.setState({
      address: this.props.onboard.address,
    });
  };

  addressField = (e) => {
    var value = e.target.value;
    this.setState({
      address: value,
    });
  };

  getAllowance = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      const bl = this.getBlockchain(this.props.onboard.networkId);
      const RadarInterface = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(IERC20.abi))
      );
      const Radar = new ethers.Contract(
        config.tokens[this.state.selectedToken][bl].address,
        RadarInterface,
        provider
      );
      const allowance = await Radar.allowance(
        this.props.onboard.address,
        config.networks[bl].bridgeAddress
      );
      await this.setState({
        allowance:
          allowance /
          10 ** config.tokens[this.state.selectedToken][bl].decimals,
      });

      console.log("Allowance: " + allowance.toString());
    } catch (e) {
      console.log(e);
    }
  };

  approve = async () => {
    if (this.state.requiredNetwork != this.props.onboard.networkId) {
      return;
    }
    if (this.state.maxAmountAlert === false) {
      this.setState({
        loadingOverlay: true,
      });
      try {
        const provider = new ethers.providers.Web3Provider(
          this.props.onboard.provider
        );
        const signer = await provider.getSigner();
        // Interface
        const TokenInterface = new ethers.utils.Interface(
          JSON.parse(JSON.stringify(IERC20.abi))
        );
        const Token = new ethers.Contract(
          config.tokens[this.state.selectedToken][
            this.state.firstBlockchain
          ].address,
          TokenInterface,
          signer
        );
        var approveAmount =
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
        const receipt = await Token.approve(
          config.networks[this.state.firstBlockchain].bridgeAddress,
          approveAmount
        );
        await receipt.wait();
        toastr.success("Success", "Approve successful");
      } catch (e) {
        console.log(e);
        toastr.error("Error", "Error approving: " + e.message);
      }
      await sleep(2000);
      await this.getAllowance();
      this.setState({
        loadingOverlay: false,
      });
    } else {
    }
  };

  getCurrentStepFromStorageState = async () => {
    try {
      var currentBridge = window.localStorage.getItem("currentBridge");
      if (
        currentBridge == undefined ||
        currentBridge == null ||
        currentBridge == "" ||
        typeof currentBridge == "undefined"
      ) {
        await this.setState({ currentStep: 1 });
        return;
      }

      currentBridge = JSON.parse(currentBridge);
      console.log("Current Bridge:");
      console.log(currentBridge);
      if (!Object.keys(currentBridge).includes("txHash")) {
        await this.setState({ currentStep: 1 });
        return;
      }

      if (
        Object.keys(currentBridge).includes("txHash") &&
        !Object.keys(currentBridge).includes("signature")
      ) {
        await this.setState({ currentStep: 2 });
        await this.step2Operations();
        return;
      }

      if (
        Object.keys(currentBridge).includes("txHash") &&
        Object.keys(currentBridge).includes("signature")
      ) {
        await this.setState({ currentStep: 3 });
        await this.step3Load();
        return;
      }

      await this.setState({ currentStep: 1 });
    } catch (e) {
      console.log(e);
      await this.setState({
        currentStep: 1,
      });
    }
  };

  step2Operations = async () => {
    try {
      var currentBridge = window.localStorage.getItem("currentBridge");
      if (
        currentBridge == undefined ||
        currentBridge == null ||
        currentBridge == "" ||
        typeof currentBridge == "undefined"
      ) {
        window.location.reload(false);
        return;
      }

      currentBridge = JSON.parse(currentBridge);
      if (!Object.keys(currentBridge).includes("txHash")) {
        window.location.reload(false);
        return;
      }

      const txHash = currentBridge.txHash;
      this.setState({ transactionId: txHash });
      const from = currentBridge.from;
      this.setState({ explorerURL: config.networks[from].explorerURL });
      const token = currentBridge.token;

      const provider = new ethers.providers.JsonRpcProvider(
        config.networks[from].endpoint
      );

      var isConfirmed = false;
      while (!isConfirmed) {
        await sleep(2500);

        const txReceipt = await provider.getTransactionReceipt(txHash);
        isConfirmed = txReceipt && txReceipt.blockNumber;
      }

      const signature = await getBridgeSignature(
        txHash.toString(),
        from,
        token
      );

      if (!signature) {
        toastr.error(
          "Error",
          'Couldn\'t retrieve signature. Try refreshing the page or using the "Find Transactions" tab.'
        );
        window.localStorage.setItem("currentBridge", "{}");
        await this.setState({
          currentStep: 1,
        });
        return;
      }

      currentBridge["signature"] = signature;
      window.localStorage.setItem(
        "currentBridge",
        JSON.stringify(currentBridge)
      );
      toastr.success("Success", "Tokens Bridged");
      await this.setState({ currentStep: 3 });
      this.step3Load();
    } catch (e) {
      console.log(e);
      await this.setState({
        currentStep: 1,
      });
    }
  };

  step3Load = async () => {
    const currentBridge = JSON.parse(
      window.localStorage.getItem("currentBridge")
    );
    const step3Data = {
      asset: currentBridge.signature.tokenId,
      from: currentBridge.from,
      to: currentBridge.signature.destChain,
      amount: (
        parseInt(currentBridge.signature.amount) /
        10 **
          config.tokens[currentBridge.signature.tokenId][
            currentBridge.signature.destChain
          ].decimals
      ).toFixed(2),
      requiredNetworkId:
        config.networks[currentBridge.signature.destChain].networkId,
    };

    await this.setState({ step3Data });
  };

  swap = async () => {
    if (this.state.requiredNetwork != this.props.onboard.networkId) {
      return;
    }
    if (this.state.maxAmountAlert === false) {
      this.setState({
        loadingOverlay: true,
      });
      try {
        const provider = new ethers.providers.Web3Provider(
          this.props.onboard.provider
        );

        const signer = await provider.getSigner();

        // Interface
        const BridgeInterface = new ethers.utils.Interface(
          JSON.parse(JSON.stringify(RadarBridge.abi))
        );

        const Bridge = new ethers.Contract(
          config.networks[this.state.firstBlockchain].bridgeAddress,
          BridgeInterface,
          signer
        );

        var amount;
        const bl = this.getBlockchain(this.props.onboard.networkId);
        if (this.state.maxAmountClicked) {
          const rpc = config.networks[bl].endpoint;
          const tokenAddress =
            config.tokens[this.state.selectedToken][bl].address;
          const userAddress = this.props.onboard.address;
          const assetDecimals =
            config.tokens[this.state.selectedToken][bl].decimals;

          amount = await getTokenBalance(rpc, tokenAddress, userAddress);
        } else {
          amount = scaleDecimals(
            this.state.amount,
            config.tokens[this.state.selectedToken][bl].decimals
          );
        }

        var destinationAddress =
          this.state.addressToggle === true
            ? this.state.address
            : this.props.onboard.address;

        const receipt = await Bridge.bridgeTokens(
          config.tokens[this.state.selectedToken][this.state.firstBlockchain]
            .address,
          amount,
          ethers.utils.formatBytes32String(this.state.secondBlockchain),
          destinationAddress
        );
        const lsObject = {
          txHash: receipt.hash.toString(),
          from: this.state.firstBlockchain,
          token: this.state.selectedToken,
        };
        localStorage.setItem("currentBridge", JSON.stringify(lsObject));
        await this.setState({
          loadingOverlay: false,
          currentStep: 2,
          transactionId: receipt.hash.toString(),
          explorerURL: config.networks[this.state.firstBlockchain].explorerURL,
        });

        await receipt.wait();
        await sleep(5000);

        await this.step2Operations();
      } catch (e) {
        console.log(e);
        toastr.error("Error", "Error bridging tokens: " + e.message);
      }
      this.setState({
        loadingOverlay: false,
      });
    } else {
    }
  };

  claimTokens = async () => {
    console.log("claim tokens started");
    await this.setState({
      loadingOverlay: true,
    });
    try {
      const signature = JSON.parse(
        window.localStorage.getItem("currentBridge")
      ).signature;

      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      const signer = await provider.getSigner();

      const RadarBridgeInterface = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(BridgeAbi))
      );

      const RadarBridge = new ethers.Contract(
        config.networks[signature.destChain].bridgeAddress,
        RadarBridgeInterface,
        signer
      );

      const receipt = await RadarBridge.claimTokens(
        ethers.utils.formatBytes32String(signature.tokenId),
        signature.amount,
        ethers.utils.formatBytes32String(signature.srcChain),
        ethers.utils.formatBytes32String(signature.destChain),
        signature.srcTimestamp,
        signature.nonce,
        signature.destAddress,
        signature.signature
      );
      await receipt.wait();

      toastr.success("Success", "Tokens claimed");
      window.localStorage.setItem("currentBridge", "{}");
      await sleep(2500);
      window.location.reload(false);
    } catch (e) {
      console.log(e);
      toastr.error("Error", "Error claiming tokens: " + e.message);
    }
    await this.setState({
      loadingOverlay: false,
    });
  };

  goToDestinationBlockchain = async () => {
    try {
      await this.props.manualChangeNetwork(
        this.state.step3Data.requiredNetworkId
      );
    } catch (e) {
      toastr.error(
        "Error",
        "Switching networks failed. Try switching manually."
      );
    }
  };

  transferAgain = async () => {
    const signature = JSON.parse(
      window.localStorage.getItem("currentBridge")
    ).signature;
    var cacheData = await window.localStorage.getItem("claim");
    if (cacheData != null) cacheData = JSON.parse(cacheData);
    else cacheData = [];
    cacheData.push(signature);
    await window.localStorage.setItem("claim", JSON.stringify(cacheData));
    await window.localStorage.setItem("currentBridge", "{}");
    window.location.reload(false);
  };

  async componentDidMount() {
    await this.getCurrentStepFromStorageState();
    await this.setInitialBlockchains();
    await this.getSelectedTokenPrice();
    this.getSelectedTokenBalance();
    this.getInitialAddress();
    this.getAllowance();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
      await this.setState({
        amount: "",
        amountInUSD: 0,
      });
      await this.setInitialBlockchains();
      await this.getSelectedTokenPrice();
      this.getSelectedTokenBalance();
      this.getInitialAddress();
      this.getAllowance();
    }
  }

  renderStep1() {
    const doNotDisplay = {
      display: "none",
    };

    const disabledButton = {
      opacity: "0.75",
      cursor: "initial",
    };

    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };

    var buttonStyle;
    var buttonText;
    var buttonOnClick;
    if (this.state.amount == 0 || this.state.amount == "") {
      buttonText = "Enter an amount";
      buttonStyle = `${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`;
      buttonOnClick = () => {};
    } else if (this.state.requiredNetwork != this.props.onboard.networkId) {
      buttonText =
        "Switch network to " + this.getBlockchain(this.state.requiredNetwork);
      buttonStyle = `${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`;
      buttonOnClick = async () => {
        try {
          await this.props.manualChangeNetwork(this.state.requiredNetwork);
        } catch (e) {
          toastr.error(
            "Error",
            "Switching networks failed. Try switching manually."
          );
        }
      };
    } else if (this.state.maxAmountAlert) {
      buttonText = "Insufficient balance";
      buttonStyle = `${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`;
      buttonOnClick = () => {};
    } else if (this.state.allowance < parseInt(this.state.amount)) {
      buttonText = "Approve";
      buttonStyle = `${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`;
      buttonOnClick = () => this.approve();
    } else {
      buttonText = "Swap";
      buttonStyle = `${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`;
      buttonOnClick = () => this.swap();
    }

    var fee = 0;
    if (this.state.amount != 0 && this.state.amount != "") {
      fee = Math.min(
        parseFloat(this.state.amount) *
          config.tokens[this.state.selectedToken][
            this.state.firstBlockchain
          ].fee.percentage,
        config.tokens[this.state.selectedToken][
          this.state.firstBlockchain
        ].fee.max
      );
    }

    return (
      <>
        <div
          className={`${"bridge-step-card"} ${styles.whiteBg}`}
          style={this.state.loadingOverlay === true ? displayBlur : {}}
        >
          <div className="bridge-step-card-header">
            <div className={`${styles.headingS} ${styles.primaryText}`}>
              Asset and network
            </div>
            <div
              className={`${"bridge-step-no"} ${styles.gradientBg} ${
                styles.labelSRegular
              } ${styles.whiteText}`}
            >
              1
            </div>
          </div>
          <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
            I want to transfer
          </div>
          {this.state.tokenDropdown === true ? (
            <>
              <div style={{ position: "relative" }}>
                <div
                  className="bridge-dropdown-selector-box gradient-border"
                  onClick={() =>
                    this.setState({
                      tokenDropdown: false,
                    })
                  }
                >
                  <div className="bridge-dropdown-selector-box-asset-section">
                    <img
                      src={this.getTokenIcon(
                        config.token_metadata[this.state.selectedToken].icon
                      )}
                      alt="token-icon"
                      className="bridge-dropdown-asset-icon"
                    />
                    <div
                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                    >
                      {this.state.selectedToken}
                    </div>
                  </div>
                  <CaretUpIcon />
                </div>
                <div
                  className={`${"bridge-dropdown"} ${styles.whiteBg} ${
                    styles.elevationLow
                  }`}
                >
                  {Object.keys(config.tokens).map((symbol) => (
                    <div
                      className="bridge-dropdown-row"
                      style={
                        symbol === this.state.selectedToken ? doNotDisplay : {}
                      }
                      onClick={(e) => this.selectToken(e, symbol)}
                    >
                      <img
                        src={this.getTokenIcon(
                          config.token_metadata[symbol].icon
                        )}
                        alt="token-icon"
                        className="bridge-dropdown-asset-icon"
                      />
                      <div
                        className={`${styles.labelMRegular} ${styles.primaryText}`}
                      >
                        {symbol}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div
              className="bridge-dropdown-selector-box"
              onClick={() =>
                this.setState({
                  tokenDropdown: true,
                  firstBlockchainDropdown: false,
                  secondBlockchainDropdown: false,
                })
              }
            >
              <div className="bridge-dropdown-selector-box-asset-section">
                <img
                  src={this.getTokenIcon(
                    config.token_metadata[this.state.selectedToken].icon
                  )}
                  alt="token-icon"
                  className="bridge-dropdown-asset-icon"
                />
                <div
                  className={`${styles.labelMRegular} ${styles.primaryText}`}
                >
                  {this.state.selectedToken}
                </div>
              </div>
              <CaretDownIcon />
            </div>
          )}
          <div className="bridge-blockchains-frame">
            <div className="bridge-blockchain-section">
              <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
                From
              </div>
              {this.state.firstBlockchainDropdown === true ? (
                <>
                  <div
                    className="bridge-dropdown-selector-box gradient-border"
                    onClick={() =>
                      this.setState({
                        firstBlockchainDropdown: false,
                      })
                    }
                  >
                    <div className="bridge-dropdown-selector-box-asset-section">
                      <img
                        src={this.getBlockchainIcon(
                          config.networks[this.state.firstBlockchain].icon
                        )}
                        alt="token-icon"
                        className="bridge-dropdown-asset-icon"
                      />
                      <div
                        className={`${styles.labelMRegular} ${styles.primaryText}`}
                      >
                        {config.networks[this.state.firstBlockchain].name}
                      </div>
                    </div>
                    <CaretUpIcon />
                  </div>
                  <div
                    className={`${"bridge-dropdown half-dd"} ${
                      styles.whiteBg
                    } ${styles.elevationLow}`}
                  >
                    {Object.keys(config.tokens[this.state.selectedToken]).map(
                      (chain) => (
                        <div
                          className="bridge-dropdown-row"
                          style={
                            chain === this.state.firstBlockchain
                              ? doNotDisplay
                              : {}
                          }
                          onClick={(e) => this.selectBlockchain(e, 0, chain)}
                        >
                          <img
                            src={this.getBlockchainIcon(
                              config.networks[chain].icon
                            )}
                            alt="token-icon"
                            className="bridge-dropdown-asset-icon"
                          />
                          <div
                            className={`${styles.labelMRegular} ${styles.primaryText}`}
                          >
                            {config.networks[chain].name}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              ) : (
                <div
                  className="bridge-dropdown-selector-box"
                  onClick={() =>
                    this.setState({
                      firstBlockchainDropdown: true,
                      secondBlockchainDropdown: false,
                      tokenDropdown: false,
                    })
                  }
                >
                  <div className="bridge-dropdown-selector-box-asset-section">
                    <img
                      src={this.getBlockchainIcon(
                        config.networks[this.state.firstBlockchain].icon
                      )}
                      alt="token-icon"
                      className="bridge-dropdown-asset-icon"
                    />
                    <div
                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                    >
                      {config.networks[this.state.firstBlockchain].name}
                    </div>
                  </div>
                  <CaretDownIcon />
                </div>
              )}
            </div>
            <div className="bridge-blockchain-section">
              <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
                To
              </div>
              {this.state.secondBlockchainDropdown === true ? (
                <>
                  <div
                    className="bridge-dropdown-selector-box gradient-border"
                    onClick={() =>
                      this.setState({
                        secondBlockchainDropdown: false,
                      })
                    }
                  >
                    <div className="bridge-dropdown-selector-box-asset-section">
                      <img
                        src={this.getBlockchainIcon(
                          config.networks[this.state.secondBlockchain].icon
                        )}
                        alt="token-icon"
                        className="bridge-dropdown-asset-icon"
                      />
                      <div
                        className={`${styles.labelMRegular} ${styles.primaryText}`}
                      >
                        {config.networks[this.state.secondBlockchain].name}
                      </div>
                    </div>
                    <CaretUpIcon />
                  </div>
                  <div
                    className={`${"bridge-dropdown half-dd"} ${
                      styles.whiteBg
                    } ${styles.elevationLow}`}
                  >
                    {Object.keys(config.tokens[this.state.selectedToken]).map(
                      (chain) => (
                        <div
                          className="bridge-dropdown-row"
                          style={
                            chain === this.state.secondBlockchain
                              ? doNotDisplay
                              : {}
                          }
                          onClick={(e) => this.selectBlockchain(e, 1, chain)}
                        >
                          <img
                            src={this.getBlockchainIcon(
                              config.networks[chain].icon
                            )}
                            alt="token-icon"
                            className="bridge-dropdown-asset-icon"
                          />
                          <div
                            className={`${styles.labelMRegular} ${styles.primaryText}`}
                          >
                            {config.networks[chain].name}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              ) : (
                <div
                  className="bridge-dropdown-selector-box"
                  onClick={() =>
                    this.setState({
                      secondBlockchainDropdown: true,
                      firstBlockchainDropdown: false,
                      tokenDropdown: false,
                    })
                  }
                >
                  <div className="bridge-dropdown-selector-box-asset-section">
                    <img
                      src={this.getBlockchainIcon(
                        config.networks[this.state.secondBlockchain].icon
                      )}
                      alt="token-icon"
                      className="bridge-dropdown-asset-icon"
                    />
                    <div
                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                    >
                      {config.networks[this.state.secondBlockchain].name}
                    </div>
                  </div>
                  <CaretDownIcon />
                </div>
              )}
            </div>
          </div>
          <div className="bridge-amount-section">
            <div className="bridge-amount-info">
              <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
                Amount
              </div>
              <div
                className="bridge-amount-balance-section"
                onClick={() =>
                  this.setState({
                    amount: this.state.currentTokenBalance.toFixed(4),
                    amountInUSD:
                      this.state.currentTokenBalance *
                      this.state.selectedTokenPrice,
                    maxAmountClicked: true,
                  })
                }
              >
                <div
                  className={`${styles.labelSRegular} ${
                    styles.secondaryText
                  } ${"bridge-balance-button"}`}
                >
                  Balance
                </div>
                {this.state.currentTokenBalanceLoading === true ? (
                  <>{this.renderLoading()}</>
                ) : (
                  <div
                    className={`${styles.labelSRegular} ${styles.secondaryText}`}
                  >
                    {this.state.currentTokenBalance.toFixed(2)}{" "}
                    {this.state.selectedToken}
                  </div>
                )}
              </div>
            </div>
            <div className={`${styles.inputBox}`}>
              <input
                type="text"
                id="amount"
                name="amount"
                placeholder="0.00"
                value={this.state.amount}
                onChange={(e) => this.amountField(e)}
                style={{
                  fontSize: "21px",
                  lineHeight: "32px",
                }}
              />
              {this.state.amountInUSDLoading === true ? (
                <>{this.renderLoading()}</>
              ) : (
                <div
                  className={`${styles.labelSRegular} ${styles.secondaryText}`}
                >
                  ${this.state.amountInUSD.toFixed(2)}
                </div>
              )}
            </div>
          </div>
          <div className="bridge-address-section">
            {this.state.addressToggle === true ? (
              <div
                className="bridge-address-toggle-section"
                onClick={() =>
                  this.setState({
                    addressToggle: false,
                  })
                }
              >
                <ToggleOnIcon />
                <div
                  className={`${styles.labelSBold} ${
                    styles.primaryText
                  } ${"bridge-address-toggle-info"}`}
                >
                  Receive tokens onto the same address
                </div>
              </div>
            ) : (
              <>
                <div className="bridge-address-toggle-off-header">
                  <div
                    className="bridge-address-toggle-section"
                    onClick={() =>
                      this.setState({
                        addressToggle: true,
                      })
                    }
                  >
                    <ToggleOffIcon />
                    <div
                      className={`${styles.labelSBold} ${
                        styles.primaryText
                      } ${"bridge-address-toggle-info"}`}
                    >
                      Receive tokens onto the same address
                    </div>
                  </div>
                  <div
                    className={`${styles.labelSBold} ${
                      styles.secondaryText
                    } ${"bridge-same-address-button"}`}
                    onClick={() =>
                      this.setState({
                        address: this.props.onboard.address,
                      })
                    }
                  >
                    Use same address
                  </div>
                </div>
                <div className={`${styles.inputBox} ${""}`}>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={this.state.address}
                    onChange={(e) => this.addressField(e)}
                    style={{
                      fontSize: "15px",
                      lineHeight: "24px",
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className={buttonStyle} onClick={buttonOnClick}>
            {buttonText}
          </div>
          {this.state.amount !== 0 && this.state.amount !== "" && (
            <div className={styles.orderDetailsSection}>
              <div className={`${styles.labelMBold} ${styles.primaryText}`}>
                Order details
              </div>
              <div className={styles.orderDetailsRow}></div>
              <div className={styles.orderDetailsRow}>
                <div
                  className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                >
                  Deposit on {this.state.firstBlockchain}
                </div>
                <div
                  className={`${styles.labelXSRegular} ${styles.primaryText}`}
                >
                  {parseFloat(this.state.amount).toFixed(2)}{" "}
                  {this.state.selectedToken} ($
                  {this.state.amountInUSD.toFixed(2)})
                </div>
              </div>
              <div className={styles.orderDetailsRow}>
                <div
                  className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                >
                  Receive on {this.state.secondBlockchain}
                </div>
                <div
                  className={`${styles.labelXSRegular} ${styles.primaryText}`}
                >
                  {(parseFloat(this.state.amount) - fee).toFixed(2)}{" "}
                  {this.state.selectedToken} ($
                  {(
                    (parseFloat(this.state.amount) - fee) *
                    this.state.selectedTokenPrice
                  ).toFixed(2)}
                  )
                </div>
              </div>
              <div className={styles.orderDetailsRow}>
                <div
                  className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                >
                  Fees
                </div>
                <div className={styles.orderDetailsFeesRow}>
                  <div
                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                  >
                    {fee.toFixed(2)} {this.state.selectedToken} ($
                    {(fee * this.state.selectedTokenPrice).toFixed(2)})
                  </div>
                  {this.state.showFees === true ? (
                    <div
                      className={styles.orderDetailsFeesIcon}
                      onClick={() =>
                        this.setState({
                          showFees: false,
                        })
                      }
                    >
                      <CaretUpIcon />
                    </div>
                  ) : (
                    <div
                      className={styles.orderDetailsFeesIcon}
                      onClick={() =>
                        this.setState({
                          showFees: true,
                        })
                      }
                    >
                      <CaretDownIcon />
                    </div>
                  )}
                </div>
              </div>
              {this.state.showFees === true && (
                <>
                  <div className={styles.orderDetailsSubRow}>
                    <div
                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                    >
                      Bridge fee (
                      {(
                        config.tokens[this.state.selectedToken][
                          this.getBlockchain(this.props.onboard.networkId)
                        ].fee.percentage * 100
                      ).toFixed(2)}
                      % or{" "}
                      {
                        config.tokens[this.state.selectedToken][
                          this.getBlockchain(this.props.onboard.networkId)
                        ].fee.max
                      }{" "}
                      {this.state.selectedToken})
                    </div>
                    <div
                      className={`${styles.labelXSRegular} ${styles.primaryText}`}
                    >
                      {fee.toFixed(2)} {this.state.selectedToken} ($
                      {(fee * this.state.selectedTokenPrice).toFixed(2)})
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {/* {this.state.amount == 0 || this.state.amount === "" ? (
            <div
              className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
            >
              Enter an amount
            </div>
          ) : (
            <>
              {this.state.allowance < parseInt(this.state.amount) ? (
                <div
                  className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                  onClick={() => this.approve()}
                >
                  Approve
                </div>
              ) : (
                <>
                  {this.state.maxAmountAlert === true ? (
                    <div
                      className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                    >
                      Insufficient balance
                    </div>
                  ) : (
                    <div
                      className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                      onClick={() => this.swap()}
                      style={
                        this.state.maxAmountAlert === true ? disabledButton : {}
                      }
                    >
                      Swap
                    </div>
                  )}
                </>
              )}
            </>
          )} */}
        </div>
      </>
    );
  }

  renderStep2() {
    const Completionist = () => <span>...</span>;
    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };
    return (
      <>
        <div
          className={`${"bridge-step-card"} ${styles.whiteBg}`}
          style={this.state.loadingOverlay === true ? displayBlur : {}}
        >
          <div className="bridge-step-card-header">
            <div className={`${styles.headingS} ${styles.primaryText}`}>
              Confirmation
            </div>
            <div
              className={`${"bridge-step-no"} ${styles.gradientBg} ${
                styles.labelSRegular
              } ${styles.whiteText}`}
            >
              2
            </div>
          </div>
          <div
            className={`${"bridge-transaction-counter"} ${styles.warningBg}  ${
              styles.warningText
            }`}
          >
            <Countdown
              date={Date.now() + 60000}
              intervalDelay={10}
              precision={0}
              renderer={(props) => (
                <div className={styles.headingXS}>{props.total / 1000}</div>
              )}
            >
              <Completionist />
            </Countdown>
            <div className={styles.labelSRegular}>seconds</div>
          </div>
          <div className="bridge-step-info-text">
            <div className={`${styles.paragraphL} ${styles.primaryText}`}>
              Transaction is pending
            </div>
            <div className={`${styles.paragraphS} ${styles.secondaryText}`}>
              You’ll be taken to the next step automatically when this
              transaction confirms on the blockchain.
            </div>
          </div>
          <div className="bridge-trans-id-box">
            <div className={`${styles.labelMRegular} ${styles.secondaryText}`}>
              Send transaction ID:{" "}
              <a className={`${styles.labelMBold} ${styles.primaryText}`}>
                {this.displayTransactionId(this.state.transactionId)}
              </a>
            </div>
            <img
              src={copyIcon}
              alt="copy-icon"
              className="bridge-copy-icon"
              onClick={() => {
                navigator.clipboard.writeText(this.state.transactionId);
              }}
            />
            <a
              href={`${this.state.explorerURL}/tx/` + this.state.transactionId}
              target="_blank"
            >
              <img
                src={gradLinkIcon}
                alt="grad-link-icon"
                className="bridge-grad-link-icon"
              />
            </a>
          </div>
        </div>
      </>
    );
  }

  renderStep3() {
    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };
    return (
      <>
        <div
          className={`${"bridge-step-card"} ${styles.whiteBg}`}
          style={this.state.loadingOverlay === true ? displayBlur : {}}
        >
          <div className="bridge-step-card-header">
            <div className={`${styles.headingS} ${styles.primaryText}`}>
              Claim asset
            </div>
            <div
              className={`${"bridge-step-no"} ${styles.gradientBg} ${
                styles.labelSRegular
              } ${styles.whiteText}`}
            >
              3
            </div>
          </div>
          {this.state.step3Data.requiredNetworkId !==
          this.props.onboard.networkId ? (
            <>
              <div className="bridge-step-info-text">
                <div className={`${styles.paragraphL} ${styles.primaryText}`}>
                  Change network
                </div>
                <div className={`${styles.paragraphS} ${styles.secondaryText}`}>
                  Connect the wallet to the destination network (
                  {this.getBlockchain(this.state.step3Data.requiredNetworkId)}).
                </div>
              </div>
              <div
                className={`${"bridge-step3-button"} ${styles.button} ${
                  styles.gradientBg
                } ${styles.labelMBold} ${styles.whiteText} ${
                  styles.inputButton
                }`}
                onClick={() => this.goToDestinationBlockchain()}
              >
                Change network to{" "}
                {this.getBlockchain(this.state.step3Data.requiredNetworkId)}
              </div>
            </>
          ) : (
            <>
              <div className="bridge-step-info-text">
                <div className={`${styles.paragraphL} ${styles.primaryText}`}>
                  Last step
                </div>
                <div className={`${styles.paragraphS} ${styles.secondaryText}`}>
                  Submit transaction to receive your asset.
                </div>
              </div>
              <div
                className={`${"bridge-step3-button"} ${styles.button} ${
                  styles.gradientBg
                } ${styles.labelMBold} ${styles.whiteText} ${
                  styles.inputButton
                }`}
                onClick={() => this.claimTokens()}
              >
                Claim asset
              </div>
              <div
                className={`${"bridge-step3-button gradient-border"} ${
                  styles.button
                } ${styles.whiteBg} ${styles.labelMBold} ${
                  styles.gradientText
                } ${styles.inputButton}`}
                onClick={() => this.transferAgain()}
              >
                Transfer again
              </div>
              <div
                className={`${styles.paragraphS} ${
                  styles.primaryText
                } ${"bridge-step3-info"}`}
              >
                You will be redirected to the first step. This transaction will
                be saved in “Find transaction”.
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  render() {
    var data = localStorage.getItem("claim");
    if (data == null || typeof data == "undefined") data = [];
    else data = JSON.parse(data);

    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };

    return (
      <>
        {this.state.loadingOverlay === true && (
          <LoaderOverlay {...this.props} />
        )}
        {this.state.currentStep === 1 ? (
          <>
            {this.renderStep1()}
            <div
              style={this.state.loadingOverlay === true ? displayBlur : {}}
              className="bridge-step-disabled"
            >
              <div className={`${styles.headingS} ${styles.disabledText}`}>
                Confirmation
              </div>
              <div
                className={`${"bridge-step-no"} ${styles.bg} ${
                  styles.labelSRegular
                } ${styles.disabledText}`}
              >
                2
              </div>
            </div>
            <div
              style={this.state.loadingOverlay === true ? displayBlur : {}}
              className="bridge-step-disabled"
            >
              <div className={`${styles.headingS} ${styles.disabledText}`}>
                Claim asset
              </div>
              <div
                className={`${"bridge-step-no"} ${styles.bg} ${
                  styles.labelSRegular
                } ${styles.disabledText}`}
              >
                3
              </div>
            </div>
          </>
        ) : (
          <>
            {this.state.currentStep === 2 ? (
              <>
                <div
                  style={this.state.loadingOverlay === true ? displayBlur : {}}
                  className="bridge-step-done"
                >
                  <div className="bridge-step-done-header">
                    <div
                      className={`${styles.headingS} ${styles.disabledText}`}
                    >
                      Asset and network
                    </div>
                    <div className={`${"bridge-step-no"} ${styles.warningBg}`}>
                      <CheckIcon />
                    </div>
                  </div>
                  {data.map((claim) => (
                    <div className="bridge-step-done-info-section">
                      <div
                        className={`${styles.labelSRegular} ${styles.disabledText}`}
                      >
                        Asset{" "}
                        <a style={{ color: "#2E3338" }}>{claim.tokenId}</a>
                      </div>
                      <div
                        className={`${styles.labelSRegular} ${styles.disabledText}`}
                      >
                        From{" "}
                        <a style={{ color: "#2E3338" }}>
                          {config.networks[claim.srcChain].name}
                        </a>
                      </div>
                      <div
                        className={`${styles.labelSRegular} ${styles.disabledText}`}
                      >
                        To{" "}
                        <a style={{ color: "#2E3338" }}>
                          {config.networks[claim.destChain].name}
                        </a>
                      </div>
                      <div
                        className={`${styles.labelSRegular} ${styles.disabledText}`}
                      >
                        Amount{" "}
                        <a style={{ color: "#2E3338" }}>
                          {(
                            parseInt(claim.amount) /
                            10 **
                              config.tokens[claim.tokenId][claim.srcChain]
                                .decimals
                          ).toFixed(2)}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
                {this.renderStep2()}
                <div
                  style={this.state.loadingOverlay === true ? displayBlur : {}}
                  className="bridge-step-disabled"
                >
                  <div className={`${styles.headingS} ${styles.disabledText}`}>
                    Claim asset
                  </div>
                  <div
                    className={`${"bridge-step-no"} ${styles.bg} ${
                      styles.labelSRegular
                    } ${styles.disabledText}`}
                  >
                    3
                  </div>
                </div>
              </>
            ) : (
              <>
                {this.state.currentStep === 3 ? (
                  <>
                    <div
                      style={
                        this.state.loadingOverlay === true ? displayBlur : {}
                      }
                      className="bridge-step-done"
                    >
                      <div className="bridge-step-done-header">
                        <div
                          className={`${styles.headingS} ${styles.disabledText}`}
                        >
                          Asset and network
                        </div>
                        <div
                          className={`${"bridge-step-no"} ${styles.warningBg}`}
                        >
                          <CheckIcon />
                        </div>
                      </div>
                      <div className="bridge-step-done-info-section">
                        <div
                          className={`${styles.labelSRegular} ${styles.disabledText}`}
                        >
                          Asset{" "}
                          <a style={{ color: "#2E3338" }}>
                            {this.state.step3Data.asset}
                          </a>
                        </div>
                        <div
                          className={`${styles.labelSRegular} ${styles.disabledText}`}
                        >
                          From{" "}
                          <a style={{ color: "#2E3338" }}>
                            {this.state.step3Data.from}
                          </a>
                        </div>
                        <div
                          className={`${styles.labelSRegular} ${styles.disabledText}`}
                        >
                          To{" "}
                          <a style={{ color: "#2E3338" }}>
                            {this.state.step3Data.to}
                          </a>
                        </div>
                        <div
                          className={`${styles.labelSRegular} ${styles.disabledText}`}
                        >
                          Amount{" "}
                          <a style={{ color: "#2E3338" }}>
                            {this.state.step3Data.amount}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      style={
                        this.state.loadingOverlay === true ? displayBlur : {}
                      }
                      className="bridge-step-done"
                    >
                      <div className="bridge-step-done-header">
                        <div
                          className={`${styles.headingS} ${styles.disabledText}`}
                        >
                          Confirmation
                        </div>
                        <div
                          className={`${"bridge-step-no"} ${styles.warningBg}`}
                        >
                          <CheckIcon />
                        </div>
                      </div>
                    </div>
                    {this.renderStep3()}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  checkWallet,
  manualChangeNetwork,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BridgeTransferFunds);
