import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/store.js";

import PortfolioPage from "./views/portfolio/PortfolioPage";

import StakePage from "./views/stake/StakePage";

import BorrowPage from "./views/borrow/BorrowPage";
import BorrowPoolPage from "./views/borrow/BorrowPoolPage";

import MultiplyPage from "./views/multiply/MultiplyPage";
import MultiplyPoolPage from "./views/multiply/MultiplyPoolPage";

import BondsPage from "./views/bonds/BondsPage";
import BondsPoolPage from "./views/bonds/BondsPoolPage";

import BridgePage from "./views/bridge/BridgePage";

import StabilizerPage from "./views/stabilizer/StabilizerPage";

import FarmPage from "./views/farm/FarmPage";
import FarmPoolPage from "./views/farm/FarmPoolPage";

import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./styles/toastr.css";

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={PortfolioPage} />

          <Route path="/stake" exact component={StakePage} />

          <Route path="/borrow" exact component={BorrowPage} />
          <Route path="/borrow/:address" exact component={BorrowPoolPage} />

          <Route path="/multiply" exact component={MultiplyPage} />
          <Route path="/multiply/:address" exact component={MultiplyPoolPage} />

          <Route path="/bonds" exact component={BondsPage} />
          <Route path="/bonds/:address" exact component={BondsPoolPage} />

          <Route path="/bridge" exact component={BridgePage} />

          <Route path="/stabilizer" exact component={StabilizerPage} />

          <Route path="/farm" exact component={FarmPage} />
          <Route path="/farm/:address" exact component={FarmPoolPage} />
        </Switch>
      </BrowserRouter>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
