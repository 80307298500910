import React, { Component } from "react";
import { connect } from "react-redux";

import {
  connectAccountOnboard,
  checkWallet,
  walletPersistance,
  manualChangeNetwork,
} from "../../../redux/actions/OnboardActions";
import Loader from "../../loader/Loader";

import styles from "../../../styles/index.module.css";
import "./loaderOverlay.css";

class LoaderOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",
    };
  }

  render() {
    return (
      <div className={styles.popupWrapper}>
        <div className="loader-overlay-content">
          <div className="loader-overlay-info-section">
            <div class="stage overlay">
              <div class="dot-flashing overlay"></div>
            </div>
            <div
              className={`${styles.headingL} ${
                styles.primaryText
              } ${"loader-overlay-header"}`}
            >
              Transaction is pending
            </div>
            <div
              className={`${styles.paragraphM} ${
                styles.secondaryText
              } ${"loader-overlay-subheader"}`}
            >
              Please wait until the transaction is confirmed on the blockchain.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  connectAccountOnboard,
  checkWallet,
  walletPersistance,
  manualChangeNetwork,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoaderOverlay);
