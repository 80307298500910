import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getBondMinPrice,
  getAPYForBond,
  getROIForBond,
} from "../../web3/bonds/lib/dao";
import config from "../../config/config.json";

import Header from "../../layouts/header/Header";
import Sidebar from "../../layouts/sidebar/Sidebar";
import Footer from "../../layouts/footer/Footer";

import Loader from "../../components/loader/Loader";
import SwitchNetworkOverlay from "../../components/overlays/switch-network/SwitchNetworkOverlay";
import WalletNotConnectedOverlay from "../../components/overlays/wallet-not-connected/WalletNotConnectedOverlay";

import GradientArrowRightIcon from "../../components/icons/GradientArrowRightIcon";
import styles from "../../styles/index.module.css";
import "./bondsPage.css";

class BondsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",

      apy: {},
      displayedApy: {},
      apyLoading: false,

      roi: {},
      roiLoading: false,

      minPriceLoading: false,
      minPrice: {},
    };
  }

  toPage(route, subroute, params) {
    this.props.history.push(route + "/" + subroute, params);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  renderLoading() {
    return <Loader {...this.props} />;
  }

  getAssetIcon = (type) => {
    console.log(type);
    const icon = require(`../../assets/SVGs/assets/${type}`).default;
    return icon;
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  getMinPrice = async () => {
    this.setState({
      minPriceLoading: true,
    });
    var minPrice = {};
    var keys = Object.keys(config.pools);
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var mp = await getBondMinPrice(key);
      minPrice[key] = mp;
    }

    this.setState({
      minPrice: minPrice,
      minPriceLoading: false,
    });
  };

  getApy = async () => {
    var apy = {};
    var displayedApy = {};
    this.setState({
      apyLoading: true,
    });
    for (const [key] of Object.entries(config.pools)) {
      var apyTemp = await getAPYForBond(config.pools[key].network, key);
      apy[key] = parseFloat(apyTemp).toFixed(2) * 100;
      displayedApy[key] = apy[key].toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    }
    this.setState({
      apy: apy,
      displayedApy: displayedApy,
      apyLoading: false,
    });
  };

  getRoi = async () => {
    var roi = {};
    this.setState({
      roiLoading: true,
    });
    for (const [key] of Object.entries(config.pools)) {
      var roiTemp = await getROIForBond(config.pools[key].network, key);
      roi[key] = roiTemp * 100;
    }
    this.setState({
      roi: roi,
      roiLoading: false,
    });
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  componentDidMount() {
    this.getMinPrice();
    this.getApy();
    this.getRoi();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
      this.getMinPrice();
      this.getApy();
      this.getRoi();
    }
  }

  render() {
    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };
    return (
      <>
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Sidebar {...this.props} state={this.state} />
          {this.props.onboard.walletConnected === false ? (
            <WalletNotConnectedOverlay {...this.props} />
          ) : (
            <>
              {this.getBlockchain(this.props.onboard.networkId) !== "BSC" &&
                this.getBlockchain(this.props.onboard.networkId) !==
                  "FANTOM" && (
                  <SwitchNetworkOverlay
                    {...this.props}
                    primaryTextFromParent={
                      "There are no available bonds on " +
                      config.networks[
                        this.getBlockchain(this.props.onboard.networkId)
                      ].name
                    }
                    secondaryTextFromParent="Please switch your wallet to one of the following networks"
                    blockchainsFromParent={["BSC", "FANTOM"]}
                  />
                )}
            </>
          )}
          <div
            className={styles.wrapper}
            style={
              this.props.onboard.walletConnected === false ||
              (this.getBlockchain(this.props.onboard.networkId) !== "BSC" &&
                this.getBlockchain(this.props.onboard.networkId) !== "FANTOM")
                ? displayBlur
                : {}
            }
          >
            <div className={styles.content}>
              <div className={styles.headerSection}>
                <div className={`${styles.headingM} ${styles.primaryText}`}>
                  Bonds
                </div>
                <div className={styles.subheaderSection}>
                  <div className={`${styles.paragraphM} ${styles.primaryText}`}>
                    Bond your asset to earn over 10% ROI in 5 days.
                  </div>
                  <div className={styles.mobSubheaderLinkSection}>
                    <a
                      href="https://radar.blog/radar-bonds-tutorial-how-to-buy-defi-bonds-6b5ae80954c6"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className={`${styles.paragraphM} ${styles.gradientText} ${styles.subheaderMiddle}`}
                      >
                        Learn more about Bonds
                      </div>
                    </a>
                    <a
                      href=""
                      target="_blank"
                      style={{ textDecoration: "none", marginTop: "-2px" }}
                    >
                      <GradientArrowRightIcon />
                    </a>
                  </div>
                </div>
              </div>
              {window.innerWidth > 1299 ? (
                <div className={styles.table}>
                  <div className={styles.tableHeader}>
                    <div
                      className={`${"bonds-table-b-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      Pool
                    </div>
                    {/* <div
                      className={`${"bonds-table-b-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      Left to buy
                    </div> */}
                    {this.state.min}
                    <div
                      className={`${"bonds-table-s-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      Min price
                    </div>
                    <div
                      className={`${"bonds-table-s-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      APY
                    </div>
                    <div
                      className={`${"bonds-table-s-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                      style={{ textAlign: "right" }}
                    >
                      ROI
                    </div>
                  </div>
                  {Object.keys(config.pools)
                    .filter((j) => {
                      return (
                        config.pools[j].network ===
                        this.getBlockchain(this.props.onboard.networkId)
                      );
                    })
                    .map((i) => (
                      <>
                        <div
                          className={styles.tableRow}
                          onClick={() =>
                            this.toPage("/bonds", config.pools[i].address, {
                              i,
                            })
                          }
                        >
                          <div className="bonds-table-b-cell">
                            <div className="bonds-table-flex-cell">
                              <div className="bonds-table-icons-section">
                                <img
                                  src={this.getAssetIcon(
                                    config.pools[i].asset1.icon
                                  )}
                                  alt="asset-icon"
                                  className="bonds-table-icon1"
                                />
                                {config.pools[i].asset2 !== "" && (
                                  <img
                                    src={this.getAssetIcon(
                                      config.pools[i].asset2.icon
                                    )}
                                    alt="asset-icon"
                                    className="bonds-table-icon2"
                                  />
                                )}
                              </div>
                              <div className="bonds-table-text-section">
                                {config.pools[i].asset2 !== "" ? (
                                  <>
                                    <div
                                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                                    >
                                      {config.pools[i].asset1.name} -{" "}
                                      {config.pools[i].asset2.name}
                                    </div>
                                    <div
                                      className={`${styles.labelMRegular} ${styles.secondaryText}`}
                                    >
                                      {config.pools[i].asset1.sym} -{" "}
                                      {config.pools[i].asset2.sym}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                                    >
                                      {config.pools[i].asset1.name}
                                    </div>
                                    <div
                                      className={`${styles.labelMRegular} ${styles.secondaryText}`}
                                    >
                                      {config.pools[i].asset1.sym}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div
                            className={`${"bonds-table-b-cell"} ${
                              styles.labelMRegular
                            } ${styles.primaryText}`}
                          ></div> */}
                          {this.state.minPriceLoading === true ? (
                            <div className="bonds-table-s-cell">
                              {this.renderLoading()}
                            </div>
                          ) : (
                            <div
                              className={`${"bonds-table-s-cell"} ${
                                styles.labelMRegular
                              } ${styles.primaryText}`}
                            >
                              ${parseFloat(this.state.minPrice[i]).toFixed(2)}
                            </div>
                          )}
                          {this.state.apyLoading === true ? (
                            <div className="bonds-table-s-cell">
                              {this.renderLoading()}
                            </div>
                          ) : (
                            <div
                              className={`${"bonds-table-s-cell"} ${
                                styles.labelMRegular
                              } ${styles.primaryText}`}
                            >
                              {this.state.displayedApy[i]}%
                            </div>
                          )}
                          {this.state.roiLoading === true ? (
                            <div
                              className="bonds-table-s-cell"
                              style={{ textAlign: "right" }}
                            >
                              {this.renderLoading()}
                            </div>
                          ) : (
                            <div
                              className={`${"bonds-table-s-cell"} ${
                                styles.labelMRegular
                              } ${styles.primaryText}`}
                              style={{ textAlign: "right" }}
                            >
                              {parseFloat(this.state.roi[i]).toFixed(2)}%
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                </div>
              ) : (
                <div className={styles.mobTableCardsSection}>
                  {Object.keys(config.pools)
                    .filter((j) => {
                      return (
                        config.pools[j].network ===
                        this.getBlockchain(this.props.onboard.networkId)
                      );
                    })
                    .map((i) => (
                      <div
                        className={`${styles.card} ${styles.whiteBg}`}
                        style={{ height: "auto" }}
                        onClick={() =>
                          this.toPage("/bonds", config.pools[i].address, {
                            i,
                          })
                        }
                      >
                        <div className={styles.mobTableCardHeader}>
                          <div className={styles.mobTableCardHeaderLeftSection}>
                            <div className="bonds-table-icons-section">
                              <img
                                src={this.getAssetIcon(
                                  config.pools[i].asset1.icon
                                )}
                                alt="asset-icon"
                                className="bonds-table-icon1"
                              />
                              {config.pools[i].asset2 !== "" && (
                                <img
                                  src={this.getAssetIcon(
                                    config.pools[i].asset2.icon
                                  )}
                                  alt="asset-icon"
                                  className="bonds-table-icon2"
                                />
                              )}
                            </div>
                            <div className="bonds-table-text-section">
                              {config.pools[i].asset2 !== "" ? (
                                <>
                                  <div
                                    className={`${styles.labelMRegular} ${styles.primaryText}`}
                                  >
                                    {config.pools[i].asset1.name} -{" "}
                                    {config.pools[i].asset2.name}
                                  </div>
                                  <div
                                    className={`${styles.labelMRegular} ${styles.secondaryText}`}
                                  >
                                    {config.pools[i].asset1.sym} -{" "}
                                    {config.pools[i].asset2.sym}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className={`${styles.labelMRegular} ${styles.primaryText}`}
                                  >
                                    {config.pools[i].asset1.name}
                                  </div>
                                  <div
                                    className={`${styles.labelMRegular} ${styles.secondaryText}`}
                                  >
                                    {config.pools[i].asset1.sym}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className={styles.mobTableCardRow}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Left to buy
                          </div>
                          <div
                            className={`${styles.labelMBold} ${styles.primaryText}`}
                          >
                            $
                          </div>
                        </div> */}
                        <div className={styles.mobTableCardRow}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Min price
                          </div>
                          {this.state.minPriceLoading === true ? (
                            <div className="bonds-table-s-cell">
                              {this.renderLoading()}
                            </div>
                          ) : (
                            <div
                              className={`${styles.labelMRegular} ${styles.primaryText}`}
                            >
                              ${parseFloat(this.state.minPrice[i]).toFixed(2)}
                            </div>
                          )}
                        </div>
                        <div className={styles.mobTableCardRow}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            API
                          </div>
                          {this.state.apyLoading === true ? (
                            <div className="bonds-table-s-cell">
                              {this.renderLoading()}
                            </div>
                          ) : (
                            <div
                              className={`${styles.labelMRegular} ${styles.primaryText}`}
                            >
                              {this.state.displayedApy[i]}%
                            </div>
                          )}
                        </div>
                        <div className={styles.mobTableCardRow}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            ROI
                          </div>
                          {this.state.roiLoading === true ? (
                            <div
                              className="bonds-table-s-cell"
                              style={{ textAlign: "right" }}
                            >
                              {this.renderLoading()}
                            </div>
                          ) : (
                            <div
                              className={`${styles.labelMRegular} ${styles.primaryText}`}
                              style={{ textAlign: "right" }}
                            >
                              {this.state.roi[i]}%
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <Footer {...this.props} state={this.state} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BondsPage);
