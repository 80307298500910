import React, { Component } from "react";
import { connect } from "react-redux";
import { ethers } from "ethers";

import "../../../utils/ethereum/onboard";
import { disconnectAccountOnboard } from "../../../redux/actions/OnboardActions";
import config from "../../../config/config.json";
import {
  getSlippage,
  setSlippage,
} from "../../../web3/localstorage/slippageManager";
import { getUserRadarBalance } from "../../../web3/bonds/lib/dao";
import { getTokenBalance } from "../../../web3/usdr/api";

import Loader from "../../../components/loader/Loader";

import LogOutIcon from "../../icons/LogOutIcon";
import CloseIcon from "../../icons/CloseIcon";
import metamaskIcon from "../../../assets/SVGs/metamask.svg";
import radarIcon from "../../../assets/SVGs/assets/radar.svg";
import usdrIcon from "../../../assets/SVGs/assets/usdr.svg";
import copyIcon from "../../../assets/SVGs/copyBlack.svg";
import linkIcon from "../../../assets/SVGs/linkBlack.svg";
import styles from "../../../styles/index.module.css";
import "./accountOverlay.css";

class AccountOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownClicked: false,

      slippage: 0.02,
      customSlippage: "",

      customSelected: false,

      radarBalance: 0,
      radarBalanceLoading: true,

      USDRBalance: 0,
      USDRBalanceLoading: false,
    };
  }

  renderLoading() {
    return <Loader {...this.props} />;
  }

  getRadarBalance = async () => {
    try {
      await this.setState({
        radarBalanceLoading: true,
        radarBalanceInUSDLoading: true,
      });
      let radarBalance = await getUserRadarBalance(
        this.props.onboard.address,
        this.getBlockchain(this.props.onboard.networkId)
      );
      await this.setState({
        radarBalance: parseFloat(radarBalance).toFixed(2),
        radarBalanceLoading: false,
      });
    } catch (e) {
      await this.setState({
        radarBalance: 0,
        radarBalanceLoading: false,
      });
    }
  };

  async getUSDRBalance() {
    try {
      const rpc = config.networks[this.state.currentChain].endpoint;
      const userAddress = this.props.onboard.address;
      const USDRAddress = config.USDR[this.state.currentChain];

      const unscaledBalance = await getTokenBalance(
        rpc,
        USDRAddress,
        userAddress
      );

      const scaledBalance = (unscaledBalance / 10 ** 18).toFixed(2);

      await this.setState({
        USDRBalance: scaledBalance,
      });
    } catch (e) {
      console.log("Failed getting usdr balance: " + e);
    }
  }

  closeDisconnectDropdown = () => {
    this.props.closeDisconnectDropdownEvent();
  };

  disconnect = () => {
    this.setState({
      dropdownClicked: true,
    });
    this.props.disconnectAccountOnboard();
    this.closeDisconnectDropdown();
  };

  displayAddress = (address) => {
    return `${address.substring(0, 2)}...${address.substring(38)}`;
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  getBlockchainIcon = (type) => {
    const icon = require(`../../../assets/SVGs/${type}`).default;
    return icon;
  };

  addRadar = async () => {
    const bl = this.getBlockchain(this.props.onboard.networkId);
    const radarAddress = config.tokens.RADAR[bl].address;

    try {
      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      await provider.send("wallet_watchAsset", {
        type: "ERC20",
        options: {
          address: radarAddress,
          symbol: "RADAR",
          decimals: 18,
          image: "https://i.imgur.com/lIMKSJg.png",
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  addUsdr = async () => {
    const bl = this.getBlockchain(this.props.onboard.networkId);
    const usdrAddress = config.USDR[bl];

    try {
      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      await provider.send("wallet_watchAsset", {
        type: "ERC20",
        options: {
          address: usdrAddress,
          symbol: "USDR",
          decimals: 18,
          image: "https://i.imgur.com/8i43CHQ.png",
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  changeSlippage = (s) => {
    setSlippage(window.localStorage, parseFloat(s) / 100);
    this.setState({ slippage: parseFloat(s) / 100 });
  };

  customSlippageField = async (e) => {
    this.setState({
      customSelected: true,
    });
    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") {
        return;
      }
    }
    if (e.target.value === "") {
      this.changeSlippage(2);
      await this.setState({ customSlippage: "", selectedCustom: false });
      return;
    }
    var value = e.target.value;
    await this.setState({
      customSlippage: value,
    });
    this.changeSlippage(value);
  };

  closeAccountOverlay = () => {
    this.props.closeAccountOverlayEvent();
  };

  componentDidMount() {
    const s = getSlippage(window.localStorage);
    this.setState({ slippage: s });
    if (s != 0.01 && s != 0.02) {
      this.setState({ customSlippage: s * 100 });
    }

    this.getRadarBalance();
    this.getUSDRBalance();
  }

  render() {
    const selectedSlippageCss = {
      background: "linear-gradient(90deg, #E926C3 0%, #FF4D86 100%)",
      color: "#FFF",
    };
    const customSelectedCss = {
      background: "linear-gradient(90deg, #E926C3 0%, #FF4D86 100%)",
      color: "#FFF",
    };

    const customSelectedInputCss = {
      background: "linear-gradient(90deg, #E926C3 0%, #FF4D86 100%)",
      fontSize: "14px",
      borderRadius: "999px",
      textAlign: "center",
      border: "1px solid #E926C3",
      color: "#FFF",
    };

    const customNotSelectedInputCss = {
      fontSize: "14px",
      borderRadius: "999px",
      textAlign: "center",
      border: "1px solid #FFF",
      color: "#2E3338",
    };

    return (
      <>
        {window.innerWidth > 1299 ? (
          <>
            <div className="account-overlay-wrapper">
              <div
                className="account-overlay-close-wrapper"
                onClick={() => this.closeAccountOverlay()}
              />
              <div className="account-overlay-layout">
                <div className="account-overlay-sidebar">
                  <div className="account-sidebar-header">
                    <div className={`${styles.headingM} ${styles.primaryText}`}>
                      Account
                    </div>
                    <div
                      onClick={() => this.closeAccountOverlay()}
                      style={{ cursor: "pointer" }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                  <div className="account-sidebar-section">
                    <div className="account-sidebar-subsection">
                      <div className="account-sidebar-subsection-left">
                        <div className="account-sidebar-address-box">
                          <img
                            src={metamaskIcon}
                            alt="metamask-icon"
                            className="account-sidebar-address-box-metamask-icon"
                          />
                          <div
                            className={`${styles.labelMRegular} ${styles.primaryText}`}
                          >
                            {this.displayAddress(
                              this.props.onboard.address
                                ? this.props.onboard.address
                                : ""
                            )}
                          </div>
                        </div>
                        <img
                          src={copyIcon}
                          alt="copy-icon"
                          className="account-sidebar-address-box-copy-icon"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              this.props.onboard.address
                            );
                          }}
                        />
                        <a
                          href={
                            "https://etherscan.io/address/" +
                            this.props.onboard.address
                          }
                          target="_blank"
                        >
                          <img
                            src={linkIcon}
                            alt="link-icon"
                            className="account-sidebar-address-box-link-icon"
                          />
                        </a>
                      </div>
                      <div
                        className="account-sidebar-subsection-right"
                        onClick={() => this.disconnect()}
                      >
                        <LogOutIcon />
                      </div>
                    </div>
                    <div
                      className={`${styles.labelSRegular} ${
                        styles.secondaryText
                      } ${"account-sidebar-subsection-header"}`}
                    >
                      Assets
                    </div>
                    <div className="account-sidebar-subsection-row">
                      <div className="account-sidebar-subsection-row-left">
                        <img
                          src={
                            this.getBlockchain(this.props.onboard.networkId) !=
                              null &&
                            this.getBlockchainIcon(
                              config.networks[
                                this.getBlockchain(this.props.onboard.networkId)
                              ].icon
                            )
                          }
                          alt="blockchain-icon"
                          className="account-sidebar-asset-icon"
                        />
                        <div
                          className={`${"account-overlay-balance"} ${
                            styles.primaryText
                          }`}
                        >
                          {parseFloat(
                            this.props.onboard.balance == null
                              ? 0
                              : this.props.onboard.balance / 10 ** 18
                          ).toFixed(2)}{" "}
                          {this.getBlockchain(this.props.onboard.networkId) !=
                          null
                            ? config.networks[
                                this.getBlockchain(this.props.onboard.networkId)
                              ].assetName
                            : ""}
                        </div>
                      </div>
                      <div className="account-sidebar-subsection-right"></div>
                    </div>
                    <div className="account-sidebar-subsection-row">
                      <div className="account-sidebar-subsection-row-left">
                        <img
                          src={radarIcon}
                          alt="radar-icon"
                          className="account-sidebar-asset-icon"
                        />
                        {this.state.radarBalanceLoading === true ? (
                          this.renderLoading()
                        ) : (
                          <div
                            className={`${"account-overlay-balance"} ${
                              styles.primaryText
                            }`}
                          >
                            {this.state.radarBalance} RADAR
                          </div>
                        )}
                      </div>
                      {Object.keys(config.tokens.RADAR).includes(
                        this.getBlockchain(this.props.onboard.networkId)
                      ) && (
                        <div
                          className={`${styles.labelSBold} ${
                            styles.gradientText
                          } ${"account-overlay-add-button"}`}
                          onClick={() => this.addRadar()}
                        >
                          Add to wallet
                        </div>
                      )}
                    </div>
                    <div className="account-sidebar-subsection-row">
                      <div className="account-sidebar-subsection-row-left">
                        <img
                          src={usdrIcon}
                          alt="usdr-icon"
                          className="account-sidebar-asset-icon"
                        />
                        {this.state.USDRBalanceLoading === true ? (
                          this.renderLoading()
                        ) : (
                          <div
                            className={`${"account-overlay-balance"} ${
                              styles.primaryText
                            }`}
                          >
                            {this.state.USDRBalance} USDR
                          </div>
                        )}
                      </div>
                      {Object.keys(config.USDR).includes(
                        this.getBlockchain(this.props.onboard.networkId)
                      ) && (
                        <div
                          className={`${styles.labelSBold} ${
                            styles.gradientText
                          } ${"account-overlay-add-button"}`}
                          onClick={() => this.addUsdr()}
                        >
                          Add to wallet
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="account-sidebar-section">
                    <div
                      className={`${styles.labelSRegular} ${
                        styles.secondaryText
                      } ${"account-sidebar-subsection-header"}`}
                      style={{ marginTop: "0" }}
                    >
                      Settings
                    </div>
                    <div className="account-sidebar-slip-sett">
                      <div
                        className={`${styles.labelMRegular} ${
                          styles.primaryText
                        } ${"disconnect-dropdown-slippage-subtitle"}`}
                      >
                        Slippage
                      </div>
                      <div className="disconnect-dropdown-slippage-input-section">
                        <div
                          className={`${"disconnect-dropdown-slippage-input-label"} ${
                            styles.labelMRegular
                          } ${styles.primaryText}`}
                          style={
                            this.state.slippage === 0.01 &&
                            this.state.customSelected === false
                              ? selectedSlippageCss
                              : {}
                          }
                          onClick={() => {
                            this.changeSlippage(1);
                            this.setState({
                              customSlippage: "",
                              customSelected: false,
                            });
                          }}
                        >
                          1%
                        </div>
                        <div
                          className={`${"disconnect-dropdown-slippage-input-label"} ${
                            styles.labelMRegular
                          } ${styles.primaryText}`}
                          style={
                            this.state.slippage === 0.02 &&
                            this.state.customSelected === false
                              ? selectedSlippageCss
                              : {}
                          }
                          onClick={() => {
                            this.changeSlippage(2);
                            this.setState({
                              customSlippage: "",
                              customSelected: false,
                            });
                          }}
                        >
                          2%
                        </div>
                        <div
                          className={`${"disconnect-dropdown-slippage-input-label"} ${
                            styles.labelMRegular
                          } ${styles.primaryText}`}
                          style={
                            this.state.customSelected === true
                              ? customSelectedCss
                              : {}
                          }
                        >
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            value={this.state.customSlippage}
                            placeholder="Custom"
                            onChange={(e) => this.customSlippageField(e)}
                            style={
                              this.state.customSelected === true
                                ? customSelectedInputCss
                                : customNotSelectedInputCss
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.labelSRegular} ${
                        styles.secondaryText
                      } ${"disconnect-dropdown-slippage-subtitle"}`}
                    >
                      Your current slippage:{" "}
                      {(this.state.slippage * 100).toFixed(2)}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="account-overlay-wrapper">
            <div
              className="account-overlay-close-wrapper"
              onClick={() => this.closeAccountOverlay()}
            />
            <div className="account-overlay-layout">
              <div className="account-overlay-sidebar">
                <div className="account-sidebar-header">
                  <div className={`${styles.headingM} ${styles.primaryText}`}>
                    Account
                  </div>
                  <div
                    onClick={() => this.closeAccountOverlay()}
                    style={{ cursor: "pointer" }}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div className="account-sidebar-section">
                  <div className="account-sidebar-subsection">
                    <div className="account-sidebar-subsection-left">
                      <div className="account-sidebar-address-box">
                        <img
                          src={metamaskIcon}
                          alt="metamask-icon"
                          className="account-sidebar-address-box-metamask-icon"
                        />
                        <div
                          className={`${styles.labelMRegular} ${styles.primaryText}`}
                        >
                          {this.displayAddress(
                            this.props.onboard.address
                              ? this.props.onboard.address
                              : ""
                          )}
                        </div>
                      </div>
                      <img
                        src={copyIcon}
                        alt="copy-icon"
                        className="account-sidebar-address-box-copy-icon"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            this.props.onboard.address
                          );
                        }}
                      />
                      <a
                        href={
                          "https://etherscan.io/address/" +
                          this.props.onboard.address
                        }
                        target="_blank"
                      >
                        <img
                          src={linkIcon}
                          alt="link-icon"
                          className="account-sidebar-address-box-link-icon"
                        />
                      </a>
                    </div>
                    <div
                      className="account-sidebar-subsection-right"
                      onClick={() => this.disconnect()}
                    >
                      <LogOutIcon />
                    </div>
                  </div>
                  <div
                    className={`${styles.labelSRegular} ${
                      styles.secondaryText
                    } ${"account-sidebar-subsection-header"}`}
                  >
                    Assets
                  </div>
                  <div className="account-sidebar-subsection-row">
                    <div className="account-sidebar-subsection-row-left">
                      <img
                        src={
                          this.getBlockchain(this.props.onboard.networkId) !=
                            null &&
                          this.getBlockchainIcon(
                            config.networks[
                              this.getBlockchain(this.props.onboard.networkId)
                            ].icon
                          )
                        }
                        alt="blockchain-icon"
                        className="account-sidebar-asset-icon"
                      />
                      <div
                        className={`${"account-overlay-balance"} ${
                          styles.primaryText
                        }`}
                      >
                        {parseFloat(
                          this.props.onboard.balance == null
                            ? 0
                            : this.props.onboard.balance / 10 ** 18
                        ).toFixed(2)}{" "}
                        {this.getBlockchain(this.props.onboard.networkId) !=
                        null
                          ? config.networks[
                              this.getBlockchain(this.props.onboard.networkId)
                            ].assetName
                          : ""}
                      </div>
                    </div>
                    <div className="account-sidebar-subsection-right"></div>
                  </div>
                  <div className="account-sidebar-subsection-row">
                    <div className="account-sidebar-subsection-row-left">
                      <img
                        src={radarIcon}
                        alt="radar-icon"
                        className="account-sidebar-asset-icon"
                      />
                      {this.state.radarBalanceLoading === true ? (
                        this.renderLoading()
                      ) : (
                        <div
                          className={`${"account-overlay-balance"} ${
                            styles.primaryText
                          }`}
                        >
                          {this.state.radarBalance} RADAR
                        </div>
                      )}
                    </div>
                    {Object.keys(config.tokens.RADAR).includes(
                      this.getBlockchain(this.props.onboard.networkId)
                    ) && (
                      <div
                        className={`${styles.labelSBold} ${
                          styles.gradientText
                        } ${"account-overlay-add-button"}`}
                        onClick={() => this.addRadar()}
                      >
                        Add to wallet
                      </div>
                    )}
                  </div>
                  <div className="account-sidebar-subsection-row">
                    <div className="account-sidebar-subsection-row-left">
                      <img
                        src={usdrIcon}
                        alt="usdr-icon"
                        className="account-sidebar-asset-icon"
                      />
                      {this.state.USDRBalanceLoading === true ? (
                        this.renderLoading()
                      ) : (
                        <div
                          className={`${"account-overlay-balance"} ${
                            styles.primaryText
                          }`}
                        >
                          {this.state.USDRBalance} USDR
                        </div>
                      )}
                    </div>
                    {Object.keys(config.USDR).includes(
                      this.getBlockchain(this.props.onboard.networkId)
                    ) && (
                      <div
                        className={`${styles.labelSBold} ${
                          styles.gradientText
                        } ${"account-overlay-add-button"}`}
                        onClick={() => this.addUsdr()}
                      >
                        Add to wallet
                      </div>
                    )}
                  </div>
                </div>
                <div className="account-sidebar-section">
                  <div
                    className={`${styles.labelSRegular} ${
                      styles.secondaryText
                    } ${"account-sidebar-subsection-header"}`}
                    style={{ marginTop: "0" }}
                  >
                    Settings
                  </div>
                  <div className="account-sidebar-slip-sett">
                    <div
                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                    >
                      Slippage
                    </div>
                    <div className="disconnect-dropdown-slippage-input-section">
                      <div
                        className={`${"disconnect-dropdown-slippage-input-label"} ${
                          styles.labelMRegular
                        } ${styles.primaryText}`}
                        style={
                          this.state.slippage === 0.01 &&
                          this.state.customSelected === false
                            ? selectedSlippageCss
                            : {}
                        }
                        onClick={() => {
                          this.changeSlippage(1);
                          this.setState({
                            customSlippage: "",
                            customSelected: false,
                          });
                        }}
                      >
                        1%
                      </div>
                      <div
                        className={`${"disconnect-dropdown-slippage-input-label"} ${
                          styles.labelMRegular
                        } ${styles.primaryText}`}
                        style={
                          this.state.slippage === 0.02 &&
                          this.state.customSelected === false
                            ? selectedSlippageCss
                            : {}
                        }
                        onClick={() => {
                          this.changeSlippage(2);
                          this.setState({
                            customSlippage: "",
                            customSelected: false,
                          });
                        }}
                      >
                        2%
                      </div>
                      <div
                        className={`${"disconnect-dropdown-slippage-input-label"} ${
                          styles.labelMRegular
                        } ${styles.primaryText}`}
                        style={
                          this.state.customSelected === true
                            ? customSelectedCss
                            : {}
                        }
                      >
                        <input
                          type="text"
                          id="amount"
                          name="amount"
                          value={this.state.customSlippage}
                          placeholder="Custom"
                          onChange={(e) => this.customSlippageField(e)}
                          style={
                            this.state.customSelected === true
                              ? customSelectedInputCss
                              : customNotSelectedInputCss
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.labelSRegular} ${
                      styles.secondaryText
                    } ${"disconnect-dropdown-slippage-subtitle"}`}
                  >
                    Your current slippage:{" "}
                    {(this.state.slippage * 100).toFixed(2)}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  disconnectAccountOnboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountOverlay);
