import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  getStakingTotalRadarDeposited,
  getStakingAPY,
  getRadarPrice,
  getUserStakingAllowance,
  getUserRadarBalance,
  getUserStakedBalance,
  getUserUnclaimedStakingReward,
  approveStaking,
  stake,
  unstake,
  claimRewardStaking,
} from "../../web3/bonds/lib/dao";
import "../../utils/ethereum/onboard";
import { checkWallet } from "../../redux/actions/OnboardActions";
import config from "../../config/config.json";

import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import Sidebar from "../../layouts/sidebar/Sidebar";
import LoaderOverlay from "../../components/overlays/loader/LoaderOverlay";

import Loader from "../../components/loader/Loader";
import WalletNotConnectedOverlay from "../../components/overlays/wallet-not-connected/WalletNotConnectedOverlay";
import SwitchNetworkOverlay from "../../components/overlays/switch-network/SwitchNetworkOverlay";

import greenArrowIcon from "../../assets/SVGs/green-arrow.svg";
import orangeArrowIcon from "../../assets/SVGs/orange-arrow.svg";
import redArrowIcon from "../../assets/SVGs/red-arrow.svg";

import GradientInterfaceIcon from "../../components/icons/GradientInterfaceIcon";
import styles from "../../styles/index.module.css";
import "./stakePage.css";

class StakePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",
      displayInfoCards: true,

      TVD: 0,
      TVDLoading: false,

      radarDeposited: 0,
      radarDepositedLoading: true,

      APY: 0,
      APYLoading: true,

      // radarRate: 1,
      // radarRateLoading: false,

      stakedRadar: 0,
      stakedRadarLoading: true,

      // stakedRadarInSRadar: 0,
      // stakedRadarInSRadarLoading: false,

      stakedRadarInUsdt: 0,
      stakedRadarInUsdtLoading: true,

      unstakedRadar: 0,
      unstakedRadarLoading: true,

      reward: 0,
      dispalyedReward: 0,
      rewardLoading: true,

      allowance: 0,
      amount: "",
      // amountInSRadar: 0,
      // amountInSRadarLoading: false,
      amountInUSD: 0,
      amountInUSDLoading: false,

      selectedAction: "stake",

      fee: 0,
      transactionFee: 0,
      maxAmountAlert: false,

      loadingOverlay: false,

      radarPrice: 0,
    };
  }

  renderLoading() {
    return <Loader {...this.props} />;
  }

  setInfoCardsDisplayState = () => {
    if (this.state.displayInfoCards === true) {
      this.setState({
        displayInfoCards: false,
      });
    } else {
      this.setState({
        displayInfoCards: true,
      });
    }
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  amountField = (e) => {
    if (this.state.selectedAction === "stake") {
      if (parseFloat(e.target.value) > parseFloat(this.state.unstakedRadar)) {
        this.setState({ maxAmountAlert: true });
      } else {
        this.setState({ maxAmountAlert: false });
      }
    } else if (this.state.selectedAction === "unstake") {
      if (parseFloat(e.target.value) > parseFloat(this.state.stakedRadar)) {
        this.setState({ maxAmountAlert: true });
      } else {
        this.setState({ maxAmountAlert: false });
      }
    }

    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") return;
    }

    var value = e.target.value;
    this.setState({
      amount: value,
      amountInUSD: value != "" ? parseFloat(value) * this.state.radarPrice : 0,
    });
  };

  componentDidMount = async () => {
    if (this.props.onboard.walletConnected) {
      await this.getMetadata();
      await this.getUserData();
    }
  };

  async componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
      await this.getMetadata();
      await this.getUserData();
    }
  }

  async getMetadata() {
    await this.setState({
      TVDLoading: true,
      APYLoading: true,
      radarDepositedLoading: true,
    });
    let radarPrice = await getRadarPrice();

    let TRD = await getStakingTotalRadarDeposited(
      this.getBlockchain(this.props.onboard.networkId)
    );
    await this.setState({
      radarDeposited: TRD.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      }),
      radarDepositedLoading: false,
      radarPrice,
    });

    let TVD = radarPrice * TRD;
    await this.setState({
      TVD: TVD.toLocaleString(undefined, { maximumFractionDigits: 0 }),
      TVDLoading: false,
    });

    let APY = await getStakingAPY(
      this.getBlockchain(this.props.onboard.networkId)
    );
    await this.setState({
      APY: parseFloat(APY * 100).toFixed(2),
      APYLoading: false,
    });
  }

  async getUserData() {
    await this.setState({
      stakedRadarLoading: true,
      stakedRadarInUsdtLoading: true,
      unstakedRadarLoading: true,
      rewardLoading: true,
    });
    let stakedRadarBalance = await getUserStakedBalance(
      this.getBlockchain(this.props.onboard.networkId),
      this.props.onboard.address
    );
    await this.setState({
      stakedRadar: stakedRadarBalance,
      stakedRadarLoading: false,
    });

    var stakedRadarInUsdt = stakedRadarBalance * this.state.radarPrice;
    await this.setState({
      stakedRadarInUsdt: parseFloat(stakedRadarInUsdt).toFixed(2),
      stakedRadarInUsdtLoading: false,
    });

    let radarBalance = await getUserRadarBalance(
      this.props.onboard.address,
      this.getBlockchain(this.props.onboard.networkId)
    );
    await this.setState({
      unstakedRadar: radarBalance,
      unstakedRadarLoading: false,
    });

    let unclaimed = await getUserUnclaimedStakingReward(
      this.getBlockchain(this.props.onboard.networkId),
      this.props.onboard.address
    );
    await this.setState({
      reward: unclaimed.toFixed(2).toString(),
      dispalyedReward: unclaimed.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      rewardLoading: false,
    });

    let allowance = await getUserStakingAllowance(
      this.getBlockchain(this.props.onboard.networkId),
      this.props.onboard.address
    );
    await this.setState({
      allowance: allowance,
    });
  }

  approve = async () => {
    if (this.state.maxAmountAlert === false) {
      await this.setState({
        loadingOverlay: true,
      });
      try {
        await approveStaking(
          this.props.onboard.provider,
          this.getBlockchain(this.props.onboard.networkId)
        );
        toastr.success("Success", "Transaction Confirmed");
      } catch (e) {
        console.log(e);
        toastr.error("Error", "Error approving: " + e.message);
      }
      await this.setState({
        loadingOverlay: false,
      });
      await this.getUserData();
    }
  };

  stake = async () => {
    if (this.state.maxAmountAlert === false) {
      await this.setState({
        loadingOverlay: true,
      });
      try {
        await stake(
          this.getBlockchain(this.props.onboard.networkId),
          this.props.onboard.provider,
          this.props.onboard.address,
          this.state.amount,
          this.state.maxAmountClicked
        );
        toastr.success("Success", "Tokens Staked");
      } catch (e) {
        console.log(e);
        toastr.error("Error", "Error staking: " + e.message);
      }
      await this.setState({
        loadingOverlay: false,
        amount: "",
        amountInUSD: 0,
      });
      await this.getUserData();
    }
  };

  unstake = async () => {
    await this.setState({
      loadingOverlay: true,
    });
    try {
      await unstake(
        this.getBlockchain(this.props.onboard.networkId),
        this.props.onboard.provider,
        this.state.amount
      );
      toastr.success("Success", "Tokens Unstaked");
    } catch (e) {
      console.log(e);
      toastr.error("Error", "Error unstaking: " + e.message);
    }
    await this.setState({
      loadingOverlay: false,
      amount: "",
      amountInUSD: 0,
    });
    await this.getUserData();
  };

  claim = async () => {
    await this.setState({
      loadingOverlay: true,
    });
    try {
      await claimRewardStaking(
        this.getBlockchain(this.props.onboard.networkId),
        this.props.onboard.provider
      );
      toastr.success("Success", "Reward Claimed");
    } catch (e) {
      console.log(e);
      toastr.error("Error", "Error claiming: " + e.message);
    }
    await this.setState({
      loadingOverlay: false,
    });
    await this.getUserData();
  };

  renderStake() {
    return (
      <>
        <div className={styles.inputMenu}>
          <div className={`${styles.inputMenuItem} ${styles.whiteBg}`}>
            <div className={`${styles.labelMBold} ${styles.gradientText}`}>
              Stake
            </div>
          </div>
          <div
            className={`${styles.inputMenuItem} ${styles.greyBg} ${styles.labelMBold} ${styles.primaryText}`}
            style={{ borderRadius: "0 8px 0 0" }}
            onClick={() =>
              this.setState({
                selectedAction: "unstake",
                amount: "",
                amountInUSD: 0,
              })
            }
          >
            Unstake
          </div>
        </div>
        <div className={`${styles.inputCard} ${styles.whiteBg}`}>
          <div className={styles.inputCardHeader}>
            <div
              className={styles.inputCardIcon}
              onClick={() => this.setInfoCardsDisplayState()}
            >
              <GradientInterfaceIcon />
            </div>
            <div
              className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
            >
              Stake Radar
            </div>
          </div>
          <div className={styles.inputHeader}>
            <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
              Amount
            </div>
            <div
              className={
                parseFloat(this.state.unstakedRadar) === 0
                  ? styles.inputBalanceSection
                  : styles.inputBalanceSectionGrad
              }
              onClick={() =>
                this.setState({
                  amount: this.state.unstakedRadar,
                  amountInUSD: this.state.unstakedRadar * this.state.radarPrice,
                })
              }
            >
              <div
                className={`${styles.labelSRegular} ${
                  styles.secondaryText
                } ${"stake-page-balance-button"}`}
              >
                Balance
              </div>
              {this.state.unstakedRadarLoading === true ? (
                <>{this.renderLoading()}</>
              ) : (
                <div
                  className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
                >
                  {this.state.unstakedRadar.toFixed(2)} RADAR
                </div>
              )}
            </div>
          </div>
          <div className={styles.inputBox}>
            <input
              type="text"
              id="amount"
              name="amount"
              value={this.state.amount}
              placeholder="0.00"
              onChange={(e) => this.amountField(e)}
              style={{
                fontSize: "21px",
                lineHeight: "32px",
              }}
            />
            {this.state.amountInUSDLoading === true ? (
              <>{this.renderLoading()}</>
            ) : (
              <div
                className={`${styles.labelSRegular} ${styles.secondaryText}`}
              >
                ${this.state.amountInUSD.toFixed(2)}
              </div>
            )}
          </div>
          {this.state.maxAmountAlert === true ? (
            <div
              className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
              style={{ cursor: "initial" }}
            >
              Insufficient RADAR balance
            </div>
          ) : (
            <>
              {this.state.amount === 0 || this.state.amount === "" ? (
                <div
                  className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                  style={{ cursor: "initial" }}
                >
                  Enter an amount
                </div>
              ) : (
                <>
                  {this.state.allowance <
                  parseInt(this.state.amount) * 10 ** 18 ? (
                    <div
                      className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                      onClick={() => this.approve()}
                    >
                      Approve
                    </div>
                  ) : (
                    <div
                      className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                      onClick={() => this.stake()}
                    >
                      Deposit
                    </div>
                  )}
                  <div className={styles.orderDetailsSection}>
                    <div
                      className={`${styles.labelMBold} ${styles.primaryText}`}
                    >
                      Order details
                    </div>
                    {/* <div className={styles.orderDetailsRow}>
                  <div
                    className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                  >
                    You get
                  </div>
                  <div
                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                  >
                    ? sRADAR
                  </div>
                </div> */}
                    {/* <div className={styles.orderDetailsRow}>
                  <div
                    className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                  >
                    Rate
                  </div>
                  <div
                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                  >
                    1 sRADAR = {this.state.radarRate} RADAR
                  </div>
                </div> */}
                    {/* <div className={styles.orderDetailsRow}>
                  <div
                    className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                  >
                    Locking period
                  </div>
                  <div
                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                  >
                    24 hours
                  </div>
                </div> */}
                    <div className={styles.orderDetailsRow}>
                      <div
                        className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                      >
                        APY
                      </div>
                      <div
                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                      >
                        {this.state.APY}%
                      </div>
                    </div>
                    {/* <div className={styles.orderDetailsRow}>
                      <div
                        className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                      >
                        Fees
                      </div>
                      <div className={styles.orderDetailsFeesRow}>
                        <div
                          className={`${styles.labelXSRegular} ${styles.primaryText}`}
                        >
                          ${this.state.fee}
                        </div>
                        {this.state.showFees === true ? (
                          <div
                            className={styles.orderDetailsFeesIcon}
                            onClick={() =>
                              this.setState({
                                showFees: false,
                              })
                            }
                          >
                            <CaretUpIcon />
                          </div>
                        ) : (
                          <div
                            className={styles.orderDetailsFeesIcon}
                            onClick={() =>
                              this.setState({
                                showFees: true,
                              })
                            }
                          >
                            <CaretDownIcon />
                          </div>
                        )}
                      </div>
                    </div>
                    {this.state.showFees === true && (
                      <div className={styles.orderDetailsSubRow}>
                        <div
                          className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                        >
                          Transaction fee
                        </div>
                        <div
                          className={`${styles.labelXSRegular} ${styles.primaryText}`}
                        >
                          ${this.state.transactionFee}
                        </div>
                      </div>
                    )} */}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }

  renderUnstake() {
    return (
      <>
        <div className={styles.inputMenu}>
          <div
            className={`${styles.inputMenuItem} ${styles.greyBg} ${styles.labelMBold} ${styles.primaryText}`}
            onClick={() =>
              this.setState({
                selectedAction: "stake",
                amount: "",
                amountInUSD: 0,
              })
            }
          >
            Stake
          </div>
          <div
            className={`${styles.inputMenuItem} ${styles.whiteBg}`}
            style={{ borderRadius: "0 8px 0 0" }}
          >
            <div className={`${styles.labelMBold} ${styles.gradientText}`}>
              Unstake
            </div>
          </div>
        </div>
        <div className={`${styles.inputCard} ${styles.whiteBg}`}>
          <div className={styles.inputCardHeader}>
            <div
              className={styles.inputCardIcon}
              onClick={() => this.setInfoCardsDisplayState()}
            >
              <GradientInterfaceIcon />
            </div>
            <div
              className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
            >
              Unstake Radar
            </div>
          </div>
          <div className={styles.inputHeader}>
            <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
              Amount
            </div>
            <div
              className={
                parseFloat(this.state.stakedRadar) === 0
                  ? styles.inputBalanceSection
                  : styles.inputBalanceSectionGrad
              }
              onClick={() =>
                this.setState({
                  amount: this.state.stakedRadar,
                  amountInUSD: this.state.stakedRadar * this.state.radarPrice,
                })
              }
            >
              <div
                className={`${styles.labelSRegular} ${styles.secondaryText}`}
              >
                Balance
              </div>
              {this.state.stakedRadarLoading === true ? (
                <>{this.renderLoading()}</>
              ) : (
                <div
                  className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
                >
                  {this.state.stakedRadar.toFixed(2)} RADAR
                </div>
              )}
            </div>
          </div>
          <div className={styles.inputBox}>
            <input
              type="text"
              id="amount"
              name="amount"
              value={this.state.amount}
              placeholder="0.00"
              onChange={(e) => this.amountField(e)}
              style={{
                fontSize: "21px",
                lineHeight: "32px",
              }}
            />
            <div className={`${styles.labelSRegular} ${styles.secondaryText}`}>
              ${this.state.amountInUSD.toFixed(2)}
            </div>
          </div>
          {this.state.maxAmountAlert === true ? (
            <div
              className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
              style={{ cursor: "initial" }}
            >
              Insufficient balance
            </div>
          ) : (
            <>
              {this.state.amount === 0 || this.state.amount === "" ? (
                <div
                  className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                  style={{ cursor: "initial" }}
                >
                  Enter an amount
                </div>
              ) : (
                <>
                  <div
                    className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                    onClick={() => this.unstake()}
                  >
                    Withdraw
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }

  render() {
    const doNotDisplay = {
      display: "none",
    };

    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };

    console.log("------------------");
    console.log("MAX AMOUNT ALERT: ");
    console.log(this.state.maxAmountAlert);
    console.log("AMOUNT: ");
    console.log(this.state.amount);
    if (
      this.state.amount !== 0 &&
      this.state.amount !== "" &&
      this.state.maxAmountAlert === false
    ) {
      console.log("label should be displayed now");
    } else {
      console.log("label should not be displayed now");
    }
    console.log("SELECTED ACTION: ");
    console.log(this.state.selectedAction);

    return (
      <>
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Sidebar {...this.props} state={this.state} />
          {this.props.onboard.walletConnected === false ? (
            <WalletNotConnectedOverlay {...this.props} />
          ) : (
            <>
              {this.getBlockchain(this.props.onboard.networkId) !== "BSC" &&
                this.getBlockchain(this.props.onboard.networkId) !== "FANTOM" &&
                this.getBlockchain(this.props.onboard.networkId) !== "ETH" && (
                  <SwitchNetworkOverlay
                    {...this.props}
                    primaryTextFromParent={
                      "Staking is not available on " +
                      config.networks[
                        this.getBlockchain(this.props.onboard.networkId)
                      ].name
                    }
                    secondaryTextFromParent="Please switch your wallet to one of the following networks"
                    blockchainsFromParent={["BSC", "FANTOM", "ETH"]}
                  />
                )}
            </>
          )}
          {this.state.loadingOverlay === true && (
            <LoaderOverlay {...this.props} />
          )}
          <div
            className={styles.wrapper}
            style={
              this.props.onboard.walletConnected === false ||
              (this.getBlockchain(this.props.onboard.networkId) !== "BSC" &&
                this.getBlockchain(this.props.onboard.networkId) !== "FANTOM" &&
                this.getBlockchain(this.props.onboard.networkId) !== "ETH") ||
              this.state.loadingOverlay === true
                ? displayBlur
                : {}
            }
          >
            <div className={styles.content}>
              <div className={styles.headerSection}>
                <div className={`${styles.headingM} ${styles.primaryText}`}>
                  Stake
                </div>
                <div className="stake-top-info-layout">
                  <div className="stake-top-info-section">
                    <div className={`${styles.labelSRegular}`}>TVD</div>
                    {this.state.TVDLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"stake-top-info-value"}`}
                      >
                        ${this.state.TVD}
                      </div>
                    )}
                  </div>
                  <div className="stake-top-info-separator" />
                  <div className="stake-top-info-section">
                    <div className={`${styles.labelSRegular}`}>
                      Radar Deposited
                    </div>
                    {this.state.radarDepositedLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"stake-top-info-value"}`}
                      >
                        {this.state.radarDeposited}
                      </div>
                    )}
                  </div>
                  <div className="stake-top-info-separator" />
                  <div className="stake-top-info-section">
                    <div className={`${styles.labelSRegular}`}>APY</div>
                    {this.state.APYLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"stake-top-info-value"}`}
                      >
                        {this.state.APY}%
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {window.innerWidth > 1299 ? (
                <div className={styles.layout}>
                  <div
                    className={styles.leftSection}
                    style={
                      this.state.displayInfoCards === false ? doNotDisplay : {}
                    }
                  >
                    <div className={styles.cardsRow}>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                        >
                          Staked
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.stakedRadarInUsdtLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              ${this.state.stakedRadarInUsdt}
                            </div>
                          )}
                          {this.state.stakedRadarLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.labelMRegular} ${styles.secondaryText} ${styles.secondValue}`}
                            >
                              {this.state.stakedRadar.toFixed(2)} RADAR
                            </div>
                          )}
                        </div>
                        {this.state.amount !== 0 && this.state.amount !== "" && (
                          <>
                            {this.state.selectedAction === "stake" ? (
                              <div
                                class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                              >
                                {parseFloat(this.state.stakedRadar)}
                                <img
                                  src={greenArrowIcon}
                                  alt="arrow-icon"
                                  className={styles.cardBulletArrowIcon}
                                />
                                {parseFloat(
                                  parseFloat(this.state.stakedRadar) +
                                    parseFloat(this.state.amount)
                                ).toFixed(2)}{" "}
                                RADAR
                              </div>
                            ) : (
                              <>
                                {this.state.maxAmountAlert === false && (
                                  <div
                                    class={`${styles.cardBullet} ${styles.dangerBg} ${styles.dangerText} ${styles.labelMBold}`}
                                  >
                                    {parseFloat(this.state.stakedRadar)}
                                    <img
                                      src={redArrowIcon}
                                      alt="arrow-icon"
                                      className={styles.cardBulletArrowIcon}
                                    />
                                    {parseFloat(
                                      parseFloat(this.state.stakedRadar) -
                                        parseFloat(this.state.amount)
                                    ).toFixed(2)}{" "}
                                    RADAR
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      {/* <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                        >
                          sRADAR
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.radarRateLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.labelMBold} ${styles.primaryText}`}
                            >
                              {parseInt(
                                parseInt(this.state.stakedRadar) *
                                  parseInt(this.state.radarRate)
                              )}{" "}
                              sRADAR
                            </div>
                          )}
                        </div>
                        {this.state.amount === 0 || this.state.amount === "" ? (
                          <>
                            {this.state.radarRateLoading === true ? (
                              <>{this.renderLoading()}</>
                            ) : (
                              <div
                                class={`${styles.cardBullet} ${styles.bg} ${styles.primaryText} ${styles.labelMBold}`}
                              >
                                1 sRADAR = {this.state.radarRate} RADAR
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {this.state.maxAmountAlert === false && (
                              <>
                                {this.state.radarRateLoading === true ? (
                                  <>{this.renderLoading()}</>
                                ) : (
                                  <>
                                    {this.state.selectedAction === "stake" ? (
                                      <div
                                        class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                      >
                                        {parseInt(
                                          parseInt(this.state.stakedRadar) +
                                            parseInt(this.state.amount)
                                        ) * parseInt(this.state.radarRate)}{" "}
                                        sRADAR
                                      </div>
                                    ) : (
                                      <div
                                        class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                      >
                                        {parseInt(
                                          parseInt(this.state.stakedRadar) -
                                            parseInt(this.state.amount)
                                        ) * parseInt(this.state.radarRate)}{" "}
                                        sRADAR
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div> */}
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="stake-page-reward-claim-button-section">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Reward
                          </div>
                          {this.state.reward > 0 && (
                            <div
                              className={`${styles.labelMBold} ${styles.gradientText}`}
                              style={{ cursor: "pointer" }}
                              onClick={() => this.claim()}
                            >
                              Claim
                            </div>
                          )}
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.rewardLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.reward} RADAR
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.cardsRow}>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                        >
                          Your APY
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.APYLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.APY}%
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightSection}>
                    {this.state.selectedAction === "stake" &&
                      this.renderStake()}
                    {this.state.selectedAction === "unstake" &&
                      this.renderUnstake()}
                  </div>
                </div>
              ) : (
                <div className={styles.layout}>
                  <div
                    className={styles.leftSection}
                    style={
                      this.state.displayInfoCards === false ? doNotDisplay : {}
                    }
                  >
                    <div className={styles.cardsRow}>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                        >
                          Staked
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.stakedRadarInUsdtLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              ${this.state.stakedRadarInUsdt}
                            </div>
                          )}
                          {this.state.stakedRadarLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.labelMRegular} ${styles.secondaryText} ${styles.secondValue}`}
                            >
                              {this.state.stakedRadar.toFixed(2)} RADAR
                            </div>
                          )}
                        </div>
                        {this.state.amount !== 0 &&
                          this.state.amount !== "" &&
                          this.state.maxAmountAlert === false && (
                            <>
                              {this.state.selectedAction === "stake" ? (
                                <div
                                  class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                >
                                  {parseFloat(
                                    parseFloat(this.state.stakedRadar) +
                                      parseFloat(this.state.amount)
                                  ).toFixed(2)}{" "}
                                  RADAR
                                </div>
                              ) : (
                                <div
                                  class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                >
                                  {parseFloat(
                                    parseFloat(this.state.stakedRadar) -
                                      parseFloat(this.state.amount)
                                  ).toFixed(2)}{" "}
                                  RADAR
                                </div>
                              )}
                            </>
                          )}
                      </div>
                      {/* <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                        >
                          sRADAR
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.radarRateLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.labelMBold} ${styles.primaryText}`}
                            >
                              {parseInt(
                                parseInt(this.state.stakedRadar) *
                                  parseInt(this.state.radarRate)
                              )}{" "}
                              sRADAR
                            </div>
                          )}
                        </div>
                        {this.state.amount === 0 || this.state.amount === "" ? (
                          <>
                            {this.state.radarRateLoading === true ? (
                              <>{this.renderLoading()}</>
                            ) : (
                              <div
                                class={`${styles.cardBullet} ${styles.bg} ${styles.primaryText} ${styles.labelMBold}`}
                              >
                                1 sRADAR = {this.state.radarRate} RADAR
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {this.state.maxAmountAlert === false && (
                              <>
                                {this.state.radarRateLoading === true ? (
                                  <>{this.renderLoading()}</>
                                ) : (
                                  <>
                                    {this.state.selectedAction === "stake" ? (
                                      <div
                                        class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                      >
                                        {parseInt(
                                          parseInt(this.state.stakedRadar) +
                                            parseInt(this.state.amount)
                                        ) * parseInt(this.state.radarRate)}{" "}
                                        sRADAR
                                      </div>
                                    ) : (
                                      <div
                                        class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                      >
                                        {parseInt(
                                          parseInt(this.state.stakedRadar) -
                                            parseInt(this.state.amount)
                                        ) * parseInt(this.state.radarRate)}{" "}
                                        sRADAR
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div> */}
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="stake-page-reward-claim-button-section">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Reward
                          </div>
                          {this.state.reward > 0 && (
                            <div
                              className={`${styles.labelMBold} ${styles.gradientText}`}
                              style={{ cursor: "pointer" }}
                              onClick={() => this.claim()}
                            >
                              Claim
                            </div>
                          )}
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.rewardLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.reward} RADAR
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.cardsRow}>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                        >
                          Your APY
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.APYLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.APY}%
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightSection}>
                    {this.state.selectedAction === "stake" &&
                      this.renderStake()}
                    {this.state.selectedAction === "unstake" &&
                      this.renderUnstake()}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer {...this.props} state={this.state} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  checkWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(StakePage);
