import React, { Component } from "react";
import { connect } from "react-redux";
import "../../../utils/ethereum/onboard";
import { disconnectAccountOnboard } from "../../../redux/actions/OnboardActions";
import config from "../../../config/config.json";

import LogOutIcon from "../../icons/LogOutIcon";
import CloseIcon from "../../icons/CloseIcon";
import metamaskIcon from "../../../assets/SVGs/metamask.svg";
import styles from "../../../styles/index.module.css";
import "./disconnectDropdown.css";
import { ethers } from "ethers";
import {
  getSlippage,
  setSlippage,
} from "../../../web3/localstorage/slippageManager";

class DisconnectDopdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownClicked: false,

      slippage: 0.02,
      customSlippage: "",

      customSelected: false,
    };
  }

  closeDisconnectDropdown = () => {
    this.props.closeDisconnectDropdownEvent();
  };

  disconnect = () => {
    this.setState({
      dropdownClicked: true,
    });
    this.props.disconnectAccountOnboard();
    this.closeDisconnectDropdown();
  };

  displayAddress = (address) => {
    return `${address.substring(0, 2)}...${address.substring(38)}`;
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  componentDidMount() {
    const s = getSlippage(window.localStorage);
    this.setState({ slippage: s });
    if (s != 0.01 && s != 0.02) {
      this.setState({ customSlippage: s * 100 });
    }
  }

  addRadar = async () => {
    const bl = this.getBlockchain(this.props.onboard.networkId);
    const radarAddress = config.tokens.RADAR[bl].address;

    try {
      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      await provider.send("wallet_watchAsset", {
        type: "ERC20",
        options: {
          address: radarAddress,
          symbol: "RADAR",
          decimals: 18,
          image: "https://i.imgur.com/lIMKSJg.png",
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  addUsdr = async () => {
    const bl = this.getBlockchain(this.props.onboard.networkId);
    const usdrAddress = config.USDR[bl];

    try {
      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      await provider.send("wallet_watchAsset", {
        type: "ERC20",
        options: {
          address: usdrAddress,
          symbol: "USDR",
          decimals: 18,
          image: "https://i.imgur.com/8i43CHQ.png",
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  changeSlippage = (s) => {
    setSlippage(window.localStorage, parseFloat(s) / 100);
    this.setState({ slippage: parseFloat(s) / 100 });
  };

  customSlippageField = async (e) => {
    this.setState({
      customSelected: true,
    });
    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") {
        return;
      }
    }
    if (e.target.value === "") {
      this.changeSlippage(2);
      await this.setState({ customSlippage: "", selectedCustom: false });
      return;
    }
    var value = e.target.value;
    await this.setState({
      customSlippage: value,
    });
    this.changeSlippage(value);
  };

  render() {
    const selectedSlippageCss = {
      background: "linear-gradient(90deg, #E926C3 0%, #FF4D86 100%)",
      color: "#FFF",
    };
    const customSelectedCss = {
      background: "linear-gradient(90deg, #E926C3 0%, #FF4D86 100%)",
      color: "#FFF",
    };

    const customSelectedInputCss = {
      background: "linear-gradient(90deg, #E926C3 0%, #FF4D86 100%)",
      fontSize: "14px",
      borderRadius: "999px",
      textAlign: "center",
      border: "1px solid #E926C3",
      color: "#FFF"
    };

    const customNotSelectedInputCss = {
      fontSize: "14px",
      borderRadius: "999px",
      textAlign: "center",
      border: "1px solid #FFF",
      color: "#2E3338"
    };
    
    return (
      <>
        {window.innerWidth > 1299 ? (
          <>
            <div className="disconnect-dropdown-wrapper">
              <div
                className="disconnect-dropdown-close-wrapper"
                onClick={() => this.closeDisconnectDropdown()}
              />
              <div
                className={`${"disconnect-dropdown"} ${styles.whiteBg} ${
                  styles.elevationLow
                } ${this.props.state.disconnectDropdownCss}`}
              >
                <div
                  className={`${styles.labelLRegular} ${styles.primaryText}`}
                >
                  Wallet
                </div>
                <div className="disconnect-dropdown-header">
                  <div className="disconnect-dropdown-header-left-section">
                    <div className="disconnect-dropdown-header-left-section-icon-section">
                      <img
                        src={metamaskIcon}
                        alt="metamask-icon"
                        className="disconnect-dropdown-header-left-section-icon"
                      />
                    </div>
                    <div className="disconnect-dropdown-header-left-section-text-section">
                      <div
                        className={`${styles.labelMBold} ${styles.primaryText}`}
                      >
                        {this.displayAddress(
                          this.props.onboard.address
                            ? this.props.onboard.address
                            : ""
                        )}
                      </div>
                      <div
                        className={`${styles.labelSRegular} ${styles.secondaryText}`}
                      >
                        {parseFloat(
                          this.props.onboard.balance == null
                            ? 0
                            : this.props.onboard.balance / 10 ** 18
                        ).toFixed(2)}
                        {this.getBlockchain(this.props.onboard.networkId) !=
                        null
                          ? config.networks[
                              this.getBlockchain(this.props.onboard.networkId)
                            ].assetName
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="disconnect-dropdown-header-right-section">
                    <div
                      className={`${styles.labelSRegular} ${styles.gradientText}`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          this.props.onboard.address
                        );
                      }}
                    >
                      Copy
                    </div>
                  </div>
                </div>
                <div className="disconnect-dropdown-slippage-section">
                  <div
                    className={`${styles.labelLRegular} ${
                      styles.primaryText
                    } ${"disconnect-dropdown-slippage-title"}`}
                  >
                    Slippage settings
                  </div>
                  <div
                    className={`${styles.labelSRegular} ${styles.secondaryText}`}
                  >
                    Select the amount of slippage you would like to use on Radar
                    App.
                  </div>
                  <div
                    className={`${styles.labelMRegular} ${
                      styles.primaryText
                    } ${"disconnect-dropdown-slippage-subtitle"}`}
                  >
                    Your current slippage:{" "}
                    {(this.state.slippage * 100).toFixed(2)}%
                  </div>
                  <div className="disconnect-dropdown-slippage-input-section">
                    <div
                      className={`${"disconnect-dropdown-slippage-input-label"} ${
                        styles.labelMRegular
                      } ${styles.primaryText}`}
                      style={
                        this.state.slippage === 0.01 &&
                        this.state.customSelected === false
                          ? selectedSlippageCss
                          : {}
                      }
                      onClick={() => {
                        this.changeSlippage(1);
                        this.setState({
                          customSlippage: "",
                          customSelected: false,
                        });
                      }}
                    >
                      1%
                    </div>
                    <div
                      className={`${"disconnect-dropdown-slippage-input-label"} ${
                        styles.labelMRegular
                      } ${styles.primaryText}`}
                      style={
                        this.state.slippage === 0.02 &&
                        this.state.customSelected === false
                          ? selectedSlippageCss
                          : {}
                      }
                      onClick={() => {
                        this.changeSlippage(2);
                        this.setState({
                          customSlippage: "",
                          customSelected: false,
                        });
                      }}
                    >
                      2%
                    </div>
                    <div
                      className={`${"disconnect-dropdown-slippage-text-input-label"} ${
                        styles.labelMRegular
                      } ${styles.primaryText}`}
                      style={
                        this.state.customSelected === true
                          ? customSelectedCss
                          : {}
                      }
                    >
                      <input
                        type="text"
                        id="amount"
                        name="amount"
                        value={this.state.customSlippage}
                        placeholder="Custom"
                        onChange={(e) => this.customSlippageField(e)}
                        style={
                          this.state.customSelected === true
                            ? customSelectedInputCss
                            : customNotSelectedInputCss
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="disconnect-dropdown-add-tokens-section">
                  <div
                    className={`${styles.labelLRegular} ${
                      styles.primaryText
                    } ${"disconnect-dropdown-add-tokens-title"}`}
                  >
                    Add token to wallet
                  </div>
                  <div className="disconnect-dropdown-add-token-buttons-section">
                    {Object.keys(config.tokens.RADAR).includes(
                      this.getBlockchain(this.props.onboard.networkId)
                    ) && (
                      <div
                        className={`${styles.gradientText} ${
                          styles.labelMBold
                        } ${"disconnect-dropdown-add-token-button"}`}
                        onClick={() => this.addRadar()}
                      >
                        Add RADAR
                      </div>
                    )}
                    {Object.keys(config.USDR).includes(
                      this.getBlockchain(this.props.onboard.networkId)
                    ) && (
                      <div
                        className={`${styles.gradientText} ${
                          styles.labelMBold
                        } ${"disconnect-dropdown-add-token-button"}`}
                        onClick={() => this.addUsdr()}
                      >
                        Add USDR
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="disconnect-button"
                  onClick={() => this.disconnect()}
                >
                  <div className="disconnect-button-layout">
                    <LogOutIcon />
                    <div
                      className={`${
                        styles.labelMBold
                      } ${"disconnect-button-text"}`}
                    >
                      Disconnect
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="disconnect-dropdown-wrapper">
              <div className="disconnect-dropdown-layout">
                {/* <div
                  className={`${styles.whiteBg} ${styles.elevationLow} ${this.props.state.disconnectDropdownCss}`}
                  onClick={() => this.disconnect()}
                > */}
                <div
                  className={`${"disconnect-dropdown"} ${styles.whiteBg} ${
                    styles.elevationLow
                  } ${this.props.state.disconnectDropdownCss}`}
                >
                  <div className="disconnect-dropdown-header">
                    <div
                      className={`${styles.labelLRegular} ${styles.primaryText}`}
                    >
                      Wallet
                    </div>
                    {/* <div onClick={() => this.closeDisconnectDropdown()}>
                      <CloseIcon />
                    </div> */}
                  </div>
                  <div className="disconnect-dropdown-header">
                    <div className="disconnect-dropdown-header-left-section">
                      <div className="disconnect-dropdown-header-left-section-icon-section">
                        <img
                          src={metamaskIcon}
                          alt="metamask-icon"
                          className="disconnect-dropdown-header-left-section-icon"
                        />
                      </div>
                      <div className="disconnect-dropdown-header-left-section-text-section">
                        <div
                          className={`${styles.labelMBold} ${styles.primaryText}`}
                        >
                          {this.displayAddress(
                            this.props.onboard.address
                              ? this.props.onboard.address
                              : ""
                          )}
                        </div>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                        >
                          {parseFloat(
                            this.props.onboard.balance == null
                              ? 0
                              : this.props.onboard.balance / 10 ** 18
                          ).toFixed(2)}
                          {this.getBlockchain(this.props.onboard.networkId) !=
                          null
                            ? config.networks[
                                this.getBlockchain(this.props.onboard.networkId)
                              ].assetName
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="disconnect-dropdown-header-right-section">
                      <div
                        className={`${styles.labelSRegular} ${styles.gradientText}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            this.props.onboard.address
                          );
                        }}
                      >
                        Copy
                      </div>
                    </div>
                  </div>
                  <div className="disconnect-dropdown-slippage-section">
                    <div
                      className={`${styles.labelLRegular} ${
                        styles.primaryText
                      } ${"disconnect-dropdown-slippage-title"}`}
                    >
                      Slippage settings
                    </div>
                    <div
                      className={`${styles.labelSRegular} ${styles.secondaryText}`}
                    >
                      Select the amount of slippage you would like to use on
                      Radar App.
                    </div>
                    <div
                      className={`${styles.labelMRegular} ${
                        styles.primaryText
                      } ${"disconnect-dropdown-slippage-subtitle"}`}
                    >
                      Your current slippage:{" "}
                      {(this.state.slippage * 100).toFixed(2)}%
                    </div>
                    <div className="disconnect-dropdown-slippage-input-section">
                      <div
                        className={`${"disconnect-dropdown-slippage-input-label"} ${
                          styles.labelMRegular
                        } ${styles.primaryText}`}
                        style={
                          this.state.slippage === 0.01 &&
                          this.state.customSelected === false
                            ? selectedSlippageCss
                            : {}
                        }
                        onClick={() => {
                          this.changeSlippage(1);
                          this.setState({
                            customSlippage: "",
                            customSelected: false,
                          });
                        }}
                      >
                        1%
                      </div>
                      <div
                        className={`${"disconnect-dropdown-slippage-input-label"} ${
                          styles.labelMRegular
                        } ${styles.primaryText}`}
                        style={
                          this.state.slippage === 0.02 &&
                          this.state.customSelected === false
                            ? selectedSlippageCss
                            : {}
                        }
                        onClick={() => {
                          this.changeSlippage(2);
                          this.setState({
                            customSlippage: "",
                            customSelected: false,
                          });
                        }}
                      >
                        2%
                      </div>
                      <div
                        className={`${"disconnect-dropdown-slippage-input-label"} ${
                          styles.labelMRegular
                        } ${styles.primaryText}`}
                        style={
                          this.state.customSelected === true
                            ? customSelectedCss
                            : {}
                        }
                      >
                        <input
                          type="text"
                          id="amount"
                          name="amount"
                          value={this.state.customSlippage}
                          placeholder="Custom"
                          onChange={(e) => this.customSlippageField(e)}
                          style={
                            this.state.customSelected === true
                              ? customSelectedInputCss
                              : customNotSelectedInputCss
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="disconnect-dropdown-add-tokens-section">
                    <div
                      className={`${styles.labelLRegular} ${
                        styles.primaryText
                      } ${"disconnect-dropdown-add-tokens-title"}`}
                    >
                      Add token to wallet
                    </div>
                    <div className="disconnect-dropdown-add-token-buttons-section">
                      {Object.keys(config.tokens.RADAR).includes(
                        this.getBlockchain(this.props.onboard.networkId)
                      ) && (
                        <div
                          className={`${styles.gradientText} ${
                            styles.labelMBold
                          } ${"disconnect-dropdown-add-token-button"}`}
                          onClick={() => this.addRadar()}
                        >
                          Add RADAR
                        </div>
                      )}
                      {Object.keys(config.USDR).includes(
                        this.getBlockchain(this.props.onboard.networkId)
                      ) && (
                        <div
                          className={`${styles.gradientText} ${
                            styles.labelMBold
                          } ${"disconnect-dropdown-add-token-button"}`}
                          onClick={() => this.addUsdr()}
                        >
                          Add USDR
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="disconnect-button"
                    onClick={() => this.disconnect()}
                  >
                    <div className="disconnect-button-layout">
                      <LogOutIcon />
                      <div
                        className={`${
                          styles.labelMBold
                        } ${"disconnect-button-text"}`}
                      >
                        Disconnect
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  disconnectAccountOnboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(DisconnectDopdown);
