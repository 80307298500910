import { ethers } from 'ethers';
import IERC20 from './abis/IERC20.json';
import IUniswapV2Pair from './abis/IUniswapV2Pair.json';

export const IERC20Interface = new ethers.utils.Interface(
    JSON.parse(JSON.stringify(IERC20.abi))
);

export const UniswapPairInterface = new ethers.utils.Interface(
    JSON.parse(JSON.stringify(IUniswapV2Pair.abi))
);