import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config/config.json";

import Header from "../../layouts/header/Header";
import Sidebar from "../../layouts/sidebar/Sidebar";
import Footer from "../../layouts/footer/Footer";
import Loader from "../../components/loader/Loader";
import SwitchNetworkOverlay from "../../components/overlays/switch-network/SwitchNetworkOverlay";
import LoaderOverlay from "../../components/overlays/loader/LoaderOverlay";
import WalletNotConnectedOverlay from "../../components/overlays/wallet-not-connected/WalletNotConnectedOverlay";

import GradientInterfaceIcon from "../../components/icons/GradientInterfaceIcon";
import CaretDownIcon from "../../components/icons/CaretDownIcon";
import CaretUpIcon from "../../components/icons/CaretUpIcon";
import CloseIcon from "../../components/icons/CloseIcon";
import styles from "../../styles/index.module.css";
import "./farmPoolPage.css";

import greenArrowIcon from "../../assets/SVGs/green-arrow.svg";
import orangeArrowIcon from "../../assets/SVGs/orange-arrow.svg";
import redArrowIcon from "../../assets/SVGs/red-arrow.svg";

import { Close } from "@material-ui/icons";
import { getRadarPrice } from "../../web3/bonds/lib/dao";
import {
  depositToFarm,
  farmClaimReward,
  getFarmPoolAPR,
  getFarmPoolTVLUSD,
  getUserReward,
  getUserStaked,
  withdrawFromFarm,
} from "../../web3/farms/api";
import {
  approveToken,
  getTokenAllowance,
  getTokenBalance,
  scaleDecimals,
} from "../../web3/usdr/api";

import { toastr } from "react-redux-toastr";
import { ethers } from "ethers";

class FarmPoolPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: true,
      backButtonRoute: "/farm",
      displayInfoCards: true,

      TVL: 0,
      TVLLoading: true,

      APR: 0,
      APRLoading: true,

      LPStaked: 0,
      LPStakedLoading: true,
      LPStakedATM: 0,

      reward: 0,
      rewardLoading: true,

      amount: "",
      amountInUSD: 0,
      amountInUSDLoading: false,
      amountMaxClicked: false,

      withdrawAmount: "",
      withdrawAmountInUSD: 0,
      withdrawAmountInUSDLoading: false,
      withdrawAmountMaxClicked: false,

      LPBalance: 0,
      LPBalanceLoading: true,

      fee: 50,
      radarFee: 25,
      transactionFee: 25,

      withdrawPopup: false,

      allowance: 0,

      depositAssetPrice: 0,

      loadingOverlay: false,
      radarPrice: 0,
    };
  }

  setInfoCardsDisplayState = () => {
    if (this.state.displayInfoCards === true) {
      this.setState({
        displayInfoCards: false,
      });
    } else {
      this.setState({
        displayInfoCards: true,
      });
    }
  };

  renderLoading() {
    return <Loader {...this.props} />;
  }

  getAssetIcon = (type) => {
    const icon = require(`../../assets/SVGs/assets/${type}`).default;
    return icon;
  };

  getBlockchainIcon = (type) => {
    const icon = require(`../../assets/SVGs/${type}`).default;
    return icon;
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  getPoolAddress = () => {
    const curentPath = window.location.pathname;
    const currentPoolAddress = curentPath.split("/farm/").pop();
    return currentPoolAddress;
  };

  getPool = () => {
    const pools = Object.keys(config.farm.pools);
    for (var i = 0; i < pools.length; i++) {
      var currentPool = pools[i];
      if (config.farm.pools[currentPool].address == this.getPoolAddress())
        return currentPool;
    }
  };

  amountField = async (e) => {
    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") return;
    }

    var value = e.target.value;
    this.setState({
      amount: value,
      amountInUSD: value != "" ? value * this.state.depositAssetPrice : 0,
      LPStakedATM: value != "" ? this.state.LPStaked + parseFloat(value) : 0,
      amountMaxClicked: false,
    });
  };

  withdrawAmountField = async (e) => {
    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") return;
    }

    var value = e.target.value;
    this.setState({
      withdrawAmount: value,
      withdrawAmountInUSD:
        value != "" ? value * this.state.depositAssetPrice : 0,
      LPStakedATM:
        value != 0 ? Math.max(this.state.LPStaked - parseFloat(value), 0) : 0,
      withdrawAmountMaxClicked: false,
    });
  };

  async componentDidMount() {
    await this.getPoolMetadata();
    await this.getUserData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
      await this.getPoolMetadata();
      await this.getUserData();
    }
  }

  async claimReward(e) {
    await e.preventDefault();
    // Loading
    await this.setState({
      loadingOverlay: true,
    });
    try {
      const poolAddress = config.farm.pools[this.getPool()].address;
      const poolType = config.farm.pools[this.getPool()].contractType;
      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      const signer = await provider.getSigner();

      await farmClaimReward(signer, poolType, poolAddress);
      toastr.success("Success", "Transaction confirmed.");
    } catch (e) {
      toastr.error("Error", "Error: " + e.message);
    }
    // Stop loading
    await this.getUserData();
    await this.setState({
      loadingOverlay: false,
    });
  }

  async withdraw() {
    if (
      this.state.withdrawAmount > this.state.LPStaked &&
      !this.state.withdrawAmountMaxClicked
    ) {
      return;
    }
    // Loading
    await this.setState({
      loadingOverlay: true,
    });
    try {
      const poolAddress = config.farm.pools[this.getPool()].address;
      const poolType = config.farm.pools[this.getPool()].contractType;
      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      const signer = await provider.getSigner();
      const depositTokenDecimals =
        config.farm.pools[this.getPool()].depositAsset.decimals;
      const bl = this.getBlockchain(this.props.onboard.networkId);
      const rpc = config.networks[bl].endpoint;
      const userAddress = this.props.onboard.address;

      var scaledAmount;
      if (this.state.withdrawAmountMaxClicked) {
        scaledAmount = await getTokenBalance(rpc, poolAddress, userAddress);
      } else {
        scaledAmount = scaleDecimals(
          this.state.withdrawAmount,
          depositTokenDecimals
        );
      }

      await withdrawFromFarm(signer, poolType, poolAddress, scaledAmount);
      toastr.success("Success", "Transaction confirmed.");
    } catch (e) {
      toastr.error("Error", "Error: " + e.message);
    }
    // Stop loading
    await this.getPoolMetadata();
    await this.getUserData();
    await this.setState({
      loadingOverlay: false,
      withdrawAmount: "",
      withdrawAmountInUSD: 0,
      withdrawAmountMaxClicked: false,
      LPStakedATM: 0,
    });
  }

  async deposit() {
    if (
      this.state.amount > this.state.LPBalance &&
      !this.state.amountMaxClicked
    ) {
      return;
    }
    // Loading
    await this.setState({
      loadingOverlay: true,
    });
    try {
      const poolAddress = config.farm.pools[this.getPool()].address;
      const poolType = config.farm.pools[this.getPool()].contractType;
      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      const signer = await provider.getSigner();
      const depositTokenAddress =
        config.farm.pools[this.getPool()].depositAsset.address;
      const depositTokenDecimals =
        config.farm.pools[this.getPool()].depositAsset.decimals;
      const bl = this.getBlockchain(this.props.onboard.networkId);
      const rpc = config.networks[bl].endpoint;
      const userAddress = this.props.onboard.address;

      var scaledAmount;
      if (this.state.amountMaxClicked) {
        scaledAmount = await getTokenBalance(
          rpc,
          depositTokenAddress,
          userAddress
        );
      } else {
        scaledAmount = scaleDecimals(this.state.amount, depositTokenDecimals);
      }

      await depositToFarm(
        signer,
        poolType,
        poolAddress,
        userAddress,
        scaledAmount
      );
      toastr.success("Success", "Transaction confirmed.");
    } catch (e) {
      toastr.error("Error", "Error: " + e.message);
    }
    // Stop loading
    await this.getPoolMetadata();
    await this.getUserData();
    await this.setState({
      loadingOverlay: false,
      amount: "",
      amountInUSD: 0,
      amountMaxClicked: false,
      LPStakedATM: 0,
    });
  }

  async approve() {
    // Loading
    await this.setState({
      loadingOverlay: true,
    });
    try {
      const poolAddress = config.farm.pools[this.getPool()].address;
      const depositTokenAddress =
        config.farm.pools[this.getPool()].depositAsset.address;

      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );

      const signer = await provider.getSigner();

      await approveToken(depositTokenAddress, poolAddress, signer, 0);
      toastr.success("Success", "Transaction confirmed.");
    } catch (e) {
      toastr.error("Error", "Error approving: " + e.message);
    }
    // Stop loading
    await this.getUserData();
    await this.setState({
      loadingOverlay: false,
    });
  }

  async getPoolMetadata() {
    await this.setState({
      APRLoading: true,
      TVLLoading: true,
    });

    const bl = this.getBlockchain(this.props.onboard.networkId);
    const rpc = config.networks[bl].endpoint;
    const radarPrice = await getRadarPrice();
    const poolKey = this.getPool();

    var poolUsdTVL = await getFarmPoolTVLUSD(
      rpc,
      1, // TODO: CHANGE ME
      config.farm.pools[poolKey].depositAsset.decimals,
      config.farm.pools[poolKey].contractType,
      config.farm.pools[poolKey].address
    );
    await this.setState({
      TVL: poolUsdTVL,
      TVLLoading: false,
      radarPrice: radarPrice,
    });

    var apr = await getFarmPoolAPR(
      rpc,
      1, // TODO: CHANGE ME
      config.farm.pools[poolKey].depositAsset.decimals,
      config.farm.pools[poolKey].contractType,
      config.farm.pools[poolKey].address,
      radarPrice
    );
    await this.setState({
      APR: apr,
      APRLoading: false,
    });

    // TODO: CHANGE ME
    await this.setState({
      depositAssetPrice: 1,
    });
  }

  async getUserData() {
    await this.setState({
      rewardLoading: true,
      LPStakedLoading: true,
      LPBalanceLoading: true,
    });

    const bl = this.getBlockchain(this.props.onboard.networkId);
    const rpc = config.networks[bl].endpoint;
    const userAddress = this.props.onboard.address;
    const poolKey = this.getPool();

    var userBalance = await getUserStaked(
      rpc,
      config.farm.pools[poolKey].depositAsset.decimals,
      config.farm.pools[poolKey].contractType,
      config.farm.pools[poolKey].address,
      userAddress
    );
    await this.setState({
      LPStakedLoading: false,
      LPStaked: userBalance,
    });

    var userRewards = await getUserReward(
      rpc,
      config.farm.pools[poolKey].contractType,
      config.farm.pools[poolKey].address,
      userAddress
    );
    await this.setState({
      rewardLoading: false,
      reward: userRewards,
    });

    var userLPBalance = await getTokenBalance(
      rpc,
      config.farm.pools[poolKey].depositAsset.address,
      userAddress
    );
    userLPBalance /= 10 ** config.farm.pools[poolKey].depositAsset.decimals;
    await this.setState({
      LPBalanceLoading: false,
      LPBalance: userLPBalance,
    });

    var userAllowance = await getTokenAllowance(
      rpc,
      config.farm.pools[poolKey].depositAsset.address,
      userAddress,
      config.farm.pools[poolKey].address
    );
    userAllowance /= 10 ** config.farm.pools[poolKey].depositAsset.decimals;
    await this.setState({
      allowance: userAllowance,
    });
  }

  render() {
    const doNotDisplay = {
      display: "none",
    };

    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };
    return (
      <>
        {this.state.loadingOverlay === true && (
          <LoaderOverlay {...this.props} />
        )}
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Sidebar {...this.props} state={this.state} />
          {this.props.onboard.walletConnected === false ? (
            <WalletNotConnectedOverlay {...this.props} />
          ) : (
            <>
              {this.getBlockchain(this.props.onboard.networkId) !== "AVAXC" &&
                this.getBlockchain(this.props.onboard.networkId) !==
                  "ETHEREUM" && (
                  <SwitchNetworkOverlay
                    {...this.props}
                    primaryTextFromParent={
                      "This farm is not available on " +
                      config.networks[
                        this.getBlockchain(this.props.onboard.networkId)
                      ].name
                    }
                    secondaryTextFromParent="Please switch your wallet to the following network"
                    blockchainsFromParent={["AVAXC"]}
                  />
                )}
            </>
          )}
          <div
            className={styles.wrapper}
            style={
              this.props.onboard.walletConnected === false ||
              (this.getBlockchain(this.props.onboard.networkId) !== "AVAXC" &&
                this.getBlockchain(this.props.onboard.networkId) !==
                  "ETHEREUM") ||
              this.state.loadingOverlay === true
                ? displayBlur
                : {}
            }
          >
            <div className={styles.content}>
              <div className={styles.headerSection}>
                <div className={`${styles.headingM} ${styles.primaryText}`}>
                  {config.farm.pools[this.getPool()].name}
                </div>
                <div className="pool-top-info-layout">
                  <div className="pool-top-info-section">
                    <div className={`${styles.labelSRegular}`}>TVL</div>
                    {this.state.TVLLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"pool-top-info-value"}`}
                      >
                        $
                        {this.state.TVL.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    )}
                  </div>
                  <div className="pool-top-info-separator" />
                  <div className="pool-top-info-section">
                    <div className={`${styles.labelSRegular}`}>APR</div>
                    {this.state.APRLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"pool-top-info-value"}`}
                      >
                        {(this.state.APR * 100).toFixed(2)}%
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {window.innerWidth > 1299 ? (
                <div className={styles.layout}>
                  <div
                    className={styles.leftSection}
                    style={
                      this.state.displayInfoCards === false ? doNotDisplay : {}
                    }
                  >
                    <div className={styles.cardsRow}>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="farm-pool-card-header">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            LP staked
                          </div>
                          {this.state.LPStaked > 0 && (
                            <div className="farm-withdraw-label">
                              <div
                                className={`${styles.labelMBold} ${styles.gradientText}`}
                                onClick={() =>
                                  this.setState({
                                    withdrawPopup: true,
                                    amount: "",
                                    withdrawAmount: "",
                                  })
                                }
                              >
                                Withdraw
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.LPStakedLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.LPStaked.toFixed(2)}{" "}
                              {
                                config.farm.pools[this.getPool()].depositAsset
                                  .sym
                              }
                            </div>
                          )}
                        </div>
                        {((this.state.amount !== 0 &&
                          this.state.amount !== "") ||
                          (this.state.withdrawAmount !== 0 &&
                            this.state.withdrawAmount !== "")) && (
                          <div
                            class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                          >
                            {this.state.LPStaked.toFixed(2)}{" "}
                            <img
                              src={greenArrowIcon}
                              alt="arrow-icon"
                              className={styles.cardBulletArrowIcon}
                            />
                            {this.state.LPStakedATM.toFixed(2)}{" "}
                            {config.farm.pools[this.getPool()].depositAsset.sym}
                          </div>
                        )}
                      </div>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="farm-pool-card-header">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Reward
                          </div>
                          {this.state.reward > 0 && (
                            <div className="farm-claim-label">
                              <div
                                className={`${styles.labelMBold} ${styles.gradientText}`}
                                onClick={(e) => this.claimReward(e)}
                              >
                                Claim
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.rewardLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.reward.toFixed(2)} RADAR ($
                              {(
                                this.state.reward * this.state.radarPrice
                              ).toFixed(2)}
                              )
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.withdrawPopup === false ? (
                    <div className={styles.rightSection}>
                      <div
                        className={`${styles.inputCard} ${styles.whiteBg}`}
                        style={{ borderRadius: "8px", borderTop: "none" }}
                      >
                        <div className={styles.inputCardHeader}>
                          <div
                            className={styles.inputCardIcon}
                            onClick={() => this.setInfoCardsDisplayState()}
                          >
                            <GradientInterfaceIcon />
                          </div>
                          <div
                            className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                          >
                            Stake{" "}
                            {config.farm.pools[this.getPool()].depositAsset.sym}
                          </div>
                        </div>
                        <div className={styles.inputHeader}>
                          <div
                            className={`${styles.labelSRegular} ${styles.primaryText}`}
                          >
                            Amount
                          </div>
                          <div
                            className={`${
                              styles.inputBalanceSectionGrad
                            } ${"pool-page-balance-button"}`}
                            onClick={() =>
                              this.setState({
                                amount: this.state.LPBalance.toFixed(4),
                                amountInUSD:
                                  this.state.LPBalance *
                                  this.state.depositAssetPrice,
                                LPStakedATM:
                                  this.state.LPStaked +
                                  parseFloat(this.state.LPBalance),
                                amountMaxClicked: true,
                              })
                            }
                          >
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              Balance
                            </div>
                            {this.state.LPBalanceLoading === true ? (
                              <>{this.renderLoading()}</>
                            ) : (
                              <div
                                className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
                              >
                                {this.state.LPBalance.toFixed(2)} LP
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.inputBox}>
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            placeholder="0.00"
                            value={this.state.amount}
                            onChange={(e) => this.amountField(e)}
                            style={{
                              fontSize: "21px",
                              lineHeight: "32px",
                            }}
                          />
                          {/* {this.state.amountInUSDLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              ${this.state.amountInUSD}
                            </div>
                          )} */}
                        </div>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          style={{ marginTop: "8px" }}
                        >
                          Don't have enough funds?
                          <a
                            href={config.farm.pools[this.getPool()].getLPURL}
                            target="_blank"
                            style={{ textDecoration: "none", color: "#FF4D86" }}
                          >
                            {" "}
                            Get LP
                          </a>
                        </div>
                        {this.state.amount === 0 || this.state.amount === "" ? (
                          <div
                            className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                          >
                            Enter an amount
                          </div>
                        ) : (
                          <>
                            {this.state.allowance <
                            parseInt(this.state.amount) ? (
                              <div
                                className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                                onClick={() => this.approve()}
                              >
                                Approve
                              </div>
                            ) : (
                              <>
                                <div
                                  className={`${styles.button} ${
                                    this.state.amount > this.state.LPBalance &&
                                    !this.state.amountMaxClicked
                                      ? styles.greyBg
                                      : styles.gradientBg
                                  } ${styles.labelMBold} ${
                                    this.state.amount > this.state.LPBalance &&
                                    !this.state.amountMaxClicked
                                      ? styles.disabledText
                                      : styles.whiteText
                                  } ${styles.inputButton}`}
                                  onClick={() => this.deposit()}
                                >
                                  {this.state.amount > this.state.LPBalance &&
                                  !this.state.amountMaxClicked
                                    ? "Insufficient balance"
                                    : "Deposit"}
                                </div>
                                {/* <div className={styles.orderDetailsSection}>
                                  <div
                                    className={`${styles.labelMBold} ${styles.primaryText}`}
                                  >
                                    Order details
                                  </div>
                                  <div className={styles.orderDetailsRow}>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                    >
                                      Fees
                                    </div>
                                    <div className={styles.orderDetailsFeesRow}>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                      >
                                        ${this.state.fee}
                                      </div>
                                      {this.state.showFees === true ? (
                                        <div
                                          className={
                                            styles.orderDetailsFeesIcon
                                          }
                                          onClick={() =>
                                            this.setState({
                                              showFees: false,
                                            })
                                          }
                                        >
                                          <CaretUpIcon />
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            styles.orderDetailsFeesIcon
                                          }
                                          onClick={() =>
                                            this.setState({
                                              showFees: true,
                                            })
                                          }
                                        >
                                          <CaretDownIcon />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.showFees === true && (
                                    <>
                                      <div
                                        className={styles.orderDetailsSubRow}
                                      >
                                        <div
                                          className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                        >
                                          Radar fee
                                        </div>
                                        <div
                                          className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                        >
                                          ${this.state.radarFee}
                                        </div>
                                      </div>
                                      <div
                                        className={styles.orderDetailsSubRow}
                                      >
                                        <div
                                          className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                        >
                                          Transaction fee
                                        </div>
                                        <div
                                          className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                        >
                                          ${this.state.transactionFee}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div> */}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.rightSection}>
                      <div
                        className={`${styles.inputCard} ${styles.whiteBg}`}
                        style={{ borderRadius: "8px", borderTop: "none" }}
                      >
                        <div className={styles.inputCardHeader}>
                          <div
                            className={styles.inputCardIcon}
                            onClick={() =>
                              this.setState({
                                withdrawPopup: false,
                                amount: "",
                                withdrawAmount: "",
                              })
                            }
                          >
                            <CloseIcon />
                          </div>
                          <div
                            className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                          >
                            Withdraw
                          </div>
                        </div>
                        <div className={styles.inputHeader}>
                          <div
                            className={`${styles.labelSRegular} ${styles.primaryText}`}
                          >
                            Amount
                          </div>
                          <div
                            className={`${
                              styles.inputBalanceSectionGrad
                            } ${"pool-page-balance-button"} `}
                            onClick={() =>
                              this.setState({
                                withdrawAmount: this.state.LPStaked.toFixed(4),
                                withdrawAmountMaxClicked: true,
                                LPStakedATM: 0,
                              })
                            }
                          >
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              Balance
                            </div>
                            {this.state.LPStakedLoading === true ? (
                              <>{this.renderLoading()}</>
                            ) : (
                              <div
                                className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
                              >
                                {this.state.LPStaked.toFixed(2)} LP
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.inputBox}>
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            placeholder="0.00"
                            value={this.state.withdrawAmount}
                            onChange={(e) => this.withdrawAmountField(e)}
                            style={{
                              fontSize: "21px",
                              lineHeight: "32px",
                            }}
                          />
                          {/* {this.state.withdrawAmountInUSDLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              ${this.state.withdrawAmountInUSD}
                            </div>
                          )} */}
                        </div>
                        {this.state.withdrawAmount === 0 ||
                        this.state.withdrawAmount === "" ? (
                          <div
                            className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                          >
                            Enter an amount
                          </div>
                        ) : (
                          <>
                            <div
                              className={`${styles.button} ${
                                this.state.withdrawAmount >
                                  this.state.LPStaked &&
                                !this.state.withdrawAmountMaxClicked
                                  ? styles.greyBg
                                  : styles.gradientBg
                              } ${styles.labelMBold} ${
                                this.state.withdrawAmount >
                                  this.state.LPStaked &&
                                !this.state.withdrawAmountMaxClicked
                                  ? styles.disabledText
                                  : styles.whiteText
                              } ${styles.inputButton}`}
                              onClick={() => this.withdraw()}
                            >
                              {this.state.withdrawAmount >
                                this.state.LPStaked &&
                              !this.state.withdrawAmountMaxClicked
                                ? "Insufficient balance"
                                : "Withdraw"}
                            </div>
                            {/* <div className={styles.orderDetailsSection}>
                              <div
                                className={`${styles.labelMBold} ${styles.primaryText}`}
                              >
                                Order details
                              </div>
                              <div className={styles.orderDetailsRow}>
                                <div
                                  className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                >
                                  Fees
                                </div>
                                <div className={styles.orderDetailsFeesRow}>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    ${this.state.fee}
                                  </div>
                                  {this.state.showFees === true ? (
                                    <div
                                      className={styles.orderDetailsFeesIcon}
                                      onClick={() =>
                                        this.setState({
                                          showFees: false,
                                        })
                                      }
                                    >
                                      <CaretUpIcon />
                                    </div>
                                  ) : (
                                    <div
                                      className={styles.orderDetailsFeesIcon}
                                      onClick={() =>
                                        this.setState({
                                          showFees: true,
                                        })
                                      }
                                    >
                                      <CaretDownIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {this.state.showFees === true && (
                                <>
                                  <div className={styles.orderDetailsSubRow}>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                    >
                                      Radar fee
                                    </div>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                    >
                                      ${this.state.radarFee}
                                    </div>
                                  </div>
                                  <div className={styles.orderDetailsSubRow}>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                    >
                                      Transaction fee
                                    </div>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                    >
                                      ${this.state.transactionFee}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div> */}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.layout}>
                  {this.state.withdrawPopup === false ? (
                    <div className={styles.rightSection}>
                      <div
                        className={`${styles.inputCard} ${styles.whiteBg}`}
                        style={{ borderRadius: "8px", borderTop: "none" }}
                      >
                        <div className={styles.inputCardHeader}>
                          <div
                            className={styles.inputCardIcon}
                            onClick={() => this.setInfoCardsDisplayState()}
                          >
                            <GradientInterfaceIcon />
                          </div>
                          <div
                            className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                          >
                            Stake{" "}
                            {config.farm.pools[this.getPool()].assets[1].sym} -{" "}
                            {config.farm.pools[this.getPool()].assets[2].sym} LP
                          </div>
                        </div>
                        <div className={styles.inputHeader}>
                          <div
                            className={`${styles.labelSRegular} ${styles.primaryText}`}
                          >
                            Amount
                          </div>
                          <div
                            className={` ${
                              styles.inputBalanceSectionGrad
                            } ${"pool-page-balance-button"} `}
                            onClick={() =>
                              this.setState({
                                amount: this.state.LPBalance.toFixed(4),
                                amountInUSD:
                                  this.state.LPBalance *
                                  this.state.depositAssetPrice,
                                LPStakedATM:
                                  this.state.LPStaked +
                                  parseFloat(this.state.LPBalance),
                                amountMaxClicked: true,
                              })
                            }
                          >
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              Balance
                            </div>
                            {this.state.LPBalanceLoading === true ? (
                              <>{this.renderLoading()}</>
                            ) : (
                              <div
                                className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
                              >
                                {this.state.LPBalance.toFixed(2)} LP
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.inputBox}>
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            placeholder="0.00"
                            value={this.state.amount}
                            onChange={(e) => this.amountField(e)}
                            style={{
                              fontSize: "21px",
                              lineHeight: "32px",
                            }}
                          />
                          {/* {this.state.amountInUSDLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              ${this.state.amountInUSD}
                            </div>
                          )} */}
                        </div>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          style={{ marginTop: "8px" }}
                        >
                          Don't have enough funds?
                          <a
                            href=""
                            target="_blank"
                            style={{ textDecoration: "none", color: "#FF4D86" }}
                          >
                            {" "}
                            Get LP
                          </a>
                        </div>
                        {this.state.amount === 0 || this.state.amount === "" ? (
                          <div
                            className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                          >
                            Enter an amount
                          </div>
                        ) : (
                          <>
                            {this.state.allowance <
                            parseInt(this.state.amount) ? (
                              <div
                                className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                                onClick={() => this.approve()}
                              >
                                Approve
                              </div>
                            ) : (
                              <>
                                <div
                                  className={`${styles.button} ${
                                    this.state.amount > this.state.LPBalance &&
                                    !this.state.amountMaxClicked
                                      ? styles.greyBg
                                      : styles.gradientBg
                                  } ${styles.labelMBold} ${
                                    this.state.amount > this.state.LPBalance &&
                                    !this.state.amountMaxClicked
                                      ? styles.disabledText
                                      : styles.whiteText
                                  } ${styles.inputButton}`}
                                  onClick={() => this.deposit()}
                                >
                                  {this.state.amount > this.state.LPBalance &&
                                  !this.state.amountMaxClicked
                                    ? "Insufficient balance"
                                    : "Deposit"}
                                </div>
                                {/* <div className={styles.orderDetailsSection}>
                                  <div
                                    className={`${styles.labelMBold} ${styles.primaryText}`}
                                  >
                                    Order details
                                  </div>
                                  <div className={styles.orderDetailsRow}>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                    >
                                      Fees
                                    </div>
                                    <div className={styles.orderDetailsFeesRow}>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                      >
                                        ${this.state.fee}
                                      </div>
                                      {this.state.showFees === true ? (
                                        <div
                                          className={
                                            styles.orderDetailsFeesIcon
                                          }
                                          onClick={() =>
                                            this.setState({
                                              showFees: false,
                                            })
                                          }
                                        >
                                          <CaretUpIcon />
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            styles.orderDetailsFeesIcon
                                          }
                                          onClick={() =>
                                            this.setState({
                                              showFees: true,
                                            })
                                          }
                                        >
                                          <CaretDownIcon />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.showFees === true && (
                                    <>
                                      <div
                                        className={styles.orderDetailsSubRow}
                                      >
                                        <div
                                          className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                        >
                                          Radar fee
                                        </div>
                                        <div
                                          className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                        >
                                          ${this.state.radarFee}
                                        </div>
                                      </div>
                                      <div
                                        className={styles.orderDetailsSubRow}
                                      >
                                        <div
                                          className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                        >
                                          Transaction fee
                                        </div>
                                        <div
                                          className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                        >
                                          ${this.state.transactionFee}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div> */}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.rightSection}>
                      <div
                        className={`${styles.inputCard} ${styles.whiteBg}`}
                        style={{ borderRadius: "8px", borderTop: "none" }}
                      >
                        <div className={styles.inputCardHeader}>
                          <div
                            className={styles.inputCardIcon}
                            onClick={() =>
                              this.setState({
                                withdrawPopup: false,
                                amount: "",
                                withdrawAmount: "",
                              })
                            }
                          >
                            <CloseIcon />
                          </div>
                          <div
                            className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                          >
                            Withdraw
                          </div>
                        </div>
                        <div className={styles.inputHeader}>
                          <div
                            className={`${styles.labelSRegular} ${styles.primaryText}`}
                          >
                            Amount
                          </div>
                          <div
                            className={` ${
                              styles.inputBalanceSectionGrad
                            } ${"pool-page-balance-button"} `}
                            onClick={() =>
                              this.setState({
                                withdrawAmount: this.state.LPStaked.toFixed(4),
                                withdrawAmountMaxClicked: true,
                                LPStakedATM: 0,
                              })
                            }
                          >
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              Balance
                            </div>
                            {this.state.LPBalanceLoading === true ? (
                              <>{this.renderLoading()}</>
                            ) : (
                              <div
                                className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
                              >
                                {this.state.LPStaked.toFixed(2)} LP
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.inputBox}>
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            placeholder="0.00"
                            value={this.state.withdrawAmount}
                            onChange={(e) => this.withdrawAmountField(e)}
                            style={{
                              fontSize: "21px",
                              lineHeight: "32px",
                            }}
                          />
                          {/* {this.state.withdrawAmountInUSDLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              ${this.state.withdrawAmountInUSD}
                            </div>
                          )} */}
                        </div>
                        {this.state.withdrawAmount === 0 ||
                        this.state.withdrawAmount === "" ? (
                          <div
                            className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                          >
                            Enter an amount
                          </div>
                        ) : (
                          <>
                            <div
                              className={`${styles.button} ${
                                this.state.withdrawAmount >
                                  this.state.LPStaked &&
                                !this.state.withdrawAmountMaxClicked
                                  ? styles.greyBg
                                  : styles.gradientBg
                              } ${styles.labelMBold} ${
                                this.state.withdrawAmount >
                                  this.state.LPStaked &&
                                !this.state.withdrawAmountMaxClicked
                                  ? styles.disabledText
                                  : styles.whiteText
                              } ${styles.inputButton}`}
                              onClick={() => this.withdraw()}
                            >
                              {this.state.withdrawAmount >
                                this.state.LPStaked &&
                              !this.state.withdrawAmountMaxClicked
                                ? "Insufficient balance"
                                : "Withdraw"}
                            </div>
                            {/* <div className={styles.orderDetailsSection}>
                              <div
                                className={`${styles.labelMBold} ${styles.primaryText}`}
                              >
                                Order details
                              </div>
                              <div className={styles.orderDetailsRow}>
                                <div
                                  className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                >
                                  Fees
                                </div>
                                <div className={styles.orderDetailsFeesRow}>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    ${this.state.fee}
                                  </div>
                                  {this.state.showFees === true ? (
                                    <div
                                      className={styles.orderDetailsFeesIcon}
                                      onClick={() =>
                                        this.setState({
                                          showFees: false,
                                        })
                                      }
                                    >
                                      <CaretUpIcon />
                                    </div>
                                  ) : (
                                    <div
                                      className={styles.orderDetailsFeesIcon}
                                      onClick={() =>
                                        this.setState({
                                          showFees: true,
                                        })
                                      }
                                    >
                                      <CaretDownIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {this.state.showFees === true && (
                                <>
                                  <div className={styles.orderDetailsSubRow}>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                    >
                                      Radar fee
                                    </div>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                    >
                                      ${this.state.radarFee}
                                    </div>
                                  </div>
                                  <div className={styles.orderDetailsSubRow}>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                    >
                                      Transaction fee
                                    </div>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                    >
                                      ${this.state.transactionFee}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div> */}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={styles.leftSection}
                    style={
                      this.state.displayInfoCards === false ? doNotDisplay : {}
                    }
                  >
                    <div className={styles.cardsRow}>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="farm-pool-card-header">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            LP staked
                          </div>
                          {this.state.LPStaked > 0 && (
                            <div className="farm-withdraw-label">
                              <div
                                className={`${styles.labelMBold} ${styles.gradientText}`}
                                onClick={() =>
                                  this.setState({
                                    withdrawPopup: true,
                                    amount: "",
                                    withdrawAmount: "",
                                  })
                                }
                              >
                                Withdraw
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.LPStakedLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.LPStaked.toFixed(2)}{" "}
                              {
                                config.farm.pools[this.getPool()].depositAsset
                                  .sym
                              }
                            </div>
                          )}
                        </div>
                        {((this.state.amount !== 0 &&
                          this.state.amount !== "") ||
                          (this.state.withdrawAmount !== 0 &&
                            this.state.withdrawAmount !== "")) && (
                          <div
                            class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                          >
                            {this.state.LPStaked.toFixed(2)}{" "}
                            <img
                              src={greenArrowIcon}
                              alt="arrow-icon"
                              className={styles.cardBulletArrowIcon}
                            />
                            {this.state.LPStakedATM.toFixed(2)}{" "}
                            {config.farm.pools[this.getPool()].depositAsset.sym}
                          </div>
                        )}
                      </div>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="farm-pool-card-header">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Reward
                          </div>
                          {this.state.reward > 0 && (
                            <div className="farm-claim-label">
                              <div
                                className={`${styles.labelMBold} ${styles.gradientText}`}
                                onClick={(e) => this.claimReward(e)}
                              >
                                Claim
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.rewardLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.reward.toFixed(2)} RADAR ($
                              {(
                                this.state.reward * this.state.radarPrice
                              ).toFixed(2)}
                              )
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer {...this.props} state={this.state} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FarmPoolPage);
