import { BigNumber, ethers } from "ethers";

import LendingOracleAggregatorABI from "./abis/LendingOracleAggregator.json";
import IERC20Abi from "./abis/IERC20.json";
import LendingPairABI from "./abis/LendingPair.json";
import LickHitterABI from "./abis/LickHitter.json";
import CurveDepositorABI from "./abis/CurveDepositor.json";
import BenqiDepositorABI from "./abis/BenqiDepositor.json";

import { signERC2612Permit } from 'eth-permit';

const getLendingPair = async (
    rpc,
    poolAddress
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(LendingPairABI.abi))
    );
    const lendingPair = new ethers.Contract(
        poolAddress,
        iff,
        provider
    );

    return lendingPair;
}

const getSignerLendingPair = async (signer, poolAddress) => {
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(LendingPairABI.abi))
    );
    const lendingPair = new ethers.Contract(
        poolAddress,
        iff,
        signer
    );

    return lendingPair;
}

export const getOraclePrice = async (
    rpc,
    oracleAddress,
    tokenAddress
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(LendingOracleAggregatorABI.abi))
    );
    const oracle = new ethers.Contract(
        oracleAddress,
        iff,
        provider
    );

    const unscaledPrice = await oracle.getUSDPrice(tokenAddress);

    return (unscaledPrice.div(10**12)) / 10**6;
}

export const getTokenAllowance = async (
    rpc,
    tokenAddress,
    userAddress,
    spender
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(IERC20Abi.abi))
    );
    const token = new ethers.Contract(
        tokenAddress,
        iff,
        provider
    );

    const allowance = await token.allowance(userAddress, spender);

    return allowance;
}

export const approveToken = async (
    tokenAddress,
    spender,
    signer,
    amount = 0
) => {
    if (amount == 0) {
        amount = ethers.constants.MaxUint256;
    }
    
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(IERC20Abi.abi))
    );
    const token = new ethers.Contract(
        tokenAddress,
        iff,
        signer
    );

    const tx = await token.approve(spender, amount);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const scaleDecimals = (x, decimals) => {
    if (decimals <= 6) {
        x *= 10**6;
        x = parseInt(x);
        var res = BigNumber.from(x).mul(10**(decimals-6));
        return res;
    } else if(decimals <= 8) {
        x *= 10**8;
        x = parseInt(x);
        var res = BigNumber.from(x).mul(10**(decimals-8));
        return res;
    } else if (decimals == 18) {
        return ethers.utils.parseEther(x.toString())
    } else {
        x *= 10**12;
        x = parseInt(x);
        var res = BigNumber.from(x).mul(10**(decimals-12));
        return res;
    }
}

export const getTokenBalance = async (
    rpc,
    tokenAddress,
    userAddress
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(IERC20Abi.abi))
    );
    const token = new ethers.Contract(
        tokenAddress,
        iff,
        provider
    );

    const balance = await token.balanceOf(userAddress);

    return balance;
}

export const erc2612Sign = async (
    signer,
    userAddress,
    tokenAddress,
    spender,
    amount
) => {
    const sig1 = await signERC2612Permit(
        signer,
        tokenAddress,
        userAddress,
        spender,
        amount.toString()
    );

    return sig1;
}

export const getLeftToBorrow = async (
    rpc,
    lendingPairAddress
) => {
    const lendingPair = await getLendingPair(rpc, lendingPairAddress)

    const atb = await lendingPair.availableToBorrow();
    const totalBorrowed = await lendingPair.getTotalBorrowed();

    return {
        available: parseInt(atb / 10**18),
        total: parseInt(atb.add(totalBorrowed) / 10**18)
    }
}

export const getMaxLTV = async (
    rpc,
    poolAddress
) => {
    const lendingPair = await getLendingPair(rpc, poolAddress);

    const max_ltv = await lendingPair.MAX_LTV();

    return max_ltv / 10000;
}

export const getLiquidationIncentive = async (
    rpc,
    poolAddress
) => {
    const lendingPair = await getLendingPair(rpc, poolAddress);

    const liq_incentive = await lendingPair.LIQUIDATION_INCENTIVE();

    return liq_incentive / 10000;
}

export const getEntryFee = async (
    rpc,
    poolAddress
) => {
    const lendingPair = await getLendingPair(rpc, poolAddress);

    const entry_fee = await lendingPair.ENTRY_FEE();

    return entry_fee / 10000;
}

export const getExitFee = async (
    rpc,
    poolAddress
) => {
    const lendingPair = await getLendingPair(rpc, poolAddress);

    const entry_fee = await lendingPair.EXIT_FEE();

    return entry_fee / 10000;
}

export const getUserCollateral = async (
    rpc,
    poolAddress,
    userAddress
) => {
    const lendingPair = await getLendingPair(rpc, poolAddress);

    const collateral = await lendingPair.getCollateralBalance(userAddress);

    return collateral;
}

export const getUserBorrow = async (
    rpc,
    poolAddress,
    userAddress
) => {
    const lendingPair = await getLendingPair(rpc, poolAddress);

    const borrow = await lendingPair.getUserBorrow(userAddress);

    return borrow;
}

export const lendingPairDeposit = async (
    signer,
    poolAddress,
    amount,
    receiver
) => {
    const lp = await getSignerLendingPair(signer, poolAddress);

    const tx = await lp.deposit(amount, receiver);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const lendingPairWithdraw = async (
    signer,
    poolAddress,
    amount,
    receiver
) => {
    const lp = await getSignerLendingPair(signer, poolAddress);

    const tx = await lp.withdraw(amount, receiver);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const lendingPairBorrow = async (
    signer,
    poolAddress,
    amount,
    receiver
) => {
    const lp = await getSignerLendingPair(signer, poolAddress);

    const tx = await lp.borrow(receiver, amount);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const lendingPairRepayWithPermit = async (
    signer,
    poolAddress,
    amount,
    receiver,
    permitData
) => {
    const lp = await getSignerLendingPair(signer, poolAddress);

    const tx = await lp.repay(receiver, amount, permitData);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const lendingPairDepositAndBorrow = async (
    signer,
    poolAddress,
    depositAmount,
    borrowAmount,
    receiver
) => {
    const lp = await getSignerLendingPair(signer, poolAddress);

    const tx = await lp.depositAndBorrow(depositAmount, borrowAmount, receiver);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const lendingPairRepayAndWithdrawWithPermit = async (
    signer,
    poolAddress,
    repayAmount,
    repaymentReceiver,
    withdrawAmount,
    withdrawReceiver,
    permitData
) => {
    const lp = await getSignerLendingPair(signer, poolAddress);

    const tx = await lp.repayAndWithdraw(repayAmount, repaymentReceiver, withdrawAmount, withdrawReceiver, permitData);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const lendingPairLeverage = async (
    signer,
    poolAddress,
    depositAmount,
    borrowAmount,
    swapData
) => {
    const lp = await getSignerLendingPair(signer, poolAddress);

    const tx = await lp.hookedDepositAndBorrow(depositAmount, borrowAmount, swapData);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const lendingPairDeLeverageWithPermit = async (
    signer,
    poolAddress,
    directRepayAmount,
    withdrawAmount,
    swapData,
    permitData
) => {
    const lp = await getSignerLendingPair(signer, poolAddress);

    const tx = await lp.hookedRepayAndWithdraw(directRepayAmount, withdrawAmount, swapData, permitData);
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const BenqiDepositorDeposit = async (
    signer,
    poolAddress,
    underlying,
    qiAsset,
    amount,
    userAddress,
    depositorAddress
) => {
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(BenqiDepositorABI.abi))
    );
    const depositor = new ethers.Contract(
        depositorAddress,
        iff,
        signer
    );

    var tx;
    if (underlying != "native") {
        tx = await depositor.deposit(
            underlying,
            qiAsset,
            poolAddress,
            userAddress,
            amount
        );
    } else {
        tx = await depositor.deposit(
            ethers.constants.AddressZero,
            qiAsset,
            poolAddress,
            userAddress,
            0,
            {
                value: amount
            }
        );
    }
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const CurveDepositorLPDeposit = async (
    signer,
    poolAddress,
    underlying,
    amount,
    userAddress,
    curvePool,
    curveLpAsset,
    curveAddLiquidityTX,
    depositorAddress
) => {
    const iff = new ethers.utils.Interface(
        JSON.parse(JSON.stringify(CurveDepositorABI.abi))
    );
    const depositor = new ethers.Contract(
        depositorAddress,
        iff,
        signer
    );

    const tx = await depositor.depositCurveAddLiquidity(
        userAddress,
        curveLpAsset,
        curvePool,
        curveAddLiquidityTX,
        underlying,
        poolAddress,
        amount,
        false
    );
    await tx.wait();

    await new Promise(r => setTimeout(r, 2000));
}

export const getNativeTokenBalance = async (
    rpc,
    userAddress
) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    const balance = await provider.getBalance(userAddress);

    return balance;
}

export const getAvailableToBorrow = (collateralValue, currentBorrow, maxLTV, leftToBorrow) => {
    // TODO: Maybe remvoe 1% not to reach MAX LTV and liquidate
    return Math.min(
        (collateralValue * maxLTV - currentBorrow) / 1.01,
        leftToBorrow
    );
}

export const getCurrentLTV = (collateralValue, currentBorrow) => {
    if (collateralValue == 0 || collateralValue == "") {
        return 0;
    }

    return currentBorrow / collateralValue;
}

export const getLiquidationPrice = (collateral, borrow, max_ltv) => {
    if (collateral == 0 || collateral == "") {
        return 0;
    }

    return (borrow / collateral) * (2 - max_ltv);
}

export const getPositionHealth = (current_ltv, max_ltv) => {
    if (max_ltv == 0 || max_ltv == "") {
        return 1;
    }

    return 1 - current_ltv / max_ltv;
}