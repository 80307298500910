import React, { Component } from "react";
import { connect } from "react-redux";
import { checkWallet } from "../../redux/actions/OnboardActions";
import "../../utils/ethereum/onboard";
import {
  getUserRadarBalance,
  getRadarPrice,
  getUserStakedBalance,
  getStakingAPY,
} from "../../web3/bonds/lib/dao";
import {
  getAvailableToBorrow,
  getCurrentLTV,
  getLeftToBorrow,
  getMaxLTV,
  getOraclePrice,
  getPositionHealth,
  getTokenBalance,
  getUserBorrow,
  getUserCollateral,
} from "../../web3/usdr/api";
import config from "../../config/config.json";

import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import Sidebar from "../../layouts/sidebar/Sidebar";
import PortfolioNews from "./sub-components/PortfolioNews";
import Loader from "../../components/loader/Loader";

import BuyRadarOverlay from "../../components/overlays/buy-radar/BuyRadarOverlay";

import styles from "../../styles/index.module.css";
import "./portfolioPage.css";
import { getFarmPoolAPR, getUserStaked } from "../../web3/farms/api";

const DUST = 0.000001;

class PortfolioPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",

      yourInvestment: null, // ASK TUDOR
      investmentProcent: 0, // ASK TUDOR

      radarBalanceInUSD: 0,
      radarBalanceInUSDLoading: true,
      radarBalance: 0,
      radarBalanceLoading: true,

      radarPrice: 0,

      USDRBalance: 0,
      USDRBalanceLoading: true,

      activePools: [],
      otherPositions: [],

      positionHealth: 0,
      positionHealthLoading: false,

      defaultPools: {
        pool1: {
          label: "Highest LTV",
          network: "AVAXC",
          ltb: null,
        },
        pool2: {
          label: "Max AVAX Exposure",
          network: "AVAXC",
          ltb: null,
        },
        pool5: {
          label: "Best stablecoin APY",
          network: "AVAXC",
          ltb: null,
        },
      },

      buyRadarOverlay: false,
    };

    this.toPage = this.toPage.bind(this);
  }

  renderLoading() {
    return <Loader {...this.props} />;
  }

  toPage = (path) => {
    this.props.history.push(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  toSubPage(route, subroute, params) {
    this.props.history.push(route + "/" + subroute, params);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  getAssetIcon = (type) => {
    const icon = require(`../../assets/SVGs/assets/${type}`).default;
    return icon;
  };

  getRadarBalance = async () => {
    try {
      await this.setState({
        radarBalanceLoading: true,
        radarBalanceInUSDLoading: true,
      });
      let radarBalance = await getUserRadarBalance(
        this.props.onboard.address,
        this.getBlockchain(this.props.onboard.networkId)
      );
      await this.setState({
        radarBalance: parseFloat(radarBalance).toFixed(2),
        radarBalanceLoading: false,
      });

      var radarPrice = await getRadarPrice();
      var radarBalanceInUSD = radarBalance * radarPrice;

      await this.setState({
        radarBalanceInUSD: radarBalanceInUSD.toFixed(2),
        radarBalanceInUSDLoading: false,
        radarPrice,
      });
    } catch (e) {
      await this.setState({
        radarBalance: 0,
        radarBalanceInUSD: 0,
        radarBalanceLoading: false,
        radarBalanceInUSDLoading: false,
      });
    }
  };

  getUSDRBalance = async () => {
    const bl = this.getBlockchain(this.props.onboard.networkId);
    if (Object.keys(config.USDR).includes(bl)) {
      await this.setState({
        USDRBalanceLoading: true,
      });
      const rpc =
        config.networks[this.getBlockchain(this.props.onboard.networkId)]
          .endpoint;
      const userAddress = this.props.onboard.address;

      var userUsdrBalance = await getTokenBalance(
        rpc,
        config.USDR[this.getBlockchain(this.props.onboard.networkId)],
        userAddress
      );
      userUsdrBalance = userUsdrBalance / 10 ** 18;
      await this.setState({
        USDRBalance: parseFloat(userUsdrBalance).toFixed(2),
        USDRBalanceLoading: false,
      });
    } else {
      await this.setState({ USDRBalance: 0, USDRBalanceLoading: false });
    }
  };

  getDefaultPoolsLTB = async () => {
    const poolKeys = Object.keys(this.state.defaultPools);
    var dp = this.state.defaultPools;
    for (var i = 0; i < poolKeys.length; i++) {
      const p = this.state.defaultPools[poolKeys[i]];
      const rpc = config.networks[p.network].endpoint;
      const poolAddress = config.borrowMultiply[poolKeys[i]].address;

      const atb = await getLeftToBorrow(rpc, poolAddress);
      dp[poolKeys[i]].ltb = atb.available;
      await this.setState({ defaultPools: dp });
    }
  };

  async checkIfPoolActive(poolId, network) {
    try {
      const poolAddress = config.borrowMultiply[poolId].address;
      const rpc = config.networks[network].endpoint; // TODO: CHANGE ME
      const userAddress = this.props.onboard.address;

      var coll = await getUserCollateral(rpc, poolAddress, userAddress);
      coll = coll / 10 ** config.borrowMultiply[poolId].collateral.decimals;

      if (coll > DUST) {
        return coll;
      } else {
        return -1;
      }
    } catch (e) {
      console.log(e);
      return -1;
    }
  }

  async getTotalInvested(activePoolsInvested) {
    var invested = activePoolsInvested;

    await this.setState({
      yourInvestment: "$" + invested.toFixed(2).toString(),
    });
  }

  async getActivePoolsData(i) {
    var ap = this.state.activePools;
    var otherPositions = this.state.otherPositions;
    var invested = i;
    const userAddress = this.props.onboard.address;
    for (var i = 0; i < ap.length; i++) {
      const poolId = ap[i].id;
      const rpc = config.networks[ap[i].network].endpoint;
      const oracleAddress = config.LendingOracleAggregator[ap[i].network];
      const tokenAddress = config.borrowMultiply[ap[i].id].collateral.address;
      const poolAddress = config.borrowMultiply[ap[i].id].address;
      const collPrice = await getOraclePrice(rpc, oracleAddress, tokenAddress);

      const usdValue = collPrice * ap[i].collateral;
      invested += usdValue;
      ap[i].collateralInUSD = "$" + usdValue.toFixed(2).toString();
      await this.setState({ activePools: ap });

      const debt = await getUserBorrow(rpc, poolAddress, userAddress);
      ap[i].debt = "$" + (debt / 10 ** 18).toFixed(2).toString();
      await this.setState({ activePools: ap });

      const poolMaxLTV = await getMaxLTV(rpc, poolAddress);
      const { available } = await getLeftToBorrow(rpc, poolAddress);

      const atb = getAvailableToBorrow(
        usdValue,
        debt / 10 ** 18,
        poolMaxLTV,
        available
      );
      ap[i].atb = "$" + atb.toFixed(2).toString();
      await this.setState({ activePools: ap });

      const currentLTV = getCurrentLTV(usdValue, debt / 10 ** 18);
      const ph = getPositionHealth(currentLTV, poolMaxLTV);
      ap[i].health = (ph * 100).toFixed(2).toString() + "%";
      await this.setState({ activePools: ap });
    }

    // Other positions
    const rpc =
      config.networks[this.getBlockchain(this.props.onboard.networkId)]
        .endpoint;
    for (var i = 0; i < otherPositions.length; i++) {
      var p = otherPositions[i];
      if (p.type == "staking") {
        // Get APY
        const apy = await getStakingAPY(
          this.getBlockchain(this.props.onboard.networkId)
        );
        p.apy =
          parseFloat(apy * 100)
            .toFixed(2)
            .toString() + "%";
        otherPositions[i] = p;
      } else if (p.type == "farm") {
        var id = p.farmId;
        var apy = await getFarmPoolAPR(
          rpc,
          1, // TODO: CHANGE ME
          config.farm.pools[id].depositAsset.decimals,
          config.farm.pools[id].contractType,
          config.farm.pools[id].address,
          this.state.radarPrice
        );
        p.apy =
          parseFloat(apy * 100)
            .toFixed(2)
            .toString() + "%";
        otherPositions[i] = p;
      }
      await this.setState({ otherPositions });
    }

    console.log(this.state.activePools);
    this.getTotalInvested(invested);
  }

  async checkActivePools() {
    await this.setState({
      activePools: [],
      otherPositions: [],
    });
    let activePools = [];
    var pools = Object.keys(config.borrowMultiply);
    const bl = this.getBlockchain(this.props.onboard.networkId);
    const rpc = config.networks[bl].endpoint;
    for (var i = 0; i < pools.length; i++) {
      const n = config.borrowMultiply[pools[i]].network;
      if (config.borrowMultiply[pools[i]].network != bl) {
        continue;
      }
      let checkIfPoolActive = await this.checkIfPoolActive(pools[i], n);
      if (checkIfPoolActive > 0) {
        activePools.push({
          id: pools[i],
          network: n,
          collateral: checkIfPoolActive,
          collateralInUSD: null,
          atb: null,
          debt: null,
          health: null,
        });
        await this.setState({ activePools });
      }

      for (var j = 0; j < activePools.length; j++) {
        console.log("active pool now: " + activePools[j].id);
      }
    }

    // Other positions
    const userAddress = this.props.onboard.address;
    var otherPositions = [];
    var invested = 0;
    try {
      const stakedRadarBalance = await getUserStakedBalance(bl, userAddress);
      if (stakedRadarBalance > 0) {
        const uV = stakedRadarBalance * this.state.radarPrice;
        invested += uV;
        otherPositions.push({
          name: "Radar Staking",
          icon: "radar.png",
          apy: null,
          deposited: stakedRadarBalance.toFixed(2).toString() + " RADAR",
          value: "$" + uV.toFixed(2).toString(),
          page: "/stake",
          type: "staking",
        });
        await this.setState({ otherPositions });
      }
    } catch (e) {
      console.log(e);
    }

    // Farm
    const farms = Object.keys(config.farm.pools);
    for (var i = 0; i < farms.length; i++) {
      const farmKey = farms[i];
      const farm = config.farm.pools[farmKey];
      try {
        if (farm.network != bl) {
          continue;
        }

        const farmLPPrice = 1; // TODO: CHANGE ME
        const userBal = await getUserStaked(
          rpc,
          farm.depositAsset.decimals,
          farm.contractType,
          farm.address,
          userAddress
        );

        if (userBal > DUST) {
          const uVF = farmLPPrice * userBal;
          invested += uVF;
          otherPositions.push({
            name: farm.name,
            icon: [farm.assets[1].icon, farm.assets[2].icon],
            apy: null,
            deposited:
              userBal.toFixed(2).toString() + " " + farm.depositAsset.sym,
            value: "$" + uVF.toFixed(2).toString(),
            page: "/farm/" + farm.address,
            type: "farm",
            farmId: farmKey,
          });
          await this.setState({ otherPositions });
        }
      } catch (e) {
        console.log(e);
      }
    }

    await this.getActivePoolsData(invested);
  }

  async componentDidMount() {
    this.getDefaultPoolsLTB();
    if (this.props.onboard.walletConnected) {
      await this.getRadarBalance();
      this.getUSDRBalance();
      this.checkActivePools();
    } else {
      await this.setState({
        activePools: [],
        otherPositions: [],
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
      if (this.props.onboard.walletConnected) {
        await this.setState({
          yourInvestment: null,
          USDRBalanceLoading: true,
        });
        await this.getRadarBalance();
        this.getUSDRBalance();
        this.checkActivePools();
      } else {
        await this.setState({
          activePools: [],
          otherPositions: [],
        });
      }
    }
  }

  closeBuyRadarOverlay = () => {
    this.setState({
      buyRadarOverlay: false,
    });
  };

  nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  render() {
    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };
    return (
      <>
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Sidebar {...this.props} state={this.state} />
          {this.state.buyRadarOverlay === true && (
            <BuyRadarOverlay
              {...this.props}
              closeBuyRadarOverlayEvent={this.closeBuyRadarOverlay}
            />
          )}
          <div
            className={styles.wrapper}
            style={this.state.buyRadarOverlay === true ? displayBlur : {}}
          >
            <div className={styles.content}>
              <div className={styles.headerSection}>
                <div className={`${styles.headingM} ${styles.primaryText}`}>
                  Portfolio
                </div>
              </div>
              <PortfolioNews {...this.props} />
              {this.props.onboard.walletConnected === true && (
                <div className="portfolio-gen-info-cards-section">
                  <div
                    className={`${"portfolio-gen-info-card"} ${styles.whiteBg}`}
                  >
                    <div
                      className={`${styles.labelSRegular} ${styles.secondaryText}`}
                    >
                      Your investments
                    </div>
                    <div className="portfolio-gen-info-card-value-section">
                      <div
                        className={`${"portfolio-gen-info-card-primary-value"} ${
                          styles.headingM
                        } ${styles.primaryText}`}
                      >
                        {this.state.yourInvestment ?? this.renderLoading()}
                      </div>
                      {/* {condition ? ( */}
                      <div
                        className={`${styles.labelMRegular} ${styles.succesText}`}
                      >
                        {/* {this.state.investmentProcent}% */}
                      </div>
                      {/* ) : (
                      <div
                        className={`${styles.labelMRegular} ${styles.dangerText}`}
                      ></div>
                    )} */}
                    </div>
                  </div>
                  <div
                    className={`${"portfolio-gen-info-card"} ${styles.whiteBg}`}
                  >
                    <div
                      className={`${styles.labelSRegular} ${styles.secondaryText}`}
                    >
                      RADAR balance
                    </div>
                    <div className="portfolio-gen-info-card-value-section">
                      <div
                        className={`${"portfolio-gen-info-card-primary-value"} ${
                          styles.headingM
                        } ${styles.primaryText}`}
                      >
                        {this.state.radarBalanceInUSDLoading === true ? (
                          this.renderLoading()
                        ) : (
                          <>${this.state.radarBalanceInUSD}</>
                        )}
                      </div>
                      <div
                        className={`${styles.labelMRegular} ${styles.secondaryText}`}
                      >
                        {this.state.radarBalanceLoading === true ? (
                          this.renderLoading()
                        ) : (
                          <>{this.state.radarBalance} RADAR</>
                        )}
                      </div>
                    </div>
                    <div className="portfolio-gen-info-card-links-section">
                      <div
                        className={`${"portfolio-gen-info-card-link"} ${
                          styles.labelSBold
                        } ${styles.gradientText}`}
                        onClick={() =>
                          this.setState({
                            buyRadarOverlay: true,
                          })
                        }
                      >
                        Buy
                      </div>
                      <div
                        className={`${"portfolio-gen-info-card-link"} ${
                          styles.labelSBold
                        } ${styles.gradientText}`}
                        onClick={() => this.toPage("/stake")}
                      >
                        Stake
                      </div>
                      <div
                        className={`${"portfolio-gen-info-card-link"} ${
                          styles.labelSBold
                        } ${styles.gradientText}`}
                        onClick={() => this.toPage("/bridge")}
                      >
                        Bridge
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${"portfolio-gen-info-card"} ${styles.whiteBg}`}
                  >
                    <div
                      className={`${styles.labelSRegular} ${styles.secondaryText}`}
                    >
                      USDR balance
                    </div>
                    <div className="portfolio-gen-info-card-value-section">
                      <div
                        className={`${"portfolio-gen-info-card-primary-value"} ${
                          styles.headingM
                        } ${styles.primaryText}`}
                      >
                        {this.state.USDRBalanceLoading === true ? (
                          this.renderLoading()
                        ) : (
                          <>${parseFloat(this.state.USDRBalance).toFixed(2)}</>
                        )}
                      </div>
                      <div
                        className={`${styles.labelMRegular} ${styles.secondaryText}`}
                      >
                        {this.state.USDRBalanceLoading === true ? (
                          this.renderLoading()
                        ) : (
                          <>
                            {parseFloat(this.state.USDRBalance).toFixed(2)} USDR
                          </>
                        )}
                      </div>
                    </div>
                    <div className="portfolio-gen-info-card-links-section">
                      <div
                        className={`${"portfolio-gen-info-card-link"} ${
                          styles.labelSBold
                        } ${styles.gradientText}`}
                        onClick={() => this.toPage("/borrow")}
                      >
                        Borrow
                      </div>
                      <div
                        className={`${"portfolio-gen-info-card-link"} ${
                          styles.labelSBold
                        } ${styles.gradientText}`}
                        onClick={() => this.toPage("/multiply")}
                      >
                        Multiply
                      </div>
                      <div
                        className={`${"portfolio-gen-info-card-link"} ${
                          styles.labelSBold
                        } ${styles.gradientText}`}
                        onClick={() => this.toPage("/stabilizer")}
                      >
                        Stabilizer
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.activePools.length > 0 && (
                <>
                  <div
                    className={`${"portfolio-position-cards-section-header"} ${
                      styles.primaryText
                    } ${styles.headingS}`}
                  >
                    Open Positions
                  </div>
                  <div className="portfolio-position-cards-section">
                    {Object.keys(this.state.activePools).map((i) => (
                      <>
                        {i < 3 && (
                          <div className="portfolio-position-new-line">
                            <div
                              className={`${"portfolio-position-card"} ${
                                styles.whiteBg
                              }`}
                            >
                              <div className="portfolio-position-card-header">
                                <div className="portfolio-position-card-header-left-section">
                                  <img
                                    src={this.getAssetIcon(
                                      config.borrowMultiply[
                                        this.state.activePools[i].id
                                      ].collateral.icon
                                    )}
                                    alt="collateral-icon"
                                    className="portfolio-position-card-header-coll-icon"
                                  />
                                  <div
                                    className={`${styles.headingS} ${styles.primaryText}`}
                                  >
                                    {
                                      config.borrowMultiply[
                                        this.state.activePools[i].id
                                      ].name
                                    }
                                  </div>
                                </div>
                                <div className="portfolio-position-card-header-right-section">
                                  {Object.keys(
                                    config.borrowMultiply[
                                      this.state.activePools[i].id
                                    ].assets
                                  ).map((j) => (
                                    <img
                                      src={this.getAssetIcon(
                                        config.borrowMultiply[
                                          this.state.activePools[i].id
                                        ].assets[j].icon
                                      )}
                                      alt="collateral-icon"
                                      className="portfolio-position-card-header-asset-icon"
                                    />
                                  ))}
                                </div>
                              </div>
                              {this.state.activePools[i].health === null ? (
                                <>{this.renderLoading()}</>
                              ) : (
                                <>
                                  <div className={`${styles.cardProgressBar}`}>
                                    <div class="progress">
                                      <div
                                        class="progress-bar"
                                        style={{
                                          width:
                                            this.state.activePools[i].health,
                                        }}
                                      >
                                        <span class="sr-only">
                                          {this.state.activePools[i].health}{" "}
                                          Complete
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`${styles.cardFullDimRow}`}>
                                    <div
                                      className={`${styles.labelSBold} ${styles.secondaryText}`}
                                    >
                                      Position health
                                    </div>
                                    <div
                                      className={`${styles.labelSBold} ${styles.secondaryText}`}
                                    >
                                      {this.state.activePools[i].health}
                                    </div>
                                  </div>
                                </>
                              )}
                              <div
                                className={styles.mobTableCardRow}
                                style={{ marginTop: "32px" }}
                              >
                                <div
                                  className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                >
                                  Available to borrow
                                </div>
                                <div
                                  className={`${styles.labelMBold} ${styles.primaryText}`}
                                >
                                  {this.state.activePools[i].atb ??
                                    this.renderLoading()}
                                </div>
                              </div>
                              <div className={styles.mobTableCardRow}>
                                <div
                                  className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                >
                                  USDR debt
                                </div>
                                <div
                                  className={`${styles.labelMBold} ${styles.primaryText}`}
                                >
                                  {this.state.activePools[i].debt ??
                                    this.renderLoading()}
                                </div>
                              </div>
                              <div className={styles.mobTableCardRow}>
                                <div
                                  className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                >
                                  Collateral
                                </div>
                                <div
                                  className={`${styles.labelMBold} ${styles.primaryText}`}
                                >
                                  {this.state.activePools[i].collateralInUSD ??
                                    this.renderLoading()}
                                </div>
                              </div>
                              <div
                                className={styles.mobTableCardRow}
                                style={{ marginBottom: "28px" }}
                              >
                                <div
                                  className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                >
                                  Your APY
                                </div>
                                <div
                                  className={`${styles.labelMBold} ${styles.primaryText}`}
                                >
                                  {(
                                    config.borrowMultiply[
                                      this.state.activePools[i].id
                                    ].apy * 100
                                  ).toFixed(2)}
                                  %
                                </div>
                              </div>
                              <div className="portfolio-open-pos-card-buttons-section">
                                <div
                                  className={`${styles.labelMBold} ${styles.gradientText}`}
                                  style={{
                                    marginRight: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.toPage(
                                      "/borrow/" +
                                        config.borrowMultiply[
                                          this.state.activePools[i].id
                                        ].address
                                    )
                                  }
                                >
                                  Borrow
                                </div>
                                <div
                                  className={`${styles.labelMBold} ${styles.gradientText}`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.toPage(
                                      "/multiply/" +
                                        config.borrowMultiply[
                                          this.state.activePools[i].id
                                        ].address
                                    )
                                  }
                                >
                                  Multiply
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </>
              )}
              {this.state.otherPositions.length > 0 && (
                <div className={styles.table} style={{ marginBottom: "32px" }}>
                  <div
                    className={styles.tableHeader}
                    style={{ marginTop: "32px" }}
                  >
                    <div
                      className={`${"borrow-table-b-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      Other Positions
                    </div>
                    <div
                      className={`${"borrow-table-b-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      APY
                    </div>
                    {this.state.min}
                    <div
                      className={`${"borrow-table-b-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      Deposited
                    </div>
                    <div
                      className={`${"borrow-table-s-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                      style={{ textAlign: "right" }}
                    >
                      Value
                    </div>
                  </div>
                  {Object.keys(this.state.otherPositions).map((i) => (
                    <div
                      className={styles.tableRow}
                      style={{ marginBottom: "8px" }}
                      onClick={() =>
                        this.toPage(this.state.otherPositions[i].page)
                      }
                    >
                      <div
                        className={`${"borrow-table-b-cell"} ${
                          styles.labelMRegular
                        } ${styles.primaryText}`}
                      >
                        <div className="borrow-table-pool-name">
                          {this.state.otherPositions[i].type != "farm" ? (
                            <img
                              src={this.getAssetIcon(
                                this.state.otherPositions[i].icon
                              )}
                              alt="collaretal-icon"
                              className="borrow-table-b-icon"
                            />
                          ) : (
                            <>
                              <img
                                src={this.getAssetIcon(
                                  this.state.otherPositions[i].icon[0]
                                )}
                                alt="collaretal-icon"
                                className="farm-table-b-icon1"
                              />
                              <img
                                src={this.getAssetIcon(
                                  this.state.otherPositions[i].icon[1]
                                )}
                                alt="collaretal-icon"
                                className="farm-table-b-icon2"
                              />
                            </>
                          )}
                          <div
                            className={`${styles.labelMRegular} ${styles.primaryText}`}
                            style={{ marginLeft: "2%" }}
                          >
                            {this.state.otherPositions[i].name}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${"borrow-table-b-cell"} ${
                          styles.labelMRegular
                        } ${styles.primaryText}`}
                      >
                        <div
                          className={`${styles.labelMRegular} ${styles.primaryText}`}
                        >
                          {this.state.otherPositions[i].apy ?? (
                            <>
                              <div style={{ width: "10%" }}>
                                {this.renderLoading()}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className={`${"borrow-table-b-cell"} ${
                          styles.labelMRegular
                        } ${styles.primaryText}`}
                      >
                        <div
                          className={`${styles.labelMRegular} ${styles.primaryText}`}
                        >
                          {this.state.otherPositions[i].deposited ?? (
                            <>
                              <div style={{ width: "10%" }}>
                                {this.renderLoading()}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className={`${"borrow-table-s-cell"} ${
                          styles.labelMRegular
                        } ${styles.primaryText}`}
                      >
                        <div
                          className={`${styles.labelMRegular} ${styles.primaryText}`}
                        >
                          {this.state.otherPositions[i].value ?? (
                            <>
                              <div style={{ width: "10%" }}>
                                {this.renderLoading()}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <>
                <div
                  className={`${"portfolio-position-cards-section-header"} ${
                    styles.primaryText
                  } ${styles.headingS}`}
                >
                  Borrow
                </div>
                <div className="portfolio-position-cards-section">
                  {Object.keys(this.state.defaultPools).map((i) => (
                    <>
                      <div className="portfolio-position-new-line">
                        <div
                          className={`${"portfolio-position-card"} ${
                            styles.whiteBg
                          }`}
                        >
                          <div className="portfolio-position-card-header">
                            <div className="portfolio-position-card-header-left-section">
                              <img
                                src={this.getAssetIcon(
                                  config.borrowMultiply[i].collateral.icon
                                )}
                                alt="collateral-icon"
                                className="portfolio-position-card-header-coll-icon"
                              />
                              <div>
                                <div
                                  className={`${styles.headingS} ${styles.primaryText}`}
                                >
                                  {config.borrowMultiply[i].name}
                                </div>
                                <div className="portfolio-card-pool-asset-icons-section">
                                  {Object.keys(
                                    config.borrowMultiply[i].assets
                                  ).map((j) => (
                                    <>
                                      <img
                                        src={this.getAssetIcon(
                                          config.borrowMultiply[i].assets[j]
                                            .icon
                                        )}
                                        alt="asset-icon"
                                        className="portfolio-card-pool-asset-icon"
                                      />
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="portfolio-position-card-header-right-section">
                              <div className="portfolio-bullet-tag">
                                <div
                                  className={`${styles.labelSBold} ${styles.gradientText}`}
                                >
                                  {this.state.defaultPools[i].label}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.mobTableCardRow}>
                            <div
                              className={`${styles.labelMRegular} ${styles.secondaryText}`}
                            >
                              USDR left to borrow
                            </div>
                            <div
                              className={`${styles.labelMBold} ${styles.primaryText}`}
                            >
                              {this.nFormatter(
                                this.state.defaultPools[i].ltb,
                                2
                              )}
                            </div>
                          </div>
                          <div className={styles.mobTableCardRow}>
                            <div
                              className={`${styles.labelMRegular} ${styles.secondaryText}`}
                            >
                              Interest
                            </div>
                            <div
                              className={`${styles.labelMBold} ${styles.primaryText}`}
                            >
                              0.00%
                            </div>
                          </div>
                          <div className={styles.mobTableCardRow}>
                            <div
                              className={`${styles.labelMRegular} ${styles.secondaryText}`}
                            >
                              APY
                            </div>
                            <div
                              className={`${styles.labelMBold} ${styles.primaryText}`}
                            >
                              {(config.borrowMultiply[i].apy * 100).toFixed(2)}%
                            </div>
                          </div>
                          <div
                            className={`${"portfolio-link-button"} ${
                              styles.button
                            } ${styles.gradientBg}`}
                            onClick={() =>
                              this.toSubPage(
                                "/borrow",
                                config.borrowMultiply[i].address,
                                {
                                  i,
                                }
                              )
                            }
                          >
                            <div
                              className={`${styles.labelMBold} ${styles.whiteText}`}
                            >
                              Borrow
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                <div
                  className={`${"portfolio-position-cards-section-header"} ${
                    styles.primaryText
                  } ${styles.headingS}`}
                >
                  Multiply
                </div>
                <div className="portfolio-position-cards-section">
                  {Object.keys(this.state.defaultPools).map((i) => (
                    <>
                      <div className="portfolio-position-new-line">
                        <div
                          className={`${"portfolio-position-card"} ${
                            styles.whiteBg
                          }`}
                        >
                          <div className="portfolio-position-card-header">
                            <div className="portfolio-position-card-header-left-section">
                              <img
                                src={this.getAssetIcon(
                                  config.borrowMultiply[i].collateral.icon
                                )}
                                alt="collateral-icon"
                                className="portfolio-position-card-header-coll-icon"
                              />
                              <div>
                                <div
                                  className={`${styles.headingS} ${styles.primaryText}`}
                                >
                                  {config.borrowMultiply[i].name}
                                </div>
                                <div className="portfolio-card-pool-asset-icons-section">
                                  {Object.keys(
                                    config.borrowMultiply[i].assets
                                  ).map((j) => (
                                    <>
                                      <img
                                        src={this.getAssetIcon(
                                          config.borrowMultiply[i].assets[j]
                                            .icon
                                        )}
                                        alt="asset-icon"
                                        className="portfolio-card-pool-asset-icon"
                                      />
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="portfolio-position-card-header-right-section">
                              <div className="portfolio-bullet-tag">
                                <div
                                  className={`${styles.labelSBold} ${styles.gradientText}`}
                                >
                                  {this.state.defaultPools[i].label}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.mobTableCardRow}>
                            <div
                              className={`${styles.labelMRegular} ${styles.secondaryText}`}
                            >
                              USDR left to borrow
                            </div>
                            <div
                              className={`${styles.labelMBold} ${styles.primaryText}`}
                            >
                              {this.nFormatter(
                                this.state.defaultPools[i].ltb,
                                2
                              )}
                            </div>
                          </div>
                          <div className={styles.mobTableCardRow}>
                            <div
                              className={`${styles.labelMRegular} ${styles.secondaryText}`}
                            >
                              Interest
                            </div>
                            <div
                              className={`${styles.labelMBold} ${styles.primaryText}`}
                            >
                              0.00%
                            </div>
                          </div>
                          <div className={styles.mobTableCardRow}>
                            <div
                              className={`${styles.labelMRegular} ${styles.secondaryText}`}
                            >
                              APY
                            </div>
                            <div
                              className={`${styles.labelMBold} ${styles.primaryText}`}
                            >
                              {(config.borrowMultiply[i].apy * 100).toFixed(2)}%
                            </div>
                          </div>
                          <div
                            className={`${"portfolio-link-button"} ${
                              styles.button
                            } ${styles.gradientBg}`}
                            onClick={() =>
                              this.toSubPage(
                                "/multiply",
                                config.borrowMultiply[i].address,
                                {
                                  i,
                                }
                              )
                            }
                          >
                            <div
                              className={`${styles.labelMBold} ${styles.whiteText}`}
                            >
                              Multiply
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            </div>
          </div>
          <Footer {...this.props} state={this.state} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  checkWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioPage);
