import React, { Component } from "react";
import { connect } from "react-redux";
import { checkWallet } from "../../../redux/actions/OnboardActions";
import "../../../utils/ethereum/onboard";

import borrowBg from "../../../assets/PNGs/portfolio/1.png";
import multiplyBg from "../../../assets/PNGs/portfolio/2.png";
import stabilizerBg from "../../../assets/PNGs/portfolio/3.png";
import borrowUpdateBg from "../../../assets/PNGs/portfolio/4.png";
import apyUpdateBg from "../../../assets/PNGs/portfolio/5.png";
import certikBg from "../../../assets/PNGs/portfolio/6.png";
import avalancheUpdateBg from "../../../assets/PNGs/portfolio/7.png";
import crvUpdateBg from "../../../assets/PNGs/portfolio/8.png";
import avalancheIcon from "../../../assets/SVGs/avalanche.svg";
import radarIcon from "../../../assets/SVGs/radar.svg";
import crvIcon from "../../../assets/SVGs/assets/curve_dai_usdc_usdt.svg";
import usdrIcon from "../../../assets/SVGs/assets/usdr.svg";

import styles from "../../../styles/index.module.css";
import "../portfolioPage.css";

class PortfolioNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",

      currentCardsLine: 1,
      currentCard: 1,
    };
    this.toPage = this.toPage.bind(this);
  }

  toPage(route, subroute, params) {
    this.props.history.push(route + "/" + subroute, params);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <>
        {window.innerWidth > 1299 ? (
          <div className="portfolio-news-layout">
            {this.state.currentCardsLine === 1 ? (
              <>
                <div className="portfolio-news-cards-section">
                  <a
                    href="https://radar.blog/radar-borrow-tutorial-how-to-borrow-usdr-at-0-interest-e1e51c90f2d2"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    {/* <div className="portfolio-card"> */}
                      <img
                        src={borrowBg}
                        alt="borrow-bg"
                        className="portfolio-card-bg-img"
                      />
                    {/* </div> */}
                  </a>
                  <a
                    href="https://radar.blog/radar-multiply-tutorial-how-to-multiply-your-crypto-3b48990f0d0a"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    {/* <div className="portfolio-card"> */}
                      <img
                        src={multiplyBg}
                        alt="multiply-bg"
                        className="portfolio-card-bg-img"
                      />
                    {/* </div> */}
                  </a>
                  <a
                    href="https://radar.blog/radar-stabilizer-tutorial-how-to-mint-usdr-8c22f893fa48"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    {/* <div className="portfolio-card"> */}
                      <img
                        src={stabilizerBg}
                        alt="stabilizer-bg"
                        className="portfolio-card-bg-img"
                      />
                    {/* </div> */}
                  </a>
                  {/* <div
                    className="portfolio-card"
                    
                    style={{ cursor: "pointer" }}
                  > */}
                    <img
                      src={borrowUpdateBg}
                      alt="borrow-update-bg"
                      className="portfolio-card-bg-img"
                      onClick={() =>
                        this.toPage(
                          "borrow",
                          "0x38c19e770a4d55C27DaEF887360aa5F955F4cdee"
                        )
                      }
                    />
                  {/* </div> */}
                </div>
                <div className="portfolio-elipses-w-section">
                  <div className="portfolio-selected-elipse" />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCardsLine: 2,
                      })
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div className="portfolio-news-cards-section">
                  {/* <div
                    className="portfolio-card"
                    
                    style={{ cursor: "pointer" }}
                  > */}
                    <img
                      src={apyUpdateBg}
                      alt="apy-update-bg"
                      className="portfolio-card-bg-img"
                      onClick={() =>
                        this.toPage(
                          "farm",
                          "0x1b842E625143Ee99743755c9801fB9AE9C7C53eC"
                        )
                      }
                    />
                  {/* </div> */}
                  <a
                    href="https://www.certik.com/projects/radar"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    {/* <div className="portfolio-card certik-card"> */}
                      <img
                        src={certikBg}
                        alt="multiply-bg"
                        className="portfolio-card-bg-img certik-card"
                      />
                    {/* </div> */}
                  </a>
                  <a href="" target="_blank" style={{ textDecoration: "none" }}>
                    {/* <div className="portfolio-card"> */}
                      <img
                        src={avalancheUpdateBg}
                        alt="avalanche-update-bg"
                        className="portfolio-card-bg-img"
                      />
                    {/* </div> */}
                  </a>
                  <a
                    href="https://avax.curve.fi/factory/77"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    {/* <div className="portfolio-card"> */}
                      <img
                        src={crvUpdateBg}
                        alt="crv-update-bg"
                        className="portfolio-card-bg-img"
                      />
                    {/* </div> */}
                  </a>
                </div>
                <div className="portfolio-elipses-w-section">
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCardsLine: 1,
                      })
                    }
                  />
                  <div className="portfolio-selected-elipse" />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="portfolio-news-layout">
            {this.state.currentCard === 1 && (
              <>
                <a
                  href="https://radar.blog/radar-borrow-tutorial-how-to-borrow-usdr-at-0-interest-e1e51c90f2d2"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className="portfolio-card">
                    <img
                      src={borrowBg}
                      alt="borrow-bg"
                      className="portfolio-card-bg-img"
                    />
                  </div>
                </a>
                <div className="portfolio-elipses-section">
                  <div className="portfolio-selected-elipse" />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 2,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 3,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 4,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 5,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 6,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 7,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 8,
                      })
                    }
                  />
                </div>
              </>
            )}
            {this.state.currentCard === 2 && (
              <>
                <a
                  href="https://radar.blog/radar-multiply-tutorial-how-to-multiply-your-crypto-3b48990f0d0a"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className="portfolio-card">
                    <img
                      src={multiplyBg}
                      alt="multiply-bg"
                      className="portfolio-card-bg-img"
                    />
                  </div>
                </a>
                <div className="portfolio-elipses-section">
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 1,
                      })
                    }
                  />
                  <div className="portfolio-selected-elipse" />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 3,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 4,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 5,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 6,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 7,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 8,
                      })
                    }
                  />
                </div>
              </>
            )}
            {this.state.currentCard === 3 && (
              <>
                <a
                  href="https://radar.blog/radar-stabilizer-tutorial-how-to-mint-usdr-8c22f893fa48"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className="portfolio-card">
                    <img
                      src={stabilizerBg}
                      alt="stabilizer-bg"
                      className="portfolio-card-bg-img"
                    />
                  </div>
                </a>
                <div className="portfolio-elipses-section">
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 1,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 2,
                      })
                    }
                  />
                  <div className="portfolio-selected-elipse" />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 4,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 5,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 6,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 7,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 8,
                      })
                    }
                  />
                </div>
              </>
            )}
            {this.state.currentCard === 4 && (
              <>
                <div
                  className="portfolio-card"
                  onClick={() =>
                    this.toPage(
                      "borrow",
                      "0x38c19e770a4d55C27DaEF887360aa5F955F4cdee"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={borrowUpdateBg}
                    alt="borrow-update-bg"
                    className="portfolio-card-bg-img"
                  />
                </div>
                <div className="portfolio-elipses-section">
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 1,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 2,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 3,
                      })
                    }
                  />
                  <div className="portfolio-selected-elipse" />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 5,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 6,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 7,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 8,
                      })
                    }
                  />
                </div>
              </>
            )}
            {this.state.currentCard === 5 && (
              <>
                <div
                  className="portfolio-card"
                  onClick={() =>
                    this.toPage(
                      "farm",
                      "0x1b842E625143Ee99743755c9801fB9AE9C7C53eC"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={apyUpdateBg}
                    alt="apy-update-bg"
                    className="portfolio-card-bg-img"
                  />
                </div>
                <div className="portfolio-elipses-section">
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 1,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 2,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 3,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 4,
                      })
                    }
                  />
                  <div className="portfolio-selected-elipse" />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 6,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 7,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 8,
                      })
                    }
                  />
                </div>
              </>
            )}
            {this.state.currentCard === 6 && (
              <>
                <a href="https://www.certik.com/projects/radar" target="_blank" style={{ textDecoration: "none" }}>
                  <div className="portfolio-card certik-card">
                    <img
                      src={certikBg}
                      alt="multiply-bg"
                      className="portfolio-card-bg-img certik-card"
                    />
                  </div>
                </a>
                <div className="portfolio-elipses-section">
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 1,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 2,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 3,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 4,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 5,
                      })
                    }
                  />
                  <div className="portfolio-selected-elipse" />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 7,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 8,
                      })
                    }
                  />
                </div>
              </>
            )}
            {this.state.currentCard === 7 && (
              <>
                <a href="" target="_blank" style={{ textDecoration: "none" }}>
                  <div className="portfolio-card">
                    <img
                      src={avalancheUpdateBg}
                      alt="avalanche-update-bg"
                      className="portfolio-card-bg-img"
                    />
                  </div>
                </a>
                <div className="portfolio-elipses-section">
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 1,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 2,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 3,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 4,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 5,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 6,
                      })
                    }
                  />
                  <div className="portfolio-selected-elipse" />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 8,
                      })
                    }
                  />
                </div>
              </>
            )}
            {this.state.currentCard === 8 && (
              <>
                <a href="https://avax.curve.fi/factory/77" target="_blank" style={{ textDecoration: "none" }}>
                  <div className="portfolio-card">
                    <img
                      src={crvUpdateBg}
                      alt="crv-update-bg"
                      className="portfolio-card-bg-img"
                    />
                  </div>
                </a>
                <div className="portfolio-elipses-section">
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 1,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 2,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 3,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 4,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 5,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 6,
                      })
                    }
                  />
                  <div
                    className="portfolio-elipse"
                    onClick={() =>
                      this.setState({
                        currentCard: 7,
                      })
                    }
                  />
                  <div className="portfolio-selected-elipse" />
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  checkWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioNews);
