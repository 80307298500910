import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config/config.json";

import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import Sidebar from "../../layouts/sidebar/Sidebar";

import SwitchNetworkOverlay from "../../components/overlays/switch-network/SwitchNetworkOverlay";
import LoaderOverlay from "../../components/overlays/loader/LoaderOverlay";
import WalletNotConnectedOverlay from "../../components/overlays/wallet-not-connected/WalletNotConnectedOverlay";

import GradientArrowRightIcon from "../../components/icons/GradientArrowRightIcon";
import GradientInfoWarningIcon from "../../components/icons/GradientInfoWarningIcon";
import CaretDownIcon from "../../components/icons/CaretDownIcon";
import CaretUpIcon from "../../components/icons/CaretUpIcon";
import ArrowsIcon from "../../components/icons/ArrowsIcon";
import USDRIcon from "../../assets/SVGs/assets/usdr.svg";

import styles from "../../styles/index.module.css";
import "./stabilizerPage.css";
import {
  burnUSDR,
  getFees,
  getTokenData,
  getTokensInfo,
  mintUSDR,
} from "./blockchain/api";
import {
  approveToken,
  erc2612Sign,
  getTokenAllowance,
  getTokenBalance,
  scaleDecimals,
} from "../../web3/usdr/api";
import { toastr } from "react-redux-toastr";
import { ethers } from "ethers";

class StabilizerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",

      infoCard: true,
      depositStable: true,

      selectedStabKey: "USDT.e",

      balance: 0,
      balanceLoading: false,

      USDRBalance: 0,
      USDRBalanceLoading: false,

      allowance: 0,
      amount: "",
      amountInUSD: 0,
      amountInUSDLoading: 0,

      assetDropdown: false,

      USDRReceived: 0,
      fee: 50,
      radarFee: 25,
      transactionFee: 25,

      availableAmount: 1000,

      currentChain: "",

      currentFee: {
        burn: 0.0,
        mint: 0.0,
      },

      tokenData: {
        price: 0,
        decimals: 0,
        available: 0,
        userBal: 0,
      },

      currentTokenStabilizerAllowance: 0,

      loadingOverlay: false,

      burnUSDRSignature: {},
    };
  }

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  getAssetIcon = (type) => {
    const icon = require(`../../assets/SVGs/assets/${type}`).default;
    return icon;
  };

  setInfoCardState = () => {
    if (this.state.infoCard === false) {
      this.setState({
        infoCard: true,
      });
    } else if (this.state.infoCard === true) {
      this.setState({
        infoCard: false,
      });
    }
  };

  amountField = async (e) => {
    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") return;
    }
    var value = e.target.value;
    await this.setState({ amount: value, burnUSDRSignature: {} });
    this.getUSDRReceived();
  };

  assetDropdownClick = async (e, i) => {
    await e.preventDefault();
    await this.setState({
      selectedStabKey: i,
      assetDropdown: false,
    });
    await this.fetchTokenData();
    await this.getAllowance();
  };

  getUSDRReceived = async () => {
    if (this.state.amount === 0 || this.state.amount === "") {
      await this.setState({
        USDRReceived: 0,
      });
    } else {
      let USDRReceived =
        parseFloat(this.state.amount) -
        parseFloat(this.state.amount) *
          (this.state.depositStable
            ? this.state.currentFee.mint
            : this.state.currentFee.burn);
      await this.setState({
        USDRReceived: USDRReceived,
      });
    }
  };

  async componentDidMount() {
    await this.refreshData();
  }

  async getAllowance() {
    try {
      const rpc = config.networks[this.state.currentChain].endpoint;
      const stabilizerAddress =
        config.stabilizer[this.state.currentChain].address;
      const token =
        config.stabilizer[this.state.currentChain].supportedAssets[
          this.state.selectedStabKey
        ].address;
      const userAddress = this.props.onboard.address;

      const currentTokenStabilizerAllowance = await getTokenAllowance(
        rpc,
        token,
        userAddress,
        stabilizerAddress
      );

      await this.setState({
        currentTokenStabilizerAllowance,
      });
    } catch (e) {
      console.log("Couldn't fetch allowance " + e);
    }
  }

  async fetchTokenData() {
    try {
      const rpc = config.networks[this.state.currentChain].endpoint;
      const stabilizerAddress =
        config.stabilizer[this.state.currentChain].address;
      const token =
        config.stabilizer[this.state.currentChain].supportedAssets[
          this.state.selectedStabKey
        ].address;
      const userAddress = this.props.onboard.address;
      const oracleAddress =
        config.LendingOracleAggregator[this.state.currentChain];

      const tokenData = await getTokenData(
        token,
        rpc,
        oracleAddress,
        userAddress,
        stabilizerAddress
      );

      await this.setState({
        tokenData,
      });

      console.log("Token Data: ");
      console.log(tokenData);
    } catch (e) {
      console.log("Error fetching tokens: " + e);
    }
  }

  async updateFees() {
    try {
      const rpc = config.networks[this.state.currentChain].endpoint;
      const addr = config.stabilizer[this.state.currentChain].address;

      const fees = await getFees(rpc, addr);

      await this.setState({
        currentFee: fees,
      });
    } catch (e) {
      console.log("Error fetching fees " + e);
    }
  }

  async setCurrentChain() {
    const b = await this.getBlockchain(this.props.onboard.networkId);
    console.log("Current chain: " + b);
    await this.setState({
      currentChain: b,
    });
  }

  async signBurn() {
    // Loading
    await this.setState({
      loadingOverlay: true,
    });
    try {
      const stabilizerAddress =
        config.stabilizer[this.state.currentChain].address;
      const usdrAddress = config.USDR[this.state.currentChain];
      const scaledAmount = scaleDecimals(this.state.amount, 18);

      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      const signer = await provider.getSigner();

      const sig = await erc2612Sign(
        signer,
        this.props.onboard.address,
        usdrAddress,
        stabilizerAddress,
        scaledAmount
      );

      await this.setState({
        burnUSDRSignature: sig,
      });
      toastr.success("Success", "Signature registered.");
    } catch (e) {
      toastr.error("Error", "Error signing: " + e.message);
    }
    // Stop loading
    await this.refreshData();
    await this.setState({
      loadingOverlay: false,
    });
  }

  async burn() {
    // Loading
    if (this.state.USDRBalance < parseFloat(this.state.amount)) {
      return;
    }
    await this.setState({
      loadingOverlay: true,
    });
    try {
      const stabilizerAddress =
        config.stabilizer[this.state.currentChain].address;
      const usdrAddress = config.USDR[this.state.currentChain];
      const scaledAmount = scaleDecimals(this.state.amount, 18);
      const token =
        config.stabilizer[this.state.currentChain].supportedAssets[
          this.state.selectedStabKey
        ].address;

      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      const signer = await provider.getSigner();
      const sig = await erc2612Sign(
        signer,
        this.props.onboard.address,
        usdrAddress,
        stabilizerAddress,
        scaledAmount
      );

      const abiCoder = new ethers.utils.AbiCoder();

      const permitData = abiCoder.encode(
        ["address", "address", "uint", "uint", "uint8", "bytes32", "bytes32"],
        [
          this.props.onboard.address,
          stabilizerAddress,
          scaledAmount,
          sig.deadline,
          sig.v,
          sig.r,
          sig.s,
        ]
      );

      await burnUSDR(
        signer,
        stabilizerAddress,
        token,
        scaledAmount,
        permitData
      );

      toastr.success("Success", "USDR Burned.");
    } catch (e) {
      toastr.error("Error", "Error: " + e.message);
    }
    // Stop loading
    await this.refreshData();
    await this.setState({
      loadingOverlay: false,
    });
  }

  async approve() {
    // Loading
    await this.setState({
      loadingOverlay: true,
    });
    try {
      const stabilizerAddress =
        config.stabilizer[this.state.currentChain].address;
      const token =
        config.stabilizer[this.state.currentChain].supportedAssets[
          this.state.selectedStabKey
        ].address;

      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );

      const signer = await provider.getSigner();

      await approveToken(token, stabilizerAddress, signer, 0);
      toastr.success("Success", "Transaction confirmed.");
    } catch (e) {
      toastr.error("Error", "Error approving: " + e.message);
    }
    // Stop loading
    await this.refreshData();
    await this.setState({
      loadingOverlay: false,
    });
  }

  async getUSDRBalance() {
    try {
      const rpc = config.networks[this.state.currentChain].endpoint;
      const userAddress = this.props.onboard.address;
      const USDRAddress = config.USDR[this.state.currentChain];

      const unscaledBalance = await getTokenBalance(
        rpc,
        USDRAddress,
        userAddress
      );

      const scaledBalance = (unscaledBalance / 10 ** 18).toFixed(2);

      await this.setState({
        USDRBalance: scaledBalance,
      });
    } catch (e) {
      console.log("Failed getting usdr balance: " + e);
    }
  }

  async deposit() {
    // Loading
    if (this.state.tokenData.userBal < parseFloat(this.state.amount)) {
      return;
    }
    await this.setState({
      loadingOverlay: true,
    });
    try {
      const stabilizerAddress =
        config.stabilizer[this.state.currentChain].address;
      const token =
        config.stabilizer[this.state.currentChain].supportedAssets[
          this.state.selectedStabKey
        ].address;
      const amount = scaleDecimals(
        this.state.amount,
        this.state.tokenData.decimals
      );

      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );

      const signer = await provider.getSigner();

      await mintUSDR(signer, stabilizerAddress, token, amount);
      toastr.success("Success", "Transaction confirmed.");
    } catch (e) {
      toastr.error("Error", "Error minting USDR: " + e.message);
    }
    // Stop loading
    await this.refreshData();
    await this.setState({
      loadingOverlay: false,
    });
  }

  async refreshData() {
    this.getUSDRReceived();
    await this.setCurrentChain();
    await this.updateFees();
    await this.fetchTokenData();
    await this.getAllowance();
    await this.getUSDRBalance();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
      await this.refreshData();
    }
  }

  renderAssetDropdown() {
    const doNotDisplay = {
      display: "none",
    };

    const supportedAssets =
      config.stabilizer[this.state.currentChain].supportedAssets;
    return (
      <div className="stabilizer-stablecoins-dropdown">
        {Object.keys(supportedAssets).map((i) => (
          <div
            className={styles.poolAssetDropdownRow}
            onClick={(e) => this.assetDropdownClick(e, i)}
            style={i === this.state.selectedStabKey ? doNotDisplay : {}}
          >
            <img
              src={this.getAssetIcon(supportedAssets[i].icon)}
              alt="asset-icon"
              className={styles.poolAssetDropdownIcon}
            />
            <div className={`${styles.labelMRegular} ${styles.primaryText}`}>
              {supportedAssets[i].sym}
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderDepositStable() {
    return (
      <>
        <div className={styles.inputHeader}>
          <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
            Amount
          </div>
          <div
            className={`${
              styles.inputBalanceSection
            } ${"pool-page-balance-button"}`}
            onClick={async () => {
              await this.setState({
                amount: this.state.tokenData.userBal,
              });
              await this.getUSDRReceived();
            }}
          >
            <div className={`${styles.labelSRegular} ${styles.secondaryText}`}>
              Balance
            </div>
            {this.state.balanceLoading === true ? (
              <>{this.renderLoading()}</>
            ) : (
              <div
                className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
              >
                {this.state.tokenData.userBal.toFixed(2)}{" "}
                {
                  config.stabilizer[this.state.currentChain].supportedAssets[
                    this.state.selectedStabKey
                  ].sym
                }
              </div>
            )}
          </div>
        </div>
        <div className={`${styles.inputBox} ${styles.flexInputBox}`}>
          <div>
            <input
              type="text"
              id="amount"
              name="amount"
              placeholder="0.00"
              value={this.state.amount}
              onChange={(e) => this.amountField(e)}
              style={{
                fontSize: "21px",
                lineHeight: "32px",
              }}
            />
            {this.state.amountInUSDLoading === true ? (
              <>{this.renderLoading()}</>
            ) : (
              <div
                className={`${styles.labelSRegular} ${styles.secondaryText}`}
              >
                $
                {(
                  (this.state.depositStable ? this.state.amount : 0) *
                  this.state.tokenData.price
                ).toFixed(2)}
              </div>
            )}
          </div>
          {this.state.assetDropdown === false ? (
            <div
              className={`${styles.button} ${styles.dropdownButton} ${styles.bg}`}
              onClick={() =>
                this.setState({
                  assetDropdown: true,
                })
              }
            >
              <img
                src={this.getAssetIcon(
                  config.stabilizer[this.state.currentChain].supportedAssets[
                    this.state.selectedStabKey
                  ].icon
                )}
                alt="asset-icon"
                className={styles.dropdownAssetIcon}
              />
              <div
                className={`${styles.labelMRegular} ${styles.primaryText} ${styles.bg}`}
              >
                {
                  config.stabilizer[this.state.currentChain].supportedAssets[
                    this.state.selectedStabKey
                  ].sym
                }
              </div>
              <CaretDownIcon />
            </div>
          ) : (
            <>
              <div style={{ position: "relative" }}>
                <div
                  className={`${styles.button} ${styles.dropdownButton} ${styles.bg}`}
                  onClick={() =>
                    this.setState({
                      assetDropdown: false,
                    })
                  }
                >
                  <img
                    src={this.getAssetIcon(
                      config.stabilizer[this.state.currentChain]
                        .supportedAssets[this.state.selectedStabKey].icon
                    )}
                    alt="asset-icon"
                    className={styles.dropdownAssetIcon}
                  />
                  <div
                    className={`${styles.labelMRegular} ${styles.primaryText} ${styles.bg}`}
                  >
                    {
                      config.stabilizer[this.state.currentChain]
                        .supportedAssets[this.state.selectedStabKey].sym
                    }
                  </div>
                  <CaretUpIcon />
                </div>
                {this.renderAssetDropdown()}
              </div>
            </>
          )}
        </div>
        <div
          className="stab-switch-icon-section"
          onClick={() =>
            this.setState({
              depositStable: false,
              assetDropdown: false,
              USDRReceived: 0,
              amount: "",
              amountInUSD: 0,
            })
          }
        >
          <ArrowsIcon />
        </div>
        <div className={styles.inputHeader}>
          <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
            Mint USDR
          </div>
        </div>
        <div className="stab-results-box">
          <div className={`${"stab-results-box-left-section"} ${styles.bg}`}>
            <div className={`${styles.paragraphXL} ${styles.primaryText}`}>
              {this.state.USDRReceived}
            </div>
          </div>
          <div
            className={`${"stab-results-box-right-section"} ${styles.whiteBg}`}
          >
            <div
              className={`${"stab-results-box-right-section-box"} ${styles.bg}`}
            >
              <img
                src={USDRIcon}
                alt="usdr-icon"
                className="stab-results-box-right-section-icon"
              />
              <div className={`${styles.labelMRegular} ${styles.primaryText}`}>
                USDR
              </div>
            </div>
          </div>
        </div>
        {this.state.amount === 0 || this.state.amount === "" ? (
          <div
            className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
          >
            Enter an amount
          </div>
        ) : (
          <>
            {this.state.currentTokenStabilizerAllowance <
            parseInt(this.state.amount) *
              10 ** this.state.tokenData.decimals ? (
              <div
                className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                onClick={() => this.approve()}
              >
                Approve
              </div>
            ) : (
              <>
                <div
                  className={`${styles.button} ${
                    this.state.tokenData.userBal < parseFloat(this.state.amount)
                      ? styles.greyBg
                      : styles.gradientBg
                  } ${styles.labelMBold} ${
                    this.state.tokenData.userBal < parseFloat(this.state.amount)
                      ? styles.disabledText
                      : styles.whiteText
                  } ${styles.inputButton}`}
                  onClick={() => this.deposit()}
                >
                  {this.state.tokenData.userBal < parseFloat(this.state.amount)
                    ? "Insufficient Balance"
                    : "Swap"}
                </div>
                <div className={styles.orderDetailsSection}>
                  {/* <div className={`${styles.labelMBold} ${styles.primaryText}`}>
                    Order details
                  </div> */}
                  {/* <div className={styles.orderDetailsRow}>
                    <div
                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                    >
                      Fees
                    </div>
                    <div className={styles.orderDetailsFeesRow}>
                      <div
                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                      >
                        ${this.state.fee}
                      </div>
                      {this.state.showFees === true ? (
                        <div
                          className={styles.orderDetailsFeesIcon}
                          onClick={() =>
                            this.setState({
                              showFees: false,
                            })
                          }
                        >
                          <CaretUpIcon />
                        </div>
                      ) : (
                        <div
                          className={styles.orderDetailsFeesIcon}
                          onClick={() =>
                            this.setState({
                              showFees: true,
                            })
                          }
                        >
                          <CaretDownIcon />
                        </div>
                      )}
                    </div>
                  </div> */}
                  {/* {this.state.showFees === true && (
                    <>
                      <div className={styles.orderDetailsSubRow}>
                        <div
                          className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                        >
                          Radar fee
                        </div>
                        <div
                          className={`${styles.labelXSRegular} ${styles.primaryText}`}
                        >
                          ${this.state.radarFee}
                        </div>
                      </div>
                      <div className={styles.orderDetailsSubRow}>
                        <div
                          className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                        >
                          Transaction fee
                        </div>
                        <div
                          className={`${styles.labelXSRegular} ${styles.primaryText}`}
                        >
                          ${this.state.transactionFee}
                        </div>
                      </div>
                    </>
                  )} */}
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }

  renderDepositUSDR() {
    return (
      <>
        <div className={styles.inputHeader}>
          <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
            Burn USDR
          </div>
          <div
            className={` ${
              styles.inputBalanceSection
            } ${"pool-page-balance-button"} `}
            onClick={async () => {
              await this.setState({
                amount: this.state.USDRBalance,
              });
              this.getUSDRReceived();
            }}
          >
            <div className={`${styles.labelSRegular} ${styles.secondaryText}`}>
              Balance
            </div>
            {this.state.USDRBalanceLoading === true ? (
              <>{this.renderLoading()}</>
            ) : (
              <div
                className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
              >
                {this.state.USDRBalance} USDR
              </div>
            )}
          </div>
        </div>
        <div className={`${styles.inputBox} ${styles.flexInputBox}`}>
          <div>
            <input
              type="text"
              id="amount"
              name="amount"
              placeholder="0.00"
              value={this.state.amount}
              onChange={(e) => this.amountField(e)}
              style={{
                fontSize: "21px",
                lineHeight: "32px",
              }}
            />
          </div>
          <div
            className={`${styles.button} ${styles.dropdownButton} ${styles.bg}`}
          >
            <img
              src={USDRIcon}
              alt="usdr-icon"
              className={styles.dropdownAssetIcon}
            />
            <div
              className={`${styles.labelMRegular} ${styles.primaryText} ${styles.bg}`}
            >
              USDR
            </div>
          </div>
        </div>
        <div
          className="stab-switch-icon-section"
          onClick={() =>
            this.setState({
              depositStable: true,
              assetDropdown: false,
              USDRReceived: 0,
              amount: "",
              amountInUSD: 0,
            })
          }
        >
          <ArrowsIcon />
        </div>
        <div className={styles.inputHeader}>
          <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
            Receive{" "}
            {
              config.stabilizer[this.state.currentChain].supportedAssets[
                this.state.selectedStabKey
              ].sym
            }
          </div>
          <div className={`${styles.labelSRegular} ${styles.primaryText}`}>
            Available {this.state.tokenData.available.toFixed(2)}
          </div>
        </div>
        <div className="stab-results-box">
          <div className={`${"stab-results-box-left-section2"} ${styles.bg}`}>
            <div className={`${styles.paragraphXL} ${styles.primaryText}`}>
              {this.state.USDRReceived}
            </div>
          </div>
          <div
            className={`${"stab-results-box-right-section2"} ${styles.whiteBg}`}
          >
            {this.state.assetDropdown === false ? (
              <>
                <div
                  className={`${"stab-results-box-right-section-box"} ${
                    styles.bg
                  } ${styles.button}`}
                  onClick={() =>
                    this.setState({
                      assetDropdown: true,
                    })
                  }
                >
                  <img
                    src={this.getAssetIcon(
                      config.stabilizer[this.state.currentChain]
                        .supportedAssets[this.state.selectedStabKey].icon
                    )}
                    alt="usdr-icon"
                    className="stab-results-box-right-section-icon"
                  />
                  <div
                    className={`${styles.labelMRegular} ${styles.primaryText}`}
                  >
                    {
                      config.stabilizer[this.state.currentChain]
                        .supportedAssets[this.state.selectedStabKey].sym
                    }
                  </div>
                  <CaretDownIcon />
                </div>
              </>
            ) : (
              <>
                <div style={{ position: "relative" }}>
                  <div
                    className={`${"stab-results-box-right-section-box"} ${
                      styles.bg
                    } ${styles.button}`}
                    onClick={() =>
                      this.setState({
                        assetDropdown: false,
                      })
                    }
                  >
                    <img
                      src={this.getAssetIcon(
                        config.stabilizer[this.state.currentChain]
                          .supportedAssets[this.state.selectedStabKey].icon
                      )}
                      alt="usdr-icon"
                      className="stab-results-box-right-section-icon"
                    />
                    <div
                      className={`${styles.labelMRegular} ${styles.primaryText}`}
                    >
                      {
                        config.stabilizer[this.state.currentChain]
                          .supportedAssets[this.state.selectedStabKey].sym
                      }
                    </div>
                    <CaretUpIcon />
                  </div>
                  <div className="stab-asset-dropdown2">
                    {this.renderAssetDropdown()}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {this.state.amount === 0 || this.state.amount === "" ? (
          <div
            className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
          >
            Enter an amount
          </div>
        ) : this.state.USDRReceived > this.state.tokenData.available ? (
          <>
            <div
              className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
            >
              Not enough liquidity
            </div>
          </>
        ) : (
          <>
            {true === false ? (
              <div
                className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                onClick={() => this.signBurn()}
              >
                Sign
              </div>
            ) : (
              <>
                <div
                  className={`${styles.button} ${
                    this.state.USDRBalance < parseFloat(this.state.amount)
                      ? styles.greyBg
                      : styles.gradientBg
                  } ${styles.labelMBold} ${
                    this.state.USDRBalance < parseFloat(this.state.amount)
                      ? styles.disabledText
                      : styles.whiteText
                  } ${styles.inputButton}`}
                  onClick={() => this.burn()}
                >
                  {this.state.USDRBalance < parseFloat(this.state.amount)
                    ? "Insufficient Balance"
                    : "Sign and Swap"}
                </div>
                <div className={styles.orderDetailsSection}>
                  {/* <div className={`${styles.labelMBold} ${styles.primaryText}`}>
                    Order details
                  </div> */}
                  {/* <div className={styles.orderDetailsRow}>
                    <div
                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                    >
                      Fees
                    </div>
                    <div className={styles.orderDetailsFeesRow}>
                      <div
                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                      >
                        ${this.state.fee}
                      </div>
                      {this.state.showFees === true ? (
                        <div
                          className={styles.orderDetailsFeesIcon}
                          onClick={() =>
                            this.setState({
                              showFees: false,
                            })
                          }
                        >
                          <CaretUpIcon />
                        </div>
                      ) : (
                        <div
                          className={styles.orderDetailsFeesIcon}
                          onClick={() =>
                            this.setState({
                              showFees: true,
                            })
                          }
                        >
                          <CaretDownIcon />
                        </div>
                      )}
                    </div>
                  </div> */}
                  {/* {this.state.showFees === true && (
                    <>
                      <div className={styles.orderDetailsSubRow}>
                        <div
                          className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                        >
                          Radar fee
                        </div>
                        <div
                          className={`${styles.labelXSRegular} ${styles.primaryText}`}
                        >
                          ${this.state.radarFee}
                        </div>
                      </div>
                      <div className={styles.orderDetailsSubRow}>
                        <div
                          className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                        >
                          Transaction fee
                        </div>
                        <div
                          className={`${styles.labelXSRegular} ${styles.primaryText}`}
                        >
                          ${this.state.transactionFee}
                        </div>
                      </div>
                    </>
                  )} */}
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }

  render() {
    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };
    return (
      <>
        {this.state.loadingOverlay === true && (
          <LoaderOverlay {...this.props} />
        )}
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Sidebar {...this.props} state={this.state} />
          {this.props.onboard.walletConnected === false ? (
            <WalletNotConnectedOverlay {...this.props} />
          ) : (
            <>
              {!Object.keys(config.stabilizer).includes(
                this.state.currentChain
              ) && (
                <SwitchNetworkOverlay
                  {...this.props}
                  primaryTextFromParent={
                    "Stabilizer is not available on " +
                    config.networks[this.getBlockchain(this.props.onboard.networkId)].name
                  }
                  secondaryTextFromParent="Please switch your wallet to the following network"
                  blockchainsFromParent={["AVAXC"]}
                />
              )}
            </>
          )}
          <div
            className={styles.wrapper}
            style={
              this.props.onboard.walletConnected === false ||
              !Object.keys(config.stabilizer).includes(
                this.state.currentChain
              ) ||
              this.state.loadingOverlay === true
                ? displayBlur
                : {}
            }
          >
            <div className={styles.content}>
              <div className={styles.headerSection}>
                <div className={`${styles.headingM} ${styles.primaryText}`}>
                  Stabilizer
                </div>
              </div>
              {Object.keys(config.stabilizer).includes(
                this.state.currentChain
              ) && (
                <>
                  <div className={styles.singleCardLayout}>
                    <div className="stab-single-card-left-side">
                      {this.state.infoCard === true && (
                        <div className={`${styles.infoCard} ${styles.whiteBg}`}>
                          <div
                            className={`${styles.headingXS} ${styles.primaryText}`}
                          >
                            What’s Stabilizer?
                          </div>
                          <div
                            className={`${styles.paragraphS} ${styles.secondaryText}`}
                          >
                            Stabilizer allows you to use stablecoins to mint new
                            USDR and use USDR to redeem other stablecoins.
                            Stabilizer ensures that the price of USDR remains
                            close to the peg. This allows users to repay their
                            loans at close to $1 even if the price of USDR
                            deviates from the peg on other exchanges.
                          </div>
                          <div className="stabilizer-tutorial-link-section">
                            <a
                              href="https://radar.blog/radar-stabilizer-tutorial-how-to-mint-usdr-8c22f893fa48"
                              target="_blank"
                              style={{ textDecoration: "none", marginRight: "8px" }}
                            >
                              <div
                                className={`${styles.paragraphM} ${styles.gradientText}`}
                              >
                                Learn more about Stabilizer
                              </div>
                            </a>
                            <a
                              href=""
                              target="_blank"
                              style={{
                                textDecoration: "none",
                                marginTop: "-2px",
                              }}
                            >
                              <GradientArrowRightIcon />
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={`${styles.singleSCard} ${styles.whiteBg}`}>
                      <div className={styles.singleCardHeader}>
                        <div
                          className="stab-card-info-icon"
                          onClick={() => this.setInfoCardState()}
                        >
                          <GradientInfoWarningIcon />
                        </div>
                        <div
                          className={`${styles.labelSRegular} ${styles.primaryText}`}
                        >
                          Mint USDR
                        </div>
                        <div />
                      </div>
                      {this.state.depositStable === true ? (
                        <>{this.renderDepositStable()}</>
                      ) : (
                        <>{this.renderDepositUSDR()}</>
                      )}
                      <div
                        className={`${styles.labelSRegular} ${styles.primaryText} fee-div`}
                      >
                        {this.state.depositStable ? "Mint" : "Burn"} Fee:{" "}
                        {(this.state.depositStable
                          ? this.state.currentFee.mint
                          : this.state.currentFee.burn) * 100}
                        %
                      </div>
                      <div />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <Footer {...this.props} state={this.state} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StabilizerPage);
