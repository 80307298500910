import { ethers } from 'ethers';
import axios from 'axios';

import { getRadarTotalSupply, radarPrice, getEthersProvider, validateNetwork, getTokenBalance, getLPPrice, sleep, fullNumber, getRadarCirculatingSupply, scaleDecimals, coingeckoGetPrice } from '../utils/utils';
import config from '../config_dao.json';
import { IERC20Interface, RadarBondInterface, RadarStakingInterface, UniswapPairInterface, RadarSingleAssetBondInterface } from '../interfaces';

const MAX_INT = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

// Gets RADAR's current marketcap from coingecko
// Returns 0 on error
export const getRadarMarketCap = async () => {
    try {
        var data = await axios.get(`${config.coingecko.URL}/api/v3/simple/price?ids=${config.coingecko.RADAR_API_ID}&vs_currencies=usd&include_market_cap=true`);
        return data.data.radar.usd_market_cap;
    } catch(e) {
        return 0;
    }
}

// Gets RADAR's current price from coingecko
// Returns 0 on error
export const getRadarPrice = async () => {
    var price = await radarPrice();
    return price;
}

// Gets user's current RADAR balance
// network is a string. ex: "BSC"
// Returns 0 on error
export const getUserRadarBalance = async (userAddress, network) => {
    validateNetwork(network);
    var bal = await getTokenBalance(userAddress, config.networks[network].RADAR_address, network);

    // Scale
    bal /= 10**18;
    return bal;
}

// Gets RADAR's circulating and total supply
// returns an object with a `total` and `circulating` keys
// Returns 0 for both keys on error
export const getRadarSupply = async () => {
    try {
        var totalSupply = await getRadarTotalSupply();
        var circulatingSupply = await getRadarCirculatingSupply();
        return {
            "total": totalSupply,
            "circulating": circulatingSupply
        };
    } catch(e) {
        console.log(e);
        return {
            "total": 0,
            "circulating": 0
        };
    }
}

// Gets total value deposited in DAO (treasury value + staking value)
// Returns 0 on error
export const getTotalValueDeposited = async (network) => {
    try {
        var stakingValue = 0;
        var stakingNetworks = Object.keys(config.staking);
        for(var i = 0; i < stakingNetworks.length; i++) {
            var tempStaking = await getStakingTotalValueDeposited(stakingNetworks[i]);
            stakingValue += tempStaking;
        }

        var bondsValue = 0;
        var bondIds = Object.keys(config.bonds);
        for(var i = 0; i < bondIds.length; i++) {
            var bondConfig = config.bonds[bondIds[i]];
            if (bondConfig.network != network)
                continue;
            var bondValue = await getTotalValueDepositedForBond(bondIds[i]);
            bondsValue += isNaN(bondValue) ? 0 : bondValue;
        }

        return bondsValue + stakingValue;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets total value deposited for a specific bond 
// Returns 0 on error
export const getTotalValueDepositedForBond = async (bondId) => {
    var bondConfig = config.bonds[bondId];
    validateNetwork(bondConfig.network);
    try {
        var provider = await getEthersProvider(config.networks[bondConfig.network].rpc, "jsonrpc");
        var contract = new ethers.Contract(
            bondConfig.address,
            RadarBondInterface,
            provider
        );

        var lpDeposited = await contract.getTotalLPDeposited();
        lpDeposited /= 10**config.tokenDecimals[bondConfig.network][bondConfig.bondAsset.toLowerCase()]; // Scale
        if (bondConfig.type == "LP") {
            var lpPrice = await getLPPrice(bondConfig.bondAsset, bondConfig.network);
        } else if (bondConfig.type == "SA") {
            var lpPrice = await coingeckoGetPrice(config.coingecko.IDS[bondConfig.network][bondConfig.bondAsset.toLowerCase()]);
        } else {
            return 0;
        }
        return (lpPrice * lpDeposited);
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets the percentage protocol owned liquidity for a certain network
// all registered bonds on that network
// Returns 0 on error
export const getProtocolOwnedLiquidityPercentage = async (network) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var bondsIds = Object.keys(config.bonds);
        var pSum = 0.0;
        var pLength = 0;
        if (!Object.keys(config.treasury).includes(network)) {
            console.log("Treasury doesn't exist on this network");
            return 0;
        }
        for(var i = 0; i < bondsIds.length; i++) {
            var bondConfig = config.bonds[bondsIds[i]];
            if (bondConfig.network != network || bondConfig.type != "LP")
                continue;
            
            var pair = new ethers.Contract(
                bondConfig.bondAsset,
                UniswapPairInterface,
                provider
            );
            var totalSupply = await pair.totalSupply();

            var treasuryBal = await getTokenBalance(config.treasury[network].address, bondConfig.bondAsset, network);

            pSum += totalSupply != 0 ? (treasuryBal / totalSupply) : 0;
            pLength += 1;
        }

        return pLength != 0 ? (pSum / pLength) : 0;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets the percentage protocol owned liquidity for 
// all bonds on all networks
// Returns 0 on error
export const getProtocolOwnedLiquidityPercentageGlobal = async () => {
    try {
        var networks = Object.keys(config.networks);
        var pSum = 0.0;
        var pLength = 0;
        for(var i = 0; i < networks.length; i++) {
            var pTemp = await getProtocolOwnedLiquidityPercentage(networks[i]);
            if (pTemp == 0)
                continue
            pSum += pTemp;
            pLength += 1;
        }

        return pLength != 0 ? (pSum / pLength) : 0;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets the percentage of how much radar is staked on all networks
// Returns 0 on error
export const getPercentageRadarStakedGlobal = async () => {
    try {
        var totalSupply = await getRadarCirculatingSupply();
        var stakedNetworks = Object.keys(config.staking);
        var pSum = 0;
        for(var i = 0; i < stakedNetworks.length; i++) {
            var currentNetwork = stakedNetworks[i];
            var currentStaking = config.staking[currentNetwork].address;
            var staked = await getUserRadarBalance(currentStaking, currentNetwork);
            pSum += staked;
        }

        return totalSupply != 0 ? (pSum / totalSupply) : 0;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets APY for staking on a specific network
// Returns 0 on error
export const getStakingAPY = async (network) => {
    validateNetwork(network);
    try {
        var provider = await  getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var staking = new ethers.Contract(
            config.staking[network].address,
            RadarStakingInterface,
            provider
        );

        var rewardRate = await staking.rewardRate();
        var totalSupply = await staking.totalSupply();

        if (totalSupply == 0)
            return 0;
        
        var tokensInYear = rewardRate * (365 * 24 * 60 * 60);
        var apy = (tokensInYear / totalSupply);

        return apy;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets how much RADAR was deposited into staking on a certain network
// Returns 0 on error
export const getStakingTotalRadarDeposited = async (network) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var staking = new ethers.Contract(
            config.staking[network].address,
            RadarStakingInterface,
            provider
        );

        var td = await staking.totalSupply();
        td /= 10**18;
        return td;
    } catch(e) {
        console.log(e)
        return 0;
    }

}

// Gets how much value (USD) in RADAR was deposited into staking on a certain network
// Returns 0 on error
export const getStakingTotalValueDeposited = async (network) => {
    validateNetwork(network);
    try {
        var stakingRadar = await getStakingTotalRadarDeposited(network);
        var radarPrice = await getRadarPrice();
        var stakingValue = stakingRadar * radarPrice;
        return stakingValue;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Approve for staking
// Throws error on error
export const approveStaking = async (web3Provider, network) => {
    validateNetwork(network)
    var provider = await getEthersProvider(web3Provider, "web3");
    var radar = new ethers.Contract(
        config.networks[network].RADAR_address,
        IERC20Interface,
        provider
    );

    var receipt = await radar.approve(config.staking[network].address, MAX_INT);
    await receipt.wait();
    await sleep(config.TX_WAIT_TIME);
}

// Gets a user address's current staked RADAR balance on a specific network
// Returns 0 on error
export const getUserStakedBalance = async (network, userAddress) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var staking = new ethers.Contract(
            config.staking[network].address,
            RadarStakingInterface,
            provider
        );

        var staked = await staking.balanceOf(userAddress);
        staked /= 10**18;
        return staked;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets a user's RADAR allowance for staking on a certain network
// Returns 0 on error
export const getUserStakingAllowance = async (network, userAddress) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var radar = new ethers.Contract(
            config.networks[network].RADAR_address,
            IERC20Interface,
            provider
        );

        var allowance = await radar.allowance(userAddress, config.staking[network].address);
        allowance /= 10**18;
        return fullNumber(allowance);
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Stake for a user on a certain network. If stakeAll is true,
// all of his radar balance will be staked
// If stakeAll is true, amount can be 0
// Throws error on error
export const stake = async (network, web3Provider, userAddress, amount, stakeAll=false) => {
    validateNetwork(network);
    var provider = await getEthersProvider(web3Provider, "web3");
    var staking;
    if (network != "ETH") {
        staking = new ethers.Contract(
            config.staking[network].address,
            RadarStakingInterface,
            provider
        );
    } else {
        const ethStakingInterface = new ethers.utils.Interface(["function stake(uint256 amount)"]);
        staking = new ethers.Contract(
            config.staking[network].address,
            ethStakingInterface,
            provider
        );
    }

    var scaledAmount;
    if(stakeAll) {
        scaledAmount = await getTokenBalance(userAddress, config.networks[network].RADAR_address, network);
    } else {
        scaledAmount = scaleDecimals(amount, 18)
    }

    var receipt;
    if (network != "ETH") {
        var receipt = await staking.stake(scaledAmount, userAddress);
    } else {
        var receipt = await staking.stake(scaledAmount);
    }
    await receipt.wait()
    await sleep(config.TX_WAIT_TIME);
}

// Unstake a user's radar from a specific network. If unstakeAll is true,
// all of his staked radar will be unstaked
// If unstakeAll is true, amount can be 0
// Throws error on error
export const unstake = async (network, web3Provider, amount, unstakeAll=false) => {
    validateNetwork(network);
    var provider = await getEthersProvider(web3Provider, "web3");
    var staking = new ethers.Contract(
        config.staking[network].address,
        RadarStakingInterface,
        provider
    );

    var scaledAmount;
    var receipt;
    if (unstakeAll) {
        receipt = await staking.exit();
    } else {
        scaledAmount = scaleDecimals(amount, 18);
        receipt = await staking.withdraw(scaledAmount);
    }

    await receipt.wait();
    await sleep(config.TX_WAIT_TIME);
}

// Gets how much RADAR a user will get if he claims rewards
// Returns 0 on error
export const getUserUnclaimedStakingReward = async (network, userAddress) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var staking = new ethers.Contract(
            config.staking[network].address,
            RadarStakingInterface,
            provider
        );

        var earned = await staking.earned(userAddress);
        earned /= 10**18; // Scale

        return earned;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Claims rewards for staking on that specific network
// throws error on error
export const claimRewardStaking = async (network, web3Provider) => {
    validateNetwork(network);
    var provider = await getEthersProvider(web3Provider, "web3");
    var staking = new ethers.Contract(
        config.staking[network].address,
        RadarStakingInterface,
        provider
    );

    var receipt = await staking.getReward();
    await receipt.wait();
    await sleep(config.TX_WAIT_TIME);
}

// Gets user's LP allowance for a bond
// Returns 0 on error
export const getUserAllowanceForBond = async (network, userAddress, bondId) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var bondConfig = config.bonds[bondId];
        var lp = new ethers.Contract(
            bondConfig.bondAsset,
            IERC20Interface,
            provider
        );

        var allowance = await lp.allowance(userAddress, bondConfig.address);
        allowance /= 10**config.tokenDecimals[network][bondConfig.bondAsset.toLowerCase()];
        return fullNumber(allowance);
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Approves LP tokens for a bond
// throws error on error
export const approveForBond = async (network, bondId, web3Provider) => {
    validateNetwork(network);
    var provider = await getEthersProvider(web3Provider, "web3");
    var bondConfig = config.bonds[bondId];
    var lp = new ethers.Contract(
        bondConfig.bondAsset,
        IERC20Interface,
        provider
    );

    var receipt = await lp.approve(bondConfig.address, MAX_INT);
    await receipt.wait();
    await sleep(config.TX_WAIT_TIME);
}

// Deposits in a bond
// If depositAll is true, it will deposit all of
// the user's LP
// slippage must be between 0 and 1: for example: 0.5 means 50%
// If depositAll is true, bondLPAmount can be 0
// throws error on error
export const depositForBond = async (network, bondId, web3Provider, userAddress, bondLPAmount, slippage, depositAll=false) => {
    validateNetwork(network);
    var provider = await getEthersProvider(web3Provider, "web3");
    var bondConfig = config.bonds[bondId];
    var bond = new ethers.Contract(
        bondConfig.address,
        RadarBondInterface,
        provider
    );

    var scaledAmount;
    if (depositAll) {
        var lp = new ethers.Contract(
            bondConfig.bondAsset,
            IERC20Interface,
            provider
        );
        scaledAmount = await lp.balanceOf(userAddress);
    } else {
        scaledAmount = scaleDecimals(bondLPAmount, config.tokenDecimals[network][bondConfig.bondAsset.toLowerCase()]);
    }

    var estimatedReward = await estimateRadarRewardForLPAmount(network, scaledAmount/10**config.tokenDecimals[network][bondConfig.bondAsset.toLowerCase()], bondId);
    estimatedReward *= 10**18;

    var minReward = fullNumber(parseInt(estimatedReward - slippage*estimatedReward).toString()).toString();

    var receipt = await bond.bond(scaledAmount, minReward);
    await receipt.wait();
    await sleep(config.TX_WAIT_TIME);
}

// Redeems a part (or all) of a user's bond
// When user clicks claim, stake is false, if user clicks 
// Claim and Autostake, stake will be true
// throws error on error
export const redeemBond = async (network, bondId, web3Provider, stake=false) => {
    validateNetwork(network);
    var provider = await getEthersProvider(web3Provider, "web3");
    var bondConfig = config.bonds[bondId];
    var bond = new ethers.Contract(
        bondConfig.address,
        RadarBondInterface,
        provider
    );

    var receipt = await bond.redeem(stake);
    await receipt.wait();
    await sleep(config.TX_WAIT_TIME);
}

// Returns true if the user has an active bond which he can
// redeem on that network on that bondId and false if he doesn't
// Returns false on error
export const userHasActiveBond = async (network, bondId, userAddress) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var bondConfig = config.bonds[bondId];
        var bond = new ethers.Contract(
            bondConfig.address,
            RadarBondInterface,
            provider
        );

        var activeBond = await bond.getBond(userAddress);
        return activeBond.payout > 0;
    } catch(e) {
        console.log(e);
        return false;
    }
}

export const getUserBondInfo = async (network, bondId, userAddress) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var bondConfig = config.bonds[bondId];
        var bond = new ethers.Contract(
            bondConfig.address,
            RadarBondInterface,
            provider
        );

        var activeBond = await bond.getBond(userAddress);

        if (activeBond.payout > 0) {
            return{
                userHasActiveBond: true,
                leftToVest: parseInt(activeBond.leftToVest),
                updateTimestamp: parseInt(activeBond.updateTimestamp),
                payout: activeBond.payout/(10**18)
            };
        } else {
            return {
                userHasActiveBond: false,
                leftToVest: 0,
                updateTimestamp: 0,
                payout: 0
            }
        }
    } catch (e) {
        return {
            userHasActiveBond: false,
            leftToVest: 0,
            updateTimestamp: 0,
            payout: 0
        }
    }
}

// Gets how much LP a user has for a specific bond
// used on the bond popup
// Your Balance: X LP
// Returns 0 on error
export const getUserLPAmountForBond = async (network, bondId, userAddress) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var bondConfig = config.bonds[bondId];
        var lp = new ethers.Contract(
            bondConfig.bondAsset,
            IERC20Interface,
            provider
        );

        var lpBal = await lp.balanceOf(userAddress);
        lpBal /= 10**config.tokenDecimals[bondConfig.network][bondConfig.bondAsset.toLowerCase()];

        return lpBal;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets how much RADAR a user will get if he bonds this specific amount
// used on bond popup
// You will get: X RADAR
// Returns 0 on error
export const estimateRadarRewardForLPAmount = async (network, amount, bondId) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var bondConfig = config.bonds[bondId];
        var bond = new ethers.Contract(
            bondConfig.address,
            RadarBondInterface,
            provider
        );

        // var scaledAmount = fullNumber(parseInt(amount * 10**18));
        var scaledAmount = scaleDecimals(amount, config.tokenDecimals[network][bondConfig.bondAsset.toLowerCase()]);
        var estimatedReward = await bond.estimateReward(scaledAmount);
        estimatedReward /= 10**18;
        return estimatedReward;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets the max amount of LP tokens a user can bond on a specific bond
// used on bond popup
// Max you can buy: X LP
// Returns 0 on error
export const getMaxLPAmountForBond = async (network, bondId) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var bondConfig = config.bonds[bondId];
        var bond = new ethers.Contract(
            bondConfig.address,
            RadarBondInterface,
            provider
        );

        var maxLP = await bond.getMaxBondAmount();
        maxLP /= 10**config.tokenDecimals[network][bondConfig.bondAsset.toLowerCase()];
        return maxLP;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets the bond's ROI
// used on bond popup
// ROI: (x*100)%
// Returns 0 on error
export const getROIForBond = async (network, bondId) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var bondConfig = config.bonds[bondId];
        var bond = new ethers.Contract(
            bondConfig.address,
            RadarBondInterface,
            provider
        );

        var terms = await bond.getBondingTerms();
        var roi = terms.bondDiscount / 10000;
        return roi;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets the bond's theoretical APY
// used on bond popup
// APY: (x*100)%
// Returns 0 on error
export const getAPYForBond = async (network, bondId) => {
    validateNetwork(network);
    try {
        var roi = await getROIForBond(network, bondId);
        var vestingTime = await getVestingTimeForBond(network, bondId);

        var secondsInAYear = 60*60*24*365;
        var comps = vestingTime != 0 ? (secondsInAYear / vestingTime) : 0;

        var apy = (roi != 0 && comps != 0) ? (1+roi)**comps : 0;
        return apy;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// Gets the bond's vesting time in seconds
// Used on bond popup
// Vesting term
// Returns 0 on error
export const getVestingTimeForBond = async (network, bondId) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var bondConfig = config.bonds[bondId];
        var bond = new ethers.Contract(
            bondConfig.address,
            RadarBondInterface,
            provider
        );

        var terms = await bond.getBondingTerms();
        var vp = terms.vestingTime;
        return vp;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

// This returns true/false if you can
// bond right now due to Radar's 
// minimum price
// returns false on Error
export const canBondNow = async (network, bondId) => {
    validateNetwork(network);
    try {
        var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
        var bondConfig = config.bonds[bondId];
        var bond = new ethers.Contract(
            bondConfig.address,
            bondConfig.type == "LP" ? RadarBondInterface : RadarSingleAssetBondInterface,
            provider
        );

        var terms = await bond.getBondingTerms();
        var minPrice = bondConfig.type == "LP" ? terms.minPrice : terms.minPriceLP;
        var payoutAssset = await bond.getPayoutAsset();
        payoutAssset = payoutAssset.toLowerCase();

        var lp = new ethers.Contract(
            bondConfig.type == "LP" ? bondConfig.bondAsset : terms.priceLP,
            UniswapPairInterface,
            provider
        );

        var token0 = await lp.token0();
        token0 = token0.toLowerCase();
        var token1 = await lp.token1();
        token1 = token1.toLowerCase();

        var token0Decimals = config.tokenDecimals[network][token0];
        var token1Decimals = config.tokenDecimals[network][token1];

        var reserves = await lp.getReserves();
        var reserve0 = reserves.reserve0;
        var reserve1 = reserves.reserve1;

        var price;
        if (token0 == payoutAssset) {
            price = (reserve1 * 10**token0Decimals) / reserve0;
        } else if (token1 == payoutAssset) {
            price = (reserve0 * 10**token1Decimals) / reserve1;
        } else {
            throw Error("Couldn't find payout asset in LP");
        }

        return price >= minPrice;
    } catch(e) {
        console.log(e);
        return false;
    }
}

export const getRadar24hChange = async () => {
    try {
        var data = await axios.get(`${config.coingecko.URL}/api/v3/coins/${config.coingecko.RADAR_API_ID}?tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false`);
        return data.data.market_data.price_change_percentage_24h;
    } catch(e) {
        console.log(e);
        return 0;
    }
}

export const getBondMinPrice = async (bondId) => {
    try {
        var bondConfig = config.bonds[bondId];
        var provider = await getEthersProvider(config.networks[bondConfig.network].rpc, "jsonrpc");

        var bond = new ethers.Contract(
            bondConfig.address,
            bondConfig.type == "LP" ? RadarBondInterface : RadarSingleAssetBondInterface,
            provider
        );
        var terms = await bond.getBondingTerms();
        var mp = bondConfig.type == "LP" ? terms.minPrice : terms.minPriceLP;

        console.log(`MP: ${mp}`)

        var pair = new ethers.Contract(
            bondConfig.type == "LP" ? bondConfig.bondAsset : terms.priceLP,
            UniswapPairInterface,
            provider
        );

        var token0 = await pair.token0();
        var token1 = await pair.token1();
        var thtkn = token0.toLowerCase() == config.networks[bondConfig.network].RADAR_address ? token1 : token0;
        thtkn = thtkn.toLowerCase();

        console.log(`thtkn: ${thtkn}`);
        mp /= 10**config.tokenDecimals[bondConfig.network][thtkn];
        console.log(`New mp: ${mp}`);

        var thtknPrice = await coingeckoGetPrice(config.coingecko.IDS[bondConfig.network][thtkn]);

        console.log(`thtkn price: ${thtknPrice}`)

        var minPrice = mp * thtknPrice;

        return minPrice;
    } catch(e) {
        console.log(e);
        return 0;
    }
}