import React, { Component } from 'react';

import icon from '../../assets/SVGs/toggle-off.svg';

class ToggleOnIcon extends Component {

    render() {

        return (

            <>
                <img
                    src={icon}
                    alt='icon'
                />
            </>
        )
    }
}

export default ToggleOnIcon;