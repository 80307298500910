import React, { Component } from "react";

import "./loader.css";

class Loader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        
          <div class="stage">
            <div class="dot-flashing"></div>
          </div>
        
      </>
    );
  }
}

export default Loader;
