import React, { Component } from "react";
import { connect } from "react-redux";

import {
  connectAccountOnboard,
  checkWallet,
  walletPersistance,
  manualChangeNetwork,
} from "../../../redux/actions/OnboardActions";

import styles from "../../../styles/index.module.css";
import "./walletNotConnectedOverlay.css";

class WalletNotConnectedOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",
    };
  }

  render() {
    return (
      <div className={styles.popupWrapper}>
        <div className="wnc-text-section">
          <div className={`${styles.headingL} ${styles.primaryText}`}>
            No wallet connected
          </div>
          <div
            className={`${styles.paragraphM} ${styles.secondaryText}`}
            style={{ marginTop: "16px" }}
          >
            Please connect your wallet
          </div>
          <div
            className={`${styles.button} ${
              styles.gradientBg
            } ${"wnc-connect-button"}`}
            onClick={() => this.props.connectAccountOnboard()}
          >
            <div className={`${styles.labelMRegular} ${styles.whiteText}`}>
              Connect wallet
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  connectAccountOnboard,
  checkWallet,
  walletPersistance,
  manualChangeNetwork,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletNotConnectedOverlay);
