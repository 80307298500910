import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config/config.json";

import Header from "../../layouts/header/Header";
import Sidebar from "../../layouts/sidebar/Sidebar";
import Footer from "../../layouts/footer/Footer";
import Loader from "../../components/loader/Loader";
import Tooltip from "../../components/tooltips/Tooltip";

import WalletNotConnectedOverlay from "../../components/overlays/wallet-not-connected/WalletNotConnectedOverlay";
import SwitchNetworkOverlay from "../../components/overlays/switch-network/SwitchNetworkOverlay";
import LoaderOverlay from "../../components/overlays/loader/LoaderOverlay";

import GradientInterfaceIcon from "../../components/icons/GradientInterfaceIcon";
import CaretDownIcon from "../../components/icons/CaretDownIcon";
import CaretUpIcon from "../../components/icons/CaretUpIcon";
import CloseIcon from "../../components/icons/CloseIcon";

import greenArrowIcon from "../../assets/SVGs/green-arrow.svg";
import orangeArrowIcon from "../../assets/SVGs/orange-arrow.svg";
import redArrowIcon from "../../assets/SVGs/red-arrow.svg";

import styles from "../../styles/index.module.css";
import "./multiplyPoolPage.css";
import {
  approveToken,
  BenqiDepositorDeposit,
  CurveDepositorLPDeposit,
  erc2612Sign,
  getAvailableToBorrow,
  getCurrentLTV,
  getLiquidationPrice,
  getPositionHealth,
  getEntryFee,
  getExitFee,
  getLeftToBorrow,
  getLiquidationIncentive,
  getMaxLTV,
  getNativeTokenBalance,
  getOraclePrice,
  getTokenAllowance,
  getTokenBalance,
  getUserBorrow,
  getUserCollateral,
  lendingPairBorrow,
  lendingPairDeLeverageWithPermit,
  lendingPairDeposit,
  lendingPairDepositAndBorrow,
  lendingPairLeverage,
  lendingPairRepayAndWithdrawWithPermit,
  lendingPairRepayWithPermit,
  lendingPairWithdraw,
  scaleDecimals,
} from "../../web3/usdr/api";
import { toastr } from "react-redux-toastr";
import { ethers } from "ethers";
import { getMinUSDRAmount, getSwapData } from "../../web3/usdr/swapper";
import { getSlippage } from "../../web3/localstorage/slippageManager";

const DUST = 0.000001;

class MultiplyPoolPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: true,
      backButtonRoute: "/borrow",
      displayInfoCards: true,

      i: "",

      maxCollateralRatio: 0,
      maxCollateralRatioLoading: true,

      liquidationFee: 0,
      liquidationFeeLoading: true,

      stabilityFee: 0,
      stabilityFeeLoading: true,

      exitFee: 0,

      liquidationPrice: 0,
      liquidationPriceLoading: true,

      collateralPrice: 0,
      collateralPriceLoading: true,

      positionHealth: "100%", // NEAPARAT PROCENT DUPA IN STATE PENTRU CA AM NEV. LA PROGRESS BAR WIDTH!
      positionHealthLoading: true,

      collateralizationRatio: 0,
      collateralizationRatioLoading: true,
      collateralizationRatioATM: -1,

      availableToBorrow: 0,
      availableToBorrowLoading: true,
      availableToBorrowATM: -1,

      collateral: 0,
      collateralLoading: true,
      collateralATM: -1,
      collateralInETH: 0,

      debt: 0,
      debtLoading: true,
      debtATM: -1,

      amount: "",
      amountInUSD: 0,
      amountInUSDLoading: 0,

      balance: 0,
      selectedAssetPrice: 0,
      balanceLoading: true,

      dropdownAsset: false,

      selected: [],

      risk: "0",

      allowance: 0,

      borrowAmount: 0,

      futureAction: "",

      actionButtonText: "",
      actionButtonDisabled: true,

      loadingOverlay: false,

      leftToBorrow: 0,

      repayPopup: false,

      repayAmount: 0.0,
      withdrawAmount: 0.0,
      repayUSDAmount: 0.0,
      withdrawUSDAmount: 0.0,

      selectedRepayMethod: "wallet",

      userUsdrBalance: 0,

      maxRepaymentWallet: 0,
      maxRepaymentWalletLoading: true,
      maxRepaymentCollateral: 0,
      maxRepaymentCollateralLoading: true,

      maxWithdrawCollateral: 0,
      withdrawBarValue: "0",

      maxRepayFlag: false,

      borrowWithSlippage: 0,

      maxBorrowMultiply: 0,

      liquidationPriceATM: 0,

      tooltipClass: "no-tooltip",
      currentTooltipInfo: 1,
    };
  }

  renderLoading() {
    return <Loader {...this.props} />;
  }

  toPage(route, subroute, params) {
    this.props.history.push(route + "/" + subroute, params);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  nullCheck = (no) => {
    if (no == NaN || no == "") {
      return 0;
    } else {
      return no;
    }
  }

  async setFutureAction() {
    var {
      balance,
      availableToBorrow,
      collateral,
      collateralPrice,
      debt,
      leftToBorrow,
      amount,
      borrowAmount,
      repayAmount,
      withdrawAmount,
      repayUSDAmount,
      selectedRepayMethod,
      exitFee,
      userUsdrBalance,
    } = this.state;

    var fs = "";

    if (!this.state.repayPopup) {
      if (
        (amount == 0 || amount == "") &&
        (borrowAmount == 0 || borrowAmount == "")
      ) {
        fs = "";
      } else if (amount != 0 && amount != "") {
        if (amount > balance) {
          fs = "insufficientFunds";
        } else if (borrowAmount == 0 || borrowAmount == "") {
          fs =
            this.state.selected[0].selectedAsset ==
            config.borrowMultiply[this.getPool()].collateral.sym
              ? "deposit"
              : "depositDepositor";
        } else {
          // Deposit and borrow
          const colValue =
            collateral * collateralPrice +
            this.state.selectedAssetPrice * parseFloat(amount) +
            this.state.borrowWithSlippage;
          const borrowTotal =
            debt +
            parseFloat(borrowAmount) +
            parseFloat(borrowAmount) * (this.state.stabilityFee / 100);
          const futureLTV = getCurrentLTV(colValue, borrowTotal);

          if (
            futureLTV * 1.01 > this.state.maxCollateralRatio / 100 ||
            parseFloat(borrowAmount) > leftToBorrow ||
            colValue == 0
          ) {
            fs = "borrowTooMuch";
          } else {
            fs = "depositAndBorrow";
          }
        }
      } else if (borrowAmount != 0 && borrowAmount != "") {
        // Just borrow
        // Deposit and borrow
        const colValue =
          collateral * collateralPrice + this.state.borrowWithSlippage;
        const borrowTotal =
          debt +
          parseFloat(borrowAmount) +
          parseFloat(borrowAmount) * (this.state.stabilityFee / 100);
        const futureLTV = getCurrentLTV(colValue, borrowTotal);
        if (
          futureLTV * 1.01 > this.state.maxCollateralRatio / 100 ||
          parseFloat(borrowAmount) > leftToBorrow ||
          colValue == 0
        ) {
          fs = "borrowTooMuch";
        } else {
          fs = "borrow";
        }
      }
    } else {
      if (
        (repayAmount == 0 || repayAmount == "") &&
        (withdrawAmount == 0 || withdrawAmount == "")
      ) {
        fs = "";
      } else if (
        repayAmount != 0 &&
        repayAmount != "" &&
        (withdrawAmount == 0 || withdrawAmount == "")
      ) {
        // Just repaying
        const colValue =
          (collateral -
            (selectedRepayMethod == "collateral" ? repayAmount : 0)) *
          collateralPrice;
        const futureLTV = getCurrentLTV(
          colValue,
          debt - parseFloat(repayUSDAmount)
        );
        const repayFee = repayUSDAmount * (exitFee / 100);
        const debtAndFee = debt + repayFee;

        if (selectedRepayMethod == "collateral") {
          if (parseFloat(repayAmount) > collateral) {
            fs = "notEnoughCollateral";
          } else if (parseFloat(repayUSDAmount) > debtAndFee * 1.1) {
            fs = "repayTooMuch";
          } else {
            fs = "repayCollateral";
          }
        } else {
          if (parseFloat(repayAmount) > userUsdrBalance) {
            fs = "notEnoughUSDR";
          } else if (parseFloat(repayAmount) > debtAndFee) {
            fs = "repayTooMuch";
          } else {
            fs = "repayWallet";
          }
        }
      } else {
        // Withdrawing

        if (repayAmount != 0 && repayAmount != "") {
          // Repay and withdraw
          const colValue =
            (collateral -
              parseFloat(withdrawAmount) -
              (selectedRepayMethod == "collateral" ? repayAmount : 0)) *
            collateralPrice;
          const futureLTV = getCurrentLTV(
            colValue,
            debt - parseFloat(repayUSDAmount)
          );
          const repayFee = repayUSDAmount * (exitFee / 100);
          const debtAndFee = debt + repayFee;
          if (
            futureLTV * 1.01 > this.state.maxCollateralRatio / 100 ||
            colValue <= 0
          ) {
            fs = "withdrawTooMuch";
          } else {
            if (selectedRepayMethod == "collateral") {
              if (parseFloat(repayAmount) > collateral) {
                fs = "notEnoughCollateral";
              } else if (parseFloat(repayUSDAmount) > debtAndFee * 1.1) {
                fs = "repayTooMuch";
              } else {
                fs = "repayWithdrawCollateral";
              }
            } else {
              if (parseFloat(repayAmount) > userUsdrBalance) {
                fs = "notEnoughUSDR";
              } else if (parseFloat(repayAmount) > debtAndFee) {
                fs = "repayTooMuch";
              } else {
                fs = "repayWithdrawWallet";
              }
            }
          }
        } else {
          // Just withdraw
          const colValue =
            (collateral - parseFloat(withdrawAmount)) * collateralPrice;
          const futureLTV = getCurrentLTV(colValue, debt);
          if (
            futureLTV * 1.01 > this.state.maxCollateralRatio / 100 ||
            colValue <= 0
          ) {
            fs = "withdrawTooMuch";
          } else {
            fs = "withdraw";
          }
        }
      }
    }

    console.log("Future action: " + fs);

    await this.setState({
      futureAction: fs,
    });
  }

  async setActionButtonText() {
    const futureAction = this.state.futureAction;

    var abt = "";
    var abtd = false;

    if (futureAction == "") {
      abt = "Enter an Amount";
      abtd = true;
    } else if (
      futureAction == "deposit" ||
      futureAction == "depositDepositor"
    ) {
      abt = "Deposit";
    } else if (futureAction == "depositAndBorrow") {
      abt = "Deposit and Multiply";
    } else if (futureAction == "insufficientFunds") {
      abt = "Insufficient Funds";
      abtd = true;
    } else if (futureAction == "borrowTooMuch") {
      abt = "Position too risky";
      abtd = true;
    } else if (futureAction == "borrow") {
      abt = "Multiply";
    } else if (futureAction == "withdrawTooMuch") {
      abt = "Withdrawing too much collateral";
      abtd = true;
    } else if (futureAction == "repayTooMuch") {
      abt = "Repayment too high";
      abtd = true;
    } else if (futureAction == "repayWallet") {
      abt = "Sign and Repay";
    } else if (futureAction == "repayWithdrawWallet") {
      abt = "Sign, Repay and Withdraw";
    } else if (futureAction == "repayCollateral") {
      abt = "Repay";
    } else if (futureAction == "repayWithdrawCollateral") {
      abt = "Repay and Withdraw";
    } else if (futureAction == "withdraw") {
      abt = "Withdraw";
    } else if (futureAction == "notEnoughUSDR") {
      abt = "Not enough USDR";
      abtd = true;
    } else if (futureAction == "notEnoughCollateral") {
      abt = "Not enough Collateral";
      abtd = true;
    }

    await this.setState({
      actionButtonDisabled: abtd,
      actionButtonText: abt,
    });
  }

  async resetInputValues() {
    await this.setState({
      amount: 0,
      amountInUSD: 0,
      borrowAmount: 0,
      withdrawAmount: 0,
      withdrawUSDAmount: 0,
      repayAmount: 0,
      repayUSDAmount: 0,
      withdrawBarValue: "0",
      selected: [
        {
          selectedAsset:
            config.borrowMultiply[this.getPool()].assets["asset1"].sym,
          selectedAssetIcon:
            config.borrowMultiply[this.getPool()].assets["asset1"].icon,
          selectedAssetAddress:
            config.borrowMultiply[this.getPool()].assets["asset1"].address,
          selectedAssetDecimals:
            config.borrowMultiply[this.getPool()].assets["asset1"].decimals,
        },
      ],
    });
    await this.getSelectedAssetBalance();
    await this.updateFutureActions();
  }

  async setFutureValues() {
    var {
      amount,
      borrowAmount,
      debt,
      selectedAssetPrice,
      repayPopup,
      repayAmount,
      repayUSDAmount,
      withdrawAmount,
      selectedRepayMethod,
      exitFee,
    } = this.state;

    var fCR = -1;
    var fATB = -1;
    var fC = -1;
    var fD = -1;
    var fLP = -1;

    if (!repayPopup) {
      if (
        amount != 0 &&
        amount != "" &&
        (borrowAmount == 0 || borrowAmount == "")
      ) {
        amount = parseFloat(amount);
        fC =
          this.state.collateral * this.state.collateralPrice +
          amount * selectedAssetPrice;
        fATB = Math.max(
          getAvailableToBorrow(
            fC,
            debt,
            this.state.maxCollateralRatio / 100,
            this.state.leftToBorrow
          ),
          0
        );
        fCR = getCurrentLTV(fC, debt);
      } else if (
        borrowAmount != 0 &&
        borrowAmount != "" &&
        (amount == 0 || amount == "")
      ) {
        borrowAmount = parseFloat(borrowAmount);
        var fee = (this.state.stabilityFee / 100) * borrowAmount;
        fD = debt + borrowAmount + fee;
        fC =
          this.state.collateral * this.state.collateralPrice +
          this.state.borrowWithSlippage;
        fATB = Math.max(
          getAvailableToBorrow(
            fC,
            fD,
            this.state.maxCollateralRatio / 100,
            this.state.leftToBorrow - borrowAmount
          ),
          0
        );
        fLP = getLiquidationPrice(
          fC / this.state.collateralPrice,
          fD,
          this.state.maxCollateralRatio / 100
        );
        fCR = getCurrentLTV(fC, fD);
      } else if (
        borrowAmount != 0 &&
        borrowAmount != "" &&
        amount != 0 &&
        amount != ""
      ) {
        amount = parseFloat(amount);
        borrowAmount = parseFloat(borrowAmount);
        fC =
          this.state.collateral * this.state.collateralPrice +
          amount * selectedAssetPrice +
          this.state.borrowWithSlippage;
        var fee = (this.state.stabilityFee / 100) * borrowAmount;
        fD = debt + borrowAmount + fee;
        fLP = getLiquidationPrice(
          fC / this.state.collateralPrice,
          fD,
          this.state.maxCollateralRatio / 100
        );
        fATB = Math.max(
          getAvailableToBorrow(
            fC,
            fD,
            this.state.maxCollateralRatio / 100,
            this.state.leftToBorrow - borrowAmount
          ),
          0
        );
        fCR = getCurrentLTV(fC, fD);
      }
    } else {
      if (
        (repayAmount == 0 || repayAmount == "") &&
        withdrawAmount != 0 &&
        withdrawAmount != ""
      ) {
        // Just withdraw
        withdrawAmount = parseFloat(withdrawAmount);
        fC = Math.max(
          (this.state.collateral - withdrawAmount) * this.state.collateralPrice,
          0
        );
        fATB = Math.max(
          getAvailableToBorrow(
            fC,
            debt,
            this.state.maxCollateralRatio / 100,
            this.state.leftToBorrow
          ),
          0
        );
        fCR = getCurrentLTV(fC, debt);
      } else if (
        repayAmount != 0 &&
        repayAmount != "" &&
        withdrawAmount != 0 &&
        withdrawAmount != ""
      ) {
        // Repay and withdraw
        withdrawAmount = parseFloat(withdrawAmount);
        repayAmount = parseFloat(repayAmount);
        if (selectedRepayMethod == "collateral") {
          fC = Math.max(
            (this.state.collateral - withdrawAmount - repayAmount) *
              this.state.collateralPrice,
            0
          );
          const repaymentAfterFee = repayUSDAmount / (1 + exitFee / 100);
          fD = Math.max(debt - repaymentAfterFee, 0);
        } else {
          fC = Math.max(
            (this.state.collateral - withdrawAmount) *
              this.state.collateralPrice,
            0
          );
          const repaymentAfterFee = repayAmount / (1 + exitFee / 100);
          fD = Math.max(debt - repaymentAfterFee, 0);
        }

        fATB = Math.max(
          getAvailableToBorrow(
            fC,
            fD,
            this.state.maxCollateralRatio / 100,
            this.state.leftToBorrow
          ),
          0
        );
        fCR = getCurrentLTV(fC, fD);
      } else if (
        (withdrawAmount == 0 || withdrawAmount == "") &&
        repayAmount != 0 &&
        repayAmount != ""
      ) {
        // Just repay
        repayAmount = parseFloat(repayAmount);
        if (selectedRepayMethod == "collateral") {
          fC = Math.max(
            (this.state.collateral - repayAmount) * this.state.collateralPrice,
            0
          );
          const repaymentAfterFee = repayUSDAmount / (1 + exitFee / 100);
          fD = Math.max(debt - repaymentAfterFee, 0);
        } else {
          fC = Math.max(this.state.collateral * this.state.collateralPrice, 0);
          const repaymentAfterFee = repayAmount / (1 + exitFee / 100);
          fD = Math.max(debt - repaymentAfterFee, 0);
        }

        fATB = Math.max(
          getAvailableToBorrow(
            fC,
            fD,
            this.state.maxCollateralRatio / 100,
            this.state.leftToBorrow
          ),
          0
        );
        fCR = getCurrentLTV(fC, fD);
      }
    }

    await this.setState({
      collateralizationRatioATM: fCR,
      availableToBorrowATM: fATB,
      collateralATM: fC,
      debtATM: fD,
      liquidationPriceATM: fLP,
    });
  }

  async updateFutureActions() {
    await this.setMaxBorrow();
    await this.setFutureAction();
    await this.setActionButtonText();
    await this.setFutureValues();
  }

  async setMaxBorrow() {
    var currentCollateralValue =
      this.state.collateral * this.state.collateralPrice +
      this.state.selectedAssetPrice * parseFloat(this.nullCheck(this.state.amount));
    const borrowFee = this.state.stabilityFee / 100;
    var currentBorrow = this.state.debt;

    const SLIPPAGE = getSlippage(window.localStorage);
    const swapPath =
      config.swappers[this.getBlockchain(this.props.onboard.networkId)][
        config.borrowMultiply[this.getPool()].swapper
      ].toCollateralMinAmounts;

    var borrowMax = 0;
    // Loop 15 times for saturation
    for (var i = 0; i < 15; i++) {
      var canBorrow = getAvailableToBorrow(
        currentCollateralValue,
        currentBorrow,
        this.state.maxCollateralRatio / 100,
        this.state.leftToBorrow
      );
      currentBorrow += canBorrow + borrowFee * canBorrow;
      var slippedUSDR = getMinUSDRAmount(swapPath.length, canBorrow, SLIPPAGE);
      currentCollateralValue += slippedUSDR;

      const futureLTV = getCurrentLTV(currentCollateralValue, currentBorrow);
      if (futureLTV * 1.01 > this.state.maxCollateralRatio / 100) {
        break;
      } else {
        borrowMax = currentBorrow - this.state.debt;
      }
    }

    console.log("Max borrow: " + borrowMax);

    await this.setState({
      maxBorrowMultiply: borrowMax,
      risk: borrowMax > 0 ? (this.state.borrowAmount / borrowMax) * 100 : 0,
    });
  }

  async setMaxWithdraw() {
    var mw = 0;

    if (
      this.state.debt <= 0.000001 ||
      (this.state.debtATM != -1 &&
        this.state.debtATM >= 0 &&
        this.state.debtATM <= 0.000001)
    ) {
      mw = this.state.collateral - DUST;
    } else {
      mw = Math.max(
        this.state.collateral -
          (this.state.selectedRepayMethod == "collateral" &&
          this.state.repayAmount != "" &&
          this.state.repayAmount != 0
            ? parseFloat(this.state.repayAmount)
            : 0) -
          (this.state.debt -
            (this.state.repayUSDAmount != 0 && this.state.repayUSDAmount != ""
              ? parseFloat(this.state.repayUSDAmount)
              : 0)) /
            (this.state.maxCollateralRatio / 100 - 0.01) /
            this.state.collateralPrice,
        0
      );
    }

    await this.setState({
      maxWithdrawCollateral: mw,
      withdrawBarValue: (this.state.withdrawAmount / mw) * 100,
    });
  }

  async setWithdrawBarValue(e) {
    e.preventDefault();
    var risk = e.target.value;
    await this.setState({
      withdrawAmount: ((risk / 100) * this.state.maxWithdrawCollateral).toFixed(
        2
      ),
      withdrawUSDAmount:
        (risk / 100) *
        this.state.maxWithdrawCollateral *
        this.state.collateralPrice,
      withdrawBarValue: risk,
    });
    await this.updateFutureActions();
  }

  setInfoCardsDisplayState = () => {
    if (this.state.displayInfoCards === true) {
      this.setState({
        displayInfoCards: false,
      });
    } else {
      this.setState({
        displayInfoCards: true,
      });
    }
  };

  getAssetIcon = (type) => {
    const icon = require(`../../assets/SVGs/assets/${type}`).default;
    return icon;
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  getPoolAddress = () => {
    const curentPath = window.location.pathname;
    const currentPoolAddress = curentPath.split("/multiply/").pop();
    return currentPoolAddress;
  };

  getPool = () => {
    const pools = Object.keys(config.borrowMultiply);
    for (var i = 0; i < pools.length; i++) {
      var currentPool = pools[i];
      if (config.borrowMultiply[currentPool].address == this.getPoolAddress())
        return currentPool;
    }
  };

  repayAmountField = async (e) => {
    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") return;
    }
    var value = e.target.value;
    await this.setState({
      repayAmount: value,
      repayUSDAmount:
        this.state.selectedRepayMethod === "wallet"
          ? value == ""
            ? 0.0
            : parseFloat(value)
          : (value == "" ? 0.0 : value) * this.state.collateralPrice,
      maxRepayFlag: false,
    });
    await this.updateFutureActions();
    await this.setMaxWithdraw();
  };

  withdrawAmountField = async (e) => {
    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") return;
    }
    var value = e.target.value;
    await this.setState({
      withdrawAmount: value,
      withdrawUSDAmount:
        (value == "" ? 0.0 : parseFloat(value)) * this.state.collateralPrice,
      withdrawBarValue:
        ((value == "" ? 0 : value) / this.state.maxWithdrawCollateral) * 100,
    });
    await this.updateFutureActions();
  };

  amountField = async (e) => {
    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") return;
    }
    var value = e.target.value;
    await this.setState({
      amount: value,
      amountInUSD: value * this.state.selectedAssetPrice,
    });
    await this.updateFutureActions();
  };

  amountFieldBorrow = async (e) => {
    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") return;
    }
    var value = e.target.value;
    await this.setState({
      borrowAmount: value,
      risk: Math.min(
        parseInt((value / this.state.availableToBorrow) * 100),
        100
      ),
    });
    await this.updateFutureActions();
  };

  assetDropdownClick = async (e, i) => {
    await e.preventDefault();
    await this.setState({
      selected: [
        {
          selectedAsset: config.borrowMultiply[this.getPool()].assets[i].sym,
          selectedAssetIcon:
            config.borrowMultiply[this.getPool()].assets[i].icon,
          selectedAssetAddress:
            config.borrowMultiply[this.getPool()].assets[i].address,
          selectedAssetDecimals:
            config.borrowMultiply[this.getPool()].assets[i].decimals,
        },
      ],
      dropdownAsset: false,
      amount: 0,
      amountInUSD: 0,
      borrowAmount: 0,
    });

    await this.getSelectedAssetBalance();
    await this.updateFutureActions();
  };

  async getSelectedAssetBalance() {
    await this.setState({ balanceLoading: true });

    const bl = this.getBlockchain(this.props.onboard.networkId);
    const pool = this.getPool();
    const rpc = config.networks[bl].endpoint;
    const userAddress = this.props.onboard.address;
    const tokenAddress = this.state.selected[0].selectedAssetAddress;
    const oracleAddress = config.LendingOracleAggregator[bl];

    var unscaledBalance;
    if (tokenAddress == "native") {
      unscaledBalance = await getNativeTokenBalance(rpc, userAddress);
    } else {
      unscaledBalance = await getTokenBalance(rpc, tokenAddress, userAddress);
    }
    const scaledBalance =
      unscaledBalance / 10 ** this.state.selected[0].selectedAssetDecimals;

    const price = await getOraclePrice(
      rpc,
      oracleAddress,
      tokenAddress == "native" ? config.nativeWrapped[bl] : tokenAddress
    );

    var spender;
    if (
      this.state.selected[0].selectedAsset ===
      config.borrowMultiply[pool].collateral.sym
    ) {
      spender = config.borrowMultiply[pool].address;
    } else {
      spender = config.borrowMultiply[pool].depositor;
      spender = config.depositors[bl][spender].address;
    }

    var allowance;
    if (tokenAddress == "native") {
      allowance = ethers.constants.MaxUint256;
    } else {
      allowance = await getTokenAllowance(
        rpc,
        tokenAddress,
        userAddress,
        spender
      );
      allowance /= 10 ** this.state.selected[0].selectedAssetDecimals;
    }

    await this.setState({
      balance: scaledBalance,
      balanceLoading: false,
      selectedAssetPrice: price,
      allowance: allowance,
    });
  }

  async fetchPoolMetadata() {
    try {
      const poolAddress = this.getPoolAddress();
      const bl = this.getBlockchain(this.props.onboard.networkId);
      const rpc = config.networks[bl].endpoint;

      var collateralPrice = await getOraclePrice(
        rpc,
        config.LendingOracleAggregator[bl],
        config.borrowMultiply[this.getPool()].collateral.address
      );

      await this.setState({
        collateralPrice: collateralPrice,
        collateralPriceLoading: false,
      });

      const max_ltv = await getMaxLTV(rpc, poolAddress);
      await this.setState({
        maxCollateralRatio: (max_ltv * 100).toFixed(2),
        maxCollateralRatioLoading: false,
      });

      const liq_inc = await getLiquidationIncentive(rpc, poolAddress);
      await this.setState({
        liquidationFee: (liq_inc * 100).toFixed(2),
        liquidationFeeLoading: false,
      });

      const entry_fee = await getEntryFee(rpc, poolAddress);
      await this.setState({
        stabilityFee: (entry_fee * 100).toFixed(2),
        stabilityFeeLoading: false,
      });

      const exit_fee = await getExitFee(rpc, poolAddress);
      await this.setState({
        exitFee: (exit_fee * 100).toFixed(2),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getUserData() {
    try {
      await this.setState({
        collateralLoading: true,
        debtLoading: true,
        availableToBorrowLoading: true,
        collateralizationRatioLoading: true,
        liquidationPriceLoading: true,
        positionHealthLoading: true,
      });
      const poolAddress = this.getPoolAddress();
      const rpc =
        config.networks[this.getBlockchain(this.props.onboard.networkId)]
          .endpoint;
      const userAddress = this.props.onboard.address;

      var userCollateral = await getUserCollateral(
        rpc,
        poolAddress,
        userAddress
      );
      userCollateral =
        userCollateral /
        10 ** config.borrowMultiply[this.getPool()].collateral.decimals;

      await this.setState({
        collateral: userCollateral,
        collateralLoading: false,
      });

      var userDebt = await getUserBorrow(rpc, poolAddress, userAddress);
      console.log("User debt: " + userDebt.toString());
      userDebt = userDebt / 10 ** 18;

      var leftToBorrow = await getLeftToBorrow(rpc, poolAddress);
      var atb = getAvailableToBorrow(
        userCollateral * this.state.collateralPrice,
        userDebt,
        this.state.maxCollateralRatio / 100,
        leftToBorrow.available
      );
      var ltv = getCurrentLTV(
        userCollateral * this.state.collateralPrice,
        userDebt
      );
      var liq_price = getLiquidationPrice(
        userCollateral,
        userDebt,
        this.state.maxCollateralRatio / 100
      );
      var positionHealth = getPositionHealth(
        ltv,
        this.state.maxCollateralRatio / 100
      );

      positionHealth = (positionHealth * 100).toFixed(2).toString() + "%";

      await this.setState({
        debt: userDebt,
        debtLoading: false,
        availableToBorrow: atb,
        availableToBorrowLoading: false,
        collateralizationRatio: ltv,
        collateralizationRatioLoading: false,
        liquidationPrice: liq_price,
        liquidationPriceLoading: false,
        positionHealth: positionHealth,
        positionHealthLoading: false,
        leftToBorrow: leftToBorrow.available,
      });

      var userUsdrBalance = await getTokenBalance(
        rpc,
        config.USDR[this.getBlockchain(this.props.onboard.networkId)],
        userAddress
      );
      userUsdrBalance = userUsdrBalance / 10 ** 18;
      await this.setState({
        userUsdrBalance: userUsdrBalance,
      });

      const maxRepayment = userDebt + (this.state.exitFee / 100) * userDebt;
      var maxRepaymentCollateral =
        (userDebt + (this.state.exitFee / 100) * userDebt) /
        this.state.collateralPrice;
      maxRepaymentCollateral *= 1.06; // Including slippage
      await this.setState({
        maxRepaymentWallet: Math.min(maxRepayment, userUsdrBalance),
        maxRepaymentWalletLoading: false,
        maxRepaymentCollateral: maxRepaymentCollateral,
        maxRepaymentCollateralLoading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    await this.setState({
      selected: [
        {
          selectedAsset:
            config.borrowMultiply[this.getPool()].assets["asset1"].sym,
          selectedAssetIcon:
            config.borrowMultiply[this.getPool()].assets["asset1"].icon,
          selectedAssetAddress:
            config.borrowMultiply[this.getPool()].assets["asset1"].address,
          selectedAssetDecimals:
            config.borrowMultiply[this.getPool()].assets["asset1"].decimals,
        },
      ],
    });
    await this.fetchPoolMetadata();
    await this.getUserData();
    await this.getSelectedAssetBalance();
    await this.updateFutureActions();
    await this.setMaxWithdraw();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
      await this.setState({
        selectedAsset:
          config.borrowMultiply[this.getPool()].assets["asset1"].sym,
        selectedAssetIcon:
          config.borrowMultiply[this.getPool()].assets["asset1"].icon,
        selectedAssetAddress:
          config.borrowMultiply[this.getPool()].assets["asset1"].address,
        selectedAssetDecimals:
          config.borrowMultiply[this.getPool()].assets["asset1"].decimals,
      });
      await this.fetchPoolMetadata();
      await this.getUserData();
      await this.getSelectedAssetBalance();
      await this.updateFutureActions();
      await this.setMaxWithdraw();
    }
  }

  async doAction() {
    const { balance, borrowAmount, futureAction } = this.state;

    if (
      futureAction == "" ||
      futureAction == "borrowTooMuch" ||
      futureAction == "insufficientFunds"
    ) {
      return;
    }

    await this.setState({
      loadingOverlay: true,
    });

    try {
      const pool = this.getPool();
      var scaledAmount;
      if (this.state.amount < DUST || this.state.amount == "") {
        scaledAmount = 0;
      } else {
        scaledAmount = scaleDecimals(
          parseFloat(this.state.amount),
          this.state.selected[0].selectedAssetDecimals
        );
      }

      var scaledBorrowAmount;
      if (this.state.borrowAmount < DUST || this.state.borrowAmount == "") {
        scaledBorrowAmount = 0;
      } else {
        scaledBorrowAmount = scaleDecimals(
          parseFloat(this.state.borrowAmount),
          18
        );
      }
      const poolAddress = this.getPoolAddress();
      const userAddress = this.props.onboard.address;
      const depositor = config.borrowMultiply[pool].depositor;
      const bl = this.getBlockchain(this.props.onboard.networkId);
      const rpc = config.networks[bl].endpoint;
      const usdrAddress = config.USDR[bl];
      const SLIPPAGE = getSlippage(window.localStorage);
      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );
      const signer = await provider.getSigner();

      if (futureAction == "borrow" || futureAction == "depositAndBorrow") {
        var depositAmount;
        if (futureAction == "depositAndBorrow") {
          depositAmount = scaledAmount;
        } else {
          depositAmount = 0;
        }
        const swapper = config.borrowMultiply[pool].swapper;
        const rpc = config.networks[bl].endpoint;
        const oracleAddress = config.LendingOracleAggregator[bl];

        const pathData = config.swappers[bl][swapper].toCollateralMinAmounts;

        const swapData = await getSwapData(
          rpc,
          pathData,
          oracleAddress,
          config.borrowMultiply[pool].collateral.address,
          config.borrowMultiply[pool].collateral.decimals,
          config.borrowMultiply[pool].collateral.underlying.address,
          config.borrowMultiply[pool].collateral.underlying.decimals,
          this.state.borrowAmount,
          SLIPPAGE
        );

        await lendingPairLeverage(
          signer,
          poolAddress,
          depositAmount,
          scaledBorrowAmount,
          swapData
        );
      } else if (futureAction == "deposit") {
        await lendingPairDeposit(
          signer,
          poolAddress,
          scaledAmount,
          userAddress
        );
      } else if (futureAction == "depositDepositor") {
        if (depositor == "BenqiDepositor") {
          // Special rules for plain AVAX
          await BenqiDepositorDeposit(
            signer,
            poolAddress,
            config.borrowMultiply[pool].assets.asset2.address,
            config.borrowMultiply[pool].collateral.address,
            scaledAmount,
            userAddress,
            config.depositors.AVAXC.BenqiDepositor.address
          );
        } else if (depositor == "CurveDepositor") {
          const curvePool =
            config.depositors[bl].CurveDepositor.curvePools[
              config.borrowMultiply[pool].collateral.sym
            ].address;
          const curveAssetId =
            config.depositors[bl].CurveDepositor.curvePools[
              config.borrowMultiply[pool].collateral.sym
            ].tokenIds[this.state.selected[0].selectedAsset];
          const underlying = this.state.selected[0].selectedAssetAddress;
          const curveLpAsset = config.borrowMultiply[pool].collateral.address;
          const depositorAddress = config.depositors[bl].CurveDepositor.address;

          if (config.borrowMultiply[pool].collateral.sym == "av3Crv") {
            const fullMint =
              (parseFloat(this.nullCheck(this.state.amount)) * this.state.selectedAssetPrice) /
              this.state.collateralPrice;
            const minMint = scaleDecimals(fullMint - SLIPPAGE * fullMint, 18);
            var amounts = [0, 0, 0];
            amounts[curveAssetId] = scaledAmount;

            const poolInterface = new ethers.utils.Interface([
              "function add_liquidity(uint256[3] memory _amounts, uint256 _min_mint_amount, bool _use_underlying) external returns (uint256)",
            ]);
            const curveAddLiquidityTX = poolInterface.encodeFunctionData(
              "add_liquidity",
              [amounts, minMint, true]
            );

            await CurveDepositorLPDeposit(
              signer,
              poolAddress,
              underlying,
              scaledAmount,
              userAddress,
              curvePool,
              curveLpAsset,
              curveAddLiquidityTX,
              depositorAddress
            );
          }
        }

        // TODO: when implementing repay action, make sure to remove the fee when calculating amount.
        // The input box is how much you will pay, the amount in the smart contract is how much of the loan will
        // be repaid (without the fee)
      } else if (futureAction == "withdraw") {
        const scaledWithdrawAmount = scaleDecimals(
          this.state.withdrawAmount,
          config.borrowMultiply[pool].collateral.decimals
        );
        await lendingPairWithdraw(
          signer,
          poolAddress,
          scaledWithdrawAmount,
          userAddress
        );
      } else if (futureAction == "repayWallet") {
        var scaledTransferAmount = scaleDecimals(this.state.repayAmount, 18);
        var scaledRepayAmount;
        if (!this.state.maxRepayFlag) {
          scaledRepayAmount = scaledTransferAmount
            .mul(10000)
            .div(10000 + this.state.exitFee * 100);
        } else {
          scaledRepayAmount = await getUserBorrow(
            rpc,
            poolAddress,
            userAddress
          );
          scaledTransferAmount = scaledRepayAmount.add(
            scaledRepayAmount.mul(10000).div(this.state.exitFee * 100)
          );
        }
        scaledTransferAmount = scaledTransferAmount.add(
          ethers.utils.parseEther("1")
        ); // For approve
        const sig = await erc2612Sign(
          signer,
          userAddress,
          usdrAddress,
          poolAddress,
          scaledTransferAmount
        );

        const abiCoder = new ethers.utils.AbiCoder();

        const permitData = abiCoder.encode(
          ["address", "address", "uint", "uint", "uint8", "bytes32", "bytes32"],
          [
            userAddress,
            poolAddress,
            scaledTransferAmount,
            sig.deadline,
            sig.v,
            sig.r,
            sig.s,
          ]
        );

        await lendingPairRepayWithPermit(
          signer,
          poolAddress,
          scaledRepayAmount,
          userAddress,
          permitData
        );
      } else if (futureAction == "repayWithdrawWallet") {
        var scaledTransferAmount = scaleDecimals(this.state.repayAmount, 18);
        var scaledRepayAmount;
        if (!this.state.maxRepayFlag) {
          scaledRepayAmount = scaledTransferAmount
            .mul(10000)
            .div(10000 + this.state.exitFee * 100);
        } else {
          scaledRepayAmount = await getUserBorrow(
            rpc,
            poolAddress,
            userAddress
          );
          scaledTransferAmount = scaledRepayAmount.add(
            scaledRepayAmount.mul(10000).div(this.state.exitFee * 100)
          );
        }
        scaledTransferAmount = scaledTransferAmount.add(
          ethers.utils.parseEther("1")
        ); // For approve
        const scaledWithdrawAmount = scaleDecimals(
          this.state.withdrawAmount,
          config.borrowMultiply[pool].collateral.decimals
        );
        const sig = await erc2612Sign(
          signer,
          userAddress,
          usdrAddress,
          poolAddress,
          scaledTransferAmount
        );

        const abiCoder = new ethers.utils.AbiCoder();

        const permitData = abiCoder.encode(
          ["address", "address", "uint", "uint", "uint8", "bytes32", "bytes32"],
          [
            userAddress,
            poolAddress,
            scaledTransferAmount,
            sig.deadline,
            sig.v,
            sig.r,
            sig.s,
          ]
        );

        await lendingPairRepayAndWithdrawWithPermit(
          signer,
          poolAddress,
          scaledRepayAmount,
          userAddress,
          scaledWithdrawAmount,
          userAddress,
          permitData
        );
      } else if (
        futureAction == "repayCollateral" ||
        futureAction == "repayWithdrawCollateral"
      ) {
        const swapper = config.borrowMultiply[pool].swapper;
        const rpc = config.networks[bl].endpoint;
        const minAmountsData = config.swappers[bl][swapper].toUSDRMinAmounts;
        const oracleAddress = config.LendingOracleAggregator[bl];
        const scaledRepayAmount = scaleDecimals(
          this.state.repayAmount,
          config.borrowMultiply[pool].collateral.decimals
        );

        const swapData = await getSwapData(
          rpc,
          minAmountsData,
          oracleAddress,
          config.borrowMultiply[pool].collateral.address,
          config.borrowMultiply[pool].collateral.decimals,
          config.borrowMultiply[pool].collateral.underlying.address,
          config.borrowMultiply[pool].collateral.underlying.decimals,
          this.state.repayUSDAmount,
          SLIPPAGE
        );

        console.log(swapData);

        await lendingPairDeLeverageWithPermit(
          signer,
          poolAddress,
          0,
          scaledRepayAmount,
          swapData,
          []
        );
      } else {
        alert("TODO: IMPLEMENT");
      }
      toastr.success("Success", "Transaction confirmed.");
    } catch (e) {
      console.log(e);
      var errorMessage;
      if (
        [
          "depositDepositor",
          "repayCollateral",
          "repayWithdrawCollateral",
          "borrow",
          "depositAndBorrow",
        ].includes(this.state.futureAction)
      ) {
        errorMessage = "Slippage too high";
      } else {
        errorMessage = e.message;
      }
      toastr.error("Error", "Error: " + errorMessage);
    }
    await this.setState({
      loadingOverlay: false,
      borrowAmount: 0,
      amount: 0,
      risk: "0",
      amountInUSD: 0,
      repayAmount: 0,
      repayUSDAmount: 0,
      withdrawAmount: 0,
      withdrawUSDAmount: 0,
      repayPopup: false,
      withdrawBarValue: "0",
      selected: [
        {
          selectedAsset:
            config.borrowMultiply[this.getPool()].assets["asset1"].sym,
          selectedAssetIcon:
            config.borrowMultiply[this.getPool()].assets["asset1"].icon,
          selectedAssetAddress:
            config.borrowMultiply[this.getPool()].assets["asset1"].address,
          selectedAssetDecimals:
            config.borrowMultiply[this.getPool()].assets["asset1"].decimals,
        },
      ],
    });
    await this.getUserData();
    await this.getSelectedAssetBalance();
    await this.updateFutureActions();
    await this.setMaxWithdraw();
  }

  async approve() {
    // Loading
    await this.setState({
      loadingOverlay: true,
    });
    try {
      var spender;
      const pool = this.getPool();
      if (
        this.state.selected[0].selectedAsset ===
        config.borrowMultiply[pool].collateral.sym
      ) {
        spender = config.borrowMultiply[pool].address;
      } else {
        spender = config.borrowMultiply[pool].depositor;
        spender =
          config.depositors[this.getBlockchain(this.props.onboard.networkId)][
            spender
          ].address;
      }
      const token = this.state.selected[0].selectedAssetAddress;

      const provider = new ethers.providers.Web3Provider(
        this.props.onboard.provider
      );

      const signer = await provider.getSigner();

      await approveToken(token, spender, signer, 0);
      toastr.success("Success", "Transaction confirmed.");
    } catch (e) {
      toastr.error("Error", "Error approving: " + e.message);
    }
    // Stop loading
    await this.getSelectedAssetBalance();
    await this.setState({
      loadingOverlay: false,
    });
  }

  renderAssetDropdown() {
    return (
      <div className={styles.poolAssetDropdown}>
        {Object.keys(config.borrowMultiply[this.getPool()].assets).map((i) => (
          <div
            className={styles.poolAssetDropdownRow}
            onClick={(e) => this.assetDropdownClick(e, i)}
          >
            <img
              src={this.getAssetIcon(
                config.borrowMultiply[this.getPool()].assets[i].icon
              )}
              alt="asset-icon"
              className={styles.poolAssetDropdownIcon}
            />
            <div className={`${styles.labelMRegular} ${styles.primaryText}`}>
              {config.borrowMultiply[this.getPool()].assets[i].sym}
            </div>
          </div>
        ))}
      </div>
    );
  }

  setRisk = async (e) => {
    var risk = e.target.value;
    // var extraBorrow = this.state.availableToBorrow;
    // if (this.state.amount != 0 && this.state.amount != "") {
    //   extraBorrow = getAvailableToBorrow(
    //     this.state.collateral * this.state.collateralPrice +
    //       this.state.amount * this.state.selectedAssetPrice,
    //     this.state.debt,
    //     this.state.maxCollateralRatio / 100,
    //     this.state.leftToBorrow
    //   );
    // }
    await this.setState({
      borrowAmount: ((parseInt(risk) * this.state.maxBorrowMultiply) / 100.01).toFixed(2),
      risk,
    });
    const SLIPPAGE = getSlippage(window.localStorage);
    const swapPath =
      config.swappers[this.getBlockchain(this.props.onboard.networkId)][
        config.borrowMultiply[this.getPool()].swapper
      ].toCollateralMinAmounts;
    const borrowWithSlippage = getMinUSDRAmount(
      swapPath.length,
      this.state.borrowAmount,
      SLIPPAGE
    );
    await this.setState({
      borrowWithSlippage,
    });
    await this.updateFutureActions();
  };

  render() {
    const i = this.getPool();

    const doNotDisplay = {
      display: "none",
    };

    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };

    return (
      <>
        {this.state.loadingOverlay === true && (
          <LoaderOverlay {...this.props} />
        )}
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Sidebar {...this.props} state={this.state} />
          {this.props.onboard.walletConnected === false ? (
            <WalletNotConnectedOverlay {...this.props} />
          ) : (
            <>
              {this.getBlockchain(this.props.onboard.networkId) !==
                config.borrowMultiply[i].network && (
                <SwitchNetworkOverlay
                  {...this.props}
                  primaryTextFromParent={
                    "This pool is not available on " +
                    config.networks[
                      this.getBlockchain(this.props.onboard.networkId)
                    ].name
                  }
                  secondaryTextFromParent="Please switch your wallet to the following network"
                  blockchainsFromParent={[config.borrowMultiply[i].network]}
                />
              )}
            </>
          )}
          <div
            className={styles.wrapper}
            style={
              this.getBlockchain(this.props.onboard.networkId) !==
                config.borrowMultiply[i].network ||
              this.state.loadingOverlay === true
                ? displayBlur
                : {}
            }
          >
            <div className={styles.content}>
              <div className={styles.headerSection}>
                <div className={`${styles.headingM} ${styles.primaryText}`}>
                  Multiply {config.borrowMultiply[i].collateral.sym}
                </div>
                <div className="pool-top-info-layout">
                  <div
                    className="pool-top-info-section"
                    onMouseEnter={() => {
                      this.setState({
                        tooltipClass: "max-col-tooltip",
                        currentTooltipInfo: 1,
                      });
                    }}
                    onMouseLeave={() => {
                      this.setState({ tooltipClass: "no-tooltip" });
                    }}
                  >
                    <div className={`${styles.labelSRegular}`}>
                      Maximum collateral ratio
                    </div>
                    {this.state.maxCollateralRatioLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"pool-top-info-value"}`}
                      >
                        {this.state.maxCollateralRatio}%
                      </div>
                    )}
                  </div>
                  <div className="pool-top-info-separator" />
                  <div
                    className="pool-top-info-section"
                    onMouseEnter={() => {
                      this.setState({
                        tooltipClass: "liq-fee-tooltip",
                        currentTooltipInfo: 2,
                      });
                    }}
                    onMouseLeave={() => {
                      this.setState({ tooltipClass: "no-tooltip" });
                    }}
                  >
                    <div className={`${styles.labelSRegular}`}>
                      Liquidation fee
                    </div>
                    {this.state.liquidationFeeLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"pool-top-info-value"}`}
                      >
                        {this.state.liquidationFee}%
                      </div>
                    )}
                  </div>
                  <div className="pool-top-info-separator" />
                  <div
                    className="pool-top-info-section"
                    onMouseEnter={() => {
                      this.setState({
                        tooltipClass: "stab-fee-tooltip",
                        currentTooltipInfo: 3,
                      });
                    }}
                    onMouseLeave={() => {
                      this.setState({ tooltipClass: "no-tooltip" });
                    }}
                  >
                    <div className={`${styles.labelSRegular}`}>
                      Entry fee
                    </div>
                    {this.state.stabilityFeeLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"pool-top-info-value"}`}
                      >
                        {this.state.stabilityFee}%
                      </div>
                    )}
                  </div>
                </div>
                {this.state.currentTooltipInfo == 1 && (
                  <div className={this.state.tooltipClass}>
                    <Tooltip
                      {...this.props}
                      textFromParent={config.tooltips[1].text}
                    />
                  </div>
                )}
                {this.state.currentTooltipInfo == 2 && (
                  <div className={this.state.tooltipClass}>
                    <Tooltip
                      {...this.props}
                      textFromParent={config.tooltips[2].text}
                    />
                  </div>
                )}
                {this.state.currentTooltipInfo == 3 && (
                  <div className={this.state.tooltipClass}>
                    <Tooltip
                      {...this.props}
                      textFromParent={config.tooltips[3].text}
                    />
                  </div>
                )}
              </div>
              {window.innerWidth > 1299 ? (
                <div className={styles.layout}>
                  <div
                    className={styles.leftSection}
                    style={
                      this.state.displayInfoCards === false ? doNotDisplay : {}
                    }
                  >
                    <div className={`${styles.cardFullDim} ${styles.whiteBg}`}>
                      <div className={`${styles.cardFullDimRow}`}>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => {
                            this.setState({
                              tooltipClass: "liq-price-tooltip",
                              currentTooltipInfo: 4,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({ tooltipClass: "no-tooltip" });
                          }}
                        >
                          Liquidation price
                        </div>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => {
                            this.setState({
                              tooltipClass: "asset-price-tooltip",
                              currentTooltipInfo: 5,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({ tooltipClass: "no-tooltip" });
                          }}
                        >
                          {config.borrowMultiply[this.getPool()].collateral.sym}{" "}
                          price
                        </div>
                      </div>
                      <div className={`${styles.cardFullDimRow}`}>
                        {this.state.liquidationPriceLoading === true ? (
                          <>{this.renderLoading()}</>
                        ) : (
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => {
                              this.setState({
                                tooltipClass: "liq-price-tooltip",
                                currentTooltipInfo: 4,
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({ tooltipClass: "no-tooltip" });
                            }}
                          >
                            ${this.state.liquidationPrice.toFixed(6)}
                          </div>
                        )}
                        {this.state.collateralPriceLoading === true ? (
                          <>{this.renderLoading()}</>
                        ) : (
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => {
                              this.setState({
                                tooltipClass: "asset-price-tooltip",
                                currentTooltipInfo: 5,
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({ tooltipClass: "no-tooltip" });
                            }}
                          >
                            ${this.state.collateralPrice}
                          </div>
                        )}
                      </div>
                      {this.state.positionHealthLoading === true ? (
                        <>{this.renderLoading()}</>
                      ) : (
                        <>
                          <div
                            className={`${styles.cardProgressBar}`}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => {
                              this.setState({
                                tooltipClass: "pos-health-tooltip",
                                currentTooltipInfo: 6,
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({ tooltipClass: "no-tooltip" });
                            }}
                          >
                            <div class="progress">
                              <div
                                class="progress-bar"
                                style={{ width: this.state.positionHealth }}
                              >
                                <span class="sr-only">60% Complete</span>
                              </div>
                            </div>
                          </div>
                          <div className={`${styles.cardFullDimRow}`}>
                            <div
                              className={`${styles.labelSBold} ${styles.secondaryText}`}
                              style={{ cursor: "pointer" }}
                              onMouseEnter={() => {
                                this.setState({
                                  tooltipClass: "pos-health-tooltip",
                                  currentTooltipInfo: 6,
                                });
                              }}
                              onMouseLeave={() => {
                                this.setState({ tooltipClass: "no-tooltip" });
                              }}
                            >
                              Position health
                            </div>
                            <div
                              className={`${styles.labelSBold} ${styles.secondaryText}`}
                              style={{ cursor: "pointer" }}
                              onMouseEnter={() => {
                                this.setState({
                                  tooltipClass: "pos-health-tooltip",
                                  currentTooltipInfo: 6,
                                });
                              }}
                              onMouseLeave={() => {
                                this.setState({ tooltipClass: "no-tooltip" });
                              }}
                            >
                              {this.state.positionHealth}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={styles.cardsRow}>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => {
                            this.setState({
                              tooltipClass: "small-card-tooltip",
                              currentTooltipInfo: 7,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({ tooltipClass: "no-tooltip" });
                          }}
                        >
                          Collateralization Ratio
                        </div>
                        <div
                          className={styles.cardValueSection}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => {
                            this.setState({
                              tooltipClass: "small-card-tooltip",
                              currentTooltipInfo: 7,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({ tooltipClass: "no-tooltip" });
                          }}
                        >
                          {this.state.collateralizationRatioLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {(
                                this.state.collateralizationRatio * 100
                              ).toFixed(2)}
                              %
                            </div>
                          )}
                        </div>
                        {this.state.currentTooltipInfo == 7 && (
                          <div className={this.state.tooltipClass}>
                            <Tooltip
                              {...this.props}
                              textFromParent={config.tooltips[7].text}
                            />
                          </div>
                        )}
                        {this.state.collateralizationRatioATM !== -1 &&
                          this.state.collateralizationRatioATM !==
                            this.state.collateralizationRatio &&
                          this.state.maxCollateralRatio && (
                            <>
                              {this.state.collateralizationRatioATM * 100 <=
                                0.5 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                >
                                  {(
                                    this.state.collateralizationRatio * 100
                                  ).toFixed(2)}
                                  %{" "}
                                  <img
                                    src={greenArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {(
                                    this.state.collateralizationRatioATM * 100
                                  ).toFixed(2)}
                                  %
                                </div>
                              )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.5 * this.state.maxCollateralRatio &&
                                this.state.collateralizationRatioATM * 100 <=
                                  0.8 * this.state.maxCollateralRatio && (
                                  <div
                                    class={`${styles.cardBullet} ${styles.warningBg} ${styles.warningText} ${styles.labelMBold}`}
                                  >
                                    {(
                                      this.state.collateralizationRatio * 100
                                    ).toFixed(2)}
                                    %{" "}
                                    <img
                                      src={orangeArrowIcon}
                                      alt="arrow-icon"
                                      className={styles.cardBulletArrowIcon}
                                    />
                                    {(
                                      this.state.collateralizationRatioATM * 100
                                    ).toFixed(2)}
                                    %
                                  </div>
                                )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.8 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.dangerBg} ${styles.dangerText} ${styles.labelMBold}`}
                                >
                                  {(
                                    this.state.collateralizationRatio * 100
                                  ).toFixed(2)}
                                  %{" "}
                                  <img
                                    src={redArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {(
                                    this.state.collateralizationRatioATM * 100
                                  ).toFixed(2)}
                                  %
                                </div>
                              )}
                            </>
                          )}
                      </div>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="borrow-header-sect">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => {
                              this.setState({
                                tooltipClass: "small-card-tooltip",
                                currentTooltipInfo: 8,
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({ tooltipClass: "no-tooltip" });
                            }}
                          >
                            Available to Borrow
                          </div>
                          {this.state.availableToBorrow > DUST && (
                            <div
                              className={`${"borrow-card-borrow-button"} ${
                                styles.labelMBold
                              } ${styles.gradientText}`}
                              onClick={async () => {
                                await this.setState({
                                  repayPopup: false,
                                });
                                this.resetInputValues();
                              }}
                            >
                              Borrow
                            </div>
                          )}
                        </div>
                        <div
                          className={styles.cardValueSection}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => {
                            this.setState({
                              tooltipClass: "small-card-tooltip",
                              currentTooltipInfo: 8,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({ tooltipClass: "no-tooltip" });
                          }}
                        >
                          {this.state.availableToBorrowLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.availableToBorrow.toFixed(2)} USDR
                            </div>
                          )}
                        </div>
                        {this.state.currentTooltipInfo == 8 && (
                          <div className={this.state.tooltipClass}>
                            <Tooltip
                              {...this.props}
                              textFromParent={config.tooltips[8].text}
                            />
                          </div>
                        )}
                        {this.state.availableToBorrowATM !== -1 &&
                          this.state.availableToBorrowATM !==
                            this.state.availableToBorrow && (
                            <>
                              {this.state.collateralizationRatioATM * 100 <=
                                0.5 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                  style={{ marginTop: "28px" }}
                                >
                                  {this.state.availableToBorrow.toFixed(2)}
                                  <img
                                    src={greenArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {this.state.availableToBorrowATM.toFixed(2)}{" "}
                                  USDR
                                </div>
                              )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.5 * this.state.maxCollateralRatio &&
                                this.state.collateralizationRatioATM * 100 <=
                                  0.8 * this.state.maxCollateralRatio && (
                                  <div
                                    class={`${styles.cardBullet} ${styles.warningBg} ${styles.warningText} ${styles.labelMBold}`}
                                    style={{ marginTop: "28px" }}
                                  >
                                    {this.state.availableToBorrow.toFixed(2)}
                                    <img
                                      src={orangeArrowIcon}
                                      alt="arrow-icon"
                                      className={styles.cardBulletArrowIcon}
                                    />
                                    {this.state.availableToBorrowATM.toFixed(2)}{" "}
                                    USDR
                                  </div>
                                )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.8 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.dangerBg} ${styles.dangerText} ${styles.labelMBold}`}
                                  style={{ marginTop: "28px" }}
                                >
                                  {this.state.availableToBorrow.toFixed(2)}
                                  <img
                                    src={redArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {this.state.availableToBorrowATM.toFixed(2)}{" "}
                                  USDR
                                </div>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                    <div className={styles.cardsRow}>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="borrow-header-sect">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => {
                              this.setState({
                                tooltipClass: "small-card-tooltip",
                                currentTooltipInfo: 9,
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({ tooltipClass: "no-tooltip" });
                            }}
                          >
                            Collateral
                          </div>
                          {this.state.collateral > DUST && (
                            <div
                              className={`${"borrow-card-withdraw-button"} ${
                                styles.labelMBold
                              } ${styles.gradientText}`}
                              onClick={async () => {
                                await this.setState({
                                  repayPopup: true,
                                  selectedRepayMethod: "",
                                });
                                this.resetInputValues();
                              }}
                            >
                              Withdraw
                            </div>
                          )}
                        </div>
                        <div
                          className={styles.cardValueSection}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => {
                            this.setState({
                              tooltipClass: "small-card-tooltip",
                              currentTooltipInfo: 9,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({ tooltipClass: "no-tooltip" });
                          }}
                        >
                          {this.state.collateralLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <>
                              <div
                                className={`${styles.headingS} ${styles.primaryText}`}
                              >
                                $
                                {(
                                  this.state.collateral *
                                  this.state.collateralPrice
                                ).toFixed(2)}
                              </div>
                              <div
                                className={`${styles.labelMRegular} ${styles.secondaryText}`}
                                style={{ marginLeft: "8px" }}
                              >
                                {this.state.collateral.toFixed(4)}{" "}
                                {
                                  config.borrowMultiply[this.getPool()]
                                    .collateral.sym
                                }
                              </div>
                            </>
                          )}
                        </div>
                        {this.state.currentTooltipInfo == 9 && (
                          <div className={this.state.tooltipClass}>
                            <Tooltip
                              {...this.props}
                              textFromParent={config.tooltips[9].text}
                            />
                          </div>
                        )}
                        {this.state.collateralATM !== -1 && (
                          <>
                            {this.state.collateralizationRatioATM * 100 <=
                              0.5 * this.state.maxCollateralRatio && (
                              <div
                                class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                style={{ marginTop: "28px" }}
                              >
                                $
                                {(
                                  this.state.collateral *
                                  this.state.collateralPrice
                                ).toFixed(2)}
                                <img
                                  src={greenArrowIcon}
                                  alt="arrow-icon"
                                  className={styles.cardBulletArrowIcon}
                                />
                                ${this.state.collateralATM.toFixed(2)}
                              </div>
                            )}
                            {this.state.collateralizationRatioATM * 100 >
                              0.5 * this.state.maxCollateralRatio &&
                              this.state.collateralizationRatioATM * 100 <=
                                0.8 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.warningBg} ${styles.warningText} ${styles.labelMBold}`}
                                  style={{ marginTop: "28px" }}
                                >
                                  $
                                  {(
                                    this.state.collateral *
                                    this.state.collateralPrice
                                  ).toFixed(2)}
                                  <img
                                    src={orangeArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  ${this.state.collateralATM.toFixed(2)}
                                </div>
                              )}
                            {this.state.collateralizationRatioATM * 100 >
                              0.8 * this.state.maxCollateralRatio && (
                              <div
                                class={`${styles.cardBullet} ${styles.dangerBg} ${styles.dangerText} ${styles.labelMBold}`}
                                style={{ marginTop: "28px" }}
                              >
                                $
                                {(
                                  this.state.collateral *
                                  this.state.collateralPrice
                                ).toFixed(2)}
                                <img
                                  src={redArrowIcon}
                                  alt="arrow-icon"
                                  className={styles.cardBulletArrowIcon}
                                />
                                ${this.state.collateralATM.toFixed(2)}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="borrow-header-sect">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => {
                              this.setState({
                                tooltipClass: "small-card-tooltip",
                                currentTooltipInfo: 10,
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({ tooltipClass: "no-tooltip" });
                            }}
                          >
                            USDR Debt
                          </div>
                          {this.state.debt > DUST && (
                            <div
                              className={`${"borrow-card-repay-button"} ${
                                styles.labelMBold
                              } ${styles.gradientText}`}
                              onClick={async () => {
                                await this.setState({
                                  repayPopup: true,
                                  selectedRepayMethod: "wallet",
                                });
                                this.resetInputValues();
                              }}
                            >
                              Repay
                            </div>
                          )}
                        </div>
                        <div
                          className={styles.cardValueSection}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => {
                            this.setState({
                              tooltipClass: "small-card-tooltip",
                              currentTooltipInfo: 10,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({ tooltipClass: "no-tooltip" });
                          }}
                        >
                          {this.state.availableToBorrowLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.debt.toFixed(2)} USDR
                            </div>
                          )}
                        </div>
                        {this.state.currentTooltipInfo == 10 && (
                          <div className={this.state.tooltipClass}>
                            <Tooltip
                              {...this.props}
                              textFromParent={config.tooltips[10].text}
                            />
                          </div>
                        )}
                        {this.state.debtATM !== -1 &&
                          this.state.debt !== this.state.debtATM && (
                            <>
                              {this.state.collateralizationRatioATM * 100 <=
                                0.5 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                  style={{ marginTop: "28px" }}
                                >
                                  {this.state.debt.toFixed(2)}
                                  <img
                                    src={greenArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {this.state.debtATM.toFixed(2)} USDR
                                </div>
                              )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.5 * this.state.maxCollateralRatio &&
                                this.state.collateralizationRatioATM * 100 <=
                                  0.8 * this.state.maxCollateralRatio && (
                                  <div
                                    class={`${styles.cardBullet} ${styles.warningBg} ${styles.warningText} ${styles.labelMBold}`}
                                    style={{ marginTop: "28px" }}
                                  >
                                    {this.state.debt.toFixed(2)}
                                    <img
                                      src={orangeArrowIcon}
                                      alt="arrow-icon"
                                      className={styles.cardBulletArrowIcon}
                                    />
                                    {this.state.debtATM.toFixed(2)} USDR
                                  </div>
                                )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.8 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.dangerBg} ${styles.dangerText} ${styles.labelMBold}`}
                                  style={{ marginTop: "28px" }}
                                >
                                  {this.state.debt.toFixed(2)}
                                  <img
                                    src={redArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {this.state.debtATM.toFixed(2)} USDR
                                </div>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                    {this.state.currentTooltipInfo == 4 && (
                      <div className={this.state.tooltipClass}>
                        <Tooltip
                          {...this.props}
                          textFromParent={config.tooltips[4].text}
                        />
                      </div>
                    )}
                    {this.state.currentTooltipInfo == 5 && (
                      <div className={this.state.tooltipClass}>
                        <Tooltip
                          {...this.props}
                          textFromParent={config.tooltips[5].text}
                        />
                      </div>
                    )}
                    {this.state.currentTooltipInfo == 6 && (
                      <div className={this.state.tooltipClass}>
                        <Tooltip
                          {...this.props}
                          textFromParent={config.tooltips[6].text}
                        />
                      </div>
                    )}
                  </div>
                  {this.state.repayPopup === false ? (
                    <div className={styles.rightSection}>
                      {this.state.selected.length > 0 ? (
                        <>
                          <div className={styles.inputMenu}>
                            <div
                              className={`${styles.inputMenuItem} ${styles.greyBg} ${styles.labelMBold} ${styles.primaryText}`}
                              onClick={() =>
                                this.toPage("/borrow", this.getPoolAddress(), {
                                  i,
                                })
                              }
                            >
                              Borrow
                            </div>
                            <div
                              className={`${styles.inputMenuItem} ${styles.whiteBg}`}
                              style={{ borderRadius: "0px 8px 0px 0px" }}
                            >
                              <div
                                className={`${styles.labelMBold} ${styles.gradientText}`}
                              >
                                Multiply
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${styles.inputCard} ${styles.whiteBg}`}
                          >
                            <div className={styles.inputCardHeader}>
                              <div
                                className={styles.inputCardIcon}
                                onClick={() => this.setInfoCardsDisplayState()}
                              >
                                <GradientInterfaceIcon />
                              </div>
                              <div
                                className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                              >
                                Multiply your exposure
                              </div>
                            </div>
                            <div className={styles.inputHeader}>
                              <div
                                className={`${styles.labelSRegular} ${styles.primaryText}`}
                              >
                                Amount
                              </div>
                              <div
                                className={
                                  this.state.balance === 0
                                    ? styles.inputBalanceSection
                                    : styles.inputBalanceSectionGrad
                                }
                                onClick={async () => {
                                  await this.setState({
                                    amount: this.state.balance,
                                    amountInUSD:
                                      this.state.balance *
                                      this.state.selectedAssetPrice,
                                  });

                                  await this.updateFutureActions();
                                }}
                              >
                                <div
                                  className={`${styles.labelSRegular} ${
                                    styles.secondaryText
                                  } ${"pool-page-balance-button"}`}
                                >
                                  Balance
                                </div>
                                {this.state.balanceLoading === true ? (
                                  <>{this.renderLoading()}</>
                                ) : (
                                  <div
                                    className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
                                  >
                                    {this.state.balance.toFixed(4)}{" "}
                                    {this.state.selected[0].selectedAsset}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className={`${styles.inputBox} ${styles.flexInputBox}`}
                            >
                              <div>
                                <input
                                  type="text"
                                  id="amount"
                                  name="amount"
                                  placeholder="0.00"
                                  value={this.state.amount}
                                  onChange={(e) => this.amountField(e)}
                                  style={{
                                    fontSize: "21px",
                                    lineHeight: "32px",
                                  }}
                                />
                                {this.state.amountInUSDLoading === true ? (
                                  <>{this.renderLoading()}</>
                                ) : (
                                  <div
                                    className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                  >
                                    ${this.state.amountInUSD.toFixed(2)}
                                  </div>
                                )}
                              </div>
                              {this.state.dropdownAsset === false ||
                              Object.keys(
                                config.borrowMultiply[this.getPool()].assets
                              ).length === 1 ? (
                                <div
                                  className={`${styles.button} ${styles.dropdownButton} ${styles.bg}`}
                                  onClick={() =>
                                    this.setState({
                                      dropdownAsset: true,
                                    })
                                  }
                                >
                                  <img
                                    src={this.getAssetIcon(
                                      this.state.selected[0].selectedAssetIcon
                                    )}
                                    alt="asset-icon"
                                    className={styles.dropdownAssetIcon}
                                  />
                                  <div
                                    className={`${styles.labelMRegular} ${styles.primaryText} ${styles.bg}`}
                                  >
                                    {this.state.selected[0].selectedAsset}
                                  </div>
                                  <CaretDownIcon />
                                </div>
                              ) : (
                                <>
                                  <div
                                    className={`${styles.button} ${styles.dropdownButton} ${styles.bg}`}
                                    onClick={() =>
                                      this.setState({
                                        dropdownAsset: false,
                                      })
                                    }
                                  >
                                    <img
                                      src={this.getAssetIcon(
                                        this.state.selected[0].selectedAssetIcon
                                      )}
                                      alt="asset-icon"
                                      className={styles.dropdownAssetIcon}
                                    />
                                    <div
                                      className={`${styles.labelMRegular} ${styles.primaryText} ${styles.bg}`}
                                    >
                                      {this.state.selected[0].selectedAsset}
                                    </div>
                                    <CaretUpIcon />
                                  </div>
                                  {this.renderAssetDropdown()}
                                </>
                              )}
                            </div>
                            {this.state.selected[0].selectedAsset ===
                              config.borrowMultiply[this.getPool()].collateral
                                .sym && (
                              <>
                                <div className={styles.inputHeader}>
                                  <div
                                    className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                  >
                                    Liquidation Price
                                  </div>
                                  <div
                                    className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                  >
                                    Collateralization Ratio
                                  </div>
                                </div>
                                <div
                                  className={styles.inputHeader}
                                  style={{ paddingTop: "0px" }}
                                >
                                  {this.state.liquidationPriceLoading ===
                                  true ? (
                                    <>{this.renderLoading()}</>
                                  ) : (
                                    <div
                                      className={`${styles.headingXS} ${styles.primaryText}`}
                                    >
                                      $
                                      {this.state.borrowAmount > 0
                                        ? this.state.liquidationPriceATM.toFixed(
                                            4
                                          )
                                        : 0.0}
                                    </div>
                                  )}
                                  {this.state.collateralizationRatioLoading ===
                                  true ? (
                                    <>{this.renderLoading()}</>
                                  ) : (
                                    <div
                                      className={`${styles.headingXS} ${styles.primaryText}`}
                                    >
                                      {this.state.borrowAmount > 0
                                        ? (
                                            this.state
                                              .collateralizationRatioATM * 100
                                          ).toFixed(2)
                                        : 0.0}
                                      %
                                    </div>
                                  )}
                                </div>
                                <div className={styles.rangeInput}>
                                  <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    onChange={(e) => this.setRisk(e)}
                                    value={this.state.risk}
                                  />
                                </div>
                                <div className={styles.inputHeader}>
                                  <div
                                    className={`${styles.labelSRegular} ${styles.primaryText}`}
                                  >
                                    Decrease risk
                                  </div>
                                  <div className={styles.inputBalanceSection}>
                                    <div
                                      className={`${styles.labelSRegular} ${styles.primaryText}`}
                                    >
                                      Increase risk
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {(this.state.amount === 0 ||
                              this.state.amount === "") &&
                            (this.state.borrowAmount === 0 ||
                              this.state.borrowAmount === "") ? (
                              <div
                                className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                              >
                                Enter an amount
                              </div>
                            ) : (
                              <>
                                {this.state.allowance <
                                parseFloat(this.nullCheck(this.state.amount)) ? (
                                  <div
                                    className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                                    onClick={() => this.approve()}
                                  >
                                    Approve
                                  </div>
                                ) : (
                                  <div
                                    className={`${styles.button} ${
                                      this.state.actionButtonDisabled
                                        ? styles.greyBg
                                        : styles.gradientBg
                                    } ${styles.labelMBold} ${
                                      this.state.actionButtonDisabled
                                        ? styles.greyText
                                        : styles.whiteText
                                    } ${styles.inputButton}`}
                                    onClick={() => this.doAction()}
                                  >
                                    {this.state.actionButtonText}
                                  </div>
                                )}
                                <div className={styles.orderDetailsSection}>
                                  <div
                                    className={`${styles.labelMBold} ${styles.primaryText}`}
                                  >
                                    Order details
                                  </div>
                                  <div className={styles.orderDetailsRow}></div>
                                  {this.state.amount != "" &&
                                    this.state.amount != 0 && (
                                      <>
                                        <div className={styles.orderDetailsRow}>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                          >
                                            Direct Deposit
                                          </div>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                          >
                                            {this.state.amount}{" "}
                                            {
                                              this.state.selected[0]
                                                .selectedAsset
                                            }{" "}
                                            ($
                                            {(
                                              this.nullCheck(this.state.amount) *
                                              this.state.selectedAssetPrice
                                            ).toFixed(2)}
                                            )
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  {this.state.borrowAmount != "" &&
                                    this.state.borrowAmount != 0 && (
                                      <>
                                        <div className={styles.orderDetailsRow}>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                          >
                                            Borrow & Swap USDR
                                          </div>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                          >
                                            {this.state.borrowAmount} USDR ($
                                            {this.state.borrowAmount})
                                          </div>
                                        </div>

                                        <div className={styles.orderDetailsRow}>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                          >
                                            Deposit*
                                          </div>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                          >
                                            {(
                                              this.state.borrowWithSlippage /
                                              this.state.collateralPrice
                                            ).toFixed(2)}{" "}
                                            {
                                              config.borrowMultiply[
                                                this.getPool()
                                              ].collateral.sym
                                            }{" "}
                                            ($
                                            {this.state.borrowWithSlippage.toFixed(
                                              2
                                            )}
                                            )
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  <div className={styles.orderDetailsRow}>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                    >
                                      Fees
                                    </div>
                                    <div className={styles.orderDetailsFeesRow}>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                      >
                                        {(
                                          this.state.borrowAmount *
                                          (this.state.stabilityFee / 100)
                                        ).toFixed(2)}{" "}
                                        USDR ($
                                        {(
                                          this.state.borrowAmount *
                                          (this.state.stabilityFee / 100)
                                        ).toFixed(2)}
                                        )
                                      </div>
                                      {this.state.showFees === true ? (
                                        <div
                                          className={
                                            styles.orderDetailsFeesIcon
                                          }
                                          onClick={() =>
                                            this.setState({
                                              showFees: false,
                                            })
                                          }
                                        >
                                          <CaretUpIcon />
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            styles.orderDetailsFeesIcon
                                          }
                                          onClick={() =>
                                            this.setState({
                                              showFees: true,
                                            })
                                          }
                                        >
                                          <CaretDownIcon />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.showFees === true && (
                                    <>
                                      {this.state.amount != "" &&
                                        this.state.amount != 0 && (
                                          <>
                                            <div
                                              className={
                                                styles.orderDetailsSubRow
                                              }
                                            >
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                              >
                                                Deposit fee (0%)
                                              </div>
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                              >
                                                0.00{" "}
                                                {
                                                  this.state.selected[0]
                                                    .selectedAsset
                                                }{" "}
                                                ($0.00)
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      {this.state.borrowAmount != "" &&
                                        this.state.borrowAmount != 0 && (
                                          <>
                                            <div
                                              className={
                                                styles.orderDetailsSubRow
                                              }
                                            >
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                              >
                                                Borrow fee (
                                                {this.state.stabilityFee}%)
                                              </div>
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                              >
                                                {(
                                                  this.state.borrowAmount *
                                                  (this.state.stabilityFee /
                                                    100)
                                                ).toFixed(2)}{" "}
                                                USDR ($
                                                {(
                                                  this.state.borrowAmount *
                                                  (this.state.stabilityFee /
                                                    100)
                                                ).toFixed(2)}
                                                )
                                              </div>
                                            </div>
                                          </>
                                        )}
                                    </>
                                  )}

                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    * Amounts may vary depending on slippage
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    <div className={styles.rightSection}>
                      <div
                        className={`${styles.inputCard} ${styles.whiteBg}`}
                        style={{ borderRadius: "8px", borderTop: "none" }}
                      >
                        <div className={styles.inputCardHeader}>
                          <div
                            className={styles.inputCardIcon}
                            onClick={async () => {
                              await this.setState({
                                repayPopup: false,
                              });
                              this.resetInputValues();
                            }}
                          >
                            <CloseIcon />
                          </div>
                          <div
                            className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                          >
                            Repay and Withdraw
                          </div>
                        </div>
                        <div className="borrow-repay-popup-content">
                          {this.state.debt > DUST && (
                            <>
                              {this.state.selectedRepayMethod === "wallet" ? (
                                <>
                                  <div className="borrow-repay-method-box">
                                    <div className="borrow-repay-method-box-header">
                                      <div className="borrow-repay-method-box-header-left-sect">
                                        <div
                                          className="borrow-repay-selected-method-circle"
                                          onClick={async (e) => {
                                            await this.setState({
                                              selectedRepayMethod: "",
                                              repayAmount: 0,
                                              repayUSDAmount: 0,
                                              withdrawAmount: 0,
                                              withdrawUSDAmount: 0,
                                              withdrawBarValue: "0",
                                            });

                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        />
                                        <div
                                          className={`${styles.labelMBold} ${styles.primaryText}`}
                                        >
                                          Repay from wallet
                                        </div>
                                      </div>
                                      {this.state.maxRepaymentWalletLoading ===
                                      true ? (
                                        <>{this.renderLoading()}</>
                                      ) : (
                                        <div
                                          className={`${styles.labelSBold} ${
                                            styles.secondaryText
                                          } ${"pool-page-balance-button"}`}
                                          onClick={async () => {
                                            await this.setState({
                                              repayAmount:
                                                this.state.maxRepaymentWallet -
                                                0.00000000000001,
                                              repayUSDAmount:
                                                this.state.maxRepaymentWallet -
                                                0.00000000000001,
                                              maxRepayFlag: true,
                                              selectedRepayMethod: "wallet",
                                            });
                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        >
                                          Max{" "}
                                          {this.state.maxRepaymentWallet.toFixed(
                                            2
                                          )}{" "}
                                          USDR
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={`${styles.inputBox} ${styles.whiteBg}`}
                                      style={{ marginTop: "16px" }}
                                    >
                                      <input
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder="0.00"
                                        value={this.state.repayAmount}
                                        onChange={(e) =>
                                          this.repayAmountField(e)
                                        }
                                        style={{
                                          fontSize: "21px",
                                          lineHeight: "32px",
                                        }}
                                      />
                                      {/* {this.state.Loading === true ? (
                                    <>{this.renderLoading()}</>
                                  ) : ( */}
                                      <div
                                        className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                      >
                                        ${this.state.repayUSDAmount.toFixed(2)}
                                      </div>
                                      {/* )} */}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="borrow-repay-method-box">
                                    <div className="borrow-repay-method-box-header">
                                      <div className="borrow-repay-method-box-header-left-sect">
                                        <div
                                          className="borrow-repay-not-selected-method-circle"
                                          onClick={async () => {
                                            await this.setState({
                                              selectedRepayMethod: "wallet",
                                              repayAmount: 0,
                                              repayUSDAmount: 0,
                                            });
                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        />
                                        <div
                                          className={`${styles.labelMBold} ${styles.primaryText}`}
                                          style={{ marginLeft: "0px" }}
                                        >
                                          Repay from wallet
                                        </div>
                                      </div>
                                      {this.state.maxRepaymentWalletLoading ===
                                      true ? (
                                        <>{this.renderLoading()}</>
                                      ) : (
                                        <div
                                          className={`${styles.labelSBold} ${
                                            styles.secondaryText
                                          } ${"pool-page-balance-button"}`}
                                          onClick={async () => {
                                            await this.setState({
                                              repayAmount:
                                                this.state.maxRepaymentWallet -
                                                0.00000000000001,
                                              repayUSDAmount:
                                                this.state.maxRepaymentWallet -
                                                0.00000000000001,
                                              maxRepayFlag: true,
                                              selectedRepayMethod: "wallet",
                                            });
                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        >
                                          Max{" "}
                                          {this.state.maxRepaymentWallet.toFixed(
                                            2
                                          )}{" "}
                                          USDR
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}

                              {this.state.selectedRepayMethod ===
                              "collateral" ? (
                                <>
                                  <div
                                    className="borrow-repay-method-box"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="borrow-repay-method-box-header">
                                      <div className="borrow-repay-method-box-header-left-sect">
                                        <div
                                          className="borrow-repay-selected-method-circle"
                                          onClick={async (e) => {
                                            await this.setState({
                                              selectedRepayMethod: "",
                                              repayAmount: 0,
                                              repayUSDAmount: 0,
                                              withdrawAmount: 0,
                                              withdrawUSDAmount: 0,
                                              withdrawBarValue: "0",
                                            });

                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        />
                                        <div
                                          className={`${styles.labelMBold} ${styles.primaryText}`}
                                        >
                                          Repay from collateral
                                        </div>
                                      </div>

                                      {this.state
                                        .maxRepaymentCollateralLoading ===
                                      true ? (
                                        <>{this.renderLoading()}</>
                                      ) : (
                                        <div
                                          className={`${styles.labelSBold} ${
                                            styles.secondaryText
                                          } ${"pool-page-balance-button"}`}
                                          onClick={async () => {
                                            await this.setState({
                                              repayAmount:
                                                this.state
                                                  .maxRepaymentCollateral,
                                              repayUSDAmount:
                                                this.state
                                                  .maxRepaymentCollateral *
                                                this.state.collateralPrice,
                                              selectedRepayMethod: "collateral",
                                              withdrawAmount: 0,
                                              withdrawUSDAmount: 0,
                                              withdrawBarValue: "0",
                                            });
                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        >
                                          Max{" "}
                                          {this.state.maxRepaymentCollateral.toFixed(
                                            2
                                          )}{" "}
                                          {
                                            config.borrowMultiply[
                                              this.getPool()
                                            ].collateral.sym
                                          }
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={`${styles.inputBox} ${styles.whiteBg}`}
                                      style={{ marginTop: "16px" }}
                                    >
                                      <input
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder="0.00"
                                        value={this.state.repayAmount}
                                        onChange={(e) =>
                                          this.repayAmountField(e)
                                        }
                                        style={{
                                          fontSize: "21px",
                                          lineHeight: "32px",
                                        }}
                                      />
                                      {/* {this.state.Loading === true ? (
                                  <>{this.renderLoading()}</>
                                ) : ( */}
                                      <div
                                        className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                      >
                                        ${this.state.repayUSDAmount.toFixed(2)}
                                      </div>
                                      {/* )} */}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="borrow-repay-method-box"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="borrow-repay-method-box-header">
                                      <div className="borrow-repay-method-box-header-left-sect">
                                        <div
                                          className="borrow-repay-not-selected-method-circle"
                                          onClick={async () => {
                                            await this.setState({
                                              selectedRepayMethod: "collateral",
                                              repayAmount: 0,
                                              repayUSDAmount: 0,
                                              withdrawAmount: 0,
                                              withdrawUSDAmount: 0,
                                              withdrawBarValue: "0",
                                            });
                                            this.updateFutureActions();
                                          }}
                                        />
                                        <div
                                          className={`${styles.labelMBold} ${styles.primaryText}`}
                                        >
                                          Repay from collateral
                                        </div>
                                      </div>

                                      {this.state
                                        .maxRepaymentCollateralLoading ===
                                      true ? (
                                        <>{this.renderLoading()}</>
                                      ) : (
                                        <div
                                          className={`${styles.labelSBold} ${
                                            styles.secondaryText
                                          } ${"pool-page-balance-button"}`}
                                          onClick={async () => {
                                            await this.setState({
                                              repayAmount:
                                                this.state
                                                  .maxRepaymentCollateral,
                                              repayUSDAmount:
                                                this.state
                                                  .maxRepaymentCollateral *
                                                this.state.collateralPrice,
                                              selectedRepayMethod: "collateral",
                                              withdrawAmount: 0,
                                              withdrawUSDAmount: 0,
                                              withdrawBarValue: "0",
                                            });
                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        >
                                          Max{" "}
                                          {this.state.maxRepaymentCollateral.toFixed(
                                            2
                                          )}{" "}
                                          {
                                            config.borrowMultiply[
                                              this.getPool()
                                            ].collateral.sym
                                          }
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}

                          {/* OLD START HERE */}

                          {/* OLD END HERE */}
                          {this.state.selectedRepayMethod != "collateral" && (
                            <>
                              <div
                                className={`${styles.labelMBold} ${styles.primaryText}`}
                                style={{ marginTop: "24px" }}
                              >
                                Withdraw collateral
                              </div>
                              <div className={styles.inputHeader}>
                                <div
                                  className={`${styles.labelSRegular} ${styles.primaryText}`}
                                >
                                  Select amount
                                </div>
                                <div className={styles.inputBalanceSection}>
                                  <div
                                    className={`${styles.labelSRegular} ${
                                      styles.primaryText
                                    } ${"pool-page-balance-button"}`}
                                    onClick={async () => {
                                      await this.setState({
                                        withdrawAmount:
                                          this.state.maxWithdrawCollateral -
                                          0.00000000000001,
                                        withdrawUSDAmount:
                                          (this.state.maxWithdrawCollateral -
                                            0.00000000000001) *
                                          this.state.collateralPrice,
                                        withdrawBarValue: "100",
                                      });
                                      this.updateFutureActions();
                                    }}
                                  >
                                    Max{" "}
                                    {this.state.maxWithdrawCollateral.toFixed(
                                      2
                                    )}{" "}
                                    {
                                      config.borrowMultiply[this.getPool()]
                                        .collateral.sym
                                    }
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${styles.inputBox} ${styles.whiteBg}`}
                              >
                                <input
                                  type="text"
                                  id="amount"
                                  name="amount"
                                  placeholder="0.00"
                                  value={this.state.withdrawAmount}
                                  onChange={(e) => this.withdrawAmountField(e)}
                                  style={{
                                    fontSize: "21px",
                                    lineHeight: "32px",
                                  }}
                                />
                                {/* {this.state.Loading === true ? (
                                    <>{this.renderLoading()}</>
                                  ) : ( */}
                                <div
                                  className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                >
                                  ${this.state.withdrawUSDAmount}
                                </div>
                                {/* )} */}
                              </div>
                              <div className={styles.rangeInput}>
                                <input
                                  type="range"
                                  min="0"
                                  max="100"
                                  onChange={(e) => this.setWithdrawBarValue(e)}
                                  value={this.state.withdrawBarValue}
                                />
                              </div>
                              <div className={styles.inputHeader}>
                                <div
                                  className={`${styles.labelSRegular} ${styles.primaryText}`}
                                >
                                  0%
                                </div>
                                <div className={styles.inputBalanceSection}>
                                  <div
                                    className={`${styles.labelSRegular} ${styles.primaryText}`}
                                  >
                                    100%
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div
                            className={`${styles.button} ${
                              this.state.actionButtonDisabled
                                ? styles.greyBg
                                : styles.gradientBg
                            } ${styles.labelMBold} ${
                              this.state.actionButtonDisabled
                                ? styles.greyText
                                : styles.whiteText
                            } ${styles.inputButton}`}
                            onClick={() => this.doAction()}
                          >
                            {this.state.actionButtonText}
                          </div>
                          {!this.state.actionButtonDisabled && (
                            <div className={styles.orderDetailsSection}>
                              <div
                                className={`${styles.labelMBold} ${styles.primaryText}`}
                              >
                                Order details
                              </div>
                              {this.state.selectedRepayMethod == "collateral" &&
                                this.state.repayAmount > 0 && (
                                  <div className={styles.orderDetailsRow}>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                    >
                                      Swap Collateral to USDR
                                      {this.state.selectedRepayMethod ==
                                        "collateral" &&
                                      this.state.repayAmount > 0
                                        ? "*"
                                        : ""}
                                    </div>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                    >
                                      {parseFloat(
                                        this.state.repayAmount
                                      ).toFixed(2)}{" "}
                                      {
                                        config.borrowMultiply[this.getPool()]
                                          .collateral.sym
                                      }{" "}
                                      ($
                                      {parseFloat(
                                        this.state.repayUSDAmount
                                      ).toFixed(2)}
                                      )
                                    </div>
                                  </div>
                                )}
                              {this.state.repayAmount > 0 && (
                                <div className={styles.orderDetailsRow}>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                  >
                                    Repay
                                    {this.state.selectedRepayMethod ==
                                      "collateral" && this.state.repayAmount > 0
                                      ? "*"
                                      : ""}
                                  </div>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    {(
                                      this.state.repayUSDAmount /
                                      (1 + this.state.exitFee / 100)
                                    ).toFixed(2)}{" "}
                                    USDR ($
                                    {(
                                      this.state.repayUSDAmount /
                                      (1 + this.state.exitFee / 100)
                                    ).toFixed(2)}
                                    )
                                  </div>
                                </div>
                              )}
                              {this.state.withdrawAmount > 0 && (
                                <div className={styles.orderDetailsRow}>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                  >
                                    Withdraw & Receive
                                  </div>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    {this.state.withdrawAmount}{" "}
                                    {
                                      config.borrowMultiply[this.getPool()]
                                        .collateral.sym
                                    }{" "}
                                    ($
                                    {(
                                      this.state.withdrawAmount *
                                      this.state.collateralPrice
                                    ).toFixed(2)}
                                    )
                                  </div>
                                </div>
                              )}
                              <div className={styles.orderDetailsRow}>
                                <div
                                  className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                >
                                  Fees
                                </div>
                                <div className={styles.orderDetailsFeesRow}>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    {(
                                      this.state.repayUSDAmount -
                                      this.state.repayUSDAmount /
                                        (1 + this.state.exitFee / 100)
                                    ).toFixed(2)}{" "}
                                    USDR ($
                                    {(
                                      this.state.repayUSDAmount -
                                      this.state.repayUSDAmount /
                                        (1 + this.state.exitFee / 100)
                                    ).toFixed(2)}
                                    )
                                  </div>
                                  {this.state.showFees === true ? (
                                    <div
                                      className={styles.orderDetailsFeesIcon}
                                      onClick={() =>
                                        this.setState({
                                          showFees: false,
                                        })
                                      }
                                    >
                                      <CaretUpIcon />
                                    </div>
                                  ) : (
                                    <div
                                      className={styles.orderDetailsFeesIcon}
                                      onClick={() =>
                                        this.setState({
                                          showFees: true,
                                        })
                                      }
                                    >
                                      <CaretDownIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {this.state.showFees === true && (
                                <>
                                  {this.state.withdrawAmount > 0 && (
                                    <div className={styles.orderDetailsSubRow}>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                      >
                                        Withdraw fee (0%)
                                      </div>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                      >
                                        0.00{" "}
                                        {
                                          config.borrowMultiply[this.getPool()]
                                            .collateral.sym
                                        }{" "}
                                        ($0.00)
                                      </div>
                                    </div>
                                  )}
                                  {this.state.repayAmount > 0 && (
                                    <div className={styles.orderDetailsSubRow}>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                      >
                                        Repay fee ({this.state.exitFee}%)
                                      </div>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                      >
                                        {(
                                          this.state.repayUSDAmount -
                                          this.state.repayUSDAmount /
                                            (1 + this.state.exitFee / 100)
                                        ).toFixed(2)}{" "}
                                        USDR ($
                                        {(
                                          this.state.repayUSDAmount -
                                          this.state.repayUSDAmount /
                                            (1 + this.state.exitFee / 100)
                                        ).toFixed(2)}
                                        )
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              {this.state.selectedRepayMethod == "collateral" &&
                                this.state.repayAmount > 0 && (
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    * Amounts may vary depending on slippage
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.layout}>
                  {this.state.repayPopup === false ? (
                    <div className={styles.rightSection}>
                      {this.state.selected.length > 0 ? (
                        <>
                          <div className={styles.inputMenu}>
                            <div
                              className={`${styles.inputMenuItem} ${styles.greyBg} ${styles.labelMBold} ${styles.primaryText}`}
                              onClick={() =>
                                this.toPage("/borrow", this.getPoolAddress(), {
                                  i,
                                })
                              }
                            >
                              Borrow
                            </div>
                            <div
                              className={`${styles.inputMenuItem} ${styles.whiteBg}`}
                            >
                              <div
                                className={`${styles.labelMBold} ${styles.gradientText}`}
                              >
                                Multiply
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${styles.inputCard} ${styles.whiteBg}`}
                          >
                            <div className={styles.inputCardHeader}>
                              <div
                                className={styles.inputCardIcon}
                                onClick={() => this.setInfoCardsDisplayState()}
                              >
                                <GradientInterfaceIcon />
                              </div>
                              <div
                                className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                              >
                                Multiply your exposure
                              </div>
                            </div>
                            <div className={styles.inputHeader}>
                              <div
                                className={`${styles.labelSRegular} ${styles.primaryText}`}
                              >
                                Amount
                              </div>
                              <div className={styles.inputBalanceSection}>
                                <div
                                  className={`${styles.labelSRegular} ${
                                    styles.secondaryText
                                  } ${"pool-page-balance-button"}`}
                                  onClick={async () => {
                                    await this.setState({
                                      amount: this.state.balance,
                                      amountInUSD:
                                        this.state.balance *
                                        this.state.selectedAssetPrice,
                                    });

                                    await this.updateFutureActions();
                                  }}
                                >
                                  Balance
                                </div>
                                {this.state.balanceLoading === true ? (
                                  <>{this.renderLoading()}</>
                                ) : (
                                  <div
                                    className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
                                  >
                                    {this.state.balance.toFixed(4)}{" "}
                                    {this.state.selected[0].selectedAsset}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className={`${styles.inputBox} ${styles.flexInputBox}`}
                            >
                              <div>
                                <input
                                  type="text"
                                  id="amount"
                                  name="amount"
                                  placeholder="0.00"
                                  value={this.state.amount}
                                  onChange={(e) => this.amountField(e)}
                                  style={{
                                    fontSize: "21px",
                                    lineHeight: "32px",
                                  }}
                                />
                                {this.state.amountInUSDLoading === true ? (
                                  <>{this.renderLoading()}</>
                                ) : (
                                  <div
                                    className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                  >
                                    ${this.state.amountInUSD.toFixed(2)}
                                  </div>
                                )}
                              </div>
                              {this.state.dropdownAsset === false ||
                              Object.keys(
                                config.borrowMultiply[this.getPool()].assets
                              ).length === 1 ? (
                                <div
                                  className={`${styles.button} ${styles.dropdownButton} ${styles.bg}`}
                                  onClick={() =>
                                    this.setState({
                                      dropdownAsset: true,
                                    })
                                  }
                                >
                                  <img
                                    src={this.getAssetIcon(
                                      this.state.selected[0].selectedAssetIcon
                                    )}
                                    alt="asset-icon"
                                    className={styles.dropdownAssetIcon}
                                  />
                                  <div
                                    className={`${styles.labelMRegular} ${styles.primaryText} ${styles.bg}`}
                                  >
                                    {this.state.selected[0].selectedAsset}
                                  </div>
                                  <CaretDownIcon />
                                </div>
                              ) : (
                                <>
                                  <div
                                    className={`${styles.button} ${styles.dropdownButton} ${styles.bg}`}
                                    onClick={() =>
                                      this.setState({
                                        dropdownAsset: false,
                                      })
                                    }
                                  >
                                    <img
                                      src={this.getAssetIcon(
                                        this.state.selected[0].selectedAssetIcon
                                      )}
                                      alt="asset-icon"
                                      className={styles.dropdownAssetIcon}
                                    />
                                    <div
                                      className={`${styles.labelMRegular} ${styles.primaryText} ${styles.bg}`}
                                    >
                                      {this.state.selected[0].selectedAsset}
                                    </div>
                                    <CaretUpIcon />
                                  </div>
                                  {this.renderAssetDropdown()}
                                </>
                              )}
                            </div>
                            {this.state.selected[0].selectedAsset ===
                              config.borrowMultiply[this.getPool()].collateral
                                .sym && (
                              <>
                                <div className={styles.inputHeader}>
                                  <div
                                    className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                  >
                                    Liquidation Price
                                  </div>
                                  <div
                                    className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                  >
                                    Collateralization Ratio
                                  </div>
                                </div>
                                <div
                                  className={styles.inputHeader}
                                  style={{ paddingTop: "0px" }}
                                >
                                  {this.state.liquidationPriceLoading ===
                                  true ? (
                                    <>{this.renderLoading()}</>
                                  ) : (
                                    <div
                                      className={`${styles.headingXS} ${styles.primaryText}`}
                                    >
                                      $
                                      {this.state.borrowAmount > 0
                                        ? this.state.liquidationPriceATM.toFixed(
                                            4
                                          )
                                        : 0.0}
                                    </div>
                                  )}
                                  {this.state.collateralizationRatioLoading ===
                                  true ? (
                                    <>{this.renderLoading()}</>
                                  ) : (
                                    <div
                                      className={`${styles.headingXS} ${styles.primaryText}`}
                                    >
                                      {this.state.borrowAmount > 0
                                        ? (
                                            this.state
                                              .collateralizationRatioATM * 100
                                          ).toFixed(2)
                                        : 0.0}
                                      %
                                    </div>
                                  )}
                                </div>
                                <div className={styles.rangeInput}>
                                  <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    onChange={(e) => this.setRisk(e)}
                                    value={this.state.risk}
                                  />
                                </div>
                                <div className={styles.inputHeader}>
                                  <div
                                    className={`${styles.labelSRegular} ${styles.primaryText}`}
                                  >
                                    Decrease risk
                                  </div>
                                  <div className={styles.inputBalanceSection}>
                                    <div
                                      className={`${styles.labelSRegular} ${styles.primaryText}`}
                                    >
                                      Increase risk
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {(this.state.amount === 0 ||
                              this.state.amount === "") &&
                            (this.state.borrowAmount === 0 ||
                              this.state.borrowAmount === "") ? (
                              <div
                                className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                              >
                                Enter an amount
                              </div>
                            ) : (
                              <>
                                {this.state.allowance <
                                parseFloat(this.nullCheck(this.state.amount)) ? (
                                  <div
                                    className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                                    onClick={() => this.approve()}
                                  >
                                    Approve
                                  </div>
                                ) : (
                                  <div
                                    className={`${styles.button} ${
                                      this.state.actionButtonDisabled
                                        ? styles.greyBg
                                        : styles.gradientBg
                                    } ${styles.labelMBold} ${
                                      this.state.actionButtonDisabled
                                        ? styles.greyText
                                        : styles.whiteText
                                    } ${styles.inputButton}`}
                                    onClick={() => this.doAction()}
                                  >
                                    {this.state.actionButtonText}
                                  </div>
                                )}
                                <div className={styles.orderDetailsSection}>
                                  <div
                                    className={`${styles.labelMBold} ${styles.primaryText}`}
                                  >
                                    Order details
                                  </div>
                                  <div className={styles.orderDetailsRow}></div>
                                  {this.state.amount != "" &&
                                    this.state.amount != 0 && (
                                      <>
                                        <div className={styles.orderDetailsRow}>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                          >
                                            Direct Deposit
                                          </div>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                          >
                                            {this.state.amount}{" "}
                                            {
                                              this.state.selected[0]
                                                .selectedAsset
                                            }{" "}
                                            ($
                                            {(
                                              this.nullCheck(this.state.amount) *
                                              this.state.selectedAssetPrice
                                            ).toFixed(2)}
                                            )
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  {this.state.borrowAmount != "" &&
                                    this.state.borrowAmount != 0 && (
                                      <>
                                        <div className={styles.orderDetailsRow}>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                          >
                                            Borrow & Swap USDR
                                          </div>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                          >
                                            {this.state.borrowAmount} USDR ($
                                            {this.state.borrowAmount})
                                          </div>
                                        </div>

                                        <div className={styles.orderDetailsRow}>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                          >
                                            Deposit*
                                          </div>
                                          <div
                                            className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                          >
                                            {(
                                              this.state.borrowWithSlippage /
                                              this.state.collateralPrice
                                            ).toFixed(2)}{" "}
                                            {
                                              config.borrowMultiply[
                                                this.getPool()
                                              ].collateral.sym
                                            }{" "}
                                            ($
                                            {this.state.borrowWithSlippage.toFixed(
                                              2
                                            )}
                                            )
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  <div className={styles.orderDetailsRow}>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                    >
                                      Fees
                                    </div>
                                    <div className={styles.orderDetailsFeesRow}>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                      >
                                        {(
                                          this.state.borrowAmount *
                                          (this.state.stabilityFee / 100)
                                        ).toFixed(2)}{" "}
                                        USDR ($
                                        {(
                                          this.state.borrowAmount *
                                          (this.state.stabilityFee / 100)
                                        ).toFixed(2)}
                                        )
                                      </div>
                                      {this.state.showFees === true ? (
                                        <div
                                          className={
                                            styles.orderDetailsFeesIcon
                                          }
                                          onClick={() =>
                                            this.setState({
                                              showFees: false,
                                            })
                                          }
                                        >
                                          <CaretUpIcon />
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            styles.orderDetailsFeesIcon
                                          }
                                          onClick={() =>
                                            this.setState({
                                              showFees: true,
                                            })
                                          }
                                        >
                                          <CaretDownIcon />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.showFees === true && (
                                    <>
                                      {this.state.amount != "" &&
                                        this.state.amount != 0 && (
                                          <>
                                            <div
                                              className={
                                                styles.orderDetailsSubRow
                                              }
                                            >
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                              >
                                                Deposit fee (0%)
                                              </div>
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                              >
                                                0.00{" "}
                                                {
                                                  this.state.selected[0]
                                                    .selectedAsset
                                                }{" "}
                                                ($0.00)
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      {this.state.borrowAmount != "" &&
                                        this.state.borrowAmount != 0 && (
                                          <>
                                            <div
                                              className={
                                                styles.orderDetailsSubRow
                                              }
                                            >
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                              >
                                                Borrow fee (
                                                {this.state.stabilityFee}%)
                                              </div>
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                              >
                                                {(
                                                  this.state.borrowAmount *
                                                  (this.state.stabilityFee /
                                                    100)
                                                ).toFixed(2)}{" "}
                                                USDR ($
                                                {(
                                                  this.state.borrowAmount *
                                                  (this.state.stabilityFee /
                                                    100)
                                                ).toFixed(2)}
                                                )
                                              </div>
                                            </div>
                                          </>
                                        )}
                                    </>
                                  )}

                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    * Amounts may vary depending on slippage
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    <div className={styles.rightSection}>
                      <div
                        className={`${styles.inputCard} ${styles.whiteBg}`}
                        style={{ borderRadius: "8px", borderTop: "none" }}
                      >
                        <div className={styles.inputCardHeader}>
                          <div
                            className={styles.inputCardIcon}
                            onClick={async () => {
                              await this.setState({
                                repayPopup: false,
                              });
                              this.resetInputValues();
                            }}
                          >
                            <CloseIcon />
                          </div>
                          <div
                            className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                          >
                            Repay and Withdraw
                          </div>
                        </div>
                        <div className="borrow-repay-popup-content">
                          {this.state.debt > DUST && (
                            <>
                              {this.state.selectedRepayMethod === "wallet" ? (
                                <>
                                  <div className="borrow-repay-method-box">
                                    <div className="borrow-repay-method-box-header">
                                      <div className="borrow-repay-method-box-header-left-sect">
                                        <div
                                          className="borrow-repay-selected-method-circle"
                                          onClick={async (e) => {
                                            await this.setState({
                                              selectedRepayMethod: "",
                                              repayAmount: 0,
                                              repayUSDAmount: 0,
                                              withdrawAmount: 0,
                                              withdrawUSDAmount: 0,
                                              withdrawBarValue: "0",
                                            });

                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        />
                                        <div
                                          className={`${styles.labelMBold} ${styles.primaryText}`}
                                        >
                                          Repay from wallet
                                        </div>
                                      </div>
                                      {this.state.maxRepaymentWalletLoading ===
                                      true ? (
                                        <>{this.renderLoading()}</>
                                      ) : (
                                        <div
                                          className={`${styles.labelSBold} ${
                                            styles.secondaryText
                                          } ${"pool-page-balance-button"}`}
                                          onClick={async () => {
                                            await this.setState({
                                              repayAmount:
                                                this.state.maxRepaymentWallet -
                                                0.00000000000001,
                                              repayUSDAmount:
                                                this.state.maxRepaymentWallet -
                                                0.00000000000001,
                                              maxRepayFlag: true,
                                              selectedRepayMethod: "wallet",
                                            });
                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        >
                                          Max{" "}
                                          {this.state.maxRepaymentWallet.toFixed(
                                            2
                                          )}{" "}
                                          USDR
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={`${styles.inputBox} ${styles.whiteBg}`}
                                      style={{ marginTop: "16px" }}
                                    >
                                      <input
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder="0.00"
                                        value={this.state.repayAmount}
                                        onChange={(e) =>
                                          this.repayAmountField(e)
                                        }
                                        style={{
                                          fontSize: "21px",
                                          lineHeight: "32px",
                                        }}
                                      />
                                      {/* {this.state.Loading === true ? (
                                    <>{this.renderLoading()}</>
                                  ) : ( */}
                                      <div
                                        className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                      >
                                        ${this.state.repayUSDAmount.toFixed(2)}
                                      </div>
                                      {/* )} */}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="borrow-repay-method-box">
                                    <div className="borrow-repay-method-box-header">
                                      <div className="borrow-repay-method-box-header-left-sect">
                                        <div
                                          className="borrow-repay-not-selected-method-circle"
                                          onClick={async () => {
                                            await this.setState({
                                              selectedRepayMethod: "wallet",
                                              repayAmount: 0,
                                              repayUSDAmount: 0,
                                            });
                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        />
                                        <div
                                          className={`${styles.labelMBold} ${styles.primaryText}`}
                                          style={{ marginLeft: "0px" }}
                                        >
                                          Repay from wallet
                                        </div>
                                      </div>
                                      {this.state.maxRepaymentWalletLoading ===
                                      true ? (
                                        <>{this.renderLoading()}</>
                                      ) : (
                                        <div
                                          className={`${styles.labelSBold} ${
                                            styles.secondaryText
                                          } ${"pool-page-balance-button"}`}
                                          onClick={async () => {
                                            await this.setState({
                                              repayAmount:
                                                this.state.maxRepaymentWallet -
                                                0.00000000000001,
                                              repayUSDAmount:
                                                this.state.maxRepaymentWallet -
                                                0.00000000000001,
                                              maxRepayFlag: true,
                                              selectedRepayMethod: "wallet",
                                            });
                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        >
                                          Max{" "}
                                          {this.state.maxRepaymentWallet.toFixed(
                                            2
                                          )}{" "}
                                          USDR
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}

                              {this.state.selectedRepayMethod ===
                              "collateral" ? (
                                <>
                                  <div
                                    className="borrow-repay-method-box"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="borrow-repay-method-box-header">
                                      <div className="borrow-repay-method-box-header-left-sect">
                                        <div
                                          className="borrow-repay-selected-method-circle"
                                          onClick={async (e) => {
                                            await this.setState({
                                              selectedRepayMethod: "",
                                              repayAmount: 0,
                                              repayUSDAmount: 0,
                                              withdrawAmount: 0,
                                              withdrawUSDAmount: 0,
                                              withdrawBarValue: "0",
                                            });

                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        />
                                        <div
                                          className={`${styles.labelMBold} ${styles.primaryText}`}
                                        >
                                          Repay from collateral
                                        </div>
                                      </div>

                                      {this.state
                                        .maxRepaymentCollateralLoading ===
                                      true ? (
                                        <>{this.renderLoading()}</>
                                      ) : (
                                        <div
                                          className={`${styles.labelSBold} ${
                                            styles.secondaryText
                                          } ${"pool-page-balance-button"}`}
                                          onClick={async () => {
                                            await this.setState({
                                              repayAmount:
                                                this.state
                                                  .maxRepaymentCollateral,
                                              repayUSDAmount:
                                                this.state
                                                  .maxRepaymentCollateral *
                                                this.state.collateralPrice,
                                              selectedRepayMethod: "collateral",
                                              withdrawAmount: 0,
                                              withdrawUSDAmount: 0,
                                              withdrawBarValue: "0",
                                            });
                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        >
                                          Max{" "}
                                          {this.state.maxRepaymentCollateral.toFixed(
                                            2
                                          )}{" "}
                                          {
                                            config.borrowMultiply[
                                              this.getPool()
                                            ].collateral.sym
                                          }
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={`${styles.inputBox} ${styles.whiteBg}`}
                                      style={{ marginTop: "16px" }}
                                    >
                                      <input
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder="0.00"
                                        value={this.state.repayAmount}
                                        onChange={(e) =>
                                          this.repayAmountField(e)
                                        }
                                        style={{
                                          fontSize: "21px",
                                          lineHeight: "32px",
                                        }}
                                      />
                                      {/* {this.state.Loading === true ? (
                                  <>{this.renderLoading()}</>
                                ) : ( */}
                                      <div
                                        className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                      >
                                        ${this.state.repayUSDAmount.toFixed(2)}
                                      </div>
                                      {/* )} */}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="borrow-repay-method-box"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="borrow-repay-method-box-header">
                                      <div className="borrow-repay-method-box-header-left-sect">
                                        <div
                                          className="borrow-repay-not-selected-method-circle"
                                          onClick={async () => {
                                            await this.setState({
                                              selectedRepayMethod: "collateral",
                                              repayAmount: 0,
                                              repayUSDAmount: 0,
                                              withdrawAmount: 0,
                                              withdrawUSDAmount: 0,
                                              withdrawBarValue: "0",
                                            });
                                            this.updateFutureActions();
                                          }}
                                        />
                                        <div
                                          className={`${styles.labelMBold} ${styles.primaryText}`}
                                        >
                                          Repay from collateral
                                        </div>
                                      </div>

                                      {this.state
                                        .maxRepaymentCollateralLoading ===
                                      true ? (
                                        <>{this.renderLoading()}</>
                                      ) : (
                                        <div
                                          className={`${styles.labelSBold} ${
                                            styles.secondaryText
                                          } ${"pool-page-balance-button"}`}
                                          onClick={async () => {
                                            await this.setState({
                                              repayAmount:
                                                this.state
                                                  .maxRepaymentCollateral,
                                              repayUSDAmount:
                                                this.state
                                                  .maxRepaymentCollateral *
                                                this.state.collateralPrice,
                                              selectedRepayMethod: "collateral",
                                              withdrawAmount: 0,
                                              withdrawUSDAmount: 0,
                                              withdrawBarValue: "0",
                                            });
                                            await this.updateFutureActions();
                                            await this.setMaxWithdraw();
                                          }}
                                        >
                                          Max{" "}
                                          {this.state.maxRepaymentCollateral.toFixed(
                                            2
                                          )}{" "}
                                          {
                                            config.borrowMultiply[
                                              this.getPool()
                                            ].collateral.sym
                                          }
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}

                          {/* OLD START HERE */}

                          {/* OLD END HERE */}
                          {this.state.selectedRepayMethod != "collateral" && (
                            <>
                              <div
                                className={`${styles.labelMBold} ${styles.primaryText}`}
                                style={{ marginTop: "24px" }}
                              >
                                Withdraw collateral
                              </div>
                              <div className={styles.inputHeader}>
                                <div
                                  className={`${styles.labelSRegular} ${styles.primaryText}`}
                                >
                                  Select amount
                                </div>
                                <div className={styles.inputBalanceSection}>
                                  <div
                                    className={`${styles.labelSRegular} ${
                                      styles.primaryText
                                    } ${"pool-page-balance-button"}`}
                                    onClick={async () => {
                                      await this.setState({
                                        withdrawAmount:
                                          this.state.maxWithdrawCollateral -
                                          0.00000000000001,
                                        withdrawUSDAmount:
                                          (this.state.maxWithdrawCollateral -
                                            0.00000000000001) *
                                          this.state.collateralPrice,
                                        withdrawBarValue: "100",
                                      });
                                      this.updateFutureActions();
                                    }}
                                  >
                                    Max{" "}
                                    {this.state.maxWithdrawCollateral.toFixed(
                                      2
                                    )}{" "}
                                    {
                                      config.borrowMultiply[this.getPool()]
                                        .collateral.sym
                                    }
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${styles.inputBox} ${styles.whiteBg}`}
                              >
                                <input
                                  type="text"
                                  id="amount"
                                  name="amount"
                                  placeholder="0.00"
                                  value={this.state.withdrawAmount}
                                  onChange={(e) => this.withdrawAmountField(e)}
                                  style={{
                                    fontSize: "21px",
                                    lineHeight: "32px",
                                  }}
                                />
                                {/* {this.state.Loading === true ? (
                                    <>{this.renderLoading()}</>
                                  ) : ( */}
                                <div
                                  className={`${styles.labelSRegular} ${styles.secondaryText}`}
                                >
                                  ${this.state.withdrawUSDAmount}
                                </div>
                                {/* )} */}
                              </div>
                              <div className={styles.rangeInput}>
                                <input
                                  type="range"
                                  min="0"
                                  max="100"
                                  onChange={(e) => this.setWithdrawBarValue(e)}
                                  value={this.state.withdrawBarValue}
                                />
                              </div>
                              <div className={styles.inputHeader}>
                                <div
                                  className={`${styles.labelSRegular} ${styles.primaryText}`}
                                >
                                  0%
                                </div>
                                <div className={styles.inputBalanceSection}>
                                  <div
                                    className={`${styles.labelSRegular} ${styles.primaryText}`}
                                  >
                                    100%
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div
                            className={`${styles.button} ${
                              this.state.actionButtonDisabled
                                ? styles.greyBg
                                : styles.gradientBg
                            } ${styles.labelMBold} ${
                              this.state.actionButtonDisabled
                                ? styles.greyText
                                : styles.whiteText
                            } ${styles.inputButton}`}
                            onClick={() => this.doAction()}
                          >
                            {this.state.actionButtonText}
                          </div>
                          {!this.state.actionButtonDisabled && (
                            <div className={styles.orderDetailsSection}>
                              <div
                                className={`${styles.labelMBold} ${styles.primaryText}`}
                              >
                                Order details
                              </div>
                              {this.state.selectedRepayMethod == "collateral" &&
                                this.state.repayAmount > 0 && (
                                  <div className={styles.orderDetailsRow}>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                    >
                                      Swap Collateral to USDR
                                      {this.state.selectedRepayMethod ==
                                        "collateral" &&
                                      this.state.repayAmount > 0
                                        ? "*"
                                        : ""}
                                    </div>
                                    <div
                                      className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                    >
                                      {parseFloat(
                                        this.state.repayAmount
                                      ).toFixed(2)}{" "}
                                      {
                                        config.borrowMultiply[this.getPool()]
                                          .collateral.sym
                                      }{" "}
                                      ($
                                      {parseFloat(
                                        this.state.repayUSDAmount
                                      ).toFixed(2)}
                                      )
                                    </div>
                                  </div>
                                )}
                              {this.state.repayAmount > 0 && (
                                <div className={styles.orderDetailsRow}>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                  >
                                    Repay
                                    {this.state.selectedRepayMethod ==
                                      "collateral" && this.state.repayAmount > 0
                                      ? "*"
                                      : ""}
                                  </div>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    {(
                                      this.state.repayUSDAmount /
                                      (1 + this.state.exitFee / 100)
                                    ).toFixed(2)}{" "}
                                    USDR ($
                                    {(
                                      this.state.repayUSDAmount /
                                      (1 + this.state.exitFee / 100)
                                    ).toFixed(2)}
                                    )
                                  </div>
                                </div>
                              )}
                              {this.state.withdrawAmount > 0 && (
                                <div className={styles.orderDetailsRow}>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                  >
                                    Withdraw & Receive
                                  </div>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    {this.state.withdrawAmount}{" "}
                                    {
                                      config.borrowMultiply[this.getPool()]
                                        .collateral.sym
                                    }{" "}
                                    ($
                                    {(
                                      this.state.withdrawAmount *
                                      this.state.collateralPrice
                                    ).toFixed(2)}
                                    )
                                  </div>
                                </div>
                              )}
                              <div className={styles.orderDetailsRow}>
                                <div
                                  className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                >
                                  Fees
                                </div>
                                <div className={styles.orderDetailsFeesRow}>
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    {(
                                      this.state.repayUSDAmount -
                                      this.state.repayUSDAmount /
                                        (1 + this.state.exitFee / 100)
                                    ).toFixed(2)}{" "}
                                    USDR ($
                                    {(
                                      this.state.repayUSDAmount -
                                      this.state.repayUSDAmount /
                                        (1 + this.state.exitFee / 100)
                                    ).toFixed(2)}
                                    )
                                  </div>
                                  {this.state.showFees === true ? (
                                    <div
                                      className={styles.orderDetailsFeesIcon}
                                      onClick={() =>
                                        this.setState({
                                          showFees: false,
                                        })
                                      }
                                    >
                                      <CaretUpIcon />
                                    </div>
                                  ) : (
                                    <div
                                      className={styles.orderDetailsFeesIcon}
                                      onClick={() =>
                                        this.setState({
                                          showFees: true,
                                        })
                                      }
                                    >
                                      <CaretDownIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {this.state.showFees === true && (
                                <>
                                  {this.state.withdrawAmount > 0 && (
                                    <div className={styles.orderDetailsSubRow}>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                      >
                                        Withdraw fee (0%)
                                      </div>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                      >
                                        0.00{" "}
                                        {
                                          config.borrowMultiply[this.getPool()]
                                            .collateral.sym
                                        }{" "}
                                        ($0.00)
                                      </div>
                                    </div>
                                  )}
                                  {this.state.repayAmount > 0 && (
                                    <div className={styles.orderDetailsSubRow}>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                      >
                                        Repay fee ({this.state.exitFee}%)
                                      </div>
                                      <div
                                        className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                      >
                                        {(
                                          this.state.repayUSDAmount -
                                          this.state.repayUSDAmount /
                                            (1 + this.state.exitFee / 100)
                                        ).toFixed(2)}{" "}
                                        USDR ($
                                        {(
                                          this.state.repayUSDAmount -
                                          this.state.repayUSDAmount /
                                            (1 + this.state.exitFee / 100)
                                        ).toFixed(2)}
                                        )
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              {this.state.selectedRepayMethod == "collateral" &&
                                this.state.repayAmount > 0 && (
                                  <div
                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                  >
                                    * Amounts may vary depending on slippage
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={styles.leftSection}
                    style={
                      this.state.displayInfoCards === false ? doNotDisplay : {}
                    }
                  >
                    <div className={`${styles.cardFullDim} ${styles.whiteBg}`}>
                      <div className={`${styles.cardFullDimRow}`}>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                        >
                          Liquidation price
                        </div>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                        >
                          {config.borrowMultiply[this.getPool()].collateral.sym}{" "}
                          price
                        </div>
                      </div>
                      <div className={`${styles.cardFullDimRow}`}>
                        {this.state.liquidationPriceLoading === true ? (
                          <>{this.renderLoading()}</>
                        ) : (
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            ${this.state.liquidationPrice.toFixed(6)}
                          </div>
                        )}
                        {this.state.collateralPriceLoading === true ? (
                          <>{this.renderLoading()}</>
                        ) : (
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            ${this.state.collateralPrice}
                          </div>
                        )}
                      </div>
                      {this.state.positionHealthLoading === true ? (
                        <>{this.renderLoading()}</>
                      ) : (
                        <>
                          <div className={`${styles.cardProgressBar}`}>
                            <div class="progress">
                              <div
                                class="progress-bar"
                                style={{ width: this.state.positionHealth }}
                              >
                                <span class="sr-only">60% Complete</span>
                              </div>
                            </div>
                          </div>
                          <div className={`${styles.cardFullDimRow}`}>
                            <div
                              className={`${styles.labelSBold} ${styles.secondaryText}`}
                            >
                              Position health
                            </div>
                            <div
                              className={`${styles.labelSBold} ${styles.secondaryText}`}
                            >
                              {this.state.positionHealth}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={styles.cardsRow}>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div
                          className={`${styles.labelSRegular} ${styles.secondaryText}`}
                        >
                          Collateralization Ratio
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.collateralizationRatioLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {(
                                this.state.collateralizationRatio * 100
                              ).toFixed(2)}
                              %
                            </div>
                          )}
                        </div>
                        {this.state.collateralizationRatioATM !== -1 &&
                          this.state.collateralizationRatioATM !==
                            this.state.collateralizationRatio &&
                          this.state.maxCollateralRatio && (
                            <>
                              {this.state.collateralizationRatioATM * 100 <=
                                0.5 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                >
                                  {(
                                    this.state.collateralizationRatio * 100
                                  ).toFixed(2)}
                                  %{" "}
                                  <img
                                    src={greenArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {(
                                    this.state.collateralizationRatioATM * 100
                                  ).toFixed(2)}
                                  %
                                </div>
                              )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.5 * this.state.maxCollateralRatio &&
                                this.state.collateralizationRatioATM * 100 <=
                                  0.8 * this.state.maxCollateralRatio && (
                                  <div
                                    class={`${styles.cardBullet} ${styles.warningBg} ${styles.warningText} ${styles.labelMBold}`}
                                  >
                                    {(
                                      this.state.collateralizationRatio * 100
                                    ).toFixed(2)}
                                    %{" "}
                                    <img
                                      src={orangeArrowIcon}
                                      alt="arrow-icon"
                                      className={styles.cardBulletArrowIcon}
                                    />
                                    {(
                                      this.state.collateralizationRatioATM * 100
                                    ).toFixed(2)}
                                    %
                                  </div>
                                )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.8 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.dangerBg} ${styles.dangerText} ${styles.labelMBold}`}
                                >
                                  {(
                                    this.state.collateralizationRatio * 100
                                  ).toFixed(2)}
                                  %{" "}
                                  <img
                                    src={redArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {(
                                    this.state.collateralizationRatioATM * 100
                                  ).toFixed(2)}
                                  %
                                </div>
                              )}
                            </>
                          )}
                      </div>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="borrow-header-sect">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Available to Borrow
                          </div>
                          {this.state.availableToBorrow > DUST && (
                            <div
                              className={`${"borrow-card-borrow-button"} ${
                                styles.labelMBold
                              } ${styles.gradientText}`}
                              onClick={async () => {
                                await this.setState({
                                  repayPopup: false,
                                });
                                this.resetInputValues();
                              }}
                            >
                              Borrow
                            </div>
                          )}
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.availableToBorrowLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.availableToBorrow.toFixed(2)} USDR
                            </div>
                          )}
                        </div>
                        {this.state.availableToBorrowATM !== -1 &&
                          this.state.availableToBorrowATM !==
                            this.state.availableToBorrow && (
                            <>
                              {this.state.collateralizationRatioATM * 100 <=
                                0.5 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                  style={{ marginTop: "28px" }}
                                >
                                  {this.state.availableToBorrow.toFixed(2)}
                                  <img
                                    src={greenArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {this.state.availableToBorrowATM.toFixed(2)}{" "}
                                  USDR
                                </div>
                              )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.5 * this.state.maxCollateralRatio &&
                                this.state.collateralizationRatioATM * 100 <=
                                  0.8 * this.state.maxCollateralRatio && (
                                  <div
                                    class={`${styles.cardBullet} ${styles.warningBg} ${styles.warningText} ${styles.labelMBold}`}
                                    style={{ marginTop: "28px" }}
                                  >
                                    {this.state.availableToBorrow.toFixed(2)}
                                    <img
                                      src={orangeArrowIcon}
                                      alt="arrow-icon"
                                      className={styles.cardBulletArrowIcon}
                                    />
                                    {this.state.availableToBorrowATM.toFixed(2)}{" "}
                                    USDR
                                  </div>
                                )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.8 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.dangerBg} ${styles.dangerText} ${styles.labelMBold}`}
                                  style={{ marginTop: "28px" }}
                                >
                                  {this.state.availableToBorrow.toFixed(2)}
                                  <img
                                    src={redArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {this.state.availableToBorrowATM.toFixed(2)}{" "}
                                  USDR
                                </div>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                    <div className={styles.cardsRow}>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="borrow-header-sect">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Collateral
                          </div>
                          {this.state.collateral > DUST && (
                            <div
                              className={`${"borrow-card-withdraw-button"} ${
                                styles.labelMBold
                              } ${styles.gradientText}`}
                              onClick={async () => {
                                await this.setState({
                                  repayPopup: true,
                                  selectedRepayMethod: "",
                                });
                                this.resetInputValues();
                              }}
                            >
                              Withdraw
                            </div>
                          )}
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.collateralLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <>
                              <div
                                className={`${styles.headingS} ${styles.primaryText}`}
                              >
                                $
                                {(
                                  this.state.collateral *
                                  this.state.collateralPrice
                                ).toFixed(2)}
                              </div>
                              <div
                                className={`${styles.labelMRegular} ${styles.secondaryText}`}
                                style={{ marginLeft: "8px" }}
                              >
                                {this.state.collateral.toFixed(4)}{" "}
                                {
                                  config.borrowMultiply[this.getPool()]
                                    .collateral.sym
                                }
                              </div>
                            </>
                          )}
                        </div>
                        {this.state.collateralATM !== -1 && (
                          <>
                            {this.state.collateralizationRatioATM * 100 <=
                              0.5 * this.state.maxCollateralRatio && (
                              <div
                                class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                style={{ marginTop: "28px" }}
                              >
                                $
                                {(
                                  this.state.collateral *
                                  this.state.collateralPrice
                                ).toFixed(2)}
                                <img
                                  src={greenArrowIcon}
                                  alt="arrow-icon"
                                  className={styles.cardBulletArrowIcon}
                                />
                                ${this.state.collateralATM.toFixed(2)}
                              </div>
                            )}
                            {this.state.collateralizationRatioATM * 100 >
                              0.5 * this.state.maxCollateralRatio &&
                              this.state.collateralizationRatioATM * 100 <=
                                0.8 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.warningBg} ${styles.warningText} ${styles.labelMBold}`}
                                  style={{ marginTop: "28px" }}
                                >
                                  $
                                  {(
                                    this.state.collateral *
                                    this.state.collateralPrice
                                  ).toFixed(2)}
                                  <img
                                    src={orangeArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  ${this.state.collateralATM.toFixed(2)}
                                </div>
                              )}
                            {this.state.collateralizationRatioATM * 100 >
                              0.8 * this.state.maxCollateralRatio && (
                              <div
                                class={`${styles.cardBullet} ${styles.dangerBg} ${styles.dangerText} ${styles.labelMBold}`}
                                style={{ marginTop: "28px" }}
                              >
                                $
                                {(
                                  this.state.collateral *
                                  this.state.collateralPrice
                                ).toFixed(2)}
                                <img
                                  src={redArrowIcon}
                                  alt="arrow-icon"
                                  className={styles.cardBulletArrowIcon}
                                />
                                ${this.state.collateralATM.toFixed(2)}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className={`${styles.card} ${styles.whiteBg}`}>
                        <div className="borrow-header-sect">
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            USDR Debt
                          </div>
                          {this.state.debt > DUST && (
                            <div
                              className={`${"borrow-card-repay-button"} ${
                                styles.labelMBold
                              } ${styles.gradientText}`}
                              onClick={async () => {
                                await this.setState({
                                  repayPopup: true,
                                  selectedRepayMethod: "wallet",
                                });
                                this.resetInputValues();
                              }}
                            >
                              Repay
                            </div>
                          )}
                        </div>
                        <div className={styles.cardValueSection}>
                          {this.state.availableToBorrowLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {this.state.debt.toFixed(2)} USDR
                            </div>
                          )}
                        </div>
                        {this.state.debtATM !== -1 &&
                          this.state.debt !== this.state.debtATM && (
                            <>
                              {this.state.collateralizationRatioATM * 100 <=
                                0.5 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.succesBg} ${styles.succesText} ${styles.labelMBold}`}
                                  style={{ marginTop: "28px" }}
                                >
                                  {this.state.debt.toFixed(2)}
                                  <img
                                    src={greenArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {this.state.debtATM.toFixed(2)} USDR
                                </div>
                              )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.5 * this.state.maxCollateralRatio &&
                                this.state.collateralizationRatioATM * 100 <=
                                  0.8 * this.state.maxCollateralRatio && (
                                  <div
                                    class={`${styles.cardBullet} ${styles.warningBg} ${styles.warningText} ${styles.labelMBold}`}
                                    style={{ marginTop: "28px" }}
                                  >
                                    {this.state.debt.toFixed(2)}
                                    <img
                                      src={orangeArrowIcon}
                                      alt="arrow-icon"
                                      className={styles.cardBulletArrowIcon}
                                    />
                                    {this.state.debtATM.toFixed(2)} USDR
                                  </div>
                                )}
                              {this.state.collateralizationRatioATM * 100 >
                                0.8 * this.state.maxCollateralRatio && (
                                <div
                                  class={`${styles.cardBullet} ${styles.dangerBg} ${styles.dangerText} ${styles.labelMBold}`}
                                  style={{ marginTop: "28px" }}
                                >
                                  {this.state.debt.toFixed(2)}
                                  <img
                                    src={redArrowIcon}
                                    alt="arrow-icon"
                                    className={styles.cardBulletArrowIcon}
                                  />
                                  {this.state.debtATM.toFixed(2)} USDR
                                </div>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer {...this.props} state={this.state} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MultiplyPoolPage);
