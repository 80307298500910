import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config/config.json";

import triangleUpIcon from "../../assets/SVGs/triangleUp.svg";
import styles from "../../styles/index.module.css";
import "./tooltip.css";

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",

      text: this.props.textFromParent,
    };
  }

  render() {
    return (
      <div className="tooltip-layout">
        <div className="tooltip-triangle-icon-section">
          <img
            src={triangleUpIcon}
            alt="triangle-up-tooltip-icon"
            className="tooltip-triangle-icon"
          />
        </div>
        <div
          className={`${"tooltip-box"} ${styles.whiteBg} ${
            styles.elevationLow
          }`}
        >
          <div className={`${styles.labelXSRegular} ${styles.primaryText}`}>
            {this.state.text}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip);
