import React, { Component } from "react";
import { connect } from "react-redux";

import "../../utils/ethereum/onboard";
import {
  connectAccountOnboard,
  checkWallet,
  walletPersistance,
  manualChangeNetwork,
} from "../../redux/actions/OnboardActions";
import config from "../../config/config.json";

import BlockchainDropdown from "../../components/dropdowns/blockchain-dropdown/BlockchainDropdown";
// import DisconnectDropdown from "../../components/dropdowns/disconnect-dropdown/DisconnectDropdown";
import AccountOverlay from "../../components/overlays/account/AccountOverlay";

import styles from "../../styles/index.module.css";
import "./footer.css";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentNet: "ETH",

      backButton: this.props.state.backButton,
      backButtonRoute: this.props.state.backButtonRoute,

      blockchainDropdown: false,
      disconnectDropdown: false,
    };
    this.toPage = this.toPage.bind(this);
  }

  toPage = (path) => {
    this.props.history.push(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  closeBlockchainDropdown = () => {
    this.setState({
      blockchainDropdown: false,
    });
  };

  // closeDisconnectDropdown = () => {
  //   this.setState({
  //     disconnectDropdown: false,
  //   });
  // };

  closeAccountOverlay = () => {
    this.setState({
      disconnectDropdown: false,
    });
  };

  connectWallet = () => {
    this.props.connectAccountOnboard();
  };

  getBlockchainIcon = (type) => {
    const icon = require(`../../assets/SVGs/${type}`).default;
    return icon;
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  displayAddress = (address) => {
    return `${address.substring(0, 2)}...${address.substring(38)}`;
  };

  componentDidMount = async () => {
    if (!this.props.onboard.walletConnected) this.props.walletPersistance();
  };

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
    }
  }

  setBlockchainDropdownState() {
    if (this.state.blockchainDropdown === true) {
      this.setState({
        blockchainDropdown: false,
        disconnectDropdown: false,
      });
    } else {
      this.setState({
        blockchainDropdown: true,
        disconnectDropdown: false,
      });
    }
  }

  setDisconnectDropdownState() {
    if (this.state.disconnectDropdown === true) {
      this.setState({
        blockchainDropdown: false,
        disconnectDropdown: false,
      });
    } else {
      this.setState({
        blockchainDropdown: false,
        disconnectDropdown: true,
      });
    }
  }

  render() {
    const doNotDisplay = {
      display: "none",
    };
    return (
      <>
        {window.innerWidth > 1299 ? null : (
          <>
            <div className="mob-footer-wrapper">
              <div
                className={`${"mob-footer-box"} ${styles.elevationLow} ${
                  styles.whiteBg
                }`}
              >
                {this.props.onboard.walletConnected === false ? (
                  <div
                    className={`${styles.button} ${
                      styles.gradientBg
                    } ${"mob-footer-connect-wallet-button"}`}
                    onClick={() => this.connectWallet()}
                  >
                    <div className={`${styles.labelMBold} ${styles.whiteText}`}>
                      Connect wallet
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${styles.button} ${
                      styles.bg
                    } ${"mob-footer-address-button"}`}
                    onClick={() => this.setDisconnectDropdownState()}
                  >
                    <div
                      className={`${styles.labelMBold} ${
                        styles.secondaryText
                      } ${styles.whiteBg} ${"mob-footer-amount-section"}`}
                    >
                      {parseFloat(
                        this.props.onboard.balance == null
                          ? 0
                          : this.props.onboard.balance / 10 ** 18
                      ).toFixed(2)}
                      {this.getBlockchain(this.props.onboard.networkId) != null
                        ? config.networks[
                            this.getBlockchain(this.props.onboard.networkId)
                          ].assetName
                        : ""}
                    </div>
                    <div
                      className={`${styles.labelMBold} ${
                        styles.primaryText
                      } ${"mob-footer-address-section"}`}
                    >
                      {this.displayAddress(
                        this.props.onboard.address
                          ? this.props.onboard.address
                          : ""
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={`${styles.button} ${
                    styles.bg
                  } ${"mob-footer-blockchain-button"}`}
                  style={
                    this.props.onboard.walletConnected === false
                      ? doNotDisplay
                      : {}
                  }
                  onClick={() =>
                    // this.setState({
                    //   blockchainDropdown: true,
                    //   disconnectDropdown: false,
                    // })
                    this.setBlockchainDropdownState()
                  }
                >
                  <div className={`${"mob-footer-blockchain-button-icon"}`}>
                    <img
                      src={
                        this.getBlockchain(this.props.onboard.networkId) !=
                          null &&
                        this.getBlockchainIcon(
                          config.networks[
                            this.getBlockchain(this.props.onboard.networkId)
                          ].icon
                        )
                      }
                      alt="blockchain-icon"
                      className="mob-footer-network-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.blockchainDropdown === true && (
              <BlockchainDropdown
                {...this.props}
                state={this.state}
                closeBlockchainDropdownEvent={this.closeBlockchainDropdown}
              />
            )}
            {/* {this.state.disconnectDropdown === true && (
              <DisconnectDropdown
                {...this.props}
                state={this.state}
                closeDisconnectDropdownEvent={this.closeDisconnectDropdown}
              />
            )} */}
            {this.state.disconnectDropdown === true && (
              <AccountOverlay
                {...this.props}
                state={this.state}
                closeAccountOverlayEvent={this.closeAccountOverlay}
              />
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  connectAccountOnboard,
  checkWallet,
  walletPersistance,
  manualChangeNetwork,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
