import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config/config.json";

import Header from "../../layouts/header/Header";
import Sidebar from "../../layouts/sidebar/Sidebar";
import Footer from "../../layouts/footer/Footer";

import Loader from "../../components/loader/Loader";
import WalletNotConnectedOverlay from "../../components/overlays/wallet-not-connected/WalletNotConnectedOverlay";
import SwitchNetworkOverlay from "../../components/overlays/switch-network/SwitchNetworkOverlay";

import GradientArrowRightIcon from "../../components/icons/GradientArrowRightIcon";
import styles from "../../styles/index.module.css";
import "./borrowPage.css";
import { getLeftToBorrow } from "../../web3/usdr/api";

class BorrowPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",
      leftToBorrow: {},
    };
  }

  toPage(route, subroute, params) {
    this.props.history.push(route + "/" + subroute, params);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  renderLoading() {
    return <Loader {...this.props} />;
  }

  getAssetIcon = (type) => {
    const icon = require(`../../assets/SVGs/assets/${type}`).default;
    return icon;
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  async getLeftToBorrow() {
    await this.setState({ leftToBorrow: {} });

    const poolKeys = Object.keys(config.borrowMultiply);
    const rpc =
      config.networks[this.getBlockchain(this.props.onboard.networkId)]
        .endpoint;

    for (var i = 0; i < poolKeys.length; i++) {
      try {
        const data = await getLeftToBorrow(
          rpc,
          config.borrowMultiply[poolKeys[i]].address
        );

        var updated = this.state.leftToBorrow;
        updated[poolKeys[i]] = data;
        await this.setState({
          leftToBorrow: updated,
        });
      } catch (e) {
        console.log(e);
        // var updated = this.state.leftToBorrow;
        // updated[poolKeys[i]] = {
        //   available: 0,
        //   total: 0
        // }
        // await this.setState({
        //   leftToBorrow: updated
        // });
      }
    }
  }

  async componentDidMount() {
    await this.getLeftToBorrow();
  }

  nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
      await this.getLeftToBorrow();
    }
  }

  render() {
    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };
    return (
      <>
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Sidebar {...this.props} state={this.state} />
          {this.props.onboard.walletConnected === false ? (
            <WalletNotConnectedOverlay {...this.props} />
          ) : (
            <>
              {this.getBlockchain(this.props.onboard.networkId) !==
                "AVAXC" && (
                <SwitchNetworkOverlay
                  {...this.props}
                  primaryTextFromParent={
                    "Borrow is not available on " +
                    config.networks[this.getBlockchain(this.props.onboard.networkId)].name
                  }
                  secondaryTextFromParent="Please switch your wallet to one of the following networks"
                  blockchainsFromParent={["AVAXC"]}
                />
              )}
            </>
          )}
          <div
            className={styles.wrapper}
            style={
              this.props.onboard.walletConnected === false ||
              this.getBlockchain(this.props.onboard.networkId) !== "AVAXC"
                ? displayBlur
                : {}
            }
          >
            <div className={styles.content}>
              <div className={styles.headerSection}>
                <div className={`${styles.headingM} ${styles.primaryText}`}>
                  Borrow
                </div>
                <div className={styles.subheaderSection}>
                  <div className={`${styles.paragraphM} ${styles.primaryText}`}>
                    Borrow USDR with 0% interest and earn yield for your
                    collateral.
                  </div>
                  <div className={styles.mobSubheaderLinkSection}>
                    <a
                      href="https://radar.blog/radar-borrow-tutorial-how-to-borrow-usdr-at-0-interest-e1e51c90f2d2"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className={`${styles.paragraphM} ${styles.gradientText} ${styles.subheaderMiddle}`}
                      >
                        Learn more about Borrow
                      </div>
                    </a>
                    <a
                      href=""
                      target="_blank"
                      style={{ textDecoration: "none", marginTop: "-2px" }}
                    >
                      <GradientArrowRightIcon />
                    </a>
                  </div>
                </div>
              </div>
              {window.innerWidth > 1299 ? (
                <div className={styles.table}>
                  <div className={styles.tableHeader}>
                    <div
                      className={`${"borrow-table-b-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      Pool
                    </div>
                    <div
                      className={`${"borrow-table-b-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      Assets
                    </div>
                    {this.state.min}
                    <div
                      className={`${"borrow-table-b-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      USDR left to borrow
                    </div>
                    <div
                      className={`${"borrow-table-s-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                    >
                      Interest
                    </div>
                    <div
                      className={`${"borrow-table-s-cell"} ${
                        styles.labelSRegular
                      } ${styles.secondaryText}`}
                      style={{ textAlign: "right" }}
                    >
                      APY
                    </div>
                  </div>
                  {Object.keys(config.borrowMultiply)
                    .filter((j) => {
                      return (
                        config.borrowMultiply[j].network ===
                        this.getBlockchain(this.props.onboard.networkId)
                      );
                    })
                    .map((i) => (
                      <>
                        <div
                          className={styles.tableRow}
                          onClick={() =>
                            this.toPage(
                              "/borrow",
                              config.borrowMultiply[i].address,
                              {
                                i,
                              }
                            )
                          }
                        >
                          <div
                            className={`${"borrow-table-b-cell"} ${
                              styles.labelMRegular
                            } ${styles.primaryText}`}
                          >
                            <div className="borrow-table-pool-name">
                              <img
                                src={this.getAssetIcon(
                                  config.borrowMultiply[i].collateral.icon
                                )}
                                alt="collaretal-icon"
                                className="borrow-table-b-icon"
                              />
                              <div
                                className={`${styles.labelMRegular} ${styles.primaryText}`}
                              >
                                {config.borrowMultiply[i].collateral.sym}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${"borrow-table-b-cell"} ${
                              styles.labelMRegular
                            } ${styles.primaryText}`}
                          >
                            <div className="borrow-table-pool-assets">
                              {config.borrowMultiply[i].assets !== "" && (
                                <div>
                                  {Object.keys(
                                    config.borrowMultiply[i].assets
                                  ).map((k) => (
                                    <img
                                      src={this.getAssetIcon(
                                        config.borrowMultiply[i].assets[k].icon
                                      )}
                                      alt="collaretal-icon"
                                      className="borrow-table-s-icon"
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className={`${"borrow-table-b-cell"} ${
                              styles.labelMRegular
                            } ${styles.primaryText}`}
                          >
                            {Object.keys(this.state.leftToBorrow).includes(
                              i
                            ) ? (
                              <>
                                {this.nFormatter(this.state.leftToBorrow[i].available, 2)} out of{" "}
                                {this.nFormatter(this.state.leftToBorrow[i].total, 2)}
                              </>
                            ) : (
                              <div style={{width: "10%"}}>{this.renderLoading()}</div>
                            )}
                          </div>
                          <div
                            className={`${"borrow-table-s-cell"} ${
                              styles.labelMRegular
                            } ${styles.primaryText}`}
                          >
                            0.00%
                          </div>
                          <div
                            className={`${"borrow-table-s-cell"} ${
                              styles.labelMRegular
                            } ${styles.primaryText}`}
                            style={{ textAlign: "right" }}
                          >
                            {(config.borrowMultiply[i].apy * 100).toFixed(2)}%
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              ) : (
                <div className={styles.mobTableCardsSection}>
                  {Object.keys(config.borrowMultiply)
                    .filter((j) => {
                      return (
                        config.borrowMultiply[j].network ===
                        this.getBlockchain(this.props.onboard.networkId)
                      );
                    })
                    .map((i) => (
                      <div
                        className={`${styles.card} ${styles.whiteBg}`}
                        style={{ height: "auto" }}
                        onClick={() =>
                          this.toPage(
                            "/borrow",
                            config.borrowMultiply[i].address,
                            {
                              i,
                            }
                          )
                        }
                      >
                        <div className={styles.mobTableCardHeader}>
                          <div className={styles.mobTableCardHeaderLeftSection}>
                            <img
                              src={this.getAssetIcon(
                                config.borrowMultiply[i].collateral.icon
                              )}
                              alt="collaretal-icon"
                              className="borrow-table-b-icon"
                            />
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              {config.borrowMultiply[i].collateral.sym}
                            </div>
                          </div>
                          <div
                            className={styles.mobTableCardHeaderRightSection}
                          >
                            {config.borrowMultiply[i].assets !== "" && (
                              <div>
                                {Object.keys(
                                  config.borrowMultiply[i].assets
                                ).map((k) => (
                                  <img
                                    src={this.getAssetIcon(
                                      config.borrowMultiply[i].assets[k].icon
                                    )}
                                    alt="collaretal-icon"
                                    className="borrow-table-s-icon"
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.mobTableCardRow}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            USDR left to borrow
                          </div>
                          <div
                            className={`${styles.labelMBold} ${styles.primaryText}`}
                          >
                            {Object.keys(this.state.leftToBorrow).includes(
                              i
                            ) ? (
                              <>
                                {this.nFormatter(this.state.leftToBorrow[i].available, 2)} out of{" "}
                                {this.nFormatter(this.state.leftToBorrow[i].total, 2)}
                              </>
                            ) : (
                              this.renderLoading()
                            )}
                          </div>
                        </div>
                        <div className={styles.mobTableCardRow}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Interest
                          </div>
                          <div
                            className={`${styles.labelMBold} ${styles.primaryText}`}
                          >
                            0.00%
                          </div>
                        </div>
                        <div className={styles.mobTableCardRow}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            APY
                          </div>
                          <div
                            className={`${styles.labelMBold} ${styles.primaryText}`}
                          >
                            {(config.borrowMultiply[i].apy * 100).toFixed(2)}%
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <Footer {...this.props} state={this.state} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BorrowPage);
