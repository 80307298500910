import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import {
  getAPYForBond,
  getROIForBond,
  getTotalValueDepositedForBond,
  getUserBondInfo,
  redeemBond,
  getUserAllowanceForBond,
  approveForBond,
  depositForBond,
  estimateRadarRewardForLPAmount,
  getMaxLPAmountForBond,
  getUserLPAmountForBond,
  getVestingTimeForBond,
} from "../../web3/bonds/lib/dao";
import "../../utils/ethereum/onboard";
import {
  checkWallet,
  manualChangeNetwork,
} from "../../redux/actions/OnboardActions";
import config from "../../config/config.json";

import Header from "../../layouts/header/Header";
import Sidebar from "../../layouts/sidebar/Sidebar";
import Footer from "../../layouts/footer/Footer";
import Loader from "../../components/loader/Loader";

import WalletNotConnectedOverlay from "../../components/overlays/wallet-not-connected/WalletNotConnectedOverlay";
import SwitchNetworkOverlay from "../../components/overlays/switch-network/SwitchNetworkOverlay";
import LoaderOverlay from "../../components/overlays/loader/LoaderOverlay";

import GradientInterfaceIcon from "../../components/icons/GradientInterfaceIcon";
import CaretDownIcon from "../../components/icons/CaretDownIcon";
import CaretUpIcon from "../../components/icons/CaretUpIcon";
import InfoWarningIcon from "../../components/icons/InfoWarningIcon";
import CloseIcon from "../../components/icons/CloseIcon";
import styles from "../../styles/index.module.css";
import "./bondsPoolPage.css";

function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));

  var dDisplay = d > 0 ? d + (d == 1 ? " day" : " days") : "";
  return dDisplay;
}

class BondsPoolPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: true,
      backButtonRoute: "/bonds",
      displayInfoCards: true,
      loadingOverlay: false,
      stakeCard: false,

      i: "",

      TVD: "",
      TVDLoading: true,

      apy: 0,
      displayedApy: "",
      apyLoading: true,

      roi: {},
      roiLoading: true,

      currentBondInfo: {
        userHasActiveBond: false,
      },

      LPBalance: 0,
      LPBalanceLoading: false,

      allowance: 0,
      amount: "",
      amountInUSD: 0,
      amountInUSDLoading: false,

      slippage: 0.5,

      maxLPAmountToBuy: "",
      maxLPAmountToBuyLoading: false,

      fee: 0,
      radarFee: 0,

      maxAmountAlert: false,
      maxAmountToBuyAlert: false,

      vestingTerm: 0,
      vestingPeriodCountdown: false,

      enterAnAmountAlert: false,
    };
  }

  renderLoading() {
    return <Loader {...this.props} />;
  }

  setInfoCardsDisplayState = () => {
    if (this.state.displayInfoCards === true) {
      this.setState({
        displayInfoCards: false,
      });
    } else {
      this.setState({
        displayInfoCards: true,
      });
    }
  };

  getAssetIcon = (type) => {
    const icon = require(`../../assets/SVGs/assets/${type}`).default;
    return icon;
  };

  getBlockchain = (networkId) => {
    const networks = Object.keys(config.networks);
    for (var i = 0; i < networks.length; i++) {
      var currentNet = networks[i];
      if (config.networks[currentNet].networkId == networkId) return currentNet;
    }
  };

  getPoolAddress = () => {
    const curentPath = window.location.pathname;
    const currentPoolAddress = curentPath.split("/bonds/").pop();
    return currentPoolAddress;
  };

  getPool = () => {
    const pools = Object.keys(config.pools);
    for (var i = 0; i < pools.length; i++) {
      var currentPool = pools[i];
      if (config.pools[currentPool].address == this.getPoolAddress())
        return currentPool;
    }
  };

  switchToRightBlockchain = async () => {
    await this.props.manualChangeNetwork(
      config.networks[config.pools[this.getPool()].network].networkId
    );
  };

  getLPBalance = async () => {
    this.setState({
      LPBalanceLoading: true,
    });
    let LPBalance = await getUserLPAmountForBond(
      this.getBlockchain(this.props.onboard.networkId),
      this.getPool(),
      this.props.onboard.address
    );
    console.log("LP Balance: "+LPBalance)
    this.setState({
      LPBalance: parseFloat(LPBalance).toFixed(2),
      LPBalanceLoading: false,
    });
  };

  getAllowance = async () => {
    var bypassAllowance = false;
    let allowance = await getUserAllowanceForBond(
      this.getBlockchain(this.props.onboard.networkId),
      this.props.onboard.address,
      this.getPool()
    );

    if (allowance.length > 15) {
      bypassAllowance = true;
    }

    this.setState({
      allowance: parseInt(allowance),
      bypassAllowance: bypassAllowance,
    });
  };

  amountField = async (e) => {
    if (parseFloat(e.target.value) > parseFloat(this.state.LPBalance)) {
      this.setState({ maxAmountAlert: true });
    } else {
      this.setState({ maxAmountAlert: false });
    }

    if (parseFloat(e.target.value) > parseFloat(this.state.maxLPAmountToBuy)) {
      this.setState({ maxAmountToBuyAlert: true });
    } else {
      this.setState({ maxAmountToBuyAlert: false });
    }

    if (parseFloat(e.target.value) === 0) {
      this.setState({ enterAnAmountAlert: true });
    } else {
      this.setState({ enterAnAmountAlert: false });
    }

    const re = /^[0-9.\b]+$/;
    if (!re.test(e.target.value)) {
      if (e.target.value !== "") return;
    }

    var value = e.target.value;
    this.setState({ amount: value });

    this.setState({
      estimatedRewardLoading: true,
    });
    let estimatedReward = await estimateRadarRewardForLPAmount(
      this.getBlockchain(this.props.onboard.networkId),
      this.state.amount,
      this.getPool()
    );
    this.setState({ estimatedReward: parseFloat(estimatedReward).toFixed(2) });
    this.setState({
      estimatedRewardLoading: false,
    });
    this.getAmountInUSD();
  };

  getAmountInUSD = async () => {
    this.setState({
      amountInUSDLoading: true,
    });
    // IMI TREBUIE ASSER PRICE
    this.setState({
      amountInUSDLoading: false,
    });
  };

  getEstimatedReward = async () => {
    this.setState({
      estimatedRewardLoading: true,
    });
    let estimatedReward = await estimateRadarRewardForLPAmount(
      this.getBlockchain(this.props.onboard.networkId),
      this.state.amount,
      this.getPool()
    );
    this.setState({
      estimatedReward: parseFloat(estimatedReward).toFixed(2),
      estimatedRewardLoading: false,
    });
  };

  getMaxYouCanBuyFunc = async () => {
    this.setState({
      maxLPAmountToBuyLoading: true,
    });
    let maxLPAmountToBuy = await getMaxLPAmountForBond(
      this.getBlockchain(this.props.onboard.networkId),
      this.getPool()
    );
    this.setState({
      maxLPAmountToBuy: parseFloat(maxLPAmountToBuy).toFixed(2),
      maxLPAmountToBuyLoading: false,
    });
  };

  approve = async () => {
    this.setState({
      loadingOverlay: true,
    });
    try {
      await approveForBond(
        this.getBlockchain(this.props.onboard.networkId),
        this.getPool(),
        this.props.onboard.provider
      );
    } catch (e) {
      console.log(e);
      toastr.error("Error", "Error approving: " + e.message);
    }
    let allowance = await getUserAllowanceForBond(
      this.getBlockchain(this.props.onboard.networkId),
      this.props.onboard.address,
      this.getPool()
    );
    this.setState({
      allowance: allowance,
      loadingOverlay: false,
    });
  };

  deposit = async () => {
    if (
      (this.state.amount !== 0 &&
        this.state.maxYouCanBuy !== 0 &&
        this.state.maxAmountAlert === false) ||
      this.state.maxAmountToBuyAlert === false
    ) {
      await this.props.activateLoaderOverlay();
      try {
        await depositForBond(
          this.getBlockchain(this.props.onboard.networkId),
          this.getPool(),
          this.props.onboard.provider,
          this.props.onboard.address,
          this.state.amount,
          this.state.slippage,
          this.state.maxAmountClicked
        );
      } catch (e) {
        console.log(e);
        toastr.error("Error", "Error depositing: " + e.message);
      }
      await this.props.deactivateLoaderOverlay();
    } else {
      return;
    }
  };

  getVestingTermFunc = async () => {
    this.setState({
      vestingTermLoading: true,
    });
    let vestingTerm = await getVestingTimeForBond("BSC", this.props.state.pid);
    this.setState({
      vestingTerm: secondsToDhms(vestingTerm),
      vestingTermLoading: false,
    });
  };

  claim = async () => {
    this.setState({
      loadingOverlay: true,
    });
    try {
      await redeemBond(
        this.getBlockchain(this.props.onboard.networkId),
        this.getPool(),
        this.props.onboard.provider,
        false
      );
    } catch (e) {
      console.log(e);
      toastr.error("Error", "Error claiming: " + e.message);
    }
    this.setState({
      loadingOverlay: false,
    });
  };

  // claimAndAutostake = async () => {
  //   this.setState({
  //     loadingOverlay: true,
  //   });
  //   try {
  //     await redeemBond(
  //       this.getBlockchain(this.props.onboard.networkId),
  //       this.getPool(),
  //       this.props.onboard.provider,
  //       true
  //     );
  //   } catch (e) {
  //     console.log(e);
  //     toastr.error("Error", "Error claiming&autostaking: " + e.message);
  //   }
  //   this.setState({
  //     loadingOverlay: false,
  //   });
  // };

  async componentDidMount() {
    await this.getPoolMetadata();
    this.getCurrentBondInfo();
    this.getEstimatedReward();
    this.getAmountInUSD();
    this.getMaxYouCanBuyFunc();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.onboard.address != prevProps.onboard.address ||
      this.props.onboard.networkId != prevProps.onboard.networkId ||
      this.props.onboard.provider != prevProps.onboard.provider
    ) {
      await this.getPoolMetadata();
      this.getCurrentBondInfo();
      this.getEstimatedReward();
      this.getAmountInUSD();
      this.getMaxYouCanBuyFunc();
    }
  }

  async getPoolMetadata() {
    await this.setState({
      TVDLoading: true,
      apyLoading: true,
      roiLoading: true,
    });
    let totalValueDeposited = await getTotalValueDepositedForBond(
      this.getPool()
    );
    await this.setState({
      // TVD: isNaN(totalValueDeposited) ? 0 : parseInt(totalValueDeposited),
      TVD: isNaN(totalValueDeposited)
        ? 0
        : totalValueDeposited.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          }),
      TVDLoading: false,
    });

    var apyTemp = await getAPYForBond(
      config.pools[this.getPool()].network,
      this.getPool()
    );
    var apy = (parseFloat(apyTemp) * 100).toFixed(2);
    var displayedApy = apy.toLocaleString(apy, {
      maximumFractionDigits: 2,
    });
    await this.setState({
      apy: apy,
      displayedApy: displayedApy,
      apyLoading: false,
    });

    var roi = await getROIForBond(config.pools[this.getPool()].network, this.getPool());
    await this.setState({
      roi: roi*100,
      roiLoading: false
    });
  }

  async getUserData() {
    let currentBondInfo = await getUserBondInfo(
      this.getBlockchain(this.props.onboard.networkId),
      this.getPool(),
      this.props.onboard.address
    );
    this.setState({
      currentBondInfo: currentBondInfo,
    });
  }

  render() {
    const doNotDisplay = {
      display: "none",
    };

    const displayBlur = {
      filter: "blur(5px)",
      "-webkit-filter": "blur(4px)",
      height: "calc(100vh-72px)",
      overflow: "hidden",
    };

    const i = this.getPool();

    console.log("amount now: " + this.state.amount);

    return (
      <>
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Sidebar {...this.props} state={this.state} />
          {this.props.onboard.walletConnected === false ? (
            <WalletNotConnectedOverlay {...this.props} />
          ) : (
            <>
              {this.getBlockchain(this.props.onboard.networkId) !==
                config.pools[i].network && (
                <SwitchNetworkOverlay
                  {...this.props}
                  primaryTextFromParent={
                    "This bond is not available on " +
                    config.networks[
                      this.getBlockchain(this.props.onboard.networkId)
                    ].name
                  }
                  secondaryTextFromParent="Please switch your wallet to the following network"
                  blockchainsFromParent={[config.pools[i].network]}
                />
              )}
            </>
          )}
          {this.state.loadingOverlay === true && (
            <LoaderOverlay {...this.props} />
          )}
          <div
            className={styles.wrapper}
            style={
              this.props.onboard.walletConnected === false ||
              this.getBlockchain(this.props.onboard.networkId) !==
                config.pools[i].network ||
              this.state.loadingOverlay === true
                ? displayBlur
                : {}
            }
          >
            <div className={styles.content}>
              <div className={styles.headerSection}>
                {config.pools[i].asset2 !== "" ? (
                  <div className={`${styles.headingM} ${styles.primaryText}`}>
                    {config.pools[i].asset1.sym} - {config.pools[i].asset2.sym}
                  </div>
                ) : (
                  <div className={`${styles.headingM} ${styles.primaryText}`}>
                    {config.pools[i].asset1.sym}
                  </div>
                )}
                <div className="pool-top-info-layout">
                  <div className="pool-top-info-section">
                    <div className={`${styles.labelSRegular}`}>TVD</div>
                    {this.state.TVDLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"pool-top-info-value"}`}
                      >
                        ${this.state.TVD}
                      </div>
                    )}
                  </div>
                  <div className="pool-top-info-separator" />
                  <div className="pool-top-info-section">
                    <div className={`${styles.labelSRegular}`}>ROI</div>
                    {this.state.roiLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"pool-top-info-value"}`}
                      >
                        {parseFloat(this.state.roi).toFixed(2)}%
                      </div>
                    )}
                  </div>
                  <div className="pool-top-info-separator" />
                  <div className="pool-top-info-section">
                    <div className={`${styles.labelSRegular}`}>APY</div>
                    {this.state.apyLoading === true ? (
                      <>{this.renderLoading()}</>
                    ) : (
                      <div
                        className={`${
                          styles.labelSBold
                        } ${"pool-top-info-value"}`}
                      >
                        {this.state.displayedApy}%
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {window.innerWidth > 1299 ? (
                <div className={styles.layout}>
                  <div
                    className={styles.leftSection}
                    style={
                      this.state.displayInfoCards === false ? doNotDisplay : {}
                    }
                  >
                    <div className={styles.cardsRow}>
                      {this.state.currentBondInfo.userHasActiveBond ===
                      false ? (
                        /* Daca userul nu a depozitat in acest bond sau vesting period s-a terminat */
                        <div className={`${styles.card} ${styles.whiteBg}`}>
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Vesting period
                          </div>
                          <div className={styles.cardValueSection}>
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              -
                            </div>
                          </div>
                        </div>
                      ) : (
                        /* Daca userul a depozitat in acest bond si vesting period countdown este activ */
                        <div className={`${styles.card} ${styles.whiteBg}`}>
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Vesting period
                          </div>
                          <div className={styles.cardValueSection}>
                            {this.state.vestingTermLoading === true ? (
                              <>{this.renderLoading()}</>
                            ) : (
                              <div
                                className={`${styles.headingS} ${styles.primaryText}`}
                              >
                                {this.state.vestingTerm}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {this.state.currentBondInfo.userHasActiveBond ===
                      false ? (
                        /* Daca userul nu a depozitat in acest bond */
                        <div className={`${styles.card} ${styles.whiteBg}`}>
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Reward
                          </div>
                          <div className={styles.cardValueSection}>
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              0.00 RADAR
                            </div>
                          </div>
                        </div>
                      ) : (
                        /* Daca userul a depozitat in acest bond */
                        <div className={`${styles.card} ${styles.whiteBg}`}>
                          <div className="pool-page-reward-card-claim-section">
                            <div>
                              <div
                                className={`${styles.labelSRegular} ${styles.secondaryText}`}
                              >
                                Reward
                              </div>
                              <div className={styles.cardValueSection}>
                                <div
                                  className={`${styles.headingS} ${styles.primaryText}`}
                                >
                                  {parseInt(
                                    this.state.currentBondInfo.payout
                                  ).toFixed(2)}{" "}
                                  RADAR
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                className={`${styles.labelMBold} ${
                                  styles.gradientText
                                } ${"pool-page-reward-card-button"}`}
                                onClick={() => this.claim()}
                              >
                                Claim
                              </div>
                              {/* <div
                                className={`${styles.labelMRegular} ${
                                  styles.gradientText
                                } ${"pool-page-reward-card-button"}`}
                                onClick={() =>
                                  this.setState({
                                    stakeCard: true,
                                  })
                                }
                              >
                                Stake
                              </div> */}
                            </div>
                          </div>
                          {this.state.currentBondInfo.payout !== 0 && (
                            /* Daca userul are unclaimed rewards; daca nu are unclaimed rewards, bullet-ul nu apare */
                            <div
                              class={`${"pool-page-reward-bullet"} ${
                                styles.bg
                              } ${styles.primaryText} ${styles.labelMBold}`}
                            >
                              Unclaimed
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.rightSection}>
                    {this.state.stakeCard === true ? (
                      <div className={`${styles.inputCard} ${styles.whiteBg}`}>
                        <div className={styles.inputCardHeader}>
                          <div
                            className={styles.inputCardIcon}
                            onClick={() =>
                              this.setState({
                                stakeCard: false,
                              })
                            }
                          >
                            <CloseIcon />
                          </div>
                          <div
                            className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                          >
                            Stake
                          </div>
                        </div>
                        <div
                          className={`${styles.paragraphM} ${
                            styles.primaryText
                          } ${"pool-page-stake-card-header"}`}
                        >
                          Your reward will be deposited on staking
                        </div>
                        <div
                          className={`${styles.labelS} ${
                            styles.primaryText
                          } ${"pool-page-stake-card-amount"}`}
                        >
                          {parseInt(this.state.currentBondInfo.payout).toFixed(
                            2
                          )}{" "}
                          RADAR
                        </div>
                        {/* <div
                          className={`${styles.button} ${
                            styles.gradientBg
                          } ${"pool-page-stake-button"}`}
                        >
                          <div
                            className={`${styles.labelMRegular} ${
                              styles.whiteText
                            } ${"pool-page-stake-button-text"}`}
                            onClick={() => this.claimAndAutostake()}
                          >
                            Stake
                          </div>
                        </div> */}
                        <div
                          className={`${styles.paragraphS} ${
                            styles.primaryText
                          } ${"pool-page-stake-card-claim-info"}`}
                        >
                          If you want to withdraw the reward to your wallet,
                          click{" "}
                          <a
                            className={`${
                              styles.gradientText
                            } ${"pool-page-stake-card-claim-button"}`}
                            onClick={() => this.claim()}
                          >
                            Claim
                          </a>
                          .
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${styles.inputCard} ${styles.whiteBg}`}
                        style={{ borderRadius: "8px", borderTop: "none" }}
                      >
                        <div className={styles.inputCardHeader}>
                          <div
                            className={styles.inputCardIcon}
                            onClick={() => this.setInfoCardsDisplayState()}
                          >
                            <GradientInterfaceIcon />
                          </div>
                          <div
                            className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                          >
                            Buy bond
                          </div>
                        </div>
                        <div className={styles.inputHeader}>
                          <div
                            className={`${styles.labelSRegular} ${styles.primaryText}`}
                          >
                            Amount
                          </div>
                          <div
                            className={
                              parseFloat(this.state.LPBalance) === 0
                                ? styles.inputBalanceSection
                                : styles.inputBalanceSectionGrad
                            }
                            onClick={() =>
                              this.setState({
                                amount: this.state.LPBalance,
                              })
                            }
                          >
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              Balance
                            </div>
                            {this.state.LPBalanceLoading === true ? (
                              <>{this.renderLoading()}</>
                            ) : (
                              <div
                                className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
                              >
                                {parseFloat(this.state.LPBalance).toFixed(2)} LP
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.inputBox}>
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            placeholder="0.00"
                            value={this.state.amount}
                            onChange={(e) => this.amountField(e)}
                            style={{
                              fontSize: "21px",
                              lineHeight: "32px",
                            }}
                          />
                          {/* {this.state.amountInUSDLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              ${this.state.amountInUSD}
                            </div>
                          )} */}
                        </div>
                        {this.state.maxAmountToBuyAlert === true ? (
                          <div
                            className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                          >
                            Sold out
                          </div>
                        ) : (
                          <>
                            {this.state.maxAmountAlert === true ? (
                              <div
                                className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                              >
                                Insufficient balance
                              </div>
                            ) : (
                              <>
                                {this.state.amount === 0 ||
                                this.state.amount === "" ||
                                this.state.enterAnAmountAlert === true ? (
                                  <div
                                    className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                                  >
                                    Enter an amount
                                  </div>
                                ) : (
                                  <>
                                    {this.state.allowance <
                                    parseInt(this.state.amount) * 10 ** 18 ? (
                                      <div
                                        className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                                        onClick={() => this.approve()}
                                      >
                                        Approve
                                      </div>
                                    ) : (
                                      <>
                                        <div
                                          className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                                          onClick={() => this.deposit()}
                                        >
                                          Deposit
                                        </div>
                                        <div
                                          className={styles.orderDetailsSection}
                                        >
                                          <div
                                            className={`${styles.labelMBold} ${styles.primaryText}`}
                                          >
                                            Order details
                                          </div>
                                          <div
                                            className={
                                              styles.orderDetailsInfoBox
                                            }
                                          >
                                            <div
                                              className={
                                                styles.orderDetailsInfoIcon
                                              }
                                            >
                                              <InfoWarningIcon />
                                            </div>
                                            <div
                                              className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                            >
                                              After the vesting period, you will
                                              receive 6.00% more RADAR than the
                                              current value of the LP in RADAR.
                                            </div>
                                          </div>
                                          <div
                                            className={styles.orderDetailsRow}
                                          >
                                            <div
                                              className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                            >
                                              Vesting period
                                            </div>
                                            {this.state.vestingTermLoading ===
                                            true ? (
                                              <>{this.renderLoading()}</>
                                            ) : (
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                              >
                                                {this.state.vestingTerm}
                                              </div>
                                            )}
                                          </div>
                                          <div
                                            className={styles.orderDetailsRow}
                                          >
                                            <div
                                              className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                            >
                                              Max you can buy
                                            </div>
                                            {this.state
                                              .estimatedRewardLoading ===
                                            true ? (
                                              <>{this.renderLoading()}</>
                                            ) : (
                                              <>
                                                {this.state
                                                  .maxLPAmountToBuyLoading ===
                                                true ? (
                                                  <>{this.renderLoading()}</>
                                                ) : (
                                                  <div
                                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                                  >
                                                    {
                                                      this.state
                                                        .maxLPAmountToBuy
                                                    }
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                          <div
                                            className={styles.orderDetailsRow}
                                          >
                                            <div
                                              className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                            >
                                              Estimated reward
                                            </div>
                                            {this.state
                                              .estimatedRewardLoading ===
                                            true ? (
                                              <>{this.renderLoading()}</>
                                            ) : (
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                              >
                                                {this.state.estimatedReward}
                                              </div>
                                            )}
                                          </div>
                                          {/* <div
                                            className={styles.orderDetailsRow}
                                          >
                                            <div
                                              className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                            >
                                              Fees
                                            </div>
                                            <div
                                              className={
                                                styles.orderDetailsFeesRow
                                              }
                                            >
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                              >
                                                ${this.state.fee}
                                              </div>
                                              {this.state.showFees === true ? (
                                                <div
                                                  className={
                                                    styles.orderDetailsFeesIcon
                                                  }
                                                  onClick={() =>
                                                    this.setState({
                                                      showFees: false,
                                                    })
                                                  }
                                                >
                                                  <CaretUpIcon />
                                                </div>
                                              ) : (
                                                <div
                                                  className={
                                                    styles.orderDetailsFeesIcon
                                                  }
                                                  onClick={() =>
                                                    this.setState({
                                                      showFees: true,
                                                    })
                                                  }
                                                >
                                                  <CaretDownIcon />
                                                </div>
                                              )}
                                            </div>
                                          </div> */}
                                          {/* {this.state.showFees === true && (
                                            <>
                                              <div
                                                className={
                                                  styles.orderDetailsSubRow
                                                }
                                              >
                                                <div
                                                  className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                                >
                                                  Radar fee
                                                </div>
                                                <div
                                                  className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                                >
                                                  ${this.state.radarFee}
                                                </div>
                                              </div>
                                            </>
                                          )} */}
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.layout}>
                  <div className={styles.rightSection}>
                    {this.state.stakeCard === true ? (
                      <div className={`${styles.inputCard} ${styles.whiteBg}`}>
                        <div className={styles.inputCardHeader}>
                          <div
                            className={styles.inputCardIcon}
                            onClick={() =>
                              this.setState({
                                stakeCard: false,
                              })
                            }
                          >
                            <CloseIcon />
                          </div>
                          <div
                            className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                          >
                            Stake
                          </div>
                        </div>
                        <div
                          className={`${styles.paragraphM} ${
                            styles.primaryText
                          } ${"pool-page-stake-card-header"}`}
                        >
                          Your reward will be deposited on staking
                        </div>
                        <div
                          className={`${styles.labelS} ${
                            styles.primaryText
                          } ${"pool-page-stake-card-amount"}`}
                        >
                          {parseInt(this.state.currentBondInfo.payout).toFixed(
                            2
                          )}{" "}
                          RADAR
                        </div>
                        {/* <div
                          className={`${styles.button} ${
                            styles.gradientBg
                          } ${"pool-page-stake-button"}`}
                        >
                          <div
                            className={`${styles.labelMRegular} ${
                              styles.whiteText
                            } ${"pool-page-stake-button-text"}`}
                            onClick={() => this.claimAndAutostake()}
                          >
                            Stake
                          </div>
                        </div> */}
                        <div
                          className={`${styles.paragraphS} ${
                            styles.primaryText
                          } ${"pool-page-stake-card-claim-info"}`}
                        >
                          If you want to withdraw the reward to your wallet,
                          click{" "}
                          <a
                            className={`${
                              styles.gradientText
                            } ${"pool-page-stake-card-claim-button"}`}
                            onClick={() => this.claim()}
                          >
                            Claim
                          </a>
                          .
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${styles.inputCard} ${styles.whiteBg}`}
                        style={{ borderRadius: "8px", borderTop: "none" }}
                      >
                        <div className={styles.inputCardHeader}>
                          <div
                            className={styles.inputCardIcon}
                            onClick={() => this.setInfoCardsDisplayState()}
                          >
                            <GradientInterfaceIcon />
                          </div>
                          <div
                            className={`${styles.paragraphM} ${styles.primaryText} ${styles.inputCardTitle}`}
                          >
                            Buy bond
                          </div>
                        </div>
                        <div className={styles.inputHeader}>
                          <div
                            className={`${styles.labelSRegular} ${styles.primaryText}`}
                          >
                            Amount
                          </div>
                          <div
                            className={
                              parseFloat(this.state.LPBalance) === 0
                                ? styles.inputBalanceSection
                                : styles.inputBalanceSectionGrad
                            }
                            onClick={() =>
                              this.setState({
                                amount: this.state.LPBalance,
                              })
                            }
                          >
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              Balance
                            </div>
                            {this.state.LPBalanceLoading === true ? (
                              <>{this.renderLoading()}</>
                            ) : (
                              <div
                                className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.inputBalance}`}
                              >
                                {parseFloat(this.state.LPBalance).toFixed(2)} LP
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.inputBox}>
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            placeholder="0.00"
                            value={this.state.amount}
                            onChange={(e) => this.amountField(e)}
                            style={{
                              fontSize: "21px",
                              lineHeight: "32px",
                            }}
                          />
                          {/* {this.state.amountInUSDLoading === true ? (
                            <>{this.renderLoading()}</>
                          ) : (
                            <div
                              className={`${styles.labelSRegular} ${styles.secondaryText}`}
                            >
                              ${this.state.amountInUSD}
                            </div>
                          )} */}
                        </div>
                        {this.state.maxAmountToBuyAlert === true ? (
                          <div
                            className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                          >
                            Sold out
                          </div>
                        ) : (
                          <>
                            {this.state.maxAmountAlert === true ? (
                              <div
                                className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                              >
                                Insufficient balance
                              </div>
                            ) : (
                              <>
                                {this.state.amount === 0 ||
                                this.state.amount === "" ||
                                this.state.enterAnAmountAlert === true ? (
                                  <div
                                    className={`${styles.button} ${styles.greyBg} ${styles.labelMBold} ${styles.disabledText} ${styles.inputButton}`}
                                  >
                                    Enter an amount
                                  </div>
                                ) : (
                                  <>
                                    {this.state.allowance <
                                    parseInt(this.state.amount) * 10 ** 18 ? (
                                      <div
                                        className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                                        onClick={() => this.approve()}
                                      >
                                        Approve
                                      </div>
                                    ) : (
                                      <>
                                        <div
                                          className={`${styles.button} ${styles.gradientBg} ${styles.labelMBold} ${styles.whiteText} ${styles.inputButton}`}
                                          onClick={() => this.deposit()}
                                        >
                                          Deposit
                                        </div>
                                        <div
                                          className={styles.orderDetailsSection}
                                        >
                                          <div
                                            className={`${styles.labelMBold} ${styles.primaryText}`}
                                          >
                                            Order details
                                          </div>
                                          <div
                                            className={
                                              styles.orderDetailsInfoBox
                                            }
                                          >
                                            <div
                                              className={
                                                styles.orderDetailsInfoIcon
                                              }
                                            >
                                              <InfoWarningIcon />
                                            </div>
                                            <div
                                              className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                            >
                                              After the vesting period, you will
                                              receive 6.00% more RADAR than the
                                              current value of the LP in RADAR.
                                            </div>
                                          </div>
                                          <div
                                            className={styles.orderDetailsRow}
                                          >
                                            <div
                                              className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                            >
                                              Vesting period
                                            </div>
                                            {this.state.vestingTermLoading ===
                                            true ? (
                                              <>{this.renderLoading()}</>
                                            ) : (
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                              >
                                                {this.state.vestingTerm}
                                              </div>
                                            )}
                                          </div>
                                          <div
                                            className={styles.orderDetailsRow}
                                          >
                                            <div
                                              className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                            >
                                              Max you can buy
                                            </div>
                                            {this.state
                                              .estimatedRewardLoading ===
                                            true ? (
                                              <>{this.renderLoading()}</>
                                            ) : (
                                              <>
                                                {this.state
                                                  .maxLPAmountToBuyLoading ===
                                                true ? (
                                                  <>{this.renderLoading()}</>
                                                ) : (
                                                  <div
                                                    className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                                  >
                                                    {
                                                      this.state
                                                        .maxLPAmountToBuy
                                                    }
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                          <div
                                            className={styles.orderDetailsRow}
                                          >
                                            <div
                                              className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                            >
                                              Estimated reward
                                            </div>
                                            {this.state
                                              .estimatedRewardLoading ===
                                            true ? (
                                              <>{this.renderLoading()}</>
                                            ) : (
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                              >
                                                {this.state.estimatedReward}
                                              </div>
                                            )}
                                          </div>
                                          {/* <div
                                            className={styles.orderDetailsRow}
                                          >
                                            <div
                                              className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                            >
                                              Fees
                                            </div>
                                            <div
                                              className={
                                                styles.orderDetailsFeesRow
                                              }
                                            >
                                              <div
                                                className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                              >
                                                ${this.state.fee}
                                              </div>
                                              {this.state.showFees === true ? (
                                                <div
                                                  className={
                                                    styles.orderDetailsFeesIcon
                                                  }
                                                  onClick={() =>
                                                    this.setState({
                                                      showFees: false,
                                                    })
                                                  }
                                                >
                                                  <CaretUpIcon />
                                                </div>
                                              ) : (
                                                <div
                                                  className={
                                                    styles.orderDetailsFeesIcon
                                                  }
                                                  onClick={() =>
                                                    this.setState({
                                                      showFees: true,
                                                    })
                                                  }
                                                >
                                                  <CaretDownIcon />
                                                </div>
                                              )}
                                            </div>
                                          </div> */}
                                          {/* {this.state.showFees === true && (
                                            <>
                                              <div
                                                className={
                                                  styles.orderDetailsSubRow
                                                }
                                              >
                                                <div
                                                  className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                                                >
                                                  Radar fee
                                                </div>
                                                <div
                                                  className={`${styles.labelXSRegular} ${styles.primaryText}`}
                                                >
                                                  ${this.state.radarFee}
                                                </div>
                                              </div>
                                            </>
                                          )} */}
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    className={styles.leftSection}
                    style={
                      this.state.displayInfoCards === false ? doNotDisplay : {}
                    }
                  >
                    <div className={styles.cardsRow}>
                      {this.state.currentBondInfo.userHasActiveBond ===
                      false ? (
                        /* Daca userul nu a depozitat in acest bond sau vesting period s-a terminat */
                        <div className={`${styles.card} ${styles.whiteBg}`}>
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Vesting period
                          </div>
                          <div className={styles.cardValueSection}>
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              -
                            </div>
                          </div>
                        </div>
                      ) : (
                        /* Daca userul a depozitat in acest bond si vesting period countdown este activ */
                        <div className={`${styles.card} ${styles.whiteBg}`}>
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Vesting period
                          </div>
                          <div className={styles.cardValueSection}>
                            {this.state.vestingTermLoading === true ? (
                              <>{this.renderLoading()}</>
                            ) : (
                              <div
                                className={`${styles.headingS} ${styles.primaryText}`}
                              >
                                {this.state.vestingTerm}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {this.state.currentBondInfo.userHasActiveBond ===
                      false ? (
                        /* Daca userul nu a depozitat in acest bond */
                        <div className={`${styles.card} ${styles.whiteBg}`}>
                          <div
                            className={`${styles.labelSRegular} ${styles.secondaryText}`}
                          >
                            Reward
                          </div>
                          <div className={styles.cardValueSection}>
                            <div
                              className={`${styles.headingS} ${styles.primaryText}`}
                            >
                              0.00 RADAR
                            </div>
                          </div>
                        </div>
                      ) : (
                        /* Daca userul a depozitat in acest bond */
                        <div className={`${styles.card} ${styles.whiteBg}`}>
                          <div className="pool-page-reward-card-claim-section">
                            <div>
                              <div
                                className={`${styles.labelSRegular} ${styles.secondaryText}`}
                              >
                                Reward
                              </div>
                              <div className={styles.cardValueSection}>
                                <div
                                  className={`${styles.headingS} ${styles.primaryText}`}
                                >
                                  {parseInt(
                                    this.state.currentBondInfo.payout
                                  ).toFixed(2)}{" "}
                                  RADAR
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                className={`${styles.labelMBold} ${
                                  styles.gradientText
                                } ${"pool-page-reward-card-button"}`}
                                onClick={() => this.claim()}
                              >
                                Claim
                              </div>
                              {/* <div
                                className={`${styles.labelMRegular} ${
                                  styles.gradientText
                                } ${"pool-page-reward-card-button"}`}
                                onClick={() =>
                                  this.setState({
                                    stakeCard: true,
                                  })
                                }
                              >
                                Stake
                              </div> */}
                            </div>
                          </div>
                          {this.state.currentBondInfo.payout !== 0 && (
                            /* Daca userul are unclaimed rewards; daca nu are unclaimed rewards, bullet-ul nu apare */
                            <div
                              class={`${"pool-page-reward-bullet"} ${
                                styles.bg
                              } ${styles.primaryText} ${styles.labelMBold}`}
                            >
                              Unclaimed
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer {...this.props} state={this.state} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  checkWallet,
  manualChangeNetwork,
};

export default connect(mapStateToProps, mapDispatchToProps)(BondsPoolPage);
