import React, { Component } from "react";
import { connect } from "react-redux";

import {
  connectAccountOnboard,
  checkWallet,
  walletPersistance,
  manualChangeNetwork,
} from "../../../redux/actions/OnboardActions";

import uniswapIcon from "../../../assets/SVGs/exchanges/uniswap.svg";
import pancakeswapIcon from "../../../assets/SVGs/exchanges/pancakeswap.svg";
import bitmartIcon from "../../../assets/SVGs/exchanges/bitmart.svg";
import radarIcon from "../../../assets/SVGs/assets/radar.svg";
import bnbIcon from "../../../assets/SVGs/assets/bnb.svg";
import busdIcon from "../../../assets/SVGs/assets/busd.svg";
import ethIcon from "../../../assets/SVGs/assets/eth.svg";
import styles from "../../../styles/index.module.css";
import "./buyRadarOverlay.css";

class BuyRadarOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backButton: false,
      backButtonRoute: "",
    };
  }

  closeBuyRadarOverlay = () => {
    this.props.closeBuyRadarOverlayEvent();
  };

  render() {
    return (
      <div className={styles.popupWrapper}>
        <div
          className="buy-radar-overlay-close-wrapper"
          onClick={() => this.closeBuyRadarOverlay()}
        />
        <div className="buy-radar-overlay-content">
          <div className={`${styles.headingL} ${styles.primaryText}`}>
            Buy RADAR
          </div>
          <div
            className={`${styles.paragraphM} ${styles.secondaryText}`}
            style={{ marginTop: "16px", marginBottom: "32px" }}
          >
            Buy radar from your favorite exchange
          </div>
          <a
            href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8&chain=mainnet"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${"buy-radar-link-box"} ${styles.whiteBg} ${
                styles.elevationLow
              }`}
            >
              <div className="buy-radar-link-box-left-section">
                <img
                  src={uniswapIcon}
                  alt="uniswap-icon"
                  className="buy-radar-link-icon"
                />
                <div className={`${styles.labelMBold} ${styles.primaryText}`}>
                  Uniswap
                </div>
              </div>
              <div className="buy-radar-link-box-right-section">
                <img
                  src={radarIcon}
                  alt="radar-icon"
                  className="buy-radar-link-icon"
                />
                <img
                  src={ethIcon}
                  alt="eth-icon"
                  className="buy-asset-link-icon"
                />
              </div>
            </div>
          </a>
          <a
            href="https://pancakeswap.finance/swap?inputCurrency=wbnb&outputCurrency=0xf03a2dc374d494fbe894563fe22ee544d826aa50"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${"buy-radar-link-box"} ${styles.whiteBg} ${
                styles.elevationLow
              }`}
            >
              <div className="buy-radar-link-box-left-section">
                <img
                  src={pancakeswapIcon}
                  alt="pancake-icon"
                  className="buy-radar-link-icon"
                />
                <div className={`${styles.labelMBold} ${styles.primaryText}`}>
                  Pancakeswap
                </div>
              </div>
              <div className="buy-radar-link-box-right-section">
                <img
                  src={radarIcon}
                  alt="radar-icon"
                  className="buy-radar-link-icon"
                />
                <img
                  src={bnbIcon}
                  alt="bnb-icon"
                  className="buy-asset-link-icon"
                />
              </div>
            </div>
          </a>
          <a
            href="https://pancakeswap.finance/swap?inputCurrency=busd&outputCurrency=0xf03a2dc374d494fbe894563fe22ee544d826aa50"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${"buy-radar-link-box"} ${styles.whiteBg} ${
                styles.elevationLow
              }`}
            >
              <div className="buy-radar-link-box-left-section">
                <img
                  src={pancakeswapIcon}
                  alt="pancake-icon"
                  className="buy-radar-link-icon"
                />
                <div className={`${styles.labelMBold} ${styles.primaryText}`}>
                  Pancakeswap
                </div>
              </div>
              <div className="buy-radar-link-box-right-section">
                <img
                  src={radarIcon}
                  alt="radar-icon"
                  className="buy-radar-link-icon"
                />
                <img
                  src={busdIcon}
                  alt="busd-icon"
                  className="buy-asset-link-icon"
                />
              </div>
            </div>
          </a>
          <a
            href="https://www.bitmart.com/trade/en?layout=basic&symbol=RADAR_USDT"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${"buy-radar-link-box"} ${styles.whiteBg} ${
                styles.elevationLow
              }`}
            >
              <div className="buy-radar-link-box-left-section">
                <img
                  src={bitmartIcon}
                  alt="bitmart-icon"
                  className="buy-radar-link-icon"
                />
                <div className={`${styles.labelMBold} ${styles.primaryText}`}>
                  Bitmart
                </div>
              </div>
            </div>
          </a>
          <div
            className={`${styles.labelMBold} ${styles.gradientText}`}
            style={{ marginTop: "12px", cursor: "pointer" }}
            onClick={() => this.closeBuyRadarOverlay()}
          >
            Close
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.connect,
    onboard: state.onboard,
  };
};

const mapDispatchToProps = {
  connectAccountOnboard,
  checkWallet,
  walletPersistance,
  manualChangeNetwork,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyRadarOverlay);
